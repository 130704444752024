<template>
  <b-modal :id="id" :ref="ref1" centered :title="title">
    <div>
      <slot name="body"/>
    </div>
    <div slot="modal-footer">
      <slot name="footer"/>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from "../main.js";
export default {
  props: ["id", "title", "ref1"]
};
</script>

<style>
</style>
