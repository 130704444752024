import Vue from "vue";
import Router from "vue-router";
import jobLanding from "./views/JobLanding.vue";
import globalTags from "./views/GlobalTags.vue";
import trainingFileUpload from "./views/TrainingFileListing.vue";
import jobSetup from "./views/JobSetup.vue";
import globalFileUpload from "./views/GlobalFileListing.vue";
import jobValidation from "./views/JobValidation.vue";
import jobInputFileUpload from "./views/FileUpload.vue";
import Login from "./views/Login.vue";
import store from "./store";
import createUser from "./views/CreateUser.vue";
import resetPassword from "./views/ResetPassword.vue";
import globalSettings from "./views/GlobalSettings.vue";
import shareAccess from "./views/ShareAccess.vue";
import TrainingFileEdit from "./views/TrainingFileEdit.vue";
import { ADMIN } from "./assets/js/constant";
import FileShareAccess from "./views/FileShareAccess.vue";
import { checkIfUserSessionIsTimedOut } from "./utilbin/validation";
import classificationTraining from "./views/TrainingFileEditClassification.vue";
import Publish from "./views/Publish";
import home from "./views/home";
import Log from "./views/Log";
import Report from "./views/Report";

Vue.use(Router);

const beforeEnterToThisRoutePerformThis = (to, from, next) => {
  if (
    !checkIfUserSessionIsTimedOut(
      store.getters.getUserObj.sessionID,
      Date.now()
    ) &&
    store.getters.isLoggedIn
  ) {
    // console.log("Inside If");
    next();
  } else if (!store.getters.isLoggedIn) {
    next("/");
  } else {
    // console.log("Inside Else");
    store.dispatch("logout").then(() => next("/"));
  }
};

const middlewareFunctionForLoginPage = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
  } else {
    next("/job-landing-view");
  }
};

export default new Router({
  //base: '/nexus',
  mode: "history",
  routes: [
    {
      path: "/job-landing-view",
      name: "jobLanding",
      component: jobLanding,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/global-file-upload",
      name: "globalFileUpload",
      component: globalFileUpload,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/training-file-upload",
      name: "trainingFileUpload",
      component: trainingFileUpload,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/job-setup",
      name: "jobSetupView",
      component: jobSetup,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/job-setup/:jobId",
      name: "jobSetupEdit",
      component: jobSetup,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/job-validate/:columnparamId/:jobId",
      name: "jobValidation",
      component: jobValidation,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/global-tags/:filename",
      name: "globalTags",
      component: globalTags,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/job-inputfile-upload",
      name: "jobInputFileUpload",
      component: jobInputFileUpload,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/",
      name: "login",
      component: Login,
      beforeEnter: middlewareFunctionForLoginPage
    },
    {
      path: "/create-user",
      name: "createUser",
      component: createUser,
      beforeEnter: (to, from, next) => {
        if (
          (store.getters.isLoggedIn &&
            store.getters.getUserObj.roles[0] === ADMIN &&
            !checkIfUserSessionIsTimedOut(
              store.getters.getUserObj.sessionID,
              Date.now()
            )) ||
          (store.getters.getUserObj.roles[0] === "1" &&
            !checkIfUserSessionIsTimedOut(
              store.getters.getUserObj.sessionID,
              Date.now()
            ))
        ) {
          next();
        } else {
          if (store.getters.isLoggedIn) {
            next("/job-landing-view");
          } else {
            next("/");
          }
        }
      }
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: resetPassword,
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/global-settings",
      name: "save-publish-settings",
      component: globalSettings,
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/share-access/:jobId",
      name: "shareAccess",
      component: shareAccess,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/hierarchial-learning-tags/:filename",
      name: "HierarchicalTrainingEdit",
      component: TrainingFileEdit,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/file-share-access/:fileId",
      name: "fileShareAccess",
      component: FileShareAccess,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/clasification-training/:filename",
      name: "classificationTraining",
      component: classificationTraining,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/publish/:jobId",
      name: "publish",
      component: Publish,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/home/*",
      name: "home",
      component: home
    },
    {
      path: "/logs",
      name: "Logs",
      component: Log,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    },
    {
      path: "/reports",
      name: "Reports",
      component: Report,
      meta: {
        requiresAuth: true
      },
      beforeEnter: beforeEnterToThisRoutePerformThis
    }
  ]
});
