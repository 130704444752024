<template>
  <div>
    <b-modal
      ref="editUserDetailsModal"
      id="editUserDetailModalID"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <modal-alert></modal-alert>
        <button
          type="button"
          class="close"
          @click="onEditModalCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5
          class="modal-title"
          id="editUserTitle"
        >Update detail for user - {{userDetailToChange.email}}</h5>
      </div>

      <div class="modal-body create-job-body">
        <div class="row">
          <!-- <div class="col-lg-6 form-group floating-label">
            <input
              type="text"
              pattern="[A-Za-z\s]{5,}"
              v-model="userDetailToChange.name"
              required
              class="form-control floating-input"
              autocomplete="off"
            />
            <label for>Name</label>
          </div> -->
          <label class="col-lg-12">Roles</label>
          <div
            class="radio-group"
            v-for="(userRole, index) in userRoles"
            :key="index"
          >
            <input
              name="role"
              type="radio"
              v-model="userDetailToChange.roles"
              :value="[userRole.key]"
              :id="'id_'+index"
            />
            <label
              class="radio-label"
              :for="'id_'+index"
            >{{ userRole.value }}</label>
          </div>
          <label class="col-lg-12">Active Status</label>
          <div class="radio-group">
            <input
              name="active"
              type="radio"
              v-model="userDetailToChange.active"
              :value="0"
              :id="'inactive'"
            />
            <label
              class="radio-label"
              :for="'inactive'"
            >INACTIVE</label>
            <input
              name="active"
              type="radio"
              v-model="userDetailToChange.active"
              :value="1"
              :id="'active'"
            />
            <label
              class="radio-label"
              :for="'active'"
            >ACTIVE</label>
          </div>
        </div>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="updateUserDetails()"
        >Update</button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="onEditModalCancel()"
        >Cancel</button>
      </div>
    </b-modal>
    <!-- delete -->
    <!-- <b-modal ref="deleteUserModal" id="deleteUserModalID" centered title="Bootstrap-Vue">
      <div slot="modal-header" style="width:100%">
        <modal-alert></modal-alert>
        <button type="button" class="close" @click="onDeleteModalCancel()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="deleteUserTitle">Delete User</h5>
      </div>
      <div class="modal-body create-job-body">
        <p>Remove {{userToDelete.email}} from users &#63;</p>
      </div>
      <div slot="modal-footer">
        <button type="button" class="btn btn-primary" @click="deleteUserDetails()">Delete</button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="onDeleteModalCancel()"
        >Cancel</button> 
      </div>
    </b-modal>-->

    <div style="float:right; display:inline-flex;">
      <vue-lpage
        :data="allUsersList"
        :page="currentPage"
        :results-per-page="perPage"
      >
        <div
          slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
          class="pagination-item"
        >
          <input
            class="form-control"
            :min="1"
            :max="Math.ceil(totalRows/perPage)"
            type="text"
            v-model="currentPage"
          />
          of {{ Math.ceil(totalRows/perPage) }}
          <a
            href="javascript:void(0);"
            :disabled="isFirstPage"
            @click="(currentPage>1) ? currentPage-- : currentPage=1"
          >
            <i class="icon24 icon-leftArrow"></i>
          </a>
          <a
            href="javascript:void(0);"
            :disabled="isLastPage"
            @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
          >
            <i class="icon24 icon-rightArrow"></i>
          </a>
        </div>
      </vue-lpage>
    </div>
    <b-table
      class="joblist-table"
      show-empty
      :items="allUsersList"
      :fields="fields"
      :current-page="Number.parseInt(currentPage)"
      :per-page="perPage"
    >
      <template v-slot:cell(roles)="row">{{getRoleValue(row.value)}}</template>
      <template v-slot:cell(active)="row">{{row.value==1}}</template>
      <template v-slot:cell(actions)="row">
        <ul
          class="action-list"
          @click="$event.stopPropagation();"
        >
          <template>
            <li>
              <a
                href="javascript:void(0);"
                @click="showEditModal(row.item)"
              >
                <i
                  class="icon24 edit-icon"
                  title="Edit Details"
                  v-b-tooltip.hover
                ></i>
              </a>
            </li>
          </template>
        </ul>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueLpage from "vue-lpage";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import {
  fetchAllUserList,
  updateUser,
  getUserRoles,
  deleteUser
} from "./../services/parentservices.js";
import Alert from "../components/Alert.vue";
const nameRegex = /^[a-zA-Z ]{5,20}$/;
export default {
  components: {
    VueLpage,
    "modal-alert": Alert
  },
  data() {
    return {
      allUsersList: [],
      fields: [],
      currentPage: 1,
      perPage: 6,
      totalRows: 0,
      userDetailToChange: {},
      initialUserDetail: {},
      userRoles: []
      // userToDelete: {}
      // typeErrorMessage: ""
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
    //   formattedItems () {
    //   if (!this.allUsersList) return []
    //   return this.allUsersList.map(item => {
    //     item._rowVariant  = this.getVariant(item.active)
    //     return item
    //   })
    // }
  },
  created: function() {
    this.fecthAllUsers();
    this.getAllUserRoles();
  },
  methods: {
    fecthAllUsers() {
      fetchAllUserList({
        userObj: this.userDetails,
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError) {
            this.allUsersList = response._result;
            this.totalRows = this.allUsersList.length;
            for (let fieldName of Object.keys(this.allUsersList[0])) {
              if (fieldName != "_id") {
                this.fields.push({ key: fieldName, sortable: true });
              }
            }
            this.fields.push({ key: "actions", label: "" });
          }
        })
        .catch(e => {});
    },
    showEditModal(param) {
      this.$refs.editUserDetailsModal.show();
      this.userDetailToChange = { ...param };
      this.initialUserDetail = { ...param };
    },
    onEditModalCancel() {
      this.$refs.editUserDetailsModal.hide();
    },
    updateUserDetails() {
      // this.userDetailToChange.name = this.userDetailToChange.name.trim();
      if (
        this.compareIfSameObjects(
          this.userDetailToChange,
          this.initialUserDetail
        )
      ) {
        this.$refs.editUserDetailsModal.hide();
        return;
      }
      let removeFromNotification;
      if (this.initialUserDetail.active == 1) {
        removeFromNotification =
          this.userDetailToChange.active == 0 ? true : false;
      }
      // if (!nameRegex.test(this.userDetailToChange.name)) {
      //   errorEventNotify(
      //     "Name should only contain letters and length of 5-20",
      //     2
      //   );
      //   return;
      // }
      updateUser({
        removeUserFromNotification: removeFromNotification,
        userObj: this.userDetailToChange,
        userId: this.userDetails._id
      })
        .then(response => {
          if (response._isError) {
            errorEventNotify(
              "There was some Technical Issue, Please try again.",
              2
            );
          } else {
            successEventNotify("User Detail Updated", 1);
            this.fecthAllUsers();
          }
        })
        .catch(e => {})
        .finally(() => {
          this.$refs.editUserDetailsModal.hide();
        });
    },
    getAllUserRoles() {
      getUserRoles().then(response => {
        this.userRoles = response._result;
      });
    },
    getRoleValue(roleKey) {
      for (let item of this.userRoles) {
        if (item.key == roleKey) {
          return item.value;
        }
      }
    },
    compareIfSameObjects(newObject, initialObject) {
      let sameCount = Object.keys(newObject).length;
      for (let key of Object.keys(newObject)) {
        if (newObject[key] == initialObject[key]) {
          sameCount--;
        }
      }
      if (sameCount <= 0) return true;
      else return false;
    }
    // getVariant (status) {
    // switch (status) {
    //   case 0:
    //     return 'warning'
    //   default:
    //     return 'info'
    // }
    // },

    // showDeleteModal(param) {
    //   console.log(JSON.stringify(param));
    //   this.$refs.deleteUserModal.show();
    //   this.userToDelete = { ...param };
    // },
    // onDeleteModalCancel() {
    //   this.$refs.deleteUserModal.hide();
    // },
    // deleteUserDetails() {
    //   console.log(this.userToDelete.email);
    //   deleteUser({
    //     email: this.userToDelete.email,
    //     userId: this.userDetails._id
    //   })
    //     .then(response => {
    //       if (response._isError) {
    //         errorEventNotify(
    //           "There was some Technical Issue, Please try again.",
    //           1
    //         );
    //       } else {
    //         console.log("success");
    //         successEventNotify(response._result.msg, 1);
    //         this.fecthAllUsers();
    //       }
    //     })
    //     .catch(e => {})
    //     .finally(() => {
    //       this.$refs.deleteUserModal.hide();
    //     });
    // },
    // showJobDeleteModalFn(job) {
    //   this.jobListActionModal.isActive = true;
    //   this.jobListActionModal.actionType = PAGELIST_ACTION_ITEMS.deletion;
    //   this.jobListActionModal.actionPayload = job;
    // },
  }
};
</script>
<style scoped>
.edit-icon {
  background-position: -10px -62px;
}

input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.radio-label {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 20px;
  margin: 0px;
  font-size: 12px;
}

input[type="radio"]:checked + label {
  color: #fff;
  background: #0090c4;
}

label + input[type="radio"] + label {
  border-left: solid 3px lightgray;
}
.radio-group {
  border: solid 3px lightgray;
  display: inline-block;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #bcbcbd;
}
.inActiveUser {
  background: grey;
}
/* .change-status-icon {
  background-position: -128px -62px;
} */
</style>