<template>
  <div>
    <!-- <page-alert></page-alert> -->
    <!-- <div>
      <b-alert
        :show="showErrorMsg"
        dismissible
        variant="danger"
        @dismissed="showErrorMsg=false"
      >{{this.alertMsg}}</b-alert>
    </div> -->
    <b-form
      class="col-lg-6 pad-top"
      @submit.prevent="update"
    >
      <h3> Select Default Login Mode: </h3>
      <br>
      <ul style="display:inline-block">
        <li
          style="padding-bottom: 15px;"
          v-for="data in loginModeList"
          :key="data.mode"
        >
          <div class="logincheckbox checkbox">
            <!-- font-size: 12px; -->
            <!-- class="checkbox"
            style="display:inline-block;height: 10px;
  width: 10px;" -->

            <!-- <div class="algocheckbox checkbox">
                              <input
                                type="checkbox"
                                v-model="algo.isSelectedExecute"
                              >
                              <span class="checkmark"></span>
                            </div>-->

            <!-- class="checkboxLabel" -->

            <input
              type="checkbox"
              :checked="data.selected"
              v-model="data.selected"
            /> {{data.mode.charAt(0).toUpperCase()+data.mode.slice(1)}} Login
            <span class="checkmark"></span>
          </div>
        </li>
      </ul>

      <b-button
        style="display:block"
        type="submit"
        class="btn-submit"
        variant="primary"
      >Update</b-button>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  fetchLoginModes,
  updateLoginModes
} from "./../services/parentservices.js";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
export default {
  components: {},
  data() {
    return {
      // userData: {
      //   name: "",
      //   email: "",
      //   type: "internal",
      //   role: ""
      // },
      loginModeList: []
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  created() {
    this.getLoginModes();
  },
  methods: {
    getLoginModes() {
      fetchLoginModes()
        .then(response => {
          if (response._isError) {
            errorEventNotify(
              "There was some technical issue while fetching login option.",
              1
            );
          } else {
            this.loginModeList = response._result;
          }
        })
        .catch(e => {
          errorEventNotify(
            "There was some technical issue while fetching login option.",
            1
          );
        })
        .finally(() => {});
    },
    update() {
      if (this.checkIfSelected()) {
        updateLoginModes({
          loginModeList: this.loginModeList,
          userId: this.userDetails._id
        })
          .then(response => {
            if (response._isError) {
            } else {
              successEventNotify("Login Mode successfully updated!!", 1);
            }
          })
          .catch(e => {
            errorEventNotify("Some error while performing update", 1);
          });
      } else {
        errorEventNotify("Select at least one mode of Login", 1);
        // return;
      }
    },
    checkIfSelected() {
      let selected = false;
      for (let obj of this.loginModeList) {
        if (obj.selected) {
          selected = true;
          break;
        }
      }
      return selected;
    }
  }
};
</script>
<style scoped>
.logincheckbox {
  padding-left: 30px;
}
</style>
