export const publishData = {
  settingsName: "",
  schemaName: "",
  publishIP: "",
  publishUsername: "",
  publishPassword: "",
  publishPortNo: "",
  publishFileType: "",
  publishFilePath: "",
  publishTimeStamp: "",
  selectedDBVendor: "",
  fileOrDbName: "",
  selectedPublishSettings: { text: "", value: "" },
  azureStorageAccountName: "",
  azureStorageAccountKeyAccess: "",
  azureStorageContainerName: "",
  snowflakeAccountName: "",
  snowflakeSchema: "",
  snowflakeDb: "",
  snowflakeWarehouse: "",
  snowflakeRole: "",
  snowflakePlatform: "",
  snowflakeUsername: "",
  snowflakePassword: ""
};

export const Options = {
  publishSettings: [
    { text: "RDBMS", value: "RDBMS" },
    { text: "Azure BLOB", value: "Azure BLOB" },
    { text: "AWS S3", value: "AWS S3" },
    { text: "SFTP", value: "SFTP" },
    { text: "Azure Data Lake", value: "Azure Data Lake" },
    { text: "Sharepoint", value: "Sharepoint" },
    { text: "Snowflake", value: "Snowflake" }
  ],
  dbVendors: [
    { text: "MySQL", value: "MySQL" },
    { text: "SQL Server", value: "SQL Server" },
    { text: "PostgreSQL", value: "PostgreSQL" },
    { text: "Oracle", value: "Oracle" },
    { text: "Snowflake", value: "Snowflake"}
  ],
  fileTypes: [
    { text: "xlsx", value: "xlsx" },
    { text: "csv", value: "csv" },
    { text: "json", value: "json" }
  ],
  timeStamp: [
    { text: "dd:mm:yyyy:hh:ss", value: "dd:mm:yyyy:hh:ss" },
    { text: "mm:dd:yyyy:hh:ss", value: "mm:dd:yyyy:hh:ss" },
    { text: "yyyy:mm:dd:hh:ss", value: "yyyy:mm:dd:hh:ss" }
  ]
};

export const messages = {
  showFailureAlertOnPublishSettingModel: false,
  showSuccessAlertOnPublishSettingModel: false,
  showIntermediateMessage: "Publish Configuration"
};
