var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr-warapper"},[_c('ams-navbar'),_c('div',{staticStyle:{"padding":"15px","display":"flex"},attrs:{"clas":"container"}},[_c('div',{staticStyle:{"width":"20%","padding":"0px 5px"}},[_c('ul',{staticClass:"global-replace-menu"},[_c('h3',[_vm._v("Logs")]),_vm._l((_vm.uniqueJobs),function(item){return _c('li',{key:item,staticClass:"job-logs",style:(_vm.currentlyClicked === item ? 'color:#0090c4;' : ''),on:{"click":function($event){return _vm.fetchJobLog(item)}}},[_vm._v(" "+_vm._s(item)+" ")])})],2)]),_c('div',{staticStyle:{"width":"80%"}},[_c('table',{staticClass:"table-header-font",staticStyle:{"width":"100%"},attrs:{"id":"stepTable"}},[_vm._m(0),_vm._l((this.logsForTheJob),function(log,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(log.date))]),_c('td',{staticStyle:{"width":"10%"}},[_vm._v(_vm._s(log.time))]),_c('td',{staticStyle:{"width":"25%"},style:([
              log.logLevel == 'INFO'
                ? _vm.INFO
                : log.logLevel == 'DEBUG'
                ? _vm.DEBUG
                : _vm.ERROR
            ])},[_vm._v(" "+_vm._s(log.logLevel)+" ")]),_c('td',{staticStyle:{"width":"75%"}},[_vm._v(_vm._s(log.message))])])})],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("DATE")]),_c('th',[_vm._v("TIME")]),_c('th',[_vm._v("LOGLEVEL")]),_c('th',[_vm._v("DESCRIPTION")])])
}]

export { render, staticRenderFns }