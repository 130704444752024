import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import { formatDate } from "./utilbin/common-utils.js";

export const EventBus = new Vue();
const token = localStorage.getItem("vuex");
if (token) {
  axios.defaults.headers.common["Authorization"] = JSON.parse(token).token;
}

Vue.use(BootstrapVue);
Vue.filter("formatDate", formatDate);

import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
if (
  process.env.NODE_ENV !== "development" &&
  process.env.NODE_ENV !== "local"
) {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.config.performance = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
