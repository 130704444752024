<template>
  <div id="globalVariantsParentDiv">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <div class="fr-warapper">
      <main>
        <!--Fractal content area-->
        <div class="fr-content global-replace">
          <ul class="global-replace-menu">
            <li
              v-bind:class="{active:(value === hierarchy)}"
              v-for="(value,index) in hierarchies"
              :key="index"
              @click="changeHierarchy($event,value)"
            >
              <a href="javascript:void(0);">{{value}}</a>
            </li>
          </ul>
          <!--Main Content-->
          <div class="main-content">
            <div class="container-fluid">
              <!--Job List Filter, Pagination and search-->
              <!-- <?php include("includes/global-replace/filter-pagination-search.php");?> -->
              <div class="d-flex align-middle mt-2 mb-2">
                <div class="mr-auto d-flex align-items-center">
                  <!--Section Heading-->
                  <div class="heading d-inline-block">
                    <h3 class="mb0">Global Dictionary</h3>
                  </div>
                </div>
                <div class="ml-auto">
                  <!--Search, Filter Pagination-->
                  <ul class="filter-list">
                    <li>
                      <button
                        class="btn btn-primary"
                        @click="saveGlobalDataManipulations($event)"
                      >
                        <i class="icon14 icon-save"></i>Save
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        @click="globalDictionaryListing()"
                      >
                        <i class="icon14 icon-close"></i> Cancel
                      </button>
                    </li>
                    <li>
                      <div class="dropdown show filter-item">
                        <a
                          class="collapsed"
                          v-b-toggle.collapseFilter
                        >
                          <i class="icon24 icon-filter"></i>
                        </a>
                      </div>
                    </li>
                    <li>
                      <vue-lpage
                        :data="globalTagsArray"
                        :page="currentPage"
                        :results-per-page="perPage"
                      >
                        <div
                          slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
                          class="pagination-item"
                        >
                          <input
                            class="form-control"
                            :min="1"
                            :max="Math.ceil(totalRows/perPage)"
                            type="text"
                            v-model="currentPage"
                          />
                          of {{ Math.ceil(totalRows/perPage) }}
                          <a
                            href="javascript:void(0);"
                            :disabled="isFirstPage"
                            @click="(currentPage>1) ? currentPage-- : currentPage=1"
                          >
                            <i class="icon24 icon-leftArrow"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            :disabled="isLastPage"
                            @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
                          >
                            <i class="icon24 icon-rightArrow"></i>
                          </a>
                        </div>
                      </vue-lpage>

                      <!-- <div class="pagination-item item-cell">
                        <input class="form-control" value="1"> of 100
                        <a href="javascript:void(0);"><i class="icon24 icon-leftArrow"></i></a>
                        <a href="javascript:void(0);"><i class="icon24 icon-rightArrow"></i></a>
                      </div>-->
                    </li>
                  </ul>
                </div>
              </div>

              <!--Filter collapse -->
              <!-- <?php include("includes/global-replace/filter-colapse.php");?> -->
              <b-collapse id="collapseFilter">
                <b-card
                  no-body
                  class="filter-card"
                >
                  <div class="card-header item-cell">
                    <p>Filters</p>
                    <div class>
                      <button
                        class="btn btn-primary"
                        @click="applyConditionalFilterFn($event)"
                      >Apply</button>
                      <button
                        class="btn btn-secondary"
                        @click="clearFilterFn()"
                      >Clear</button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group mb-0">
                          <label for="user-name">Status</label>
                          <!-- <select v-model="filterOptions.selectedCondition" class="form-control choices-js" value="">
                            <option v-for=" (option,idx) in filterOptions.conditions" :label="option.text" :value="option" :key="idx"></option>
                          </select>-->
                          <multiselect
                            v-model="filterOptions.selectedCondition"
                            track-by="text"
                            style="margin-top: -10px;"
                            label="text"
                            :options="filterOptions.conditions"
                            :close-on-select="true"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              <strong>{{ option.text }}</strong>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div
                          class="search-item form-group mb-0"
                          style="width:100%"
                        >
                          <label for="user-name">Search</label>
                          <input
                            ref="applyFilter"
                            type="text"
                            v-model="filterOptions.conditionalInput"
                            class="form-control search-control awesomplete"
                          />
                          <!-- <a href="javascript:void(0);"><i class="icon24 icon-search"></i></a> -->
                          <!-- <datalist id="jobList">
                          </datalist>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-collapse>

              <!--Configuration Table (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
              <div class="table-record">
                <b-table
                  class="export-import-table"
                  :fields="fields"
                  :items="globalTagsArray"
                  :per-page="perPage"
                  :current-page="Number.parseInt(currentPage)"
                >
                  <!-- A virtual column -->
                  <template v-slot:cell(index)="data">{{data.index + 1}}</template>
                  <!-- A custom formatted column -->
                  <template v-slot:cell(globalName)="data">
                    <input
                      @blur="(!(data.item.isDirty) || !('isDirty' in data.item)) ? dirtyTriggerOnRowInput(data.item) : {}"
                      :id="data.item._id"
                      :text="data.item.globalTags[0]"
                      v-model="data.item.globalTags[0]"
                      class="form-control table-input"
                    />
                  </template>
                  <template
                    v-slot:cell(Tag)="data"
                    v-for="val in maxVariantLength"
                  >
                    <input
                      @blur="(!(data.item.isDirty) || !('isDirty' in data.item)) ? dirtyTriggerOnRowInput(data.item) : {}"
                      :key="val"
                      :id="data.item._id"
                      :text="data.item.globalTags[val]"
                      v-model="data.item.globalTags[val]"
                      class="form-control table-input"
                    />
                  </template>
                </b-table>
              </div>

              <!-- Modal Component -->
              <b-modal
                ref="saveChangesRef"
                id="save-changes"
                title="Save Unsaved Changes"
              >
                <div>
                  <p>You have unsaved changes. Please save the changes or continue without saving.</p>
                </div>
                <div slot="modal-footer">
                  <div slot="modal-ok">
                    <b-btn
                      variant="outline-primary"
                      @click="saveGlobalDataManipulations($event,1)"
                    >Save & Continue</b-btn>
                    <b-btn
                      variant="outline-secondary"
                      @click="dischardUnsavedChanges($event)"
                    >Continue</b-btn>
                  </div>
                </div>
              </b-modal>

              <b-modal
                ref="applyFilterRef"
                id="applyFilter"
                title="You have unsaved changes"
              >
                <div>
                  <p>You have unsaved changes. Please save the changes or continue without saving.</p>
                </div>
                <div slot="modal-footer">
                  <div slot="modal-ok">
                    <b-btn
                      variant="outline-primary"
                      @click="saveDataAndApplyFilter($event,1)"
                    >Save & Apply Filter</b-btn>
                    <b-btn
                      variant="outline-secondary"
                      @click="applyFilterWithoutSaving($event)"
                    >Apply Filter</b-btn>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  fetchGlobalVariantsData,
  saveGlobalDataManipulations,
  conditionalGlobalStringFilter
} from "../services/parentservices.js";
import navBar from "../components/Navbar.vue";
import VueLpage from "vue-lpage";
import Multiselect from "vue-multiselect";
export default {
  name: "globalTags",
  components: {
    "ams-navbar": navBar,
    VueLpage,
    Multiselect
  },
  data() {
    return {
      fields: [{ key: "index" }, { key: "globalName", sortable: true }],
      hierarchies: [],
      hierarchy: "",
      newhierarchy: "",
      collectionName: "",
      isDirtyRows: new Set(),
      currentPage: 1,
      perPage: 18,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      maxVariantLength: 3,
      globalTagsArray: [],
      filterOptions: {
        conditions: [
          {
            text: "Equal To",
            value: {
              id: 1,
              text: "EQUALS"
            }
          },
          {
            text: "Is not equal to",
            value: {
              id: 2,
              text: "IS_NOT_EQUAL_TO"
            }
          },
          {
            text: "Contains",
            value: {
              id: 3,
              text: "CONTAINS"
            }
          },
          {
            text: "Does not contain",
            value: {
              id: 4,
              text: "DOES_NOT_CONTAIN"
            }
          },
          {
            text: "Starts with",
            value: {
              id: 5,
              text: "STARTS_WITH"
            }
          },
          {
            text: "Does not start with",
            value: {
              id: 6,
              text: "DOES_NOT_START_WITH"
            }
          },
          {
            text: "Ends with",
            value: {
              id: 7,
              text: "ENDS_WITH"
            }
          },
          {
            text: "Does not end with",
            value: {
              id: 8,
              text: "DOES_NOT_END_WITH"
            }
          }
        ],
        selectedCondition: {
          text: "Contains",
          value: {
            id: 3,
            text: "CONTAINS"
          }
        },
        conditionalInput: null
      }
    };
  },
  created() {
    this.collectionName = this.$route.params.filename;
    this.fetchHierarchyData();
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    dirtyTriggerOnRowInput(data) {
      data.isDirty = true;
      this.isDirtyRows.add(data._id);
    },
    saveGlobalDataManipulations(event, flag) {
      let globalArray = this.globalTagsArray;
      let data = [];
      this.isDirtyRows.forEach(value => {
        data.push(
          globalArray.find(globalTag => {
            if (globalTag._id === value) {
              globalTag.globalName = globalTag.globalTags[0];
              delete globalTag.isDirty;
              return globalTag;
            }
          })
        );
      });
      let param = {
        data: data,
        collectionName: this.collectionName,
        userId: this.userDetails._id
      };
      saveGlobalDataManipulations(param)
        .then(response => {
          this.isDirtyRows = new Set();
          if (flag === 1) {
            this.$refs.saveChangesRef.hide();
            this.changeHierarchy(event, this.newhierarchy);
          }
        })
        .catch(e => {});
    },
    applyConditionalFilterFn(event) {
      if (this.isDirtyRows.size != 0) {
        this.$refs.applyFilterRef.show();
        return;
      }
      if (event.isTrusted && !_.isEmpty(this.filterOptions.selectedCondition)) {
        let actionParam = {
          searchInput: this.filterOptions.conditionalInput,
          criteria: this.filterOptions.selectedCondition.value,
          collectionName: this.collectionName,
          hierarchy: this.hierarchy,
          userId: this.userDetails._id
        };
        conditionalGlobalStringFilter(actionParam)
          .then(response => {
            if (!response._isError) {
              let dataPackage = response._result;
              this.globalTagsArray = dataPackage;
              this.totalRows = dataPackage.length;
            } else {
              return;
            }
          })
          .catch(e => {});
      }
    },
    clearFilterFn() {
      this.$refs.applyFilter.value = "";
      // this.fetchHierarchyData();
    },
    fetchHierarchyData() {
      let param = {
        collectionName: this.collectionName,
        hierarchy: this.hierarchy,
        userId: this.userDetails._id
      };
      fetchGlobalVariantsData(param)
        .then(response => {
          if (!response._isError) {
            let dataPackage = response._result;
            if (this.hierarchy === "") {
              this.hierarchy = dataPackage.hierarchies[0];
            }
            this.currentPage = 1;
            this.hierarchies = dataPackage.hierarchies;
            this.maxVariantLength = dataPackage.maxVariantLength;
            this.globalTagsArray = dataPackage.globalTagsArray;
            this.totalRows = this.globalTagsArray.length;
            for (let i = 1; i <= this.maxVariantLength; i++) {
              this.fields.push({ key: "Tag " + i });
            }
          } else {
            return;
          }
        })
        .catch(e => {});
    },
    dischardUnsavedChanges(event) {
      this.$refs.saveChangesRef.hide();
      this.isDirtyRows = new Set();
      this.changeHierarchy(event, this.newhierarchy);
    },
    changeHierarchy(event, value) {
      if (this.isDirtyRows.size != 0) {
        this.$refs.saveChangesRef.show();
        this.newhierarchy = value;
      } else {
        this.hierarchy = value;
        this.fetchHierarchyData();
      }
    },
    globalDictionaryListing() {
      this.$router.push({
        name: "globalFileUpload"
      });
    },
    saveDataAndApplyFilter() {
      this.$refs.applyFilterRef.hide();
      let globalArray = this.globalTagsArray;
      let data = [];
      this.isDirtyRows.forEach(value => {
        data.push(
          globalArray.find(globalTag => {
            if (globalTag._id === value) {
              globalTag.globalName = globalTag.globalTags[0];
              delete globalTag.isDirty;
              return globalTag;
            }
          })
        );
      });
      let param = {
        data: data,
        collectionName: this.collectionName,
        userId: this.userDetails._id
      };
      saveGlobalDataManipulations(param)
        .then(response => {
          let param = {
            collectionName: this.collectionName,
            hierarchy: this.hierarchy,
            userId: this.userDetails._id
          };
          fetchGlobalVariantsData(param)
            .then(response => {
              if (!response._isError) {
                let dataPackage = response._result;
                if (this.hierarchy === "") {
                  this.hierarchy = dataPackage.hierarchies[0];
                }
                this.currentPage = 1;
                this.hierarchies = dataPackage.hierarchies;
                this.maxVariantLength = dataPackage.maxVariantLength;
                this.globalTagsArray = dataPackage.globalTagsArray;
                this.totalRows = this.globalTagsArray.length;
                for (let i = 1; i <= this.maxVariantLength; i++) {
                  this.fields.push({ key: "Tag " + i });
                }
              } else {
                return;
              }
            })
            .then(() => {
              this.isDirtyRows = new Set();
              this.applyConditionalFilterFn(event);
            });
        })

        .catch(e => {
          console.log(e);
        });
    },
    applyFilterWithoutSaving() {
      this.$refs.applyFilterRef.hide();
      fetchGlobalVariantsData({
        collectionName: this.collectionName,
        hierarchy: this.hierarchy,
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError) {
            let dataPackage = response._result;
            if (this.hierarchy === "") {
              this.hierarchy = dataPackage.hierarchies[0];
            }
            this.currentPage = 1;
            this.hierarchies = dataPackage.hierarchies;
            this.maxVariantLength = dataPackage.maxVariantLength;
            this.globalTagsArray = dataPackage.globalTagsArray;
            this.totalRows = this.globalTagsArray.length;
            for (let i = 1; i <= this.maxVariantLength; i++) {
              this.fields.push({ key: "Tag " + i });
            }
          } else {
            return;
          }
        })
        .then(() => {
          this.isDirtyRows = new Set();
          this.applyConditionalFilterFn(event);
        });
    }
  }
};
</script>