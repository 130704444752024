<template>
  <!--Fractal Wrapper-->
  <div class="fr-warapper">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <main>
      <!--Fractal content area-->
      <div class="fr-content">
        <!--Main Content-->
        <div class="main-content">
          <div class="container-fluid">
            <!--Data Export import Table-->
            <div class="d-flex mt-3 mb-2">
              <div class="data-export-file flex-fill">
                <form
                  id="file-upload-form"
                  enctype="multipart/form-data"
                  novalidate
                  class="training-file-form"
                >
                  <div
                    v-if="showUpload"
                    class="upload-right"
                  >
                    <input
                      id="file-upload"
                      type="file"
                      required
                      accept=".xlsx, .xls, .csv"
                      ref="trainingDictFileUploader"
                      :name="uploadFieldName"
                      :disabled="isSaving"
                      @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                    />
                    <label
                      for="file-upload"
                      id="file-drag"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div class>
                        <!-- <img src="../assets/images/cloud-illustation.svg"> -->
                        <p v-if="isInitial">Drop file here or click to upload</p>
                        <p v-if="fileName">{{fileName}}</p>
                        <p v-if="isSaving">Uploading {{ fileCount }} file...</p>
                        <a
                          id="file-upload-btn"
                          class="btn btn-primary btn-add-files"
                        >
                          <i class="icon16 icon-plus"></i> Upload Files
                        </a>
                      </div>
                    </label>
                    <!--                                 
                                <progress id="file-progress" value="0">
                                    <span>0</span>%
                    </progress>-->

                    <output
                      for="file-upload"
                      id="messages"
                    ></output>
                  </div>
                </form>
              </div>
            </div>
            <!-- <b-modal
              id="add-additional-info-modal"
              ref="addAdditionalInfo"
              centered
              title="Bootstrap-Vue"
            >
              <div
                slot="modal-header"
                style="width:100%"
              >
                <button
                  type="button"
                  class="close"
                  @click="hide($event)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title">
                  <i class="icon16 icon-plus-blue"></i> Additional Data for File
                </h5>
              </div>

              <div class="modal-body">
                <div class="form-group floating-label ">
                  <input
                    type="text"
                    v-model="form.displayFileName"
                    class="form-control floating-input"
                    placeholder=" "
                    autocomplete="off"
                  >
                  <label for="">Display Name</label>
                </div>
                <div class="form-group floating-label">
                  <multiselect
                    v-model="form.algorithm"
                    :options="algoMaster"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="Pick a value"
                  ></multiselect>
                </div>
                <div class="form-group floating-label">
                  <textarea
                    class="form-control floating-input floating-textarea"
                    rows="5"
                    placeholder=" "
                    autocomplete="off"
                    v-model="form.description"
                  ></textarea>
                  <label for="">Description</label>
                </div>
              </div>
              <div slot="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="onSubmit($event)"
                >
                  <i class="icon14 icon-save"></i> Submit
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="hide($event)"
                  data-dismiss="modal"
                >
                  <i class="icon14 icon-close"></i> Cancel
                </button>
              </div>
            </b-modal>-->
            <div
              v-if="showAdditionalInfo"
              class="left-side-form"
            >
              <div class="col-lg-5 form-group floating-label">
                <input
                  type="text"
                  v-model.trim="form.displayFileName"
                  class="form-control floating-input"
                  placeholder=" "
                  autocomplete="off"
                />
                <label for>Display Name</label>
              </div>
              <div class="col-lg-5">
                <multiselect
                  v-model="form.algorithm"
                  :options="algoMaster"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="Pick a value"
                ></multiselect>
              </div>
              <!-- <div class="col-lg-5 form-group floating-label">
                <textarea
                  class="form-control floating-input floating-textarea"
                  rows="5"
                  placeholder=" "
                  autocomplete="off"
                  v-model="form.description"
                ></textarea>
                <label for="">Description</label>
              </div>-->
              <button
                type="button"
                class="btn btn-primary"
                @click="onSubmit($event)"
              >
                <i class="icon14 icon-save"></i> Submit
              </button>
            </div>
            <!--Filter pagination-->
            <div class="d-flex align-middle mt-2 mb-2">
              <div class="mr-auto d-flex align-items-center">
                <!--Section Heading-->
                <div class="heading d-inline-block">
                  <h2 class="mb0"></h2>
                </div>
              </div>
              <div class="ml-auto">
                <!--Search, Filter Pagination-->
                <ul class="filter-list">
                  <li>
                    <div class="search-item">
                      <div class="awesomplete">
                        <input
                          class="form-control search-control awesomplete"
                          v-model="trainingFileListSearch"
                          @input="debounceSearch($event)"
                          data-list="#jobList"
                          placeholder="Search"
                          autocomplete="off"
                          aria-autocomplete="list"
                        />
                        <ul hidden></ul>
                        <span
                          class="visually-hidden"
                          role="status"
                          aria-live="assertive"
                          aria-relevant="additions"
                        ></span>
                      </div>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-search"></i>
                      </a>
                      <datalist id="jobList"></datalist>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown show filter-item">
                      <a
                        class="collapsed"
                        v-b-toggle.collapseFilter
                      >
                        <i class="icon24 icon-filter"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <vue-lpage
                      :data="filteredData"
                      :page="currentPage"
                      :results-per-page="perPage"
                    >
                      <div
                        slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
                        class="pagination-item"
                      >
                        <input
                          class="form-control"
                          :min="1"
                          :max="Math.ceil(totalRows/perPage)"
                          type="text"
                          v-model="currentPage"
                        />
                        of {{ Math.ceil(totalRows/perPage) }}
                        <a
                          href="javascript:void(0);"
                          :disabled="isFirstPage"
                          @click="(currentPage>1) ? currentPage-- : currentPage=1"
                        >
                          <i class="icon24 icon-leftArrow"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          :disabled="isLastPage"
                          @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
                        >
                          <i class="icon24 icon-rightArrow"></i>
                        </a>
                      </div>
                    </vue-lpage>
                    <!-- <div class="pagination-item item-cell">
                      <input class="form-control" value="1"> of 100
                      <a href="javascript:void(0);"><i class="icon24 icon-leftArrow"></i></a>
                      <a href="javascript:void(0);"><i class="icon24 icon-rightArrow"></i></a>
                    </div>-->
                  </li>
                </ul>
              </div>
            </div>

            <!--Filter collapse -->
            <!-- <?php include("includes/global-replace/filter-colapse.php");?> -->
            <b-collapse id="collapseFilter">
              <b-card
                no-body
                class="filter-card"
              >
                <div class="card-header d-flex align-middle justify-content-between">
                  <p>Filters</p>
                  <div class>
                    <button
                      class="btn btn-primary"
                      @click="applyFilterPanelFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-secondary"
                      @click="clearFilterPanelFn($event)"
                    >Clear</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group floating-label">
                        <input
                          type="text"
                          class="form-control floating-input"
                          id="file-name"
                          placeholder=" "
                          v-model="filterPanelModel.fileName"
                        />
                        <label for="file-name">File Name</label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender from"
                              id="fromDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">From</label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender to"
                              id="toDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">To</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>

            <!-- Data Import Export Table (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
            <div class="expo-impo-table-wrapper">
              <b-table
                class="table data-import-export-table"
                @row-clicked="rowClick($event)"
                show-empty
                selectable
                :items="filteredData"
                :fields="fields"
                :current-page="Number.parseInt(currentPage)"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(id)="">
                  <div
                    class="checkbox"
                    @click="$event.stopPropagation();"
                  >
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </div>
                </template>
                <template v-slot:cell(fileName)="row">{{row.value}}</template>
                <template v-slot:cell(uploadedBy)="row">{{row.value}}</template>
                <template v-slot:cell(uploadedDate)="row">{{row.value | formatDate}}</template>
                <template v-slot:cell(actions)="row">
                  <ul
                    class="action-list"
                    style="width:200px"
                    @click="$event.stopPropagation();"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-tooltip.hover
                        data-placement="top"
                        title="Replace file"
                        @click="showTrainingFileReplaceModalFn(row.item)"
                      >
                        <i class="icon24 icon-refresh"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Share"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Send For Review"
                      >
                        <i class="icon24 icon-send"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="showFileDeleteModalFn(row.item)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                      >
                        <i class="icon24 icon-delete"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!--TRAININGFILE LIST ACTIONS MODAL STARTS-->
    <modal-action
      v-if="fileListActionModal.isActive"
      v-on:closeActionModal="closeActionModalFn"
    >
      <template v-if="fileListActionModal.actionType === 1">
        <span slot="header">
          <h5 class="modal-title">Delete File</h5>
        </span>
        <span slot="body">Are you sure you want to delete the file?</span>
        <span slot="footer">
          <button
            @click="applyTrainingFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >YES</button>
        </span>
      </template>
      <template v-if="fileListActionModal.actionType === 5">
        <span slot="header">
          <h5 class="modal-title">Replace File</h5>
        </span>
        <span slot="body">
          <label for="fileReplace">
            <input
              type="file"
              name="replaceFileInput"
              required
              accept=".xlsx, .xls, .csv"
              id="fileReplace"
              ref="fileReplace"
              :disabled="isSaving"
              @change="replaceFileChangeFn($event.target.name, $event.target.files);"
            />
          </label>
        </span>
        <span slot="footer">
          <button
            @click="applyTrainingFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >YES</button>
        </span>
      </template>
    </modal-action>
    <!--TRAININGFILE ACTIONS MODAL ENDS-->
  </div>
</template>

<script>
import VueLpage from "vue-lpage";
import navBar from "../components/Navbar.vue";
import {
  fetchUploadedTrainingFilesList,
  sendUploadedTrainingFilesList,
  deleteFile,
  fetchAlgoMasterData,
  replaceTrainingFileSvc
} from "./../services/parentservices.js";
import Multiselect from "vue-multiselect";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import { mapGetters } from "vuex";
import {
  DISPLAYFILENAME,
  ALGO,
  UPLOADEDBY,
  PAGELIST_ACTION_ITEMS,
  PAGELIST_ACTION_MODAL,
  NEXUSALGORITHM
} from "../assets/js/constant.js";
import modal from "./../components/Modal.vue";
import {
  checkEmptyObject,
  checkEmptyString,
  encryptData,
  testForbiddenChars
} from "./../utilbin/common-utils.js";
import { validateFileImport } from "./../utilbin/validation.js";
import TinyDatePicker from "tiny-date-picker";

class FILTER_PANEL_MODEL {
  constructor() {
    this.fileName = "";
    this.fromDate = "";
    this.toDate = "";
  }
}

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
const FILE_UPLOAD_KEY = "trainingFile";

export default {
  name: "trainingFileUpload",
  components: {
    "ams-navbar": navBar,
    VueLpage,
    Multiselect,
    "modal-action": modal
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: ""
        },
        {
          key: "displayFileName",
          label: "File Name",
          sortable: true
        },
        {
          key: "uploadedBy",
          label: "Uploaded By",
          sortable: true
        },
        { key: "uploadedDate", label: "Uploaded Date", sortable: true },
        { key: "algorithm", label: "Algorithm", sortable: true },
        { key: "actions", label: "Actions" }
      ],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sortBy: "uploadedDate",
      sortDesc: true,
      sortDirection: "desc",
      uploadedFilesSearch: null,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "trainingUploadedFiles",
      fileCount: 0,
      filteredData: [],
      fileListingData: [],
      fileListingHeaders: null,
      trainingFileListSearch: "",
      fileInfo: "",
      fileName: "",
      form: {
        displayFileName: "",
        algorithm: ""
      },
      algoMaster: [],
      showUpload: true,
      showAdditionalInfo: false,
      fileListActionModal: new PAGELIST_ACTION_MODAL(),
      filterPanelModel: new FILTER_PANEL_MODEL()
    };
  },
  created: function() {
    this.fetchUploadedFilesData();
    this.fetchAlgoMaster();
    this.debounceSearch = _.debounce(this.applySearch, 1000);
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    fetchAlgoMaster() {
      fetchAlgoMasterData({ userId: this.userDetails._id })
        .then(response => {
          if (!response._isError) {
            this.algoMaster = response._result
              .filter(e => e.algoName !== NEXUSALGORITHM.ontology)
              .map(e => e.algoName);
          }
        })
        .catch(e => {
          errorEventNotify("Error happened at fetching algorithms", 1);
        });
    },
    async onSubmit(event) {
      if (
        this.form.displayFileName !== "" &&
        this.form.algorithm &&
        this.fileInfo !== ""
      ) {
        if (testForbiddenChars(this.form.displayFileName)) {
          errorEventNotify(
            `File Name cannot contain [/\"$*<>:|?_()%+=@!#^&{}';,-]`,
            1
          );
          return;
        }
        let formData = new FormData();
        Array.from(Array(this.fileInfo.length).keys()).map(x => {
          formData.append(
            FILE_UPLOAD_KEY,
            this.fileInfo[x],
            this.fileInfo[x].name
          );
        });
        if (!checkEmptyObject(formData) && formData.has(FILE_UPLOAD_KEY)) {
          let obj = {};
          obj[DISPLAYFILENAME] = this.form.displayFileName;
          obj["userId"] = this.userDetails._id;
          obj["userRole"] = this.userDetails.roles;
          obj[ALGO] = this.form.algorithm;
          obj[UPLOADEDBY] = this.userDetails.name;
          formData.append(
            "encryptedData",
            await encryptData(JSON.stringify(obj))
          );
          // formData.append(DISPLAYFILENAME, this.form.displayFileName);
          // formData.append(ALGO, this.form.algorithm);
          // formData.append(UPLOADEDBY, this.userDetails.name);
          // formData.append("userId", this.userDetails._id);
          // formData.append("userRole", this.userDetails.roles);
          this.showUpload = true;
          this.showAdditionalInfo = false;
          this.saveUploadedFiles(formData);
        } else {
          return;
        }
      } else {
        errorEventNotify("All fields are mandatory", 1);
      }
    },
    debounceSearch() {},
    applySearch(event) {
      if (this.trainingFileListSearch != "") {
        this.filteredData = [];
        this.filteredData = this.fileListingData.filter(obj => {
          return (
            obj["displayFileName"]
              .toLowerCase()
              .includes(this.trainingFileListSearch.toLowerCase()) ||
            obj["uploadedBy"]
              .toLowerCase()
              .includes(this.trainingFileListSearch.toLowerCase()) ||
            obj["uploadedDate"]
              .toLowerCase()
              .includes(this.trainingFileListSearch.toLowerCase())
          );
        });
      } else {
        this.fetchUploadedFilesData();
      }
    },
    fetchUploadedFilesData() {
      fetchUploadedTrainingFilesList({ userId: this.userDetails._id })
        .then(response => {
          if (!response._isError) {
            this.fileListingData = response._result;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            if (this.trainingFileListSearch !== "") {
              this.filteredData = this.fileListingData.filter(obj => {
                return (
                  obj["displayFileName"]
                    .toLowerCase()
                    .includes(this.trainingFileListSearch.toLowerCase()) ||
                  obj["uploadedBy"]
                    .toLowerCase()
                    .includes(this.trainingFileListSearch.toLowerCase()) ||
                  obj["uploadedDate"]
                    .toLowerCase()
                    .includes(this.trainingFileListSearch.toLowerCase())
                );
              });
            } else {
              this.filteredData = response._result;
            }
            // this.filteredData = response._result;
            this.totalRows = this.fileListingData.length;
          } else {
            return;
          }
        })
        .catch(e => {});
    },
    deleteTrainingFileListFn(fileObject) {
      deleteFile({
        fileObject,
        flag: "training",
        userId: this.userDetails._id,
        userRoles: this.userDetails.roles
      })
        .then(response => {
          if (!response._isError) {
            this.fetchUploadedFilesData();
            successEventNotify("File deleted successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        })
        .finally(() => {
          this.fileListActionModal = new PAGELIST_ACTION_MODAL();
        });
    },
    resetUploadComponent() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.form.displayFileName = "";
      this.form.algorithm = "";
      this.fileName = "";
    },
    saveUploadedFiles(formData) {
      this.currentStatus = STATUS_SAVING;

      sendUploadedTrainingFilesList(formData)
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            successEventNotify("File uploaded successfully", 1);
            this.resetUploadComponent();
            let dataPackage = response._result;
            this.uploadedFiles = [].concat(dataPackage);
            this.fileListingData = dataPackage;
            this.currentStatus = STATUS_SUCCESS;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            if (this.fileListingHeaders.length > 0) {
              this.currentStatus = STATUS_INITIAL;
              this.fetchUploadedFilesData();
            }
          } else {
            this.currentStatus = STATUS_FAILED;
            errorEventNotify(response._message, 1);
            return;
          }
        })
        .catch(e => {
          this.currentStatus = STATUS_FAILED;
          this.uploadError = e;
        });
    },
    filesChange(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
        for (let i = 0; i < fileList.length; i++) {
          this.fileName += fileList[i].name;
        }

        this.currentStatus = STATUS_SAVING;
        // this.$refs.addAdditionalInfo.show();
        this.showUpload = false;
        this.showAdditionalInfo = true;
      } else {
        //errorEventNotify("Could not upload file", 1);
      }
    },
    closeActionModalFn() {
      this.fileListActionModal = new PAGELIST_ACTION_MODAL();
    },
    showFileDeleteModalFn(file) {
      this.fileListActionModal.isActive = true;
      this.fileListActionModal.actionType = PAGELIST_ACTION_ITEMS.deletion;
      this.fileListActionModal.actionPayload = file;
    },
    applyTrainingFileListActionFn(event) {
      switch (this.fileListActionModal.actionType) {
        case 1:
          this.deleteTrainingFileListFn(this.fileListActionModal.actionPayload);
          break;
        case 5:
          this.replaceFileFn(this.fileListActionModal.actionPayload);
          break;
        default:
      }
    },
    applyFilterPanelFn(event) {
      //1.setting the dates from Tiny date picker
      this.filterPanelModel.fromDate = document.querySelector(
        ".calender.from"
      ).value;
      this.filterPanelModel.toDate = document.querySelector(
        ".calender.to"
      ).value;

      //1.Validating filter options and dates (both dates incase date selected)
      let validationFlag =
        this.filterPanelModel.fileName ||
        this.filterPanelModel.fromDate ||
        this.filterPanelModel.toDate;

      if (
        validationFlag &&
        !this.filterPanelModel.fromDate &&
        !this.filterPanelModel.toDate
      ) {
        validationFlag = true;
      } else {
        if (this.filterPanelModel.fromDate && this.filterPanelModel.toDate) {
          validationFlag = true;
          // console.log("Validations Passed");
          successEventNotify("success", 1);
        } else {
          errorEventNotify(
            "Atleast one option or both dates are required for filter!",
            1
          );
          validationFlag = false;
          return;
        }
      }

      //3.Filtering jobs
      if (validationFlag) {
        let filteredFiles = [...this.fileListingData];
        if (!checkEmptyString(this.filterPanelModel.fileName)) {
          filteredFiles = filteredFiles.filter(file => {
            return file["displayFileName"]
              .toLowerCase()
              .includes(this.filterPanelModel.fileName.toLowerCase());
          });
        }
        if (
          !checkEmptyString(this.filterPanelModel.fromDate) &&
          !checkEmptyString(this.filterPanelModel.toDate)
        ) {
          filteredFiles = filteredFiles.filter(file => {
            return (
              new Date(file["uploadedDate"]) >=
                new Date(this.filterPanelModel.fromDate) &&
              new Date(file["uploadedDate"]) <=
                new Date(this.filterPanelModel.toDate)
            );
          });
        }

        //4.Setting filtered jobs and pagination length
        this.filteredData = [];
        this.filteredData = filteredFiles;
        this.totalRows = filteredFiles.length;
      }
    },
    clearFilterPanelFn(event) {
      this.filterPanelModel = new FILTER_PANEL_MODEL();
      this.filteredData = this.totalRows = null;
      document.querySelector(".calender.from").value = "";
      document.querySelector(".calender.to").value = "";
      this.filteredData = this.fileListingData;
      this.totalRows = this.filteredData.length;
    },
    showTrainingFileReplaceModalFn(file) {
      this.fileListActionModal.isActive = true;
      this.fileListActionModal.actionType = PAGELIST_ACTION_ITEMS.replaceFile;
      this.fileListActionModal.actionPayload = file;
    },
    replaceFileChangeFn(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
      } else {
        errorEventNotify("Could not upload file", 1);
      }
    },
    async replaceFileFn(fileObject) {
      // console.log("fileObject --->", fileObject);
      let formData = new FormData();
      Array.from(Array(this.fileInfo.length).keys()).map(x => {
        formData.append(
          FILE_UPLOAD_KEY,
          this.fileInfo[x],
          this.fileInfo[x].name
        );
      });

      const obj = {
        [DISPLAYFILENAME]: fileObject.displayFileName,
        [ALGO]: fileObject.algorithm,
        userId: this.userDetails._id
      };
      formData.append("encryptedData", await encryptData(JSON.stringify(obj)));
      replaceTrainingFileSvc(formData)
        .then(response => {
          if (!response._isError) {
            successEventNotify("File uploaded successfully", 1);
            this.fileInfo = "";
            this.closeActionModalFn();
          } else {
            this.fileInfo = "";
            this.closeActionModalFn();
            errorEventNotify(response._message, 1);
          }
        })
        .catch(error => {
          this.fileInfo = "";
          this.closeActionModalFn();
          errorEventNotify(error.message, 1);
        });
    },
    rowClick(event) {
      let fileName = event.displayFileName.replace(/ /g, "_");

      if (
        event != null &&
        !event._id != undefined &&
        event.algorithm === NEXUSALGORITHM.enrichment
      ) {
        this.$router.push({
          name: "classificationTraining",
          params: { filename: fileName }
        });
      }
    }
  },
  mounted() {
    this.resetUploadComponent();
    TinyDatePicker(document.querySelector(".calender.from"));
    TinyDatePicker(document.querySelector(".calender.to"));
  }
};
</script>

<style scoped>
.filter-list > li {
  display: inline-block;
  margin: 0 0px;
}
.training-file-form {
  display: inline-flex;
}
/* .floating-textarea {
  height: auto !important;
  padding-top: 14px !important;
} */
.floating-label {
  margin-bottom: 22px;
}
</style>
