<template>
  <div id="pageLevelAlertParent">
    <!--Original solution STARTS-->
    <!-- <b-alert
      class="page-level-alert"
      :show="dismissCountDown"
      dismissible
      :variant="alertPayload.variant"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      {{alertPayload.message}}
    </b-alert> -->
    <!--Original solution ENDS-->
    <!--Temp solution STARTS-->
    <b-alert
      class="temp-solution"
      :class="{'green-variant':greenVariant, 'red-variant':redVariant}"
      :show="dismissCountDown"
      dismissible
      :variant="alertPayload.variant"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      {{alertPayload.message}}
    </b-alert>
    <!--Temp solution ENDS-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../main.js";
export default {
  name: "pageLevelAlert",
  mounted: function() {
    let alertComponent = this;
    EventBus.$on("SUCCESS_EVENT_NOTIFY_PAGE", function(payload) {
      alertComponent.alertPayload = {};
      alertComponent.alertPayload = payload;
      alertComponent.dismissCountDown = 3;
      //temp solution
      alertComponent.greenVariant = true;
      alertComponent.redVariant = false;
    });

    EventBus.$on("ERROR_EVENT_NOTIFY_PAGE", function(payload) {
      alertComponent.alertPayload = {};
      alertComponent.alertPayload = payload;
      alertComponent.dismissCountDown = 3;
      //temp solution
      alertComponent.redVariant = true;
      alertComponent.greenVariant = false;
    });
  },
  data() {
    return {
      alertPayload: {
        message: null,
        variant: null
      },
      dismissCountDown: 0,
      redVariant: false,
      greenVariant: false
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  }
};
</script>

<style scoped>
.page-level-alert {
  text-align: center;
  position: absolute;
  z-index: 1090;
  width: 100%;
}

.temp-solution {
  text-align: center;
  position: absolute;
  z-index: 1090;
  width: 100%;
  padding: 10px;
  color: white;
}

.temp-solution.green-variant {
  background-color: #4caf50;
}

.temp-solution.red-variant {
  background-color: #f44336;
}
</style>
