<template>
  <div id="shareAccessParentDiv">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <div class="fr-warapper">
      <main>
        <!--Fractal content area-->
        <div class="fr-content">
          <!--Main Content-->
          <div class="main-content">
            <div class="container-fluid">
              <!--Job List Filter, Pagination and search-->
              <!-- <?php include("includes/sharing-access/global-search.php");?> -->
              <div class="d-flex align-middle mt-2 mb-2">
                <div class="mr-auto d-flex align-items-center">
                  <!--Section Heading-->
                  <div class="heading">
                    <h2 class="mb0">{{fileName}}</h2>
                  </div>
                  <b-btn
                    v-b-modal.addNewUser
                    variant="primary"
                    class="ml-3"
                  >
                    <i class="icon16 icon-plus"></i>New
                  </b-btn>
                  <!--Add new user modal-->
                  <b-modal
                    id="addNewUser"
                    ref="addNewUser"
                    centered
                    title="Bootstrap-Vue"
                  >
                    <div
                      slot="modal-header"
                      style="width:100%"
                    >
                      <button
                        type="button"
                        class="close"
                        @click="hide()"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5 class="modal-title">
                        <i class="icon16 icon-plus-blue"></i> Select Users To Share Job With
                      </h5>
                    </div>

                    <div class="modal-body">
                      <label
                        style="color: gray;"
                        for="share-with"
                      >Share With</label>
                      <multiselect
                        v-model="selectedUsers"
                        label="email"
                        track-by="email"
                        :options="sentForReviewEmailIds"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                      ></multiselect>
                    </div>
                    <div slot="modal-footer">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="addUsers($event)"
                      >
                        <i class="icon14 icon-save"></i> Ok
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        data-dismiss="modal"
                        @click="hide()"
                      >
                        <i class="icon14 icon-close"></i> Cancel
                      </button>
                    </div>
                  </b-modal>
                </div>
                <div class="ml-auto">
                  <!--Search, Filter Pagination-->
                  <ul class="filter-list">
                    <li>
                      <div class="search-item">
                        <input
                          class="form-control search-control awesomplete"
                          data-list="#jobList"
                          placeholder="Search"
                          v-model="userListSearch"
                          @input="debounceSearch($event)"
                          autocomplete="off"
                          aria-autocomplete="list"
                          maxlength="30"
                        >

                        <a href="javascript:void(0);">
                          <i class="icon24 icon-search"></i>
                        </a>
                        <datalist id="jobList"></datalist>
                      </div>
                    </li>
                    <li>
                      <button
                        class="btn btn-primary btn-link"
                        @click="saveUsers()"
                      >
                        <i class="icon14 icon-save"></i>Save
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        @click="navigateToJobLanding"
                      >
                        <i class="icon14 icon-back"></i>
                        Back
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <!--Users Table (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
              <b-table
                class="table sharing-access-table"
                :fields="fields"
                :items="usersList"
                :per-page="perPage"
                :current-page="Number.parseInt(currentPage)"
              >
                <!-- A custom formatted column -->
                <template v-slot:cell(name)="data">{{data.value}}</template>

                <template v-slot:cell(view)="data">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      value="view"
                      v-model="data.item.privileges"
                      :disabled="true"
                    >
                    <span class="checkmark"></span>
                  </div>
                </template>
                <template v-slot:cell(delete)="data">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      :id="'delete_'
                      +data.item._id"
                      value="delete"
                      v-model="data.item.privileges"
                      @change="checkboxchanged($event,data.item)"
                      :disabled="!('email'in usersList.find(k => k._id==data.item._id))"
                    >
                    <span class="checkmark"></span>
                  </div>
                </template>
                <template v-slot:cell(share)="data">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      :id="'share_'
                      +data.item._id"
                      value="share"
                      v-model="data.item.privileges"
                      @change="checkboxchanged($event,data.item)"
                      :disabled="!('email'in usersList.find(k => k._id==data.item._id))"
                    >
                    <span class="checkmark"></span>
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="action-list">
                    <li
                      :id="'edit1_'+data.item._id"
                      class="edit"
                      :style="(!('email'in usersList.find(k => k._id==data.item._id)))? {display:'inline-block'}:{display:'none'} "
                      @click="toggleEdit(data.item)"
                    >
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-edit"></i>
                      </a>
                    </li>
                    <li
                      :id="'save_'+data.item._id"
                      class="save"
                      :style="(('email'in usersList.find(k => k._id==data.item._id)))? {display:'inline-block'}:{display:'none'}"
                      @click="toggleSave(data.item)"
                    >
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-save"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="deleteUser(data.item)"
                      >
                        <i class="icon24 icon-delete"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import navBar from "../components/Navbar.vue";
import Multiselect from "vue-multiselect";
import {
  fetchShareUsersListForFile,
  saveFileAccessDetails,
  fetchJobShareAccessDetails,
  deleteShareAccessUser,
  fetchFileShareAccessDetails
} from "./../services/parentservices.js";
export default {
  name: "fileShareAccess",
  components: {
    "ams-navbar": navBar,
    Multiselect
  },
  data() {
    return {
      accessOptions: ["edit", "delete", "share"],
      fields: [
        { key: "name", label: "User Name", sortable: true },
        { key: "view", label: "View" },
        { key: "delete", label: "Delete" },
        { key: "share", label: "Share" },
        { key: "action", label: "" }
      ],
      currentPage: 1,
      perPage: 18,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sentForReviewEmailIds: [],
      selectedUsers: [],
      usersList: [],
      filteredData: [],
      fileName: "",
      userListSearch: "",
      revokedList: []
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  created: function() {
    this.fetchFileByParam();
    this.debounceSearch = _.debounce(this.applySearch, 1000);
  },
  methods: {
    debounceSearch() {},
    applySearch(event) {
      if (this.userListSearch != "") {
        this.filteredData = [];
        this.filteredData = this.usersList.filter(obj => {
          return (
            obj["name"]
              .toLowerCase()
              .includes(this.userListSearch.toLowerCase()) ||
            obj["name"]
              .toLowerCase()
              .includes(this.userListSearch.toLowerCase())
          );
        });
        this.usersList = this.filteredData;
      } else {
        this.fetchFileByParam();
      }
    },
    navigateToJobLanding() {
      //then going job landing
      this.$router.push({
        name: "jobInputFileUpload"
      });
    },
    checkboxchanged(event, value) {
      let accessArray = [];
      if (event.target.checked === true) {
        for (let element of accessArray) {
          //document.getElementById(element + "_" + value._id).disabled = true;
        }
        value.privileges.push(...[...new Set(accessArray)]);
      }
    },
    saveUsers() {
      let sharedAccessList = this.usersList.map(function(x) {
        return {
          _id: x._id,
          name: x.name,
          privileges:
            typeof x.privileges === "string"
              ? JSON.parse("[" + x.privileges + "]")
              : x.privileges
        };
      });
      let param = {
        shareAccess: sharedAccessList,
        fileId: this.$route.params.fileId,
        userObj: this.userDetails,
        mailingList: this.usersList.filter(el => "email" in el),
        revokedList: this.revokedList
      };
      saveFileAccessDetails(param)
        .then(response => {
          if (response._isError === false) {
            this.revokedList = [];
            this.fetchFileByParam();
          }
        })
        .catch(e => {})
        .finally(() => {});
    },
    fetchFileByParam() {
      let param = {
        fileId: this.$route.params.fileId,
        userObj: this.userDetails
      };
      fetchFileShareAccessDetails(param)
        .then(response => {
          if (response._isError == false) {
            this.usersList = response._result.shareAccess;
            this.fileName = response._result.fileName;
          }
        })
        .catch(e => {})
        .finally(() => {
          this.fetchUsers();
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    addUsers() {
      this.usersList.push(...this.selectedUsers);
      this.fetchUsers();
      this.hide();
    },
    fetchUsers() {
      let param = {
        fileId: this.$route.params.fileId,
        userObj: this.userDetails
      };
      if (this.usersList.length > 0 && this.usersList.length != undefined) {
        let userIds = this.usersList.map(x => x._id);
        userIds.push(this.userDetails._id);
        param.ids = userIds;
      } else {
        param.ids = this.userDetails._id;
      }
      fetchShareUsersListForFile(param)
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            this.sentForReviewEmailIds = response._result;
          }
        })
        .catch(e => {});
    },
    hide() {
      this.$refs.addNewUser.hide();
      this.selectedUsers = [];
    },
    toggleSave(value) {
      document.getElementById("edit1_" + value._id).style.display =
        "inline-block";
      document.getElementById("save_" + value._id).style.display = "none";
    },
    toggleEdit(value) {
      let acccessArray = [];
      document.getElementById("edit1_" + value._id).style.display = "none";
      document.getElementById("save_" + value._id).style.display =
        "inline-block";

      if (value.privileges.includes("share")) {
        acccessArray = ["share"];
      } else {
        acccessArray = this.accessOptions;
      }
      for (let element of acccessArray) {
        document.getElementById(element + "_" + value._id).disabled = false;
      }
    },
    deleteUser(value) {
      this.revokedList = this.usersList.filter(function(el) {
        return el._id == value._id && !("email" in el);
      });
      this.usersList = this.usersList.filter(function(el) {
        return el._id != value._id;
      });
      this.fetchUsers();
    }
  }
};
</script>
