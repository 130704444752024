<template>
  <!--Fractal Wrapper-->
  <div class="fr-warapper">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <b-alert
      style="z-index:5; position:fixed; width:100%; text-align:center;"
      :show="dismissCountDown"
      dismissible
      variant="danger"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >File Name cannot contain ('_','|')</b-alert>
    <main>
      <!--Fractal content area-->
      <div class="fr-content">
        <!--Main Content-->
        <div class="main-content">
          <div class="container-fluid">
            <!--Data Export import Table-->
            <div class="d-flex mt-3 mb-2">
              <div class="data-export-file flex-fill">
                <form
                  id="file-upload-form"
                  enctype="multipart/form-data"
                  novalidate
                >
                  <div
                    v-if="showUpload"
                    class="upload-right"
                  >
                    <input
                      id="file-upload"
                      type="file"
                      required
                      accept=".xlsx, .xls, .csv"
                      ref="jobInputFileUploader"
                      :name="uploadFieldName"
                      :disabled="isSaving"
                      @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                    />
                    <label
                      for="file-upload"
                      id="file-drag"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <div class>
                        <!-- <img src="../assets/images/cloud-illustation.svg"> -->
                        <p v-if="isInitial">Drop file here or click to upload</p>
                        <p v-if="fileName">{{fileName}}</p>
                        <p v-if="isSaving">Uploading {{ fileCount }} file...</p>
                        <a
                          id="file-upload-btn"
                          class="btn btn-primary btn-add-files"
                        >
                          <i class="icon16 icon-plus"></i> Upload Files
                        </a>
                      </div>
                    </label>
                    <!--                                 
                                <progress id="file-progress" value="0">
                                    <span>0</span>%
                    </progress>-->
                    <output
                      for="file-upload"
                      id="messages"
                    ></output>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="d-flex mt-3 mb-2"
              v-if="showUpload"
            >
              <div class="data-export-file flex-fill azure-upload">
                <button
                  class="btn btn-primary btn-add-files azure-btn"
                  @click="azureFetchFile()"
                > Upload Azure Data Lake Files </button>
              </div>
            </div>
            <div
              v-if="showAdditionalInfo"
              class="left-side-form"
            >
              <div class="col-lg-6 form-group floating-label">
                <input
                  type="text"
                  v-model.trim="form.displayFileName"
                  class="form-control floating-input"
                  placeholder=" "
                  autocomplete="off"
                />
                <label for>Display Name</label>
              </div>
              <!-- <div class="col-lg-6 form-group floating-label">
                <input
                  type="text"
                  v-model="form.description"
                  class="form-control floating-input"
                  placeholder=" "
                  autocomplete="off"
                >
                <label for>Description</label>
              </div>-->
              <button
                type="button"
                class="btn btn-primary"
                @click="onSubmit($event)"
              >
                <i class="icon14 icon-save"></i> Submit
              </button>
            </div>

            <!--Filter pagination-->
            <div class="d-flex align-middle mt-2 mb-2">
              <div class="mr-auto d-flex align-items-center">
                <!--Section Heading-->
                <div class="heading d-inline-block">
                  <h2 class="mb0"></h2>
                </div>
              </div>
              <div class="ml-auto">
                <!--Search, Filter Pagination-->
                <ul class="filter-list">
                  <li>
                    <div class="search-item">
                      <div class="awesomplete">
                        <input
                          class="form-control search-control awesomplete"
                          data-list="#jobList"
                          placeholder="Search"
                          autocomplete="off"
                          aria-autocomplete="list"
                          @input="debounceSearch($event)"
                          v-model="inputFileListSearch"
                        />
                        <ul hidden></ul>
                        <span
                          class="visually-hidden"
                          role="status"
                          aria-live="assertive"
                          aria-relevant="additions"
                        ></span>
                      </div>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-search"></i>
                      </a>
                      <datalist id="jobList"></datalist>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown show filter-item">
                      <!-- <a class="collapsed" data-toggle="collapse" href="#collapseFilter" aria-expanded="false" aria-controls="collapseExample"> -->
                      <a
                        class="collapsed"
                        v-b-toggle.collapseFilter
                      >
                        <i class="icon24 icon-filter"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <vue-lpage
                      :data="filteredData"
                      :page="currentPage"
                      :results-per-page="perPage"
                    >
                      <div
                        slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
                        class="pagination-item"
                      >
                        <input
                          class="form-control"
                          :min="1"
                          :max="Math.ceil(totalRows/perPage)"
                          type="text"
                          v-model="currentPage"
                        />
                        of {{ Math.ceil(totalRows/perPage) }}
                        <a
                          href="javascript:void(0);"
                          :disabled="isFirstPage"
                          @click="(currentPage>1) ? currentPage-- : currentPage=1"
                        >
                          <i class="icon24 icon-leftArrow"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          :disabled="isLastPage"
                          @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
                        >
                          <i class="icon24 icon-rightArrow"></i>
                        </a>
                      </div>
                    </vue-lpage>
                    <!-- <div class="pagination-item item-cell">
                      <input class="form-control" value="1"> of 100
                      <a href="javascript:void(0);"><i class="icon24 icon-leftArrow"></i></a>
                      <a href="javascript:void(0);"><i class="icon24 icon-rightArrow"></i></a>
                    </div>-->
                  </li>
                </ul>
              </div>
            </div>

            <!--Filter collapse -->
            <!-- <?php include("includes/global-replace/filter-colapse.php");?> -->
            <b-collapse id="collapseFilter">
              <b-card
                no-body
                class="filter-card"
              >
                <div class="card-header d-flex align-middle justify-content-between">
                  <p>Filters</p>
                  <div class>
                    <button
                      class="btn btn-primary"
                      @click="applyFilterPanelFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-secondary"
                      @click="clearFilterPanelFn($event)"
                    >Clear</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group floating-label">
                        <input
                          type="text"
                          class="form-control floating-input"
                          id="file-name"
                          placeholder=" "
                          v-model="filterPanelModel.fileName"
                        />
                        <label for="file-name">File Name</label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender from"
                              id="fromDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">From</label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender to"
                              id="toDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">To</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>
            <!-- Data Import Export Table (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
            <div class="expo-impo-table-wrapper">
              <b-table
                class="table data-import-export-table"
                show-empty
                selectable
                :items="filteredData"
                :fields="fields"
                :current-page="Number.parseInt(currentPage)"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(id)="">
                  <div class="checkbox">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </div>
                </template>
                <template v-slot:cell(fileName)="row">{{row.value}}</template>
                <template v-slot:cell(uploadedBy)="row">{{row.value}}</template>
                <template v-slot:cell(uploadedDate)="row">{{row.value | formatDate}}</template>
                <template v-slot:cell(statusMode)="row"><span :class="getStatusColClass(row.value)">{{row.value}}</span></template>
                <template v-slot:cell(actions)="row">
                  <ul
                    class="action-list"
                    style="width:200px"
                  >
                    <!--in-progress action template-->
                    <template v-if="row.item.statusMode === 'in-progress'">
                      <li>
                        <a
                          href="javascript:void(0);"
                          class="progrss-bar"
                        >
                          <span style=" width: 80%;"></span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i class="icon24 icon-pause"></i>
                        </a>
                      </li>
                    </template>
                    <!--./in-progress action template-->
                    <!--success action template-->
                    <template v-if="row.item.statusMode === 'Success'">
                      <li>
                        <a
                          href="javascript:void(0);"
                          v-b-tooltip.hover
                          data-placement="top"
                          title="Create Sampling file"
                          @click="showInputFileExtractModalFn(row.item,row.item.extractMode)"
                        >
                          <i
                            v-if="row.item.extractMode === extractMode.extract"
                            :id="'icon' + row.index"
                            class="icon24 icon-review"
                          ></i>
                          <img
                            v-if="row.item.extractMode === extractMode.loading"
                            :id="'image' + row.index"
                            src="../assets/images/6.gif"
                          />
                          <i
                            v-if="row.item.extractMode === extractMode.download"
                            :id="'icon' + row.index"
                            class="icon24 icon-play"
                          ></i>
                          <i
                            v-if="row.item.extractMode === extractMode.failed"
                            :id="'icon' + row.index"
                            class="icon24 icon-failed"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0);"
                          v-b-tooltip.hover
                          data-placement="top"
                          title="Replace file"
                          @click="showInputFileReplaceModalFn(row.item,row.item.replaceMode)"
                        >
                          <i
                            v-if="row.item.replaceMode === replaceMode.replace"
                            :id="'icon' + row.index"
                            class="icon24 icon-refresh"
                          ></i>
                          <img
                            v-if="row.item.replaceMode === replaceMode.loading"
                            :id="'image' + row.index"
                            src="../assets/images/6.gif"
                          />
                          <i
                            v-if="row.item.replaceMode === replaceMode.failed"
                            :id="'icon' + row.index"
                            class="icon24 icon-failed"
                          ></i>
                        </a>
                      </li>
                      <li v-if="condition('share',row.item)">
                        <a
                          href="javascript:void(0);"
                          v-b-tooltip.hover
                          data-placement="top"
                          title="Share"
                          @click="navigateToShareAccess(row.item)"
                        >
                          <i class="icon24 icon-share"></i>
                        </a>
                      </li>
                      <li v-if="condition('delete',row.item)">
                        <a
                          href="javascript:void(0);"
                          @click="showInputFileDeleteModalFn(row.item)"
                          v-b-tooltip.hover
                          data-placement="top"
                          title="Delete"
                        >
                          <i class="icon24 icon-delete"></i>
                        </a>
                      </li>
                    </template>
                    <!--./success action template-->
                  </ul>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!--FILE LIST ACTIONS MODAL STARTS-->
    <modal-action
      v-if="inputFileListActionModal.isActive"
      v-on:closeActionModal="closeActionModalFn"
    >
      <template v-if="inputFileListActionModal.actionType === 1">
        <span slot="header">
          <h5 class="modal-title">Delete File</h5>
        </span>
        <span slot="body">Are you sure you want to delete the file?</span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >YES</button>
        </span>
      </template>
      <template v-if="inputFileListActionModal.actionType === 5">
        <span slot="header">
          <h5 class="modal-title">Replace File</h5>
        </span>
        <span slot="body">
          <modal-alert></modal-alert>
          <b-alert
            style="background-color: #f44336;padding: 8px;margin-bottom: 15px"
            v-if="failedMsg"
            show
            dismissible
            variant="danger"
          >Failed to download file from Azure Data Lake.</b-alert>
          <multiselect
            style="bottom:9px;margin-bottom:20px"
            v-model="selectedSetting"
            placeholder="Select Setting"
            :options="settingOption"
            :close-on-select="true"
            @input="pullAzureFiles()"
          ></multiselect>
          <template v-if="selectedSetting === 'Local'">
            <label for="fileReplace">
              <input
                type="file"
                name="replaceFileInput"
                required
                accept=".xlsx, .xls, .csv"
                id="fileReplace"
                ref="fileReplace"
                :disabled="isSaving"
                @change="replaceFileChangeFn($event.target.name, $event.target.files);"
              />
            </label>
          </template>
          <template v-if="selectedSetting === 'AzureDataLake'">
            <template v-if="azureConfigStatus">
              <p style="padding:0px 5px"> {{configMsg}} </p>
            </template>
            <template v-if="!azureConfigStatus">
              <div
                style="display: -webkit-inline-box;"
                v-if="!showAzureFileDropdown"
              >
                <img
                  style="margin-bottom:2px"
                  src="../assets/images/6.gif"
                >
                <p style="padding:0px 5px"> Fetching Files from Azure Data Lake </p>
              </div>
              <div v-if="showAzureFileDropdown">
                <multiselect
                  style="bottom:9px;margin-bottom:20px"
                  v-model="selectedAzureFile"
                  placeholder="Search or add a column"
                  :options="azureFileList"
                  :close-on-select="true"
                >
                </multiselect>
              </div>
            </template>
          </template>
        </span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >YES</button>
        </span>
      </template>
      <template v-if="inputFileListActionModal.actionType === 6">
        <span slot="header">
          <h5 class="modal-title">Extract File</h5>
        </span>
        <span slot="body">
          <b-alert
            style="background-color: #f44336;padding: 8px;margin-bottom: 15px"
            v-if="failedMsg"
            show
            dismissible
            variant="danger"
          >Sampling resulted in 0 records. Please Select proper columns.</b-alert>
          <div class="samplingcheckbox checkbox">
            <input
              type="checkbox"
              v-model="selectAllColumns"
            > Select All Columns
            <span class="checkmark"></span>
          </div>
          <multiselect
            v-if="!selectAllColumns"
            style="bottom:9px;margin-bottom:20px"
            v-model="selectedColumns"
            placeholder="Search or add a column"
            :options="inputFileListActionModal.actionPayload.columns"
            :multiple="true"
            :close-on-select="false"
          ></multiselect>
          <div class="form-group floating-label">
            <input
              maxlength="2"
              pattern="^[0-9]$"
              type="text"
              class="form-control floating-input"
              placeholder=" "
              v-model="threshold"
              autocomplete="off"
            />
            <label
              class="required-field"
              for="user-name"
            >Enter Threshold</label>
          </div>
        </span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >Save</button>
          <!-- <button type="button" class="btn btn-outline-secondary" @click="hideModal($event)">Cancel</button> -->
        </span>
      </template>
      <template v-if="inputFileListActionModal.actionType === 7">
        <span slot="header">
          <h5 class="modal-title">Upload File </h5>
        </span>
        <span slot="body">
          <modal-alert></modal-alert>
          <template v-if="azureConfigStatus">
            <p style="padding:0px 5px"> {{configMsg}} </p>
          </template>
          <template v-if="!azureConfigStatus">
            <div
              style="display: -webkit-inline-box;"
              v-if="!showAzureFileDropdown"
            >
              <img
                style="margin-bottom:2px"
                src="../assets/images/6.gif"
              >
              <p style="padding:0px 5px"> Fetching Files from Azure Data Lake </p>
            </div>
            <div v-if="showAzureFileDropdown">
              <multiselect
                style="bottom:9px;margin-bottom:20px"
                v-model="selectedAzureFile"
                placeholder="Search or add a column"
                :options="azureFileList"
                :close-on-select="true"
              >
              </multiselect>
              <div class="form-group floating-label">
                <input
                  type="text"
                  v-model="azureDisplayFileName"
                  class="form-control floating-input"
                  placeholder=" "
                  autocomplete="off"
                >
                <label for>Display Name</label>
              </div>
            </div>
          </template>
        </span>
        <span slot="footer">
          <button
            v-if="!azureConfigStatus"
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >Save</button>
        </span>
      </template>
    </modal-action>
    <!--FILE LIST ACTIONS MODAL ENDS-->
  </div>
</template>

<script>
import VueLpage from "vue-lpage";
import navBar from "../components/Navbar.vue";
import {
  checkEmptyObject,
  checkEmptyString
} from "./../utilbin/common-utils.js";
import {
  fetchUploadedFilesList,
  sendUploadedFilesList,
  deleteFile,
  fetchAlgoMasterData,
  replaceInputFileSvc,
  extractFile,
  downloadInputFileAsCSV,
  fetchAzureFiles,
  azureUploadFile,
  azureConfigStatus,
  azureReplaceFile
} from "./../services/parentservices.js";
import { mapGetters } from "vuex";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import {
  DISPLAYFILENAME,
  DESCRIPTION,
  ALGO,
  UPLOADEDBY,
  PAGELIST_ACTION_ITEMS,
  PAGELIST_ACTION_MODAL,
  EXTRACT_FILE_MODE,
  REPLACE_FILE_MODE
} from "../assets/js/constant.js";
import Multiselect from "vue-multiselect";
import modal from "./../components/Modal.vue";
import { validateFileImport } from "./../utilbin/validation.js";
import TinyDatePicker from "tiny-date-picker";
import { encryptData, testForbiddenChars } from "./../utilbin/common-utils";
import { constants } from "crypto";
import Alert from "../components/Alert.vue";
import io from "socket.io-client";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
const FILE_UPLOAD_KEY = "avatar";

class FILTER_PANEL_MODEL {
  constructor() {
    this.fileName = "";
    this.fromDate = "";
    this.toDate = "";
  }
}

export default {
  name: "jobInputFileUpload",
  components: {
    "ams-navbar": navBar,
    VueLpage,
    Multiselect,
    "modal-action": modal,
    "modal-alert": Alert
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: ""
        },
        {
          key: "displayFileName",
          label: "File Name",
          sortable: true
        },
        {
          key: "uploadedBy",
          label: "Uploaded By",
          sortable: true
        },
        { key: "uploadedDate", label: "Uploaded Date", sortable: true },
        { key: "statusMode", label: "Status" },
        { key: "actions", label: "Actions" }
      ],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      sortBy: "uploadedDate",
      sortDesc: true,
      dismissSecs: 4,
      dismissCountDown: 0,
      sortDirection: "desc",
      uploadedFilesSearch: null,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "jobInputUploadedFiles",
      fileCount: 0,
      filteredData: [],
      fileListingData: [],
      fileListingHeaders: null,
      inputFileListSearch: "",
      fileInfo: "",
      fileName: "",
      form: {
        displayFileName: "",
        algorithm: ""
      },
      algoMaster: [],
      showUpload: true,
      showAdditionalInfo: false,
      inputFileListActionModal: new PAGELIST_ACTION_MODAL(),
      filterPanelModel: new FILTER_PANEL_MODEL(),
      selectedColumns: [],
      threshold: "",
      extractMode: EXTRACT_FILE_MODE,
      failedMsg: false,
      azureFileList: [],
      showAzureFileDropdown: false,
      selectedAzureFile: "",
      azureDisplayFileName: "",
      configMsg: "",
      azureConfigStatus: false,
      socket: io(process.env.VUE_APP_SOCKET_ENDPOINT, {
        path: process.env.VUE_APP_SOCKET_PATH,
        transports: ["polling"]
      }),
      selectedSetting: "",
      settingOption: ["Local", "AzureDataLake"],
      replaceMode: REPLACE_FILE_MODE,
      selectAllColumns: false
    };
  },
  created: function() {
    this.fetchUploadedFilesData({
      userId: this.userDetails._id,
      userRole: this.userDetails.roles[0]
    });
    // this.fetchAlgoMaster();
    this.debounceSearch = _.debounce(this.applySearch, 1000);
    this.azureConfigStatusFn();
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    async onSubmit(event) {
      if (this.form.displayFileName !== "" && this.fileInfo !== "") {
        if (testForbiddenChars(this.form.displayFileName)) {
          errorEventNotify(
            `File Name cannot contain [/\"$*<>:|?_()%+=@!#^&{}';,-]`,
            1
          );
          return;
        }
        let formData = new FormData();
        Array.from(Array(this.fileInfo.length).keys()).map(x => {
          formData.append(
            FILE_UPLOAD_KEY,
            this.fileInfo[x],
            this.fileInfo[x].name
          );
        });
        if (!checkEmptyObject(formData) && formData.has(FILE_UPLOAD_KEY)) {
          let obj = {};
          obj[DISPLAYFILENAME] = this.form.displayFileName;
          obj["userId"] = this.userDetails._id;
          obj["name"] = this.userDetails.name;
          obj["userRole"] = this.userDetails.roles[0];
          formData.append(
            "encryptedData",
            await encryptData(JSON.stringify(obj))
          );
          // formData.append(DISPLAYFILENAME, this.form.displayFileName);
          // formData.append(ALGO, this.form.algorithm);
          // formData.append("userId", this.userDetails._id);
          // formData.append("name", this.userDetails.name);
          // formData.append("userRole", this.userDetails.roles[0]);
          this.showUpload = true;
          this.showAdditionalInfo = false;
          this.saveUploadedFiles(formData);
        } else {
          return;
        }
      } else {
        errorEventNotify("All fields are mandatory", 1);
      }
    },
    navigateToShareAccess(item) {
      if (!checkEmptyObject(item)) {
        this.$router.push({
          name: "fileShareAccess",
          params: { fileId: item._id }
        });
      }
    },
    debounceSearch() {},
    applySearch(event) {
      if (this.inputFileListSearch != "") {
        this.filteredData = [];
        this.filteredData = this.fileListingData.filter(obj => {
          return (
            obj["displayFileName"]
              .toLowerCase()
              .includes(this.inputFileListSearch.toLowerCase()) ||
            obj["uploadedBy"]
              .toLowerCase()
              .includes(this.inputFileListSearch.toLowerCase()) ||
            obj["uploadedDate"]
              .toLowerCase()
              .includes(this.inputFileListSearch.toLowerCase())
          );
        });
        // this.fileListingData = this.filteredData;
      } else {
        this.fetchUploadedFilesData({
          userId: this.userDetails._id,
          userRole: this.userDetails.roles[0]
        });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    fetchUploadedFilesData(param) {
      fetchUploadedFilesList(param)
        .then(response => {
          if (!response._isError) {
            this.fileListingData = response._result;
            this.filteredData = response._result;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            this.totalRows = this.fileListingData.length;
          } else {
            return;
          }
        })
        .catch(e => {});
    },
    hideModal(event) {
      this.threshold = "";
      this.selectedColumns = [];
      closeActionModalFn();
    },
    deleteInputFileListFn(fileObject) {
      deleteFile({
        fileObject,
        flag: "input",
        userId: this.userDetails._id,
        userRoles: this.userDetails.roles
      })
        .then(response => {
          if (!response._isError) {
            this.fetchUploadedFilesData({
              userId: this.userDetails._id,
              userRole: this.userDetails.roles[0]
            });
            successEventNotify("File deleted successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        })
        .finally(() => {
          this.inputFileListActionModal = new PAGELIST_ACTION_MODAL();
        });
    },
    resetUploadComponent() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.form.displayFileName = "";
      this.form.algorithm = "";
      this.fileName = "";
    },
    saveUploadedFiles(formData) {
      this.currentStatus = STATUS_SAVING;
      sendUploadedFilesList(formData)
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            successEventNotify("File uploaded successfully", 1);
            this.resetUploadComponent();
            let dataPackage = response._result;
            this.uploadedFiles = [].concat(dataPackage);
            this.fileListingData = dataPackage;
            this.currentStatus = STATUS_SUCCESS;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            if (this.fileListingHeaders.length > 0) {
              this.currentStatus = STATUS_INITIAL;
              this.fetchUploadedFilesData({
                userId: this.userDetails._id,
                userRole: this.userDetails.roles[0]
              });
            }
          } else {
            this.currentStatus = STATUS_FAILED;
            errorEventNotify(response._message, 1);
            return;
          }
        })
        .catch(e => {
          this.currentStatus = STATUS_FAILED;
          this.uploadError = e;
        });
    },
    filesChange(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
        for (let i = 0; i < fileList.length; i++) {
          this.fileName += fileList[i].name;
        }

        this.currentStatus = STATUS_SAVING;
        // this.$refs.addAdditionalInfo.show();
        this.showUpload = false;
        this.showAdditionalInfo = true;
      } else {
        errorEventNotify("Could not upload file", 1);
      }
    },
    condition(value, fileObject) {
      if (
        fileObject.ownerId == this.userDetails._id ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        return true;
      } else {
        if (fileObject.shareAccess.length > 0 && this.userDetails.name) {
          return fileObject.shareAccess
            .find(x => x.name == this.userDetails.name)
            .privileges.includes(value);
        }
        return false;
      }
    },
    showInputFileDeleteModalFn(file) {
      this.inputFileListActionModal.isActive = true;
      this.inputFileListActionModal.actionType = PAGELIST_ACTION_ITEMS.deletion;
      this.inputFileListActionModal.actionPayload = file;
    },
    closeActionModalFn() {
      this.threshold = "";
      this.selectedColumns = [];
      this.selectedSetting = "";
      this.selectedAzureFile = "";
      this.inputFileListActionModal = new PAGELIST_ACTION_MODAL();
    },
    applyInputFileListActionFn(event) {
      switch (this.inputFileListActionModal.actionType) {
        case 1:
          this.deleteInputFileListFn(
            this.inputFileListActionModal.actionPayload
          );
          break;
        case 5:
          this.replaceFileFn(this.inputFileListActionModal.actionPayload);
          break;
        case 6:
          this.extractFileFn(this.inputFileListActionModal.actionPayload);
          break;
        case 7:
          this.azureUploadFileFn(this.inputFileListActionModal.actionPayload);
          break;
        default:
      }
    },
    applyFilterPanelFn(event) {
      //1.setting the dates from Tiny date picker
      this.filterPanelModel.fromDate = document.querySelector(
        ".calender.from"
      ).value;
      this.filterPanelModel.toDate = document.querySelector(
        ".calender.to"
      ).value;

      //1.Validating filter options and dates (both dates incase date selected)
      let validationFlag =
        this.filterPanelModel.fileName ||
        this.filterPanelModel.fromDate ||
        this.filterPanelModel.toDate;

      if (
        validationFlag &&
        !this.filterPanelModel.fromDate &&
        !this.filterPanelModel.toDate
      ) {
        validationFlag = true;
      } else {
        if (this.filterPanelModel.fromDate && this.filterPanelModel.toDate) {
          validationFlag = true;
          // console.log("Validations Passed");
          successEventNotify("success", 1);
        } else {
          errorEventNotify(
            "Atleast one option or both dates are required for filter!",
            1
          );
          validationFlag = false;
          return;
        }
      }

      //3.Filtering jobs
      if (validationFlag) {
        let filteredFiles = [...this.fileListingData];

        if (!checkEmptyString(this.filterPanelModel.fileName)) {
          filteredFiles = filteredFiles.filter(file => {
            return file["displayFileName"]
              .toLowerCase()
              .includes(this.filterPanelModel.fileName.toLowerCase());
          });
        }
        if (
          !checkEmptyString(this.filterPanelModel.fromDate) &&
          !checkEmptyString(this.filterPanelModel.toDate)
        ) {
          filteredFiles = filteredFiles.filter(file => {
            return (
              new Date(file["uploadedDate"]) >=
                new Date(this.filterPanelModel.fromDate) &&
              new Date(file["uploadedDate"]) <=
                new Date(this.filterPanelModel.toDate)
            );
          });
        }

        //4.Setting filtered jobs and pagination length
        this.filteredData = [];
        this.filteredData = filteredFiles;
        this.totalRows = filteredFiles.length;
      }
    },
    clearFilterPanelFn(event) {
      this.filterPanelModel = new FILTER_PANEL_MODEL();
      this.filteredData = this.totalRows = null;
      document.querySelector(".calender.from").value = "";
      document.querySelector(".calender.to").value = "";
      this.filteredData = this.fileListingData;
      this.totalRows = this.filteredData.length;
    },
    showInputFileReplaceModalFn(file, status) {
      if (status === this.replaceMode.loading) {
      } else {
        this.failedMsg = false;
        if (status === this.replaceMode.failed) {
          this.failedMsg = true;
        }
        this.inputFileListActionModal.isActive = true;
        this.inputFileListActionModal.actionType =
          PAGELIST_ACTION_ITEMS.replaceFile;
        this.inputFileListActionModal.actionPayload = file;
      }
    },
    showInputFileExtractModalFn(file, status) {
      // console.log("flag -.-->", status);
      if (status === this.extractMode.download) {
        this.onDownload(file);
      } else if (status === this.extractMode.loading) {
      } else {
        this.failedMsg = false;
        if (status === this.extractMode.failed) {
          this.failedMsg = true;
        }
        this.inputFileListActionModal.isActive = true;
        this.inputFileListActionModal.actionType =
          PAGELIST_ACTION_ITEMS.extractFile;
        this.inputFileListActionModal.actionPayload = file;
      }
    },
    replaceFileChangeFn(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
      } else {
        errorEventNotify("Could not upload file", 1);
      }
    },
    async replaceFileFn(fileObject) {
      // console.log("azure --->", this.selectedSetting, fileObject);
      if (this.selectedSetting === "AzureDataLake") {
        // console.log("column selected --->", this.selectedAzureFile);
        if (!this.selectedAzureFile) {
          errorEventNotify("All fields are mandatory", 2);
          return false;
        }
        let actionParam = {
          userId: this.userDetails._id,
          name: this.userDetails.name,
          selectedColumn: this.selectedAzureFile,
          displayFileName: fileObject.displayFileName,
          userRole: this.userDetails.roles[0],
          fileId: fileObject._id
        };
        azureReplaceFile(actionParam)
          .then(response => {
            // console.log("responsee at file upload -->", response);
            if (!response._isError) {
              // successEventNotify("File uploaded successfully", 1);
              this.showAzureFileDropdown = false;
              this.selectedAzureFile = "";
              this.selectedSetting = "";
              this.fileListingData = response._result;
              this.filteredData = response._result;
              this.fileListingHeaders = Object.keys(this.fileListingData[0]);
              this.totalRows = this.fileListingData.length;
              this.inputFileListActionModal = new PAGELIST_ACTION_MODAL();
            } else {
              throw new Error(response._message);
            }
          })
          .catch(e => {
            errorEventNotify(e.message, 2);
          });
      } else {
        let formData = new FormData();
        Array.from(Array(this.fileInfo.length).keys()).map(x => {
          formData.append(
            FILE_UPLOAD_KEY,
            this.fileInfo[x],
            this.fileInfo[x].name
          );
        });

        const obj = {
          [DISPLAYFILENAME]: fileObject.displayFileName,
          userId: this.userDetails._id
        };
        formData.append(
          "encryptedData",
          await encryptData(JSON.stringify(obj))
        );
        replaceInputFileSvc(formData)
          .then(response => {
            if (!response._isError) {
              successEventNotify("File uploaded successfully", 1);
              this.fileInfo = "";
              this.selectedSetting = "";
              this.closeActionModalFn();
            } else {
              this.fileInfo = "";
              this.closeActionModalFn();
              errorEventNotify(response._message, 1);
            }
          })
          .catch(error => {
            this.fileInfo = "";
            this.closeActionModalFn();
            errorEventNotify(error.message, 1);
          });
      }
    },
    extractFileFn(fileObject) {
      // console.log("fileobject --->", fileObject);
      // console.log("column selected --->", this.selectedColumns);
      // console.log("column threshold --->", this.threshold);
      if (this.selectAllColumns) {
        this.selectedColumns = fileObject.columns;
      }
      // console.log("ths -->", this.selectedColumns);
      let actionParam = {
        userId: this.userDetails._id,
        userRoles: this.userDetails.roles,
        selectedColumns: this.selectedColumns,
        threshold: this.threshold / 100,
        displayFileName: fileObject.displayFileName,
        fileId: fileObject._id
      };
      extractFile(actionParam)
        .then(response => {
          // console.log("responsee at file upload -->", response);
          if (!response._isError) {
            this.fileListingData = response._result;
            this.filteredData = response._result;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            this.totalRows = this.fileListingData.length;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        })
        .finally(() => {
          this.threshold = "";
          this.selectedColumns = [];
          this.selectAllColumns = false;
          this.inputFileListActionModal = new PAGELIST_ACTION_MODAL();
        });
    },
    onDownload(fileObject) {
      // console.log("fileobject --->", fileObject);
      let objectParam = {
        userId: this.userDetails._id,
        fileId: fileObject._id,
        displayFileName: fileObject.displayFileName
      };
      downloadInputFileAsCSV(objectParam)
        .then(response => {
          // console.log("response at download --->", response);
          if (!response._isError) {
            let dataParam = response._result;
            let csvRows = [];

            const headers = Object.keys(dataParam[0]);
            csvRows.push(headers.join(","));

            for (const row of dataParam) {
              const values = headers.map(header => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
              });
              csvRows.push(values.join(","));
            }

            csvRows = csvRows.join("\n");
            this.download(csvRows, fileObject.displayFileName);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(err => {
          errorEventNotify(err.message, 1);
        });
    },
    download(data, fileName) {
      // console.log("data at download --->", data);

      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", fileName + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    azureUploadFileFn(fileObject) {
      if (!this.selectedAzureFile || !this.azureDisplayFileName) {
        errorEventNotify("All fields are mandatory", 2);
        return false;
      }
      let actionParam = {
        userId: this.userDetails._id,
        name: this.userDetails.name,
        selectedColumn: this.selectedAzureFile,
        displayFileName: this.azureDisplayFileName,
        userRole: this.userDetails.roles[0]
        // fileId: fileObject._id
      };
      azureUploadFile(actionParam)
        .then(response => {
          if (!response._isError) {
            successEventNotify("File uploaded successfully", 1);
            this.showAzureFileDropdown = false;
            this.selectedAzureFile = "";
            this.fileListingData = response._result;
            this.filteredData = response._result;
            this.fileListingHeaders = Object.keys(this.fileListingData[0]);
            this.totalRows = this.fileListingData.length;
            this.inputFileListActionModal = new PAGELIST_ACTION_MODAL();
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 2);
        });
    },
    showAzureFileListModalFn(file) {
      this.inputFileListActionModal.isActive = true;
      this.inputFileListActionModal.actionType =
        PAGELIST_ACTION_ITEMS.fetchAzureFile;
      this.inputFileListActionModal.actionPayload = file;
    },
    azureFetchFile() {
      this.showAzureFileListModalFn();
      let actionParam = {
        userId: this.userDetails._id
      };
      fetchAzureFiles(actionParam)
        .then(response => {
          if (!response._isError) {
            this.azureFileList = response._result;
            this.showAzureFileDropdown = true;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        });
    },
    azureConfigStatusFn() {
      let actionParam = {
        userId: this.userDetails._id
      };
      azureConfigStatus(actionParam)
        .then(response => {
          if (!response._isError) {
            if (!response._result) {
              this.configMsg =
                "First you need to configure Azure Data lake. And then try again.";
              this.azureConfigStatus = true;
            }
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        });
    },
    pullAzureFiles() {
      if (this.selectedSetting === "AzureDataLake" && !this.azureConfigStatus) {
        let actionParam = {
          userId: this.userDetails._id
        };
        fetchAzureFiles(actionParam)
          .then(response => {
            if (!response._isError) {
              this.azureFileList = response._result;
              this.showAzureFileDropdown = true;
            } else {
              throw new Error(response._message);
            }
          })
          .catch(e => {
            errorEventNotify(e.message, 1);
          });
      } else {
        console.log("local selected");
      }
      // this.showAzureFileListModalFn();
    },
    getStatusColClass(status) {
      switch (status) {
        case "in-progress":
          return "in-progress";
          break;
        case "Success":
          return "Completed";
          break;
        default:
          return null;
          break;
      }
    }
  },
  mounted() {
    this.resetUploadComponent();
    TinyDatePicker(document.querySelector(".calender.from"));
    TinyDatePicker(document.querySelector(".calender.to"));
    this.socket.on("REFRESH_INPUT_PAGE", data => {
      this.fetchUploadedFilesData({
        userId: this.userDetails._id,
        userRole: this.userDetails.roles[0]
      });
    });
  }
};
</script>

<style scoped>
.filter-list > li {
  display: inline-block;
  margin: 0 0px;
}

.floating-label {
  margin-bottom: 22px;
}
.hideicon {
  display: none;
}
.icon24.icon-failed {
  background-position: -1018px -62px;
  transform: rotate(270deg);
}
.azure-upload {
  padding: 1em;
  background-color: #e9e9e9;
  text-align: center;
}
.azure-btn {
  text-transform: uppercase;
  color: #ffffff;
  height: 28px;
}
.samplingcheckbox {
  padding-left: 26px;
  padding-bottom: 20px;
  color: darkgray;
}
.action-list li a.progrss-bar {
  padding: 0 !important;
}
</style>
