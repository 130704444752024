<template>

</template>

<script>
import { microsoftLogin } from "./../services/parentservices.js";
export default {
  name: "home",
  data() {
    return {
      tempLoader: false
    };
  },
  created() {
    this.microsoftLoginFn();
  },
  mounted() {
    if (this.tempLoader === false) {
      const loader = document.querySelector(".loading-gif");
      loader.classList.add("mystyle");
    }
  },
  methods: {
    microsoftLoginFn() {
      const actionPayload = {
        ldapToken: this.$route.query.a
      };
      // console.log("action --->", actionPayload);
      this.$store
        .dispatch("microsoftLoginAction", actionPayload)
        .then(response => {
          // console.log("logged in --->", this.$store.getters.isLoggedIn);
          this.tempLoader = true;
          const loader = document.querySelector(".loading-gif");
          loader.classList.remove("mystyle");
          if (this.$store.getters.isLoggedIn) {
            this.$router.push({
              name: "jobLanding"
            });
          } else {
            this.$router.push("/");
          }
        })
        .catch();
    }
  }
};
</script>

<style>
</style>