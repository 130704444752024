<template>
  <b-alert
    class="notify-actions"
    :show="dismissCountDown"
    dismissible
    variant="warning"
    @dismissed="dismissCountDown=0"
    @dismiss-count-down="countDownChanged"
  >
    This alert will dismiss after {{dismissCountDown}} seconds...
  </b-alert>
</template>

<script>
export default {
  name: "notifyAction",
  data() {
    return {
      dismissSecs: 7,
      dismissCountDown: 0
    };
  },
  created() {
    this.showAlert();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
</script>

<style scoped>
.notify-action {
  z-index: 5;
  position: fixed;
  width: 100%;
  text-align: center;
}
</style>
