<template>
  <div class="fr-warapper">
    <!-- Step Dependency modal for publish view starts here -->
    <b-modal
      id="step-dependency-modal"
      ref="stepDependency"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <h5 class="modal-title">
          <i class="icon16 icon-plus-blue"></i> Confirmation to Delete Step
        </h5>
      </div>
      <div class="modal-body">
        <p>{{ msg }}</p>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="
            deleteStepFn(
              $event,
              currentStep,
              finalStepArray,
              dependentStepArray
            )
          "
        >
          <i class="icon14 icon-save"></i> Ok
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="cancelStepFn()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </b-modal>
    <!-- Step Dependency modal for publish view ends here -->

    <!-- Publish Export to constructor Modal Written on: 03-Sept-2019 Starts here-->
    <b-modal
      id="publish-export-to-modal"
      ref="publishExportToModalRef"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <h5 class="modal-title">Export To Details</h5>
      </div>
      <div class="modal-body">
        <input
          placeholder="File Name"
          class="form-control search-control awesomplete mt-2"
          type="text"
          v-model="publishConstructor.fileNameOrDbName"
        />
        <multiselect
          class="mul-required-field"
          placeholder="Publish Setting Name"
          v-model="publishConstructor.exportTo"
          track-by="settingsName"
          label="settingsName"
          :options="allPublishSettings"
          :close-on-select="true"
        ></multiselect>
        <multiselect
          v-if="
            publishConstructor.exportTo &&
              (publishConstructor.exportTo.fileType === 'csv' ||
                publishConstructor.exportTo.connType === 'Sharepoint')
          "
          track-by="value"
          label="text"
          v-model="publishConstructor.customDelim"
          :options="delimiterOption"
          :close-on-select="true"
        ></multiselect>
        <multiselect
          track-by="value"
          label="value"
          v-model="publishConstructor.customColumnsToPublish"
          :options="gridDataTableInputCols"
          :close-on-select="true"
          :multiple="true"
        ></multiselect>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="onExportToDone()"
        >
          <i class="icon14 icon-save"></i> Done
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onExportToCancel()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </b-modal>
    <!-- Publish Export to constructor Modal Written on: 03-Sept-2019  Ends here-->
    <div>
      <!-- Nexus NavBar  -->
      <ams-navbar></ams-navbar>
      <!-- Nexus NavBar  -->
    </div>
    <div class="fr-content">
      <page-alert />
      <div
        class="container-fluid"
        style="overflow:hidden;"
      >
        <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
          <div class="heading d-inline-block">
            <h3 class="mb-0">
              <span>Job Name:</span>
              {{ jobParent.jobNameToDisplay }}
            </h3>
          </div>
          <div>
            <button
              class="btn btn-primary"
              @click="saveAndPublish()"
              :disabled="jobParent.status === 6"
            >
              <i class="icon16 icon-publish"></i>Save and Publish
            </button>
            <button
              class="btn btn-outline-secondary"
              @click="goBackToJobSetup()"
            >
              <i class="icon14 icon-back"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <!-- b-container component starts from here -->
      <b-container
        fluid
        class="bv-example-row"
        style="margin-top: 25px;"
      >
        <b-row>
          <b-col
            sm="5"
            style="overflow: scroll; height: 80vh;"
          >
            <b-row align-v="start">
              <b-col>
                <div class="headingFontStyle">
                  <strong>Publish Configuration</strong>
                  <button
                    class="btn btn-outline-secondary"
                    style="margin-left: 10px;"
                    @click="addExportTo()"
                  >
                    <i class="icon14 icon-plus-blue"></i>Add
                  </button>
                  <br />
                  <!-- <span class="help-data-color">Publish the grid data to the selected publish configuration</span> -->
                </div>
                <!-- Configs List Starts Here -->
                <div
                  v-if="this.allExportToSettings.length > 0"
                  style="font-size: 12px; margin-top: 15px; overflow: scroll; max-height: 226px;"
                >
                  <hr />
                  <div
                    v-for="(exportSetting, index) in this.allExportToSettings"
                    :key="index"
                  >
                    <label style="font-size: 12px; margin-top: 18px;">
                      <strong>{{ exportSetting.exportTo.settingsName }}</strong>
                      <a
                        href="Javascript:void(0);"
                        @click="editExportConfig(exportSetting, index)"
                      >
                        <i
                          class="icon24 icon-edit"
                          title="Edit"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteExportConfig(index)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </label>
                    <div>
                      <label>
                        <span>
                          <strong>File Name -&nbsp;&nbsp;</strong>
                        </span>
                        <span class="help-data-color">{{
                          exportSetting.fileNameOrDbName
                        }}</span>
                      </label>
                    </div>
                    <div>
                      <label v-if="exportSetting.customColumnsToPublish.length > 0">
                        <span>
                          <strong>Columns To Publish -&nbsp;&nbsp;</strong>
                        </span>
                        <span
                          v-for="(eachColumn,
                          index) in exportSetting.customColumnsToPublish"
                          :key="index"
                          class="help-data-color"
                        >{{ eachColumn.value }} &nbsp;&nbsp;</span>
                      </label>
                    </div>
                    <div>
                      <label v-if="
                          exportSetting.exportTo.fileType &&
                            exportSetting.exportTo.fileType === 'csv'
                        ">
                        <span>
                          <strong>Delimiter -&nbsp;&nbsp;</strong>
                        </span>
                        <span class="help-data-color">{{
                            exportSetting.customDelim.text
                          }}
                          &nbsp;&nbsp;</span>
                      </label>
                    </div>
                    <hr />
                  </div>
                </div>
                <!-- Configs List Ends Here -->
                <!-- <div
                  class='ongoingdata'
                  style="font-size: 12px; margin-top: 15px;"
                > -->
                <!-- <b-form-checkbox
                    id="checkbox-1"
                    v-model="dataReceivedAs.isComplete"
                    name="checkbox-1"
                    v-b-tooltip.hover
                    title="Ongoing Job"
                  ><strong style="padding: 7px;vertical-align: middle;">Ongoing Job</strong></b-form-checkbox> -->
                <!-- </div> -->
                <div class="ongoingdata checkbox">
                  <input
                    type="checkbox"
                    v-model="dataReceivedAs.isComplete"
                    v-b-tooltip.hover
                    title="Select for full data refreshes."
                  />
                  <strong style="padding: 27px; font-size: 13px ">Ongoing Job</strong>
                  <span class="checkmark"> </span>
                </div>
                <b-row
                  align-v="start"
                  v-if="dataReceivedAs.isComplete === true"
                >
                  <b-col sm="3">
                    <label
                      v-b-tooltip.hover
                      title="Provide unique lookup column to identify new records."
                      style="font-size: 12px; margin-top: 18px;"
                    >
                      <strong>Lookup Column</strong>
                    </label>
                  </b-col>
                  <b-col
                    sm="7"
                    style="margin-left: -20px; width:110%;"
                  >
                    <div>
                      <multiselect
                        class="mul-required-field"
                        placeholder="Column To Perform Lookup"
                        v-model="dataReceivedAs.ifCompleteThenColumnToLookup"
                        track-by="text"
                        label="text"
                        :options="gridDataTableInputCols"
                        :close-on-select="true"
                      ></multiselect>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row align-v="start">
              <b-col style="margin-top: 20px;">
                <div class="headingFontStyle">
                  <strong>Schedule Configuration</strong>
                  <br />
                  <span class="help-data-color">Schedule the job for ongoing operations</span>
                </div>
                <b-row>
                  <b-col>
                    <schedule-job v-on:scheduleObj="scheduleObjFn"></schedule-job>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div
                      class="headingFontStyle"
                      style="margin-top: 30px;"
                    >
                      <strong>Notification List</strong>
                      <br />
                      <span class="help-data-color">Selected emails will receive notification about job
                        activities.</span>
                    </div>
                    <div>
                      <multiselect
                        v-model="selected"
                        tag-placeholder="Add this as new tag"
                        placeholder="Search or add an email"
                        label="email"
                        track-by="email"
                        :options="sentForReviewEmailIds"
                        :multiple="true"
                        :taggable="true"
                        @tag="addTag"
                      ></multiselect>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="6">
            <b-row
              align-v="center"
              style="margin-left: 6%"
            >
              <b-col>
                <b-row align-v="start">
                  <b-col sm="4">
                    <div class="headingFontStyle">
                      <strong>Execute Step up to</strong>
                      <br />
                      <span class="help-data-color">Job Execution will pause at the selected step</span>
                    </div>
                  </b-col>
                  <b-col
                    sm="4"
                    style="margin-left: 10px; width:110%;"
                  >
                    <multiselect
                      v-model="tillWhereToExecute"
                      placeholder="Select step number"
                      track-by="stepNumber"
                      label="stepNumber"
                      :options="stepsAction"
                      :close-on-select="true"
                    ></multiselect>
                  </b-col>
                </b-row>
                <div
                  class="headingFontStyle"
                  style="margin-top : 8px;"
                >
                  <strong>Steps performed on job</strong>
                  <div
                    class="step-list table-action-list steplistStyle"
                    v-if="this.stepsAction.length != 0"
                    style="margin-top: 25px; height: 65vh"
                  >
                    <table
                      style="width:100%"
                      id="stepTable"
                      class="table-header-font"
                    >
                      <tr>
                        <th>Step Name</th>
                        <th>Performed on Column</th>
                        <th>Delete Step</th>
                        <th>Create Backup</th>
                      </tr>
                      <tr
                        v-for="(step, index) in this.stepsAction"
                        :key="index"
                      >
                        <td>{{ step.actionName }}</td>
                        <td v-if="step.actionName === actionName.update">
                          {{ step.actionParam.columnName.value }}
                        </td>
                        <td v-if="step.actionName === actionName.blank">
                          {{ step.actionParam.newColumnName }}
                        </td>
                        <td v-if="step.actionName === actionName.split">
                          {{ step.actionParam.targetColumn.value }}
                          <br />
                          Split By: {{ step.actionParam.delimeter }}
                          <span v-if="step.actionParam.delimeter === ' '">space</span>
                        </td>
                        <td v-if="step.actionName === actionName.merge">
                          {{ step.actionParam.targetColumn.value }} +
                          {{ step.actionParam.mergeWithColumn.value }} =>
                          {{ step.actionParam.mergingColumnName }}
                        </td>
                        <td v-if="step.actionName === actionName.delete">
                          {{ step.actionParam.value }}
                        </td>
                        <td v-if="step.actionName === actionName.copy">
                          Src : {{ step.actionParam.targetColumn.value }}
                          <br />
                          Dest: {{ step.actionParam.newColumnName }}
                        </td>
                        <td v-if="step.actionName === actionName.insert">
                          Column name: {{ step.actionParam.columnName.value }}
                          <br />
                          Insert Data: {{ step.actionParam.newValue }}
                        </td>
                        <td v-if="step.actionName === actionName.globalReplace">
                          Replace {{ step.actionParam.selectedText }} with
                          {{ step.actionParam.replacementText }} in
                          {{ step.actionParam.selectedTextColumn.value }}
                        </td>
                        <td v-if="step.actionName === actionName.algoExecute">
                          {{ step.actionParam.algoName }}
                        </td>
                        <td v-if="
                            step.actionName === actionName.removeDuplicates ||
                              step.actionName === actionName.bulkColumnDelete
                          ">
                          Selected Columns :
                          <span
                            v-for="(value, index) in step.actionParam.data"
                            :key="index"
                          >{{ value }}</span>
                        </td>
                        <td v-if="
                            step.actionName === actionName.arithmaticOperation
                          ">
                          <span v-if="step.actionParam.column2">Operation: {{ step.actionParam.column1 }}
                            {{ step.actionParam.selectedOperator }}
                            {{ step.actionParam.column2 }} =
                            {{ step.actionParam.column }}</span>
                          <span v-if="step.actionParam.staticNumber">
                            Operation:
                            <br />
                            {{ step.actionParam.column1 }}
                            {{ step.actionParam.selectedOperator }}
                            {{ step.actionParam.staticNumber }} =
                            {{ step.actionParam.column }}
                          </span>
                        </td>
                        <td style="text-align: center; vertical-align: right;">
                          <a
                            :id="'deleteDisabled' + index"
                            href="javascript:void(0);"
                            class="list-item floatingDeleteIcon"
                            data-placement="top"
                            title="UnHide"
                            @click="removeSteps(step)"
                          >
                            <i class="icon14 icon-close-blue mr-3 remove-accordion"></i>
                          </a>
                        </td>
                        <td style="text-align: center; vertical-align: right;">
                          <div class="takeBackupCheckbox checkbox">
                            <input
                              class="takeBackupCheckbox"
                              type="checkbox"
                              :id="'checkbox-1' + index"
                              v-model="
                                jobParent.steps[index].toTakeBackUpAtThisStep
                              "
                              name="To take backup at this step"
                              :disabled="
                                step.actionName === actionName.algoExecute
                              "
                            />
                            <span class="checkmark"></span>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-if="this.stepsAction.length == 0">
                  <p>No Steps found for this job</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <!-- b-container component ends here -->
    </div>
  </div>
</template>

<script>
/*
  Nexus publish page. Changed from modal view to a seperate page.
  Page includes nexus-specific componenets such as navBar, PageAlert,ScheduleJob

  Dated :: 02 July 2019
  Last Edited :: 03 September 2019

*/

import navBar from "../components/Navbar.vue";
import PageAlert from "../components/PageLevelAlert.vue";
import Multiselect from "vue-multiselect";
import ScheduleJob from "../components/ScheduleJob.vue";
import Alert from "../components/Alert.vue";

import * as parentservices from "../services/parentservices.js";
import { mapGetters } from "vuex";
import { publishData } from "../assets/js/PublishData";
import {
  encryptText,
  decryptText,
  checkEmptyObject,
  testForbiddenChars
} from "./../utilbin/common-utils.js";
import {
  ACTION_NAME,
  STEP_MODE,
  PUBLISH_CONSTRUCTOR,
  SCHEDULEOBJECT
} from "../assets/js/constant";
import {
  validateDeleteStepDependencies,
  addStep,
  validateDependencies
} from "./../utilbin/step-utility";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";

import { EventBus } from "../main";

import router from "../router";
import _ from "lodash";

const jobModel = {
  _id: null,
  jobName: "",
  status: null,
  active: null,
  inputFileName: "",
  algorithm: [],
  shareAccess: [],
  owner: "",
  sentForReview: [],
  jobNameToDisplay: "",
  steps: [],
  dataSets: null,
  createdDate: null,
  lastBackup: null,
  jobId: "",
  jobStepStatus: {},
  modifiedBy: "",
  notificationReceiver: [],
  stepNumberTillWhereToExecute: 9999
};

export default {
  name: "nexus-publish-job-view",
  components: {
    "ams-navbar": navBar,
    "page-alert": PageAlert,
    Multiselect,
    "schedule-job": ScheduleJob
  },
  data() {
    return {
      allPublishSettings: [],
      jobParent: jobModel,
      publishData: publishData,
      currentSelectionForPublishSettings: "",
      sentForReviewEmailIds: [],
      jobId: this.$route.params,
      selected: [],
      actionName: ACTION_NAME,
      stepsAction: [],
      msg: "",
      tillWhereToExecute: 0,
      dataReceivedAs: {
        isComplete: false,
        ifCompleteThenColumnToLookup: ""
      },
      delimiter: { text: "Comma", value: ",", extension: "csv" },
      delimiterOption: [
        { text: "Tab", value: "\t", extension: "tsv" },
        { text: "Comma", value: ",", extension: "csv" },
        { text: "Pipe", value: "|", extension: "txt" }
      ],
      allExportToSettings: [],
      publishConstructor: {},
      gridDataTableInputCols: [],
      publishSettingName: [],
      scheduleObject: new SCHEDULEOBJECT(),
      inputFilesList: []
    };
  },
  computed: {
    ...mapGetters({
      editAction: "getEditAction",
      userDetails: "getUserObj",
      showSubset: "showSubset"
    })
  },
  mounted() {
    this.fetchAllSavedPublishSettings();
  },
  created() {
    this.fetchJobParentFn();
    this.fetchNotificationUsers();
    this.fetchPublishDetailsForJob();
    this.fetchInputFilesList();
  },
  methods: {
    fetchAllSavedPublishSettings() {
      parentservices.fetchAllPublishSettings().then(response => {
        if (response._isError === true) {
          // console.log("Internal server error in fetching publish settings");
        } else {
          this.allPublishSettings = response._result;
        }
      });
    },
    fetchUsers() {
      parentservices
        .fetchReviewUsersList({
          jobId: this.jobId.jobId,
          userId: this.userDetails._id
        })
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            this.sentForReviewEmailIds = response._result;
          }
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    onClickSaveAndPublish() {
      EventBus.$emit("save-schedule-setting-event", {
        jobId: this.jobId.jobId,
        jobParent: this.jobParent
      });
    },
    validateJobSetupRouteParam: param => {
      if (
        !checkEmptyObject(param) &&
        "jobId" in param &&
        param["jobId"] !== "" &&
        param["jobId"] !== undefined &&
        param["jobId"] !== null
      ) {
        return true;
      } else {
        return false;
      }
    },
    fetchJobParentFn() {
      let routeParam = this.$route.params;
      routeParam.userObj = this.userDetails;
      routeParam.userId = this.userDetails._id;
      this.jobId = this.$route.params;
      if (this.validateJobSetupRouteParam(routeParam)) {
        parentservices.fetchJobDetails(routeParam).then(response => {
          if (response._isError == false && response._result) {
            this.jobParent = response._result.jobObject;
            this.publishData.fileOrDbName = this.jobParent.fileNameOrDbName;
            this.stepsAction = response._result.jobObject.steps;
            this.selected = this.jobParent.notificationReceiver;
            this.selected.forEach(x => {
              x.email = decryptText(x.email);
            });
            this.dataReceivedAs = this.jobParent.dataReceivedAs;
            this.fetchColumnsHeaders();
            if (this.jobParent.schedule) {
              this.scheduleObject.inputRepoValue = {
                value: this.jobParent.schedule.inputType
              };
              this.scheduleObject.fileName = this.jobParent.schedule.fileName;
              this.scheduleObject.displayFileName = this.jobParent.schedule.displayFileName;
              this.scheduleObject.scheduleType =
                this.jobParent.schedule.scheduleType == "auto"
                  ? "auto"
                  : "timeBased";
              this.scheduleObject.time = this.jobParent.schedule.time;
              if (this.jobParent.schedule.scheduleType === "daily") {
                this.scheduleObject.scheduleValue = {
                  value: "Day"
                };
              }

              if (this.jobParent.schedule.scheduleType === "week") {
                let week;
                switch (this.jobParent.schedule.scheduleValue) {
                  case 0:
                    week = "Sunday";
                    break;
                  case 1:
                    week = "Monday";
                    break;
                  case 2:
                    week = "Tuesday";
                    break;
                  case 3:
                    week = "Wednesday";
                    break;
                  case 4:
                    week = "Thursday";
                    break;
                  case 5:
                    week = "Friday";
                    break;
                  case 6:
                    week = "Saturday";
                    break;
                  default:
                    break;
                }
                this.scheduleObject.scheduleValue = {
                  value: "Week"
                };
                this.scheduleObject.weekValue = { value: week };
              }

              if (this.jobParent.schedule.scheduleType === "month") {
                this.scheduleObject.scheduleValue = {
                  value: "Month"
                };
                this.scheduleObject.monthValue = this.jobParent.schedule.scheduleValue;
              }
            }
          }
        });
      }
    },
    removeSteps(step) {
      const dependencies = validateDeleteStepDependencies(
        step,
        this.stepsAction
      );
      this.finalStepArray = dependencies.finalStepArray;
      this.dependentStepArray = dependencies.presentStep;
      this.currentStep = step;
      if (dependencies.presentStep.length > 1) {
        this.$refs.stepDependency.show();
        let dependentActions = [];
        for (let i = 0; i < dependencies.presentStep.length; i++) {
          dependentActions.push(dependencies.presentStep[i].actionName);
        }
        this.msg =
          "It has dependencies in the following steps : " +
          dependentActions +
          ". On click yes, below step will also get deleted.";
      } else {
        this.$refs.stepDependency.show();
        this.msg = "Are you sure you want to delete this step ?";
      }
    },
    deleteStepFn(event, currentStep, updatedSteps, dependentStepArray) {
      let executeFound = dependentStepArray.filter(el => {
        return el.actionName === ACTION_NAME.algoExecute;
      });
      if (executeFound.length > 0) {
        for (let j = currentStep.stepNumber; j < updatedSteps.length; j++) {
          if (updatedSteps[j].actionName === "Execute") {
            break;
          } else {
            currentStep.stepNumber === 0
              ? (updatedSteps[j].lastBackup =
                  updatedSteps[currentStep.stepNumber].lastBackup)
              : (updatedSteps[j].lastBackup =
                  updatedSteps[currentStep.stepNumber - 1].lastBackup);
          }
        }
      }
      if (this.editAction.prevStep !== "") {
        this.$store
          .dispatch("updateDisabledStepAction", currentStep.stepNumber)
          .then(response => {})
          .catch(e => {});
      }
      const actionParam = {
        jobId: this.jobParent._id,
        updatedSteps: updatedSteps,
        userId: this.userDetails._id
      };
      this.stepsAction = updatedSteps;
      if (currentStep.stepNumber <= this.tillWhereToExecute.stepNumber) {
        this.tillWhereToExecute = 0;
      }
      this.$refs.stepDependency.hide();
    },
    cancelStepFn() {
      this.$refs.stepDependency.hide();
    },
    saveAndPublish() {
      if (this.allExportToSettings.length === 0) {
        errorEventNotify("No export setting has been configured", 1);
        return;
      }
      if (
        this.scheduleObject.displayFileName ||
        this.scheduleObject.inputRepoValue ||
        this.scheduleObject.fileName
      ) {
        if (
          this.scheduleObject &&
          this.scheduleObject.displayFileName &&
          this.scheduleObject.inputRepoValue &&
          this.scheduleObject.fileName
        ) {
          // console.log("schedule obj --->", this.scheduleObject);
          let fileExists = this.inputFilesList.filter(file => {
            return (
              !this.jobParent.schedule &&
              file.displayFileName === this.scheduleObject.displayFileName
            );
          });
          let fileNameValidation = /([a-zA-Z0-9\s_\\.\-\(\):])+(.csv|.xlsx|.xls)$/i;
          if (!fileNameValidation.test(this.scheduleObject.fileName)) {
            errorEventNotify(
              "Only files with extension .xlsx .xls .csv supported",
              1
            );
            return;
          } else if (
            fileExists.length > 0 ||
            testForbiddenChars(this.scheduleObject.displayFileName)
          ) {
            errorEventNotify(
              "Display file name exist or may contain a special character, choose different name",
              1
            );
            return;
          } else {
          }
        } else {
          errorEventNotify(
            "All fields are mandatory for schedule configuration",
            1
          );
          return;
        }
      }
      if (this.tillWhereToExecute.stepNumber) {
        this.jobParent.stepNumberTillWhereToExecute = this.tillWhereToExecute.stepNumber;
      }

      this.jobParent.notificationReceiver = [];
      this.jobParent.steps = this.stepsAction;
      this.jobParent.dataReceivedAs = this.dataReceivedAs;

      for (let idx = 0; idx < this.selected.length; idx++) {
        this.jobParent.notificationReceiver.push({
          email: encryptText(this.selected[idx].email),
          _id: this.selected[idx]._id
        });
      }

      let paramToSaveJobSteps = this.jobParent;
      paramToSaveJobSteps.jobId = this.jobParent._id;
      paramToSaveJobSteps.userObj = this.userDetails;
      paramToSaveJobSteps.checkConnection = true;
      paramToSaveJobSteps.publishSettingNameList = this.publishSettingName;
      let publishDetailsForJob = [];
      let publishColMissing = [];
      for (
        let eachExportConfig = 0;
        eachExportConfig < this.allExportToSettings.length;
        eachExportConfig++
      ) {
        if (
          this.allExportToSettings[eachExportConfig].customColumnsToPublish ===
          ""
        ) {
          this.allExportToSettings[
            eachExportConfig
          ].customColumnsToPublish = this.gridDataTableInputCols;
        }
        publishDetailsForJob.push({
          fileNameOrDbName: this.allExportToSettings[eachExportConfig]
            .fileNameOrDbName,
          publishSettingId: this.allExportToSettings[eachExportConfig].exportTo
            ._id,
          customColumnsToPublish: this.allExportToSettings[
            eachExportConfig
          ].customColumnsToPublish.map(columns => {
            return { value: columns.value };
          }),
          customDelim: this.allExportToSettings[eachExportConfig].customDelim
        });
        let diff = _.differenceBy(
          this.allExportToSettings[eachExportConfig].customColumnsToPublish,
          this.gridDataTableInputCols,
          "value"
        );
        if (diff.length > 0) {
          publishColMissing.push(diff);
        }
      }
      if (publishColMissing.length > 0) {
        errorEventNotify(
          "Columns mentioned in the publish setting are not available on the grid.",
          1
        );
        return;
      }
      parentservices.saveAlgoSetupData(paramToSaveJobSteps).then(response => {
        if (response._isError) {
          errorEventNotify(response._message, 1);
        } else {
          let publishParam = {
            jobParent: this.jobParent,
            fileName: this.jobParent.fileNameOrDbName,
            jobId: this.jobParent._id,
            userObj: this.userDetails,
            publishExportConfig: this.allExportToSettings,
            publishDetailsForJob: publishDetailsForJob,
            subsetDataMode: this.showSubset
          };
          parentservices
            .publishJobParentService(publishParam)
            .then(response => {
              this.columnsToPublish = [];
              this.delimiter = { text: "Comma", value: ",", extension: "csv" };
              EventBus.$emit("save-schedule-setting-event", {
                jobId: this.jobParent._id,
                jobParent: this.jobParent
              });
              this.$router.push({
                name: "jobLanding"
              });
            });
        }
      });
    },
    fetchNotificationUsers() {
      parentservices
        .fetchAllUsers({
          userId: this.userDetails._id,
          jobId: this.jobId.jobId
        })
        .then(response => {
          this.sentForReviewEmailIds = response._result;
        });
    },
    goBackToJobSetup() {
      this.$router.push({
        name: "jobSetupEdit",
        params: {
          jobId: this.jobParent._id,
          status: 4
        }
      });
    },
    addExportTo() {
      this.publishConstructor = new PUBLISH_CONSTRUCTOR();
      this.$refs.publishExportToModalRef.show();
    },
    onExportToDone() {
      if (!this.valid()) {
        errorEventNotify("Required fields cannot be left blank", 1);
        return;
      }
      if (!this.publishConstructor.customDelim) {
        this.publishConstructor.customDelim = {
          text: "Comma",
          value: ",",
          extension: "csv"
        };
      }
      if (this.publishConstructor.exportTo.connType == "SFTP") {
        this.publishSettingName.push(
          this.publishConstructor.exportTo.settingsName
        );
      }
      if (this.publishConstructor.isEditing) {
        let idx = this.publishConstructor.index;
        delete this.publishConstructor.isEditing;
        delete this.publishConstructor.index;
        this.allExportToSettings[idx] = this.publishConstructor;
      } else {
        this.allExportToSettings.push(this.publishConstructor);
      }
      this.publishConstructor = new PUBLISH_CONSTRUCTOR();
      this.$refs.publishExportToModalRef.hide();
    },
    onExportToCancel() {
      this.publishConstructor = new PUBLISH_CONSTRUCTOR();
      this.$refs.publishExportToModalRef.hide();
    },
    fetchColumnsHeaders() {
      parentservices
        .fetchGridColumns({
          jobId: this.jobParent._id,
          userId: this.userDetails._id,
          jobName: this.jobParent.jobName
        })
        .then(res => {
          this.gridDataTableInputCols = res._result;
        });
    },
    editExportConfig(data, index) {
      this.publishConstructor = new PUBLISH_CONSTRUCTOR();
      this.publishConstructor.fileNameOrDbName = data.fileNameOrDbName;
      this.publishConstructor.exportTo = data.exportTo;
      this.publishConstructor.customColumnsToPublish =
        data.customColumnsToPublish;
      this.publishConstructor.customDelim = data.customDelim;
      this.publishConstructor.extension = data.extension;
      this.publishConstructor.isEditing = true;
      this.publishConstructor.index = index;
      this.$refs.publishExportToModalRef.show();
    },
    valid() {
      return (
        this.publishConstructor.fileNameOrDbName &&
        this.publishConstructor.exportTo
      );
    },
    deleteExportConfig(index) {
      this.allExportToSettings.splice(index, 1);
    },
    fetchPublishDetailsForJob() {
      parentservices
        .fetchPublishDetailsForJob({
          userId: this.userDetails._id,
          jobId: this.jobId.jobId
        })
        .then(response => {
          if (response._isError === true) {
            errorEventNotify("Cannot load existing config", 1);
          } else {
            if (response._result.length != 0) {
              this.allExportToSettings = response._result;
            }
          }
        });
    },
    scheduleObjFn(scheduleObject) {
      this.scheduleObject = scheduleObject;
    },
    fetchInputFilesList() {
      parentservices
        .fetchUploadedFilesList({
          userId: this.userDetails._id,
          userRole: this.userDetails.roles[0]
        })
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            this.inputFilesList = response._result;
          }
        });
    }
  }
};
</script>

<style scoped>
.fliterBorder {
  border-bottom: 2px solid lightgray;
}

.steplistStyle {
  overflow: scroll;
  height: 350px;
}

.floatingDeleteIcon {
  text-align: center;
}

.cardStyle {
  background-color: #e9e9e9;
  font-size: 12px;
}

.headingFontStyle {
  font-size: 13px;
}

#stepTable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#stepTable td,
#stepTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#stepTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#stepTable tr:hover {
  background-color: #ddd;
}

#stepTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0090c4;
  color: white;
}

.table-header-font {
  font-size: 13px;
  font-weight: 100;
}
.help-data-color {
  color: #0090c4;
  font-size: 11px;
}

.takeBackupCheckbox {
  display: inline-grid;
}
.ongoingdata {
  margin-top: 23px;
}
</style>