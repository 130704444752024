import apiService from "./axiosservice.js";
export default {
  getAllMoviesData() {
    return apiService()
      .get("/moviesdb1.2L.json")
      .then(response => {
        return response.data;
      });
  },
  fetchJobCreateEditSelectFile() {
    return apiService()
      .get("/fetch-inputfilenames")
      .then(response => {
        return response.data;
      });
  }
};

export const conditionalGlobalStringFilter = param => {
  return apiService()
    .post("/cond-stringfilter-global", param)
    .then(response => {
      return response.data;
    });
};

export const saveGlobalDataManipulations = param => {
  return apiService()
    .post("/update-globallisting-records", param)
    .then(response => {
      return response.data;
    });
};

export const fetchUploadedFilesList = param => {
  return apiService()
    .post("/fetch-inputfileList", param)
    .then(response => {
      return response.data;
    });
};

export const sendUploadedFilesList = param => {
  return apiService()
    .post("/file", param)
    .then(response => {
      return response.data;
    });
};

export const fetchGlobalVariantsData = param => {
  return apiService()
    .post("/fetch-globaldata-list", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAllJobList = param => {
  return apiService()
    .post("/fetch-activejobs", param)
    .then(response => {
      return response.data;
    });
};

export const saveNewJobData = param => {
  return apiService()
    .post("/savejob", param)
    .then(response => {
      return response.data;
    });
};

export const fetchJobDetails = param => {
  return apiService()
    .post("/editjob", param)
    .then(response => {
      return response.data;
    });
};

export const fetchJobDetailsFromBackup = param => {
  return apiService()
    .post("/fetchjobbackup", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAlgoMasterData = param => {
  return apiService()
    .post("/fetch-algomaster", param)
    .then(response => {
      return response.data;
    });
};

export const saveAlgoSetupData = param => {
  return apiService()
    .post("/save-job-metadata", param)
    .then(response => {
      return response.data;
    });
};

export const sendAlgorithmLearningFile = param => {
  return apiService()
    .post("/upload-learningfile", param)
    .then(response => {
      return response.data;
    });
};

export const executeAlgorithm = param => {
  return apiService()
    .post("/execute-algo", param)
    .then(response => {
      return response.data;
    });
};

export const deleteJob = param => {
  return apiService()
    .post("/delete-job", param)
    .then(response => {
      return response.data;
    });
};

export const automateStep = param => {
  return apiService()
    .post("/play-steps", param)
    .then(response => {
      return response.data;
    });
};

export const updateColumnMetaData = param => {
  return apiService()
    .post("/update-column-metadata", param)
    .then(response => {
      return response.data;
    });
};

export const fetchGridData = param => {
  return apiService()
    .post("/fetch-grid-data-review", param)
    .then(response => {
      return response.data;
    });
};

export const deleteFile = param => {
  return apiService()
    .post("/delete-uploaded-data", param)
    .then(res => {
      return res.data;
    });
};

export const saveGridDataManipulationService = param => {
  return apiService()
    .post("/update-grid-records", param)
    .then(response => {
      return response.data;
    });
};

//Grid column level operations STARTS
const sortSingleClSvc = param => {
  return apiService()
    .post("/sort-grid", param)
    .then(response => {
      return response.data;
    });
};
const freeTextSearchSvc = param => {
  return apiService()
    .post("/search-grid", param)
    .then(response => {
      return response.data;
    });
};
const conditionalStringFilterSvc = param => {
  return apiService()
    .post("/cond-stringfilter-grid", param)
    .then(response => {
      return response.data;
    });
};
const conditionalNumberFilterSvc = param => {
  return apiService()
    .post("/cond-numberfilter-grid", param)
    .then(response => {
      return response.data;
    });
};

const multipleConditionalFilterSvc = param => {
  return apiService()
    .post("/multiple-cond-filter", param)
    .then(response => {
      return response.data;
    });
};

const deleteClSvc = param => {
  return apiService()
    .post("/deletecolumn-grid", param)
    .then(response => {
      return response.data;
    });
};
const copyClSvc = param => {
  return apiService()
    .post("/copycolumn-grid", param)
    .then(response => {
      return response.data;
    });
};

const mergeClSvc = param => {
  return apiService()
    .post("/mergecolumns-grid", param)
    .then(response => {
      return response.data;
    });
};

const addBlankClSvc = param => {
  return apiService()
    .post("/addcolumn-grid", param)
    .then(response => {
      return response.data;
    });
};
const splitClSvc = param => {
  return apiService()
    .post("/splitcolumn-grid", param)
    .then(response => {
      return response.data;
    });
};

const singleClReplaceSvc = param => {
  return apiService()
    .post("/column-replace-grid", param)
    .then(response => {
      return response.data;
    });
};
const colorFilterClSvc = param => {
  return apiService()
    .post("/color-filter", param)
    .then(response => {
      return response.data;
    });
};

const globalDataReplaceSvc = param => {
  return apiService()
    .post("/global-replace-grid", param)
    .then(response => {
      return response.data;
    });
};

//suggestion grid insert data with conditional filters
const suggesstionInsertFilters = param => {
  return apiService()
    .post("/suggestion-insert", param)
    .then(response => {
      return response.data;
    });
};

const suggesstionUpdateFilters = param => {
  return apiService()
    .post("/suggestion-update", param)
    .then(response => {
      return response.data;
    });
};

const removeConditionalFilterOnColumn = param => {
  return apiService()
    .post("/fetch-clear-data", param)
    .then(response => {
      return response.data;
    });
};

export const removeDuplicate = param => {
  return apiService()
    .post("/remove-duplicates", param)
    .then(response => {
      return response.data;
    });
};

export const performArithmaticOps = param => {
  return apiService()
    .post("/arithmatic-ops", param)
    .then(response => {
      return response.data;
    });
};

export const gridClOperations = {
  sortSingleClSvc: sortSingleClSvc,
  mergeClSvc: mergeClSvc,
  splitClSvc: splitClSvc,
  copyClSvc: copyClSvc,
  freeTextSearchSvc: freeTextSearchSvc,
  conditionalStringFilterSvc: conditionalStringFilterSvc,
  conditionalNumberFilterSvc: conditionalNumberFilterSvc,
  multipleConditionalFilterSvc: multipleConditionalFilterSvc,
  deleteClSvc: deleteClSvc,
  addBlankClSvc: addBlankClSvc,
  singleClReplaceSvc: singleClReplaceSvc,
  globalDataReplaceSvc: globalDataReplaceSvc,
  colorFilterClSvc: colorFilterClSvc,
  suggesstionInsertFilters: suggesstionInsertFilters,
  suggesstionUpdateFilters: suggesstionUpdateFilters,
  removeConditionalFilterOnColumn: removeConditionalFilterOnColumn,
  removeDuplicate: removeDuplicate,
  performArithmaticOps: performArithmaticOps
};
//Grid column level operations ENDS

//Global Data Listing
export const fetchUploadedGlobalFilesList = param => {
  return apiService()
    .post("/fetch-global-files", param)
    .then(response => {
      return response.data;
    });
};

export const sendUploadedGlobalFilesList = param => {
  return apiService()
    .post("/uploadGlobalListingFile", param)
    .then(response => {
      return response.data;
    });
};

export const changeStatus = param => {
  return apiService()
    .post("/status-update-review", param)
    .then(response => {
      return response.data;
    });
};

export const scheduleJob = param => {
  return apiService()
    .post("/schedule-job", param)
    .then(response => {
      return response.data;
    });
};

//Training Data Listing
export const fetchUploadedTrainingFilesList = param => {
  return apiService()
    .post("/fetch-training-files", param)
    .then(response => {
      return response.data;
    });
};

export const sendUploadedTrainingFilesList = param => {
  return apiService()
    .post("/upload-training-file", param)
    .then(response => {
      return response.data;
    });
};

export const fetchTrainingFileDataHeaders = param => {
  return apiService()
    .post("/fetch-training-fileheaders", param)
    .then(response => {
      return response.data;
    });
};

export const fetchTrainingFileData = param => {
  return apiService()
    .post("/fetch-training-filelist", param)
    .then(response => {
      return response.data;
    });
};

//create user
export const createUser = param => {
  return apiService()
    .post("/create-user", param)
    .then(response => {
      return response.data;
    });
};
//sent for review
export const sentForReview = param => {
  return apiService()
    .post("/sent-for-review", param)
    .then(response => {
      return response.data;
    });
};

//forget password
export const forgetPassword = param => {
  return apiService()
    .post("/forget-password", param)
    .then(response => {
      return response.data;
    });
};

//reset password
export const passwordReset = param => {
  return apiService()
    .post("/reset", param)
    .then(response => {
      return response.data;
    });
};
export const savePublishSettings = param => {
  return apiService()
    .post("/save-global-settings", param)
    .then(response => {
      return response.data;
    });
};
//reviewed
export const reviewed = param => {
  return apiService()
    .post("/job-reviewed", param)
    .then(response => {
      return response.data;
    });
};

export const publishJobParentService = param => {
  return apiService()
    .post("/publish-job", param)
    .then(response => {
      return response.data;
    });
};

export const saveScheduleSettings = param => {
  return apiService()
    .post("/save-schedule-settings", param)
    .then(response => {
      return response.data;
    });
};

export const fetchShareUsersList = param => {
  return apiService()
    .post("/fetch-share-users", param)
    .then(response => {
      return response.data;
    });
};

export const getAllScheduleOptions = param => {
  return apiService()
    .post("/get-all-schedule-conn-options", param)
    .then(allConnTypes => {
      return allConnTypes;
    });
};
export const fetchReviewUsersList = param => {
  return apiService()
    .post("/fetch-review-users", param)
    .then(response => {
      return response.data;
    });
};

export const saveShareAccessDetails = param => {
  return apiService()
    .post("/save-share-access", param)
    .then(response => {
      return response.data;
    });
};
export const fetchJobShareAccessDetails = param => {
  return apiService()
    .post("/fetch-share-access", param)
    .then(response => {
      return response.data;
    });
};
export const deleteShareAccessUser = param => {
  return apiService()
    .post("/delete-share-access-user", param)
    .then(response => {
      return response.data;
    });
};

export const isFirstPublish = jobId => {
  return apiService()
    .post("/is-firstPublish", jobId)
    .then(response => {
      return response.data;
    });
};

export const fetchAllAlgoByJobId = param => {
  return apiService()
    .post("/fetch-saved-algorithm-by-jobid", param)
    .then(response => {
      return response.data._result;
    });
};

export const fetchJobDetailsById = param => {
  return apiService()
    .post("/find-job-data", param)
    .then(response => {
      return response.data;
    });
};

//Refresh Step
export const refreshStep = param => {
  return apiService()
    .post("/refresh-step", param)
    .then(response => {
      return response.data;
    });
};

//Save add steps
export const saveAddSteps = param => {
  return apiService()
    .post("/save-add-steps", param)
    .then(response => {
      return response.data;
    });
};

//Delete steps in Edit Mode
export const deleteStep = param => {
  return apiService()
    .post("/delete-steps", param)
    .then(response => {
      return response.data;
    });
};

export const deleteMultiCol = param => {
  return apiService()
    .post("/delete-multiple-columns-from-grid", param)
    .then(response => {
      return response.data;
    });
};

export const checkIfDictExists = param => {
  return apiService()
    .post("/check-if-dict-exists", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAlgoById = param => {
  return apiService()
    .post("/fetch-algo-by-id", param)
    .then(response => {
      return response.data;
    });
};

export const fetchOutputColumns = param => {
  return apiService()
    .post("/fetch-output-columns", param)
    .then(response => {
      return response.data;
    });
};

export const fetchHiearchicalLearning = param => {
  return apiService()
    .post("/get-hierarchical-learning-data", param)
    .then(response => {
      return response.data;
    });
};

export const updateHierarchicalLearning = param => {
  return apiService()
    .post("/update-hierarchicalLearning-records", param)
    .then(response => {
      return response.data;
    });
};
export const markOneAsValidated = param => {
  return apiService()
    .post("/mark-one-as-validated", param)
    .then(response => {
      return response.data;
    });
};

export const unmarkOneValidated = param => {
  return apiService()
    .post("/unmark-one-validated", param)
    .then(response => {
      return response.data;
    });
};

export const markManyAsValidated = param => {
  return apiService()
    .post("/mark-many-as-validated", param)
    .then(response => {
      return response.data;
    });
};

export const viewAllValidated = param => {
  return apiService()
    .post("/view-all-validated", param)
    .then(response => {
      return response.data;
    });
};

export const unmarkManyAsValidated = param => {
  return apiService()
    .post("/unmark-many-as-validated", param)
    .then(response => {
      return response.data;
    });
};

export const fetchFileShareAccessDetails = param => {
  return apiService()
    .post("/fetch-file-share-access", param)
    .then(response => {
      return response.data;
    });
};

export const fetchShareUsersListForFile = param => {
  return apiService()
    .post("/fetch-file-share-users", param)
    .then(response => {
      return response.data;
    });
};

export const saveFileAccessDetails = param => {
  return apiService()
    .post("/save-file-share-access", param)
    .then(response => {
      return response.data;
    });
};
export const markOneForReview = param => {
  return apiService()
    .post("/mark-one-for-review", param)
    .then(response => {
      return response.data;
    });
};

export const unmarkOneReviewed = param => {
  return apiService()
    .post("/unmark-one-reviewed", param)
    .then(response => {
      return response.data;
    });
};
export const markManyForReview = param => {
  return apiService()
    .post("/mark-many-for-review", param)
    .then(response => {
      return response.data;
    });
};

export const viewAllReviewed = param => {
  return apiService()
    .post("/view-all-reviewed", param)
    .then(response => {
      return response.data;
    });
};

export const unmarkManyReviewed = param => {
  return apiService()
    .post("/unmark-many-reviewed", param)
    .then(response => {
      return response.data;
    });
};

export const saveInputSettings = param => {
  return apiService()
    .post("/save-input-settings", param)
    .then(response => {
      return response.data;
    });
};

export const getUserRoles = () => {
  return apiService()
    .get("/all-user-roles")
    .then(response => {
      return response.data;
    });
};

export const fetchAllPublishSettings = () => {
  return apiService()
    .get("/fetch-all-publish-settings")
    .then(response => {
      return response.data;
    });
};

export const deleteStepsAfterSelectedSteps = param => {
  return apiService()
    .post("/delete-steps-by-stepnumber", param)
    .then(response => {
      return response.data;
    });
};

export const logoutParentSvc = param => {
  return apiService()
    .post("/logout", param)
    .then(response => {
      return response.data;
    });
};
export const fetchJobParent = param => {
  return apiService()
    .post("/fetch-job-data", param)
    .then(response => {
      return response.data;
    });
};

export const updateJobStepStatus = param => {
  return apiService()
    .post("/update-step-status", param)
    .then(response => {
      return response.data;
    });
};

export const replaceInputFileSvc = param => {
  return apiService()
    .post("/replace-input-file", param)
    .then(response => {
      return response.data;
    });
};

export const replaceTrainingFileSvc = param => {
  return apiService()
    .post("/replace-training-file", param)
    .then(response => {
      return response.data;
    });
};

export const replaceGlobalFile = param => {
  return apiService()
    .post("/replace-global-file", param)
    .then(response => {
      return response.data;
    });
};

export const fetchClassificationTrainingData = param => {
  return apiService()
    .post("/fetch-classification-training-data", param)
    .then(response => {
      return response.data;
    });
};

export const saveClassificationDataManipulations = param => {
  return apiService()
    .post("/update-classification-training-records", param)
    .then(response => {
      return response.data;
    });
};

export const conditionalClassificationStringFilter = param => {
  return apiService()
    .post("/cond-stringfilter-classification", param)
    .then(response => {
      return response.data;
    });
};

export const columnReplaceActionTraining = param => {
  return apiService()
    .post("/column-replace-training", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAllUsers = param => {
  return apiService()
    .post("/fetch-all-user", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("Internal Server Error ERRCODE 503");
    });
};

export const downloadFileAsCSV = param => {
  return apiService()
    .post("/download-as-csv", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("Internal Server Error ERRCODE 503");
    });
};

export const changeOwnership = param => {
  return apiService()
    .post("/change-ownership", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("Error is - ", err);
    });
};

export const fetchJobStatus = param => {
  return apiService()
    .post("/fetch-all-job-status", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.log("Error is - ", err);
    });
};

export const updateJobStatusForcefully = param => {
  return apiService()
    .post("/update-job-status-forcefully", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchUserForOwnershipChange = param => {
  return apiService()
    .post("/fetch-user-for-ownership-change", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err;
    });
};

export const extractFile = param => {
  return apiService()
    .post("/extract-input-file", param)
    .then(response => {
      return response.data;
    });
};

export const downloadInputFileAsCSV = param => {
  return apiService()
    .post("/download-input-file", param)
    .then(response => {
      return response.data;
    });
};

export const fetchHeaderMetaDataForAttributeMappingFile = param => {
  return apiService()
    .post("/attribute-mapping-file-header-metaData", param)
    .then(response => {
      return response.data;
    });
};

export const performAttributeMapping = param => {
  return apiService()
    .post("/attribute-mapping", param)
    .then(response => {
      return response.data;
    });
};

export const fetchGridColumns = param => {
  return apiService()
    .post("/fetch-column-headers", param)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return "Error in fetching columns";
    });
};

export const microsoftLogin = param => {
  return apiService()
    .post("/", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAllUserList = param => {
  return apiService()
    .post("/display-all-user", param)
    .then(response => {
      return response.data;
    });
};

export const updateUser = param => {
  return apiService()
    .post("/update-user", param)
    .then(response => {
      return response.data;
    });
};

// export const deleteUser = param => {
//   return apiService()
//     .post("/delete-user", param)
//     .then(response => {
//       return response.data;
//     });
// };

export const fetchAzureFiles = param => {
  return apiService()
    .post("/fetch-azure-files-list", param)
    .then(response => {
      return response.data;
    });
};

export const azureConfigStatus = param => {
  return apiService()
    .post("/azure-config-status", param)
    .then(response => {
      return response.data;
    });
};

export const azureUploadFile = param => {
  return apiService()
    .post("/upload-azure-file", param)
    .then(response => {
      return response.data;
    });
};

export const processOutput = param => {
  return apiService()
    .post("/process-output", param)
    .then(response => {
      return response.data;
    });
};

export const fetchLoginModes = param => {
  return apiService()
    .post("/login-mode", param)
    .then(response => {
      return response.data;
    });
};
export const updateLoginModes = param => {
  return apiService()
    .post("/login-mode-update", param)
    .then(response => {
      return response.data;
    });
};

export const showFullDataSubset = param => {
  return apiService()
    .post("/show-complete-data-subset", param)
    .then(response => {
      return response.data;
    });
};

export const hideFullDataSubset = param => {
  return apiService()
    .post("/hide-complete-data-subset", param)
    .then(response => {
      return response.data;
    });
};

export const viewAllGridAndReviewed = param => {
  return apiService()
    .post("/view-all-reviewed-grid-data", param)
    .then(response => {
      return response.data;
    });
};

export const viewAllGridAndValidated = param => {
  return apiService()
    .post("/view-all-validated-grid-data", param)
    .then(response => {
      return response.data;
    });
};

export const azureReplaceFile = param => {
  return apiService()
    .post("/replace-azure-file", param)
    .then(response => {
      return response.data;
    });
};

export const fetchPublishDetailsForJob = param => {
  return apiService()
    .post("/fetch-publish-jobDetails", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAllScheduleSettings = () => {
  return apiService()
    .get("/fetch-all-schedule-settings")
    .then(response => {
      return response.data;
    });
};

export const saveColumnOrder = param => {
  return apiService()
    .post("/save-column-order", param)
    .then(response => {
      return response.data;
    });
};

export const getLogsForJob = param => {
  return apiService()
    .post("/fetch-logs", param)
    .then(response => {
      return response.data;
    });
};

export const getJobsLog = param => {
  return apiService()
    .post("/fetch-jobs-logs", param)
    .then(response => {
      return response.data;
    });
};

export const getReportCount = param => {
  return apiService()
    .post("/fetch-report", param)
    .then(response => {
      return response.data;
    });
};

export const fetchReportUser = param => {
  return apiService()
    .post("/fetch-report-user", param)
    .then(response => {
      return response.data;
    });
};

export const getFilterReport = param => {
  return apiService()
    .post("/fetch-filter-report", param)
    .then(response => {
      return response.data;
    });
};

export const fetchAllInputSettings = () => {
  return apiService()
    .get("/fetch-all-input-settings")
    .then(response => {
      return response.data;
    });
};
