import Cryptr from "cryptr";
const cryptr = new Cryptr("headphonesyaaams");

export const escapeRegexString = text => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const createRegexString = (incomingStr, typeOprand) => {
  if (incomingStr && typeOprand) {
    try {
      switch (typeOprand.text) {
        case "EQUALS":
          return new RegExp("^" + incomingStr + "$");

        case "IS_NOT_EQUAL_TO":
          return new RegExp("[^" + incomingStr + "]$");

        case "CONTAINS":
          return new RegExp(incomingStr, "i");

        case "DOES_NOT_CONTAIN":
          return new RegExp("^(?:(?!" + incomingStr + ").)*$\r?\n?", "i");

        case "STARTS_WITH":
          return new RegExp("^" + incomingStr, "i");

        case "DOES_NOT_START_WITH":
          return new RegExp("^(?!(" + incomingStr + "))", "i");

        case "ENDS_WITH":
          return new RegExp(incomingStr + "$", "i");

        case "DOES_NOT_END_WITH":
          return new RegExp("^(?!.*" + incomingStr + "$).*$", "i");
      }
    } catch (error) {
      throw error;
    }
  }
};

export const capitalizeString = incomingStr => {
  return (incomingStr.charAt(0).toUpperCase() + incomingStr.slice(1)).replace(
    /[_-]/g,
    " "
  );
};

export const trimStringSpaces = incomingStr => {
  return incomingStr.replace(/^\s+|\s+$/gm, "");
};

export const checkEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const checkEmptyString = str => {
  return !str && (!str.trim() || 0 === str.length);
};

export const convertMapToJsonString = incomingMap => {
  return JSON.stringify([...incomingMap]);
};

export const parseJwtToken = token => {
  try {
    return JSON.parse(window.atob(token));
  } catch (e) {
    return null;
  }
};

export const capitalizeEachWord = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const checkNullObject = obj => {
  return obj == null || obj == undefined;
};

export const filterHiddenColumnsFn = (
  storeDatableDisplayColumns,
  hiddenColumns
) => {
  let filterHiddenColumns;
  if (hiddenColumns.length > 0) {
    filterHiddenColumns = storeDatableDisplayColumns.filter(e => {
      return hiddenColumns.filter(f => {
        if (e.value === f.value) {
          e.isHidden = true;
        }
      });
    });
  } else {
    filterHiddenColumns = storeDatableDisplayColumns;
  }
  return filterHiddenColumns;
};

export const testForbiddenChars = param => {
  const forbiddenRegex = /[/\"$*<>:|?_()%+=@!#^&{}';,-]/;
  return forbiddenRegex.test(param);
};

export async function encryptData(data) {
  return await cryptr.encrypt(data);
}

export async function decryptData(data) {
  return await cryptr.decrypt(data);
}

export const formatDate = d => {
  d = new Date(d);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const dayVal = d.getDate();
  var monthVal = d.getMonth();
  var yearVal = d.getFullYear();

  return dayVal + "-" + monthNames[monthVal] + "-" + yearVal;
};

export function encryptText(textToEncrypt) {
  let arr1 = [];
  for (let n = 0; n < textToEncrypt.length; n++) {
    let hex = Number(textToEncrypt.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join("");
}

export function decryptText(textToDecrypt) {
  let text = textToDecrypt.toString();
  var plainText = "";
  for (let n = 0; n < text.length; n += 2) {
    plainText += String.fromCharCode(parseInt(text.substr(n, 2), 16));
  }
  return plainText;
}