<template>
  <transition name="nexus-modal-fade">
    <div class="nexus-modal-backdrop">
      <div
        class="nexus-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="nexus-modal-header"
          id="modalTitle"
        >
          <slot name="header">
            This is the default tile!
          </slot>
          <!-- <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            x
          </button> -->
          <button
            type="button"
            @click="closeActionModal($event)"
            aria-label="Close modal"
            class="close"
          ><span aria-hidden="true">×</span></button>
        </header>
        <section class="nexus-modal-body">
          <slot name="body">
            I'm the default body!
          </slot>
        </section>
        <footer class="nexus-modal-footer">
          <slot name="footer">
            I'm the default footer!
          </slot>
          <button
            type="button"
            class="btn btn-outline-secondary nex-modal-btn"
            @click="closeActionModal($event)"
            aria-label="Close modal"
          >
            CANCEL
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",

  methods: {
    closeActionModal(event) {
      this.$emit("closeActionModal");
    }
  }
};
</script>

<style>
.nexus-modal-backdrop {
  z-index: 1040;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nexus-modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  min-height: 30vh;
  min-width: 50vh;
  flex-direction: column;
  border-radius: 0.3rem;
  z-index: 1050;
}

.nexus-modal-header {
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;

  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.nexus-modal-footer {
  align-items: center;
  -webkit-box-pack: end;
  display: flex;
  -webkit-box-align: center;

  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.nexus-modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
  -webkit-box-flex: 1;
}

.btn-close {
  border: none;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.nexus-modal-fade-enter,
.nexus-modal-fade-leave-active {
  opacity: 0;
}

.nexus-modal-fade-enter-active,
.nexus-modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.nex-modal-btn {
  margin: 4px;
  cursor: pointer;
}
</style>
