<template>
  <div>
    <!--Algo Steps-->
    <div class="algo-steps">
      <!--Head Section-->
      <div class="head d-flex align-items-center justify-content-between">
        <a
          href="javascript:void(0);"
          class="toggle-algo"
          @click="toggleAlgoSetup"
        >
          <i class="icon24 icon-arrow-tbl"></i>
        </a>
        <span class="title">
          <i class="icon24 icon-algo-setup"></i>
          Algo Setup
        </span>
        <a
          v-if="condition('edit')"
          href="javascript:void(0);"
          class="add-algo"
          @click="addAlgo()"
        >
          <i class="icon16 icon-plus-blue"></i>
          Add
        </a>
      </div>

      <div class="algo-setup-wrapper data-scrollbar">
        <!--Linear and Hierarchical Selection-->
        <!--Hirarchicl and Linear-->
        <div
          id="accordion"
          class="algo-Container"
        >
          <span v-if="algoSetupjobParent.algorithm.length > 0">
            <!-- changes starts -->
                      <div class="choices" style="padding:10px">
                        <multiselect
                          placeholder="Select Business Column"
                          v-model="algoSetupjobParent.businessColumn"
                          track-by="value"
                          label="text"
                          :options="gridDataTableInputColumns"
                          :close-on-select="true"
                          :max-height="100"
                          @input="selectedBusinessColumn($event)"
                        ></multiselect>
                      </div>
                       <!-- changes ends -->
            <div
              class="card primary-acordion"
              v-for="(algo, index) in algoSetupjobParent.algorithm"
              :key="index"
            >
              <div
                class="card-header"
                id="headingOne"
              >
                <a
                  v-b-toggle="'collapse'+index"
                  @click="showCollapse = !showCollapse"
                  :aria-expanded="showCollapse ? 'true' : 'false'"
                >
                  <i
                    v-if="condition('edit')"
                    class="icon14 icon-close-blue mr-3 remove-accordion"
                    @click="deleteAlgo(index)"
                  ></i>
                  {{algo.algoName}} {{algoSetupjobParent.algorithm.length - index}}
                  <!-- <div class="algocheckbox checkbox">
                              <input
                                type="checkbox"
                                v-model="algo.isSelectedExecute"
                              >
                              <span class="checkmark"></span>
                            </div>-->
                </a>
              </div>
              <b-collapse
                :id="'collapse'+index"
                class="collapse show"
                aria-labelledby="headingOne"
              >
                <div class="card-body">
                  <div class="choices">
                    <multiselect
                      class="mul-required-field"
                      v-model="algo.algoName"
                      @select="onAlgoChange(algo,index)"
                      :options="algoMaster"
                      :close-on-select="true"
                      placeholder="Select Algorithm"
                    ></multiselect>
                  </div>
                  <!--Hierarchical Selection-->
                  <div
                    class="hierarchical-selection"
                    style="display:block"
                    v-if="algo.algoName !== ''"
                  >
                    <span v-if="algo.algoName === nexusAlgorithm.align">
                      <div class="d-flex align-items-center w-100 mb-5">
                        <i class="icon24 icon-learning-file mr-3"></i>
                        <div class="flex-fill">
                          <select
                            v-model="algo.learningFilePath"
                            class="form-control choices-js"
                            @change="notifyDictExists($event,algo)"
                          >
                            <option
                              v-for=" (option,idx) in trainingFiles_Hierarchical"
                              :label="option.displayFileName"
                              :value="option.filePath"
                              :key="idx"
                            ></option>
                          </select>
                        </div>
                        <i
                          v-if="algo.learningFilePath !== '' && condition('edit')"
                          margin-left="20px"
                          class="icon14 icon-close-blue mr-3 remove-accordion"
                          @click="removeFileFromAlgo(index,$event,algo)"
                        ></i>
                      </div>
                      <div class="item-cell header">
                        <span>Attribute</span>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-link btn-primary add-attribute"
                          @click="addProductAttribute(algo,index)"
                        >
                          <i class="icon14 icon-plus"></i>
                          Add
                        </a>
                      </div>
                      <span v-if="algo.productAttribute.length > 0">
                        <span
                          v-for="(prod, index3) in algo.productAttribute"
                          :key="index3"
                        >
                          <ul class="hierarchical-list">
                            <li class="item-cell">
                              <div class="choices">
                                <multiselect
                                  class="mul-required-field"
                                  v-model="prod.name"
                                  :options="hierarchicalAlgoAttributeOptions"
                                  placeholder="Select an option"
                                  :close-on-select="true"
                                ></multiselect>
                              </div>
                              <div class="action-link">
                                <a href="javascript:void(0);">
                                  <i
                                    class="icon16 icon-expand"
                                    @click="setHierachicalModal($event,prod,index,index3)"
                                  ></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="remove-attribute"
                                >
                                  <i
                                    class="icon16 icon-close"
                                    @click="deleteProductAttribute(algo,index3)"
                                  ></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </span>
                      </span>
                      <!-- <div class="d-flex align-items-center w-100 mb-5">
                                <i class="icon24 icon-learning-file mr-3"></i>
                                <div class="flex-fill">
                                  <multiselect
                                    v-model="value"I
                                    track-by="name"
                                    label="name"
                                    :options="learningAlgos"
                                    :close-on-select="true"
                                  >
                                    <template
                                      slot="singleLabel"
                                      slot-scope="{ option }"
                                    >
                                      <strong>{{ option.name }}</strong>
                                    </template>
                                  </multiselect>
                                </div>
                                </div>-->
                    </span>
                  </div>
                  <!--Ontology Selection-->
                  <div
                    class="hierarchical-selection"
                    style="display:block"
                    v-if="algo.algoName !== ''"
                  >
                    <span v-if="algo.algoName === nexusAlgorithm.ontology">
                      <div class="d-flex align-items-center w-100 mb-5">
                        <i class="icon24 icon-learning-file mr-3"></i>
                        <div class="flex-fill">
                          <select
                            v-model="algo.learningFileName"
                            class="form-control choices-js"
                          >
                            <option
                              v-for=" (option,idx) in globalFiles_Ontology"
                              :label="option.displayFileName"
                              :value="option.displayFileName"
                              :key="idx"
                            ></option>
                          </select>
                        </div>
                        <i
                          v-if="algo.learningFileName !== '' && condition('edit')"
                          margin-left="20px"
                          class="icon14 icon-close-blue mr-3 remove-accordion"
                          @click="removeFileFromAlgo(index,$event,algo)"
                        ></i>
                      </div>
                      <div class="item-cell header">
                        <span>Attribute</span>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-link btn-primary add-attribute"
                          @click="addProductAttribute(algo,index)"
                        >
                          <i class="icon14 icon-plus"></i>
                          Add
                        </a>
                      </div>
                      <span v-if="algo.productAttribute.length > 0">
                        <span
                          v-for="(prod, index3) in algo.productAttribute"
                          :key="index3"
                        >
                          <ul class="hierarchical-list">
                            <li class="item-cell">
                              <div
                                class="form-group"
                                style="width: 80%;"
                              >
                                <input
                                  type="text"
                                  class="form-control floating-input"
                                  v-model="prod.name"
                                >
                              </div>
                              <div class="action-link">
                                <a href="javascript:void(0);">
                                  <i
                                    class="icon16 icon-expand"
                                    @click="setOntologyModal($event,prod,index,index3)"
                                  ></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="remove-attribute"
                                >
                                  <i
                                    class="icon16 icon-close"
                                    @click="deleteProductAttribute(algo,index3)"
                                  ></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </div>
                  <!--Linear Selection-->
                  <div
                    class="hierarchical-selection"
                    style="display:block"
                    v-if="algo.algoName !== ''"
                  >
                    <span v-if="algo.algoName === nexusAlgorithm.extraction">
                      <div class="d-flex align-items-center w-100 mb-5">
                        <i class="icon24 icon-learning-file mr-3"></i>
                        <div class="flex-fill">
                          <!-- <multiselect
                                      @input="createLinearAlgoObject($event,algo)"
                                      v-model="algo.learningFilePath"
                                      placeholder="Select Learning File"
                                      track-by="filePath"
                                      label="fileName"
                                      :options="trainingFiles"
                                      :multiple="false"
                                      :close-on-select="true"
                                    ></multiselect>-->
                          <select
                            @change="createLinearAlgoObject($event,algo)"
                            v-model="algo.learningFilePath"
                            class="form-control choices-js"
                          >
                            <option
                              v-for=" (option,idx) in trainingFiles_Linear"
                              :label="option.displayFileName"
                              :value="option.filePath"
                              :key="idx"
                            ></option>
                          </select>
                        </div>
                        <i
                          v-if="algo.learningFilePath !== '' && condition('edit')"
                          margin-left="20px"
                          class="icon14 icon-close-blue mr-3 remove-accordion"
                          @click="removeFileFromAlgo(index,$event,algo)"
                        ></i>
                      </div>
                      <span v-if="algo.productAttribute.length > 0">
                        <span
                          v-for="(prod, index3) in algo.productAttribute"
                          :key="index3"
                        >
                          <ul class="hierarchical-list">
                            <li style="font-size: 15px;font-weight:bold">Column:</li>
                            <li style="font-size: 13px;">{{prod.name | capitalize }}</li>
                            <li style="font-size: 15px;;font-weight:bold">Tags:</li>
                            <li
                              style="font-size: 13px;"
                              v-for="(column, index3) in prod.columns"
                              :key="index3"
                            >{{ column | capitalize }}</li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    class="hierarchical-selection"
                    style="display:block"
                    v-if="algo.algoName !== ''"
                  >
                    <span v-if="algo.algoName === nexusAlgorithm.enrichment">
                      <div class="d-flex align-items-center w-100 mb-5">
                        <i class="icon24 icon-learning-file mr-3"></i>
                        <div class="flex-fill">
                          <select
                            v-model="algo.learningFilePath"
                            class="form-control choices-js"
                            @change="notifyDictExists($event,algo)"
                          >
                            <option
                              v-for=" (option,idx) in trainingFiles_Classification"
                              :label="option.displayFileName"
                              :value="option.filePath"
                              :key="idx"
                            ></option>
                          </select>
                          <span
                            style="display:none"
                            v-if="algo.learningFilePath"
                          >
                            <span
                              v-for="(option,idx) in trainingFiles_Classification"
                              :key="idx"
                            >
                              <span v-if="algo.learningFilePath === option.filePath">
                                <input v-model="algo.collectionName=option.displayFileName">
                              </span>
                            </span>
                          </span>
                        </div>
                        <i
                          v-if="algo.learningFilePath !== '' && condition('edit')"
                          margin-left="20px"
                          class="icon14 icon-close-blue mr-3 remove-accordion"
                          @click="removeFileFromAlgo(index,$event,algo)"
                        ></i>
                      </div>
                      <!--Key column for classification training STARTS-->
                      <div class="choices">
                        <multiselect
                          class="mul-required-field"
                          placeholder="Select Key Column"
                          v-model="algo.keyColumnClassification"
                          track-by="value"
                          label="text"
                          :options="gridDataTableInputColumns"
                          :close-on-select="true"
                          :max-height="100"
                        ></multiselect>
                      </div>
                      <!-- <div class="choices">
                                    <select
                                      v-model="algo.learningFilePath"
                                      class="form-control choices-js"
                                      @change="notifyDictExists($event,algo)"
                                    >
                                      <option
                                        v-for=" (option,idx) in trainingFiles"
                                        :label="option.fileName"
                                        :value="option.filePath"
                                        :key="idx"
                                      ></option>
                                    </select>
                                </div>-->
                      <!--Key column for classification training ENDS-->
                      <div class="item-cell header">
                        <span>Attribute</span>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-link btn-primary add-attribute"
                          @click="classificationAlgoAttributeOptions[algo.learningFilePath]?addProductAttribute(algo,index):''"
                        >
                          <i class="icon14 icon-plus"></i>
                          Add
                        </a>
                      </div>
                      <span v-if="algo.productAttribute.length > 0">
                        <span
                          v-for="(prod, index3) in algo.productAttribute"
                          :key="index3"
                        >
                          <ul class="hierarchical-list">
                            <li class="item-cell">
                              <div class="choices">
                                <multiselect
                                  v-model="prod.name"
                                  :options="classificationAlgoAttributeOptions[algo.learningFilePath]?classificationAlgoAttributeOptions[algo.learningFilePath]:[]"
                                  placeholder="Select an option"
                                  :close-on-select="true"
                                ></multiselect>
                              </div>
                              <div class="action-link">
                                <a href="javascript:void(0);">
                                  <i
                                    class="icon16 icon-expand"
                                    @click="setClassificationModel($event,prod,index,index3)"
                                  ></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  class="remove-attribute"
                                >
                                  <i
                                    class="icon16 icon-close"
                                    @click="deleteProductAttribute(algo,index3)"
                                  ></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </b-collapse>
            </div>
          </span>
        </div>
      </div>
      <div
        class="algo-footer"
        v-if="algoSetupjobParent.status !== 6"
      >
        <div class="d-flex align-items-center justify-content-center">
          <button
            v-if="algoSetupjobParent.status !== 8 && condition('edit') "
            class="btn btn-primary btn-link"
            @click="saveAlgoSetup($event)"
          >
            <i class="icon14 icon-save"></i>Save
          </button>
          <button
            v-if="condition('edit')"
            class="btn btn-outline-secondary"
            @click="onAlgoSetUpCancel()"
          >
            <i class="icon14 icon-close"></i>Cancel
          </button>
        </div>
      </div>
    </div>

    <!--FILE LIST ACTIONS MODAL STARTS-->
    <modal-action
      v-if="algoSetupModal.isActive"
      v-on:closeActionModal="closeActionModalFn"
    >
      <!-- Check If Dict Exists Modal -->
      <template v-if="algoSetupModal.actionType === 1">
        <span slot="header">
          <h5 class="modal-title">{{modalValue}} Already Exists
          </h5>
        </span>
        <span slot="body">{{modalValue}} Already Exists. Do you wish to overwrite the existing {{modalValue}} ?</span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            YES
          </button>
        </span>
      </template>
      <!-- hierarchical Modal Component -->
      <template v-if="algoSetupModal.actionType === 2 && algoSetupjobParent.algorithm.length > 0">
        <span slot="header">
          <h5 class="modal-title">{{modalHeaderName}}
          </h5>
        </span>
        <span slot="body">
          <div
            class="thresholds-wrapper"
            v-if="hierarchicalPAModalValues !== null"
          >
            <div
              class="form-group"
              v-for="(threshold,thIndex) in hierarchicalPAModalValues.algorithmThresholds"
              :key="thIndex"
            >
              <div class="checkbox">
                <input
                  type="checkbox"
                  v-model="threshold.isSubALgoSelectedExecute"
                >
                <span class="checkmark"></span>
                <label
                  style="padding-left:30px;"
                  :for="threshold.key"
                >{{threshold.key}}</label>
              </div>
              <input
                style="margin-left: 30px;width: 90% !important;"
                :name="threshold.key"
                type="number"
                title="Only decimal value are expected"
                step="0.01"
                class="form-control"
                v-model.number="threshold.value"
              >
            </div>
          </div>
        </span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            Save
          </button>
        </span>
      </template>
      <!-- Ontology Modal Component -->
      <template v-if="algoSetupModal.actionType === 3 && algoSetupjobParent.algorithm.length > 0">
        <span slot="header">
          <h5 class="modal-title">{{modalHeaderName}}
          </h5>
        </span>
        <span slot="body">
          <div
            class="thresholds-wrapper"
            v-if="ontologyPAModalValues !== null"
          >
            <div
              class="form-group"
              v-for="(threshold,thIndex) in ontologyPAModalValues.algorithmThresholds"
              :key="thIndex"
            >
              <label :for="threshold.key">{{threshold.key}}</label>
              <input
                :name="threshold.key"
                type="text"
                class="form-control"
                v-model.number="threshold.value"
              >
              <div class="action-items">
                <a href="javascript:void(0);">
                  <i class="icon16 icon-reload"></i>
                </a>
                <a href="javascript:void(0);">
                  <i class="icon16 icon-close"></i>
                </a>
              </div>
            </div>
            <div
              class="form-group pb-0 pt-0"
              style="display:grid;"
            >
              <!-- <select v-model="ontologyPAModalValues.selectedColumns" name="choices-multiple-remove-button" class="form-controlchoices-multiple-remove-button" placeholder="Select multiple options" multiple>
                          <option v-for="(op, idx) in algoSelectColumnMaster" :key="idx" :value="op.value" :label="op.name"></option>
                        </select>-->
              <!-- <multiselect v-model="ontologyPAModalValues.selectedColumns" :options="algoSelectColumnMaster" :multiple="true" :close-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name">
                          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.name }} </span></template>
                        </multiselect>-->
              <multiselect
                v-model="ontologyPAModalValues.selectedColumns"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                label="name"
                track-by="name"
                :options="algoSelectColumnMaster"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
              <!-- <multiselect v-model="ontologyPAModalValues.selectedColumns" track-by="name" label="name" :options="algoSelectColumnMaster" :close-on-select="true">
                          <template slot="singleLabel" slot-scope="{ option }"><strong> {{ option.name }}</strong></template>
                        </multiselect>-->
            </div>
          </div>
        </span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            Save
          </button>
        </span>
      </template>
      <!-- Classification Modal Component -->
      <template v-if="algoSetupModal.actionType === 4 && algoSetupjobParent.algorithm.length > 0">
        <span slot="header">
          <h5 class="modal-title">{{modalHeaderName}}
          </h5>
        </span>
        <span slot="body">
          <div
            class="thresholds-wrapper"
            v-if="classificationPAModalValues !== null"
          >
            <div
              class="form-group pb-0 pt-0"
              style="display:grid;"
            >
              <!-- <multiselect
                          v-model="classificationPAModalValues.selectedAlgoType"
                          tag-placeholder="Add this as new tag"
                          placeholder="Search or add a tag"
                          label="name"
                          track-by="name"
                          :options="algoForClassification"
                          :multiple="false"
                          :taggable="true"
                        ></multiselect>-->
              <multiselect
                v-model="classificationPAModalValues.selectedColumns"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                label="name"
                track-by="name"
                :options="algoSelectColumnMaster"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
            </div>
          </div>
        </span>
        <span slot="footer">
          <button
            @click="applyInputFileListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            Save
          </button>
        </span>
      </template>
    </modal-action>
    <!--FILE LIST ACTIONS MODAL ENDS-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import navBar from "../components/Navbar.vue";
// import gridComp from "../components/Grid.vue";
import { capitalizeString } from "./../utilbin/common-utils.js";
import {
  validateAlgoToSave,
  sameAttrributeCheck,
  validateFile
} from "./../utilbin/validation.js";
import {
  fetchJobDetails,
  checkIfDictExists,
  fetchAlgoMasterData,
  fetchTrainingFileData,
  fetchUploadedGlobalFilesList,
  fetchTrainingFileDataHeaders,
  saveAlgoSetupData,
  fetchAllAlgoByJobId,
  fetchAlgoById,
  fetchOutputColumns
} from "./../services/parentservices.js";
import Multiselect from "vue-multiselect";
import {
  ACTION_NAME,
  PAGELIST_ACTION_MODAL,
  ALGOSETUP_ACTION_ITEMS,
  NEXUSALGORITHM
} from "../assets/js/constant";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import Alert from "../components/Alert.vue";
import modal from "./../components/Modal.vue";

// const sLearning = "Ontology";
// const crfLearning = "Linear";
// const usLearning = "Hierarchical";
// const classificationAlgorithm = "Classification";
const validateAlgo = validateAlgoToSave;

const jobModel = {
  _id: null,
  jobName: "",
  status: null,
  active: null,
  inputFileName: "",
  algorithm: [],
  businessColumn: null,
  shareAccess: [],
  owner: "",
  sentForReview: [],
  jobNameToDisplay: "",
  steps: [],
  dataSets: null,
  createdDate: null,
  lastBackup: null
};
const algorithmModel = {
  algoName: "",
  isSelectedExecute: false,
  productAttribute: [],
  learningFileName: "",
  learningFilePath: "",
  isLearningFileChanged: true,
  keyColumnClassification: null,
  collectionName: ""
};
const productAttributeModel = {
  name: "",
  columns: [],
  threshold: []
};

const ONTOLOGY_PA_THRESHOLD_MODAL = {
  algoIndex: null,
  productAttributeIndex: null,
  algorithmThresholds: [],
  selectedColumns: []
};
const HIERARCHICAL_PA_THRESHOLD_MODAL = {
  algoIndex: null,
  productAttributeIndex: null,
  algorithmThresholds: [],
  selectedColumns: []
};
const CLASSIFICATION_PA_MODAL = {
  algoIndex: null,
  productAttributeIndex: null,
  algorithmThresholds: [],
  selectedColumns: [],
  algoType: ""
};
const ALGONAME = [
  "Select",
  "Hierarchical",
  "Ontology",
  "Linear",
  "Other",
  "Classification"
];
export default {
  name: "algoSetup",
  components: {
    Multiselect,
    "modal-alert": Alert,
    "modal-action": modal
  },
  props: {
    jobParent: Object
  },
  data() {
    return {
      classificationAlgoAttributeOptions: {},
      modalValue: "",
      dictExistsMap: {},
      algoObj: null,
      algoSetupjobParent: {},
      algoMaster: [],
      algoMasterResponse: [],
      // algo_crfLearning: crfLearning,
      // algo_usLearning: usLearning,
      // algo_sLearning: sLearning,
      // algo_classification: classificationAlgorithm,
      modalHeaderName: "",
      ontologyPAModalValues: null,
      classificationPAModalValues: null,
      hierarchicalPAModalValues: null,
      showCollapse: true,
      algoName: ALGONAME,
      value: "",
      trainingFiles_Linear: [],
      trainingFiles_Hierarchical: [],
      trainingFiles_Classification: [],
      globalFiles_Ontology: [],
      algoSetupModal: new PAGELIST_ACTION_MODAL(),
      oldJobParent: JSON.parse(JSON.stringify(this.jobParent)),
      nexusAlgorithm: NEXUSALGORITHM
    };
  },
  created() {
    this.algoSetupjobParent = this.jobParent;
    this.fetchTrainingFiles();
    this.fetchAlgoMaster();
    this.fetchGlobalFiles();
    this.checkIfModelExists();
    let classificationAlgo = this.algoSetupjobParent.algorithm.filter(
      algo => algo.algoName === NEXUSALGORITHM.enrichment
    );
    for (let index = 0; index < classificationAlgo.length; index++) {
      this.fetchClassificationAlgoAttribute(
        classificationAlgo[index].learningFilePath
      );
    }
  },
  mounted() {},
  updated() {},
  computed: {
    ...mapGetters({
      userDetails: "getUserObj",
      gridDataTableDisplayRows: "getStoreDisplayData",
      gridDataTableInputColumns: "getStoreDatableInputColumns",
      stepsAction: "getStepAction"
    }),
    algoSelectColumnMaster: function() {
      let options = null;
      if (
        this.gridDataTableDisplayRows &&
        this.gridDataTableDisplayRows.length > 0
      ) {
        options = Object.keys(this.gridDataTableDisplayRows[0])
          .filter(e => {
            let removeConfHeader = e.split(" ");
            return (
              e !== "_id" &&
              e !== "isValidated" &&
              e !== "markedForReview" &&
              e.indexOf("isTD") === -1 &&
              removeConfHeader[removeConfHeader.length - 1] !== "Conf"
            );
          })
          .map(e => {
            return { name: capitalizeString(e), value: e };
          });
      }
      return options;
    },
    hierarchicalAlgoAttributeOptions: function() {
      let options = [];
      //1.getting keys from grid
      if (
        this.gridDataTableDisplayRows &&
        this.gridDataTableDisplayRows.length > 0
      ) {
        options = Object.keys(this.gridDataTableDisplayRows[0]).filter(item => {
          let removeConfHeader = item.split(" ");
          return (
            item !== "_id" &&
            item !== "isValidated" &&
            item !== "markedForReview" &&
            item.indexOf("isTD") === -1 &&
            removeConfHeader[removeConfHeader.length - 1] !== "Conf"
          );
        });
      }

      //2.getting tags from linear setup
      const linearSetups = this.algoSetupjobParent.algorithm.filter(
        algo =>
          algo.algoName === NEXUSALGORITHM.extraction &&
          algo.productAttribute.length > 0
      );
      if (linearSetups) {
        linearSetups.forEach((algo, index) => {
          options.push(algo.productAttribute[0].columns.map(e => e));
        });
      }
      let uniqueColumns = _.uniq(options.flat());
      return uniqueColumns;
    }
  },
  methods: {
    toggleAlgoSetup() {
      // const algoWrapper = document.querySelector("#algo-selection-container");
      let table = document.querySelector("#demoDiv");
      let tableWidth = table.clientWidth;
      let el = document.querySelector(".algo-steps");
      el.classList.toggle("collapsed");
      if (el.classList.contains("collapsed")) {
        table.style.width = tableWidth + 220 + "px";
        //algoWrapper.style.display = "none";
      } else {
        table.style.width = tableWidth - 220 + "px";
        //algoWrapper.style.display = "block";
      }
    },
    checkIfModelExists() {
      checkIfDictExists({
        jobObject: this.algoSetupjobParent,
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError) {
            this.dictExistsMap = response._result;
          }
        })
        .catch(e => {});
    },
    condition(value) {
      if (
        this.algoSetupjobParent.owner == this.userDetails._id ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        return true;
      } else {
        if (
          this.algoSetupjobParent.shareAccess.length > 0 &&
          this.userDetails._id
        ) {
          return this.algoSetupjobParent.shareAccess
            .find(x => x._id == this.userDetails._id)
            .privileges.includes(value);
        }
        return false;
      }
    },
    addAlgo() {
      let algorithmModelTemp = { ...algorithmModel };
      this.algoSetupjobParent.algorithm.unshift(algorithmModelTemp);
    },
    deleteAlgo(index) {
      this.algoSetupjobParent.algorithm.splice(index, 1);
    },
    onAlgoChange(algoObj, idx) {
      if (!_.isEmpty(algoObj)) {
        algoObj.productAttribute = [];
        algoObj.learningFilePath = "";
        algoObj.learningFileName = "";
      }
    },
    fetchAlgoMaster() {
      fetchAlgoMasterData({ userId: this.userDetails._id })
        .then(response => {
          if (!response._isError) {
            this.algoMaster = response._result.map(e => e.algoName);
            this.algoMasterResponse = response._result;
          }
        })
        .catch(e => {});
    },
    notifyDictExists(event, algo) {
      if (algo._id) {
        if (this.dictExistsMap[algo.algoName + "_" + algo._id]) {
          if (algo.algoName === NEXUSALGORITHM.align) {
            this.modalValue = "Dictionary";
          } else if (algo.algoName === NEXUSALGORITHM.enrichment) {
            this.modalValue = "Learning Data";
          }
          this.showAlgoSetupModalFn(
            ALGOSETUP_ACTION_ITEMS.modelAlreadyExistsModal,
            ""
          );
          //this.$refs.modelAlreadyExistsModal.show();
          this.algoObj = algo;
        }
      } else {
        if (algo.algoName === NEXUSALGORITHM.enrichment) {
          this.fetchClassificationAlgoAttribute(algo.learningFilePath);
          algo.productAttribute = [];
        }
      }
    },
    fetchClassificationAlgoAttribute(filePath) {
      if (this.classificationAlgoAttributeOptions[filePath]) {
        return;
      }
      fetchOutputColumns({
        jobName: this.algoSetupjobParent.jobName,
        learningFilePath: filePath,
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError) {
            this.classificationAlgoAttributeOptions[filePath] =
              response._result;
          }
        })
        .catch(e => {});
    },
    fetchTrainingFiles() {
      fetchTrainingFileData({
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError && response._result) {
            this.trainingFiles_Linear = response._result.filter(
              x => x.algorithm == NEXUSALGORITHM.extraction
            );
            this.trainingFiles_Hierarchical = response._result.filter(
              x => x.algorithm == NEXUSALGORITHM.align
            );
            this.trainingFiles_Classification = response._result.filter(
              x => x.algorithm == NEXUSALGORITHM.enrichment
            );
          }
        })
        .catch(e => {});
    },
    removeFileFromAlgo(index, $event, algo) {
      this.algoSetupjobParent.algorithm[index].learningFileName = "";
      this.algoSetupjobParent.algorithm[index].learningFilePath = "";
      if (algo.algoName === NEXUSALGORITHM.extraction)
        this.createLinearAlgoObject($event, algo);
      if (algo.algoName === NEXUSALGORITHM.enrichment) {
        this.fetchClassificationAlgoAttribute(algo.learningFilePath);
        algo.productAttribute = [];
        algo.keyColumnClassification = null;
      }
    },
    createLinearAlgoObject($event, algo) {
      if (event && !_.isEmpty(event) && event.isTrusted) {
        algo.productAttribute = [];
        if (algo.learningFileName === "" && algo.learningFilePath === "") {
          return;
        }
        let param = {
          filePath: algo.learningFilePath,
          userId: this.userDetails._id,
          jobName: this.algoSetupjobParent.jobName
        };
        fetchTrainingFileDataHeaders(param)
          .then(response => {
            if (!response._isError) {
              if (response._message === "Failed") {
                errorEventNotify("Training File is not correct", 1);
              } else {
                algo.learningFileName = response._result.learningFileName;
                algo.productAttribute.push(response._result.productAttr);
              }
            }
          })
          .catch(e => {});
      }
    },
    addProductAttribute(algoObj, index) {
      let productAttributeConfig = Object.assign({}, productAttributeModel);
      //extracting algo threshold from algomaster
      let selectedAlgoThresholds = null;
      this.algoMasterResponse.forEach(o => {
        if (o.algoName === algoObj.algoName) {
          selectedAlgoThresholds = o["algoValue"];
        }
      });
      //this code is such crap
      let selectedAlgoThresholdsModified = [];
      selectedAlgoThresholds.forEach(o => {
        let x = {
          key: Object.keys(o)[0],
          value: o[Object.keys(o)[0]],
          isSubALgoSelectedExecute: o[Object.keys(o)[1]]
        };
        selectedAlgoThresholdsModified.push(x);
      });
      productAttributeConfig.threshold = selectedAlgoThresholdsModified;
      algoObj.productAttribute.push(productAttributeConfig);
    },
    setHierachicalModal(event, paObject, algorithmIdx, productAttributeIdx) {
      if (event && event.isTrusted) {
        this.modalHeaderName = paObject.name;
        this.hierarchicalPAModalValues = HIERARCHICAL_PA_THRESHOLD_MODAL;
        this.hierarchicalPAModalValues["algorithmThresholds"] =
          paObject.threshold;
        this.hierarchicalPAModalValues["selectedColumns"] = paObject.columns;
        this.hierarchicalPAModalValues["algoIndex"] = algorithmIdx;
        this.hierarchicalPAModalValues[
          "productAttributeIndex"
        ] = productAttributeIdx;
        this.showAlgoSetupModalFn(
          ALGOSETUP_ACTION_ITEMS.hierarchicalConfigModalRef,
          ""
        );
      }
    },
    deleteProductAttribute(algoObj, index) {
      algoObj.productAttribute.splice(index, 1);
    },
    fetchGlobalFiles() {
      fetchUploadedGlobalFilesList({ userId: this.userDetails._id })
        .then(response => {
          if (!response._isError && response._result) {
            this.globalFiles_Ontology = response._result.filter(
              x => x.algorithm === NEXUSALGORITHM.ontology
            );
          }
        })
        .catch(e => {});
    },
    setOntologyModal(event, paObject, algorithmIdx, productAttributeIdx) {
      if (event && event.isTrusted) {
        this.modalHeaderName = paObject.name;
        this.ontologyPAModalValues = ONTOLOGY_PA_THRESHOLD_MODAL;
        this.ontologyPAModalValues["algorithmThresholds"] = paObject.threshold;
        this.ontologyPAModalValues["selectedColumns"] = paObject.columns;
        this.ontologyPAModalValues["algoIndex"] = algorithmIdx;
        this.ontologyPAModalValues[
          "productAttributeIndex"
        ] = productAttributeIdx;
        this.showAlgoSetupModalFn(
          ALGOSETUP_ACTION_ITEMS.ontologyConfigModalRef,
          ""
        );
      }
    },
    saveAlgoSetup(event) {
      this.algoSetupjobParent.steps = this.stepsAction;
      let param = this.algoSetupjobParent;
      param.jobId = this.algoSetupjobParent._id;
      param.userObj = this.userDetails;
      // console.log("job parent --->", this.algoSetupjobParent.algorithm);
      if (this.algoSetupjobParent.algorithm.length === 0) {
        errorEventNotify("Add algorithm to save", 1);
        return;
      }
      let validityObject = validateAlgo(this.algoSetupjobParent.algorithm);
      if (!validityObject.errorMessage) {
        if (
          !validateFile(
            this.algoSetupjobParent.algorithm,
            this.classificationAlgoAttributeOptions
          )
        ) {
          errorEventNotify("File contain empty headers", 1);
        } else if (!sameAttrributeCheck(this.algoSetupjobParent.algorithm)) {
          errorEventNotify("Output column name already exists", 1);
          // errorEventNotify("Same output column cannot be used twice", 1);
        } else {
          saveAlgoSetupData(param)
            .then(response => {
              if (!response._isError) {
                this.algoSetupjobParent = [];
                this.algoSetupjobParent = response._result;
                this.$store
                  .dispatch("algosToExecuteAction", [
                    ...this.algoSetupjobParent.algorithm
                  ])
                  .then(response => {})
                  .catch(e => {
                    errorEventNotify(
                      "Some Problems Removing the filter from the grid",
                      2
                    );
                  });
                // this.algosToExecute = [...this.algoSetupjobParent.algorithm];
                successEventNotify("Algorithm saved successfully", 1);
              }
            })
            .catch(e => {
              errorEventNotify(
                "Error at saving Algorithm::" + response._message,
                1
              );
            });
        }
      } else {
        errorEventNotify(validityObject.errorMessage, 1);
      }
    },
    selectedBusinessColumn(event){
      let param = this.algoSetupjobParent;
      param.jobId = this.algoSetupjobParent._id;
      param.userObj = this.userDetails;
      saveAlgoSetupData(param)
            .then(response => {
              if (!response._isError) {
                //this.algoSetupjobParent = [];
                //this.algoSetupjobParent = response._result;
                this.$store
                  .dispatch("algosToExecuteAction", [
                    ...this.algoSetupjobParent.businessColumn
                  ])
                  .then(response => {})
                  .catch(e => { });
              }
            })
            .catch(e => {});
    },
    onAlgoSetUpCancel() {
      fetchAllAlgoByJobId({
        id: this.algoSetupjobParent._id,
        userId: this.userDetails._id
      }).then(response => {
        this.algoSetupjobParent.algorithm = response;
      });
    },
    setClassificationModel(event, paObject, algorithmIdx, productAttributeIdx) {
      if (event && event.isTrusted) {
        this.modalHeaderName = paObject.name;
        this.classificationPAModalValues = CLASSIFICATION_PA_MODAL;
        this.classificationPAModalValues["algorithmThresholds"] =
          paObject.threshold;
        this.classificationPAModalValues["selectedColumns"] = paObject.columns;
        this.classificationPAModalValues["algoIndex"] = algorithmIdx;
        this.classificationPAModalValues[
          "productAttributeIndex"
        ] = productAttributeIdx;
        this.classificationPAModalValues["algoType"] = "SVM";
        this.showAlgoSetupModalFn(
          ALGOSETUP_ACTION_ITEMS.classificationConfigModalRef,
          ""
        );
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    hideModal() {
      this.algoObj.isLearningFileChanged = true;
      this.fetchClassificationAlgoAttribute(this.algoObj.learningFilePath);
      // this.algoObj.productAttribute = [];
      this.algoSetupModal = new PAGELIST_ACTION_MODAL();
    },
    extractHierarchicalModal(event) {
      if (event && event.isTrusted) {
        let algoId = this.hierarchicalPAModalValues.algoIndex;
        let paId = this.hierarchicalPAModalValues.productAttributeIndex;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].threshold = this.hierarchicalPAModalValues.algorithmThresholds;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].columns = this.hierarchicalPAModalValues.selectedColumns;
        this.hierarchicalPAModalValues = null;
        this.algoSetupModal = new PAGELIST_ACTION_MODAL();
      }
    },
    extractOntologyModal(event) {
      if (event && event.isTrusted) {
        let algoId = this.ontologyPAModalValues.algoIndex;
        let paId = this.ontologyPAModalValues.productAttributeIndex;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].threshold = this.ontologyPAModalValues.algorithmThresholds;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].columns = this.ontologyPAModalValues.selectedColumns;
        // this.$refs.linearConfigModalRef.hide();
        this.ontologyPAModalValues = null;
        this.algoSetupModal = new PAGELIST_ACTION_MODAL();
      }
    },
    extractClassificationModal(event) {
      if (event && event.isTrusted) {
        let algoId = this.classificationPAModalValues.algoIndex;
        let paId = this.classificationPAModalValues.productAttributeIndex;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].threshold = this.classificationPAModalValues.algorithmThresholds;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].columns = this.classificationPAModalValues.selectedColumns;
        this.algoSetupjobParent.algorithm[algoId].productAttribute[
          paId
        ].algoType = "SVM";

        // this.$refs.classificationConfigModalRef.hide();
        this.classificationPAModalValues = null;
        this.algoSetupModal = new PAGELIST_ACTION_MODAL();
      }
    },
    showAlgoSetupModalFn(type, payload) {
      this.algoSetupModal.isActive = true;
      this.algoSetupModal.actionType = type;
      this.algoSetupModal.actionPayload = payload;
    },
    closeActionModalFn() {
      this.algoSetupModal = new PAGELIST_ACTION_MODAL();
      let prevAlgo = this.oldJobParent.algorithm.filter(
        algo => algo._id === this.algoObj._id
      );
      this.algoObj.learningFilePath = this.trainingFiles_Classification.filter(
        item => item.displayFileName === prevAlgo[0].collectionName
      )[0].filePath;

      if (this.algoSetupModal.actionType === 1) {
        this.algoObj.isLearningFileChanged = false;
        fetchAlgoById({
          algoId: this.algoObj._id,
          jobId: this.algoSetupjobParent._id,
          userId: this.userDetails._id
        })
          .then(response => {
            if (!response._isError) {
              this.algoObj.learningFilePath = response._result.learningFilePath;
            }
          })
          .catch(e => {});
      }
    },
    applyInputFileListActionFn(event) {
      switch (this.algoSetupModal.actionType) {
        case 1:
          this.hideModal();
          break;
        case 2:
          this.extractHierarchicalModal(event);
          break;
        case 3:
          this.extractOntologyModal(event);
          break;
        case 4:
          this.extractClassificationModal(event);
          break;
        default:
      }
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>

<style>
</style>
