import { errorEventNotify } from "./../assets/js/notifyAction";
import { checkEmptyObject, testForbiddenChars } from "./common-utils.js";
import { TIMEOUT_TIME_MS, NEXUSALGORITHM } from "../assets/js/constant";

const notValid = false;
const valid = true;

export const validateColumnFn = (copyColumnModel, gridDataTableDisplayCols) => {
  if (
    !checkEmptyObject(copyColumnModel) &&
    copyColumnModel.newColumnName !== null &&
    copyColumnModel.newColumnName !== undefined &&
    copyColumnModel.newColumnName !== ""
  ) {
    const headerColsNameArray = gridDataTableDisplayCols.map(o => {
      return o.text;
    });
    const headerColsNameArrayLower = gridDataTableDisplayCols.map(o => {
      return o.text.toLowerCase();
    });
    if (
      headerColsNameArray.includes(copyColumnModel.newColumnName.trim()) ||
      headerColsNameArrayLower.includes(copyColumnModel.newColumnName.trim())
    ) {
      errorEventNotify("Column name already exists! Enter a different name", 1);
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const validateSplitColumnFn = (
  splitColumnModel,
  gridDataTableDisplayCols
) => {
  if (
    !checkEmptyObject(splitColumnModel) &&
    splitColumnModel.columnOne !== null &&
    splitColumnModel.columnOne !== undefined &&
    splitColumnModel.columnOne !== "" &&
    splitColumnModel.columnTwo !== null &&
    splitColumnModel.columnTwo !== undefined &&
    splitColumnModel.columnTwo !== ""
  ) {
    const headerColsNameArray = gridDataTableDisplayCols.map(o => {
      return o.text;
    });
    const headerColsNameArrayLower = gridDataTableDisplayCols.map(o => {
      return o.text.toLowerCase();
    });
    if (
      headerColsNameArray.includes(splitColumnModel.columnOne.trim()) ||
      headerColsNameArrayLower.includes(splitColumnModel.columnOne.trim()) ||
      headerColsNameArray.includes(splitColumnModel.columnTwo.trim()) ||
      headerColsNameArrayLower.includes(splitColumnModel.columnTwo.trim())
    ) {
      errorEventNotify("Column name already exists! Enter a different name", 1);
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const validateMergeColumnFn = (
  mergeSingleColumnModel,
  gridDataTableDisplayCols
) => {
  if (
    !checkEmptyObject(mergeSingleColumnModel) &&
    mergeSingleColumnModel.mergingColumnName !== null &&
    mergeSingleColumnModel.mergingColumnName !== undefined &&
    mergeSingleColumnModel.mergingColumnName !== ""
  ) {
    const headerColsNameArray = gridDataTableDisplayCols.map(o => {
      return o.text;
    });
    const headerColsNameArrayLower = gridDataTableDisplayCols.map(o => {
      return o.text.toLowerCase();
    });
    if (
      headerColsNameArray.includes(
        mergeSingleColumnModel.mergingColumnName.trim()
      ) ||
      headerColsNameArrayLower.includes(
        mergeSingleColumnModel.mergingColumnName.trim()
      )
    ) {
      errorEventNotify("Column name already exists! Enter a different name", 1);
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const validateCopyColumnFn = (column, model, gridRows) => {
  let validationFlag = false;
  //0.validating hamburger selected column
  if (!checkEmptyObject(column) && column.value !== "") {
    validationFlag = true;
  } else {
    validationFlag = false;
    errorEventNotify("Error at copy column validation!", 1);
    return validationFlag;
  }
  //0.validating copy column model
  if (!checkEmptyObject(model) && model.newColumnName) {
    validationFlag = true;
  } else {
    validationFlag = false;
    // errorEventNotify("Column model empty or new column value empty!", 1);
    errorEventNotify("Column name cannot be blank", 1);

    return validationFlag;
  }
  //2.Getting header column names to check for duplicate
  for (let i = 0; i < gridRows.length; i++) {
    const duplicateFlag =
      gridRows[i].text.toLowerCase() === model.newColumnName ||
      gridRows[i].text === model.newColumnName;
    if (duplicateFlag) {
      validationFlag = false;
      errorEventNotify("Column name already exists! Enter a different name", 1);
      break;
    } else {
      validationFlag = true;
    }
  }
  return validationFlag;
};

const validateColumnObject = column => {
  let validationFlag = false;
  //0.validating hamburger selected column
  validationFlag = !checkEmptyObject(column) && column.value !== "";
  if (!validationFlag) {
    errorEventNotify("Some error happend at validating the operation!", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }
  return validationFlag;
};

const validateSplitColumnDelemFn = (column, model, gridRows) => {
  let validationFlag = false;
  //0.validating hamburger selected column
  validationFlag = !checkEmptyObject(column) && column.value !== "";
  if (!validationFlag) {
    errorEventNotify("Some error at split fn validation", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }

  //1.validating the split column model
  if (!model.columnOne || !model.columnTwo || !model.delimeter) {
    validationFlag = false;
    errorEventNotify("Split column names & delimiter are mandatory fields", 1);
    return validationFlag;
  } else if (model.columnOne.toLowerCase() === model.columnTwo.toLowerCase()) {
    validationFlag = false;
    errorEventNotify("Split column names cannot be same", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }

  //2.validating duplicacy of new column names
  for (let i = 0; i < gridRows.length; i++) {
    const duplicateFlagCol1 =
      gridRows[i].text.toLowerCase() === model.columnOne ||
      gridRows[i].text === model.columnOne;
    const duplicateFlagCol2 =
      gridRows[i].text.toLowerCase() === model.columnTwo ||
      gridRows[i].text === model.columnTwo;
    if (duplicateFlagCol1) {
      validationFlag = false;
      errorEventNotify(
        "Column name already exists! Enter a different name for column one",
        1
      );
      break;
    } else {
      validationFlag = true;
    }
    if (duplicateFlagCol2) {
      validationFlag = false;
      errorEventNotify(
        "Column name already exists! Enter a different name for column two",
        1
      );
      break;
    } else {
      validationFlag = true;
    }
  }
  // gridRows.forEach(elm => {
  //   const duplicateFlagCol1 =
  //     elm.text.toLowerCase() === model.columnOne ||
  //     elm.text === model.columnOne;
  //   const duplicateFlagCol2 =
  //     elm.text.toLowerCase() === model.columnTwo ||
  //     elm.text === model.columnTwo;
  //   if (duplicateFlagCol1) {
  //     validationFlag = false;
  //     errorEventNotify(
  //       "Column name already exists! Enter a different name for column one",
  //       1
  //     );
  //   } else {
  //     validationFlag = true;
  //   }
  //   if (duplicateFlagCol2) {
  //     validationFlag = false;
  //     errorEventNotify(
  //       "Column name already exists! Enter a different name for column two",
  //       1
  //     );
  //   } else {
  //     validationFlag = true;
  //   }
  // });
  return validationFlag;
};

const validateSingleColumnMergeFn = (column, model, gridRows) => {
  let validationFlag = false;
  //0.validating hamburger selected column
  validationFlag = !checkEmptyObject(column) && column.value !== "";
  if (!validationFlag) {
    errorEventNotify("Error at merge fn validation", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }

  //1.validating the merge column model
  if (checkEmptyObject(model.mergeWithColumn) || !model.mergingColumnName) {
    validationFlag = false;
    errorEventNotify(
      "Column name & merging column selection are mandatory fields!",
      1
    );
    return validationFlag;
  } else {
    validationFlag = true;
  }

  //2.validating duplicacy of new column names
  for (let i = 0; i < gridRows.length; i++) {
    const duplicateFlag =
      gridRows[i].text.toLowerCase() === model.mergingColumnName ||
      gridRows[i].text === model.mergingColumnName;

    if (duplicateFlag) {
      validationFlag = false;
      errorEventNotify(
        "Column name already exists! Enter a different name for merging column",
        1
      );
      break;
    } else {
      validationFlag = true;
    }
  }
  return validationFlag;
};

const validateColumnFreeTextSearchFn = (column, model) => {
  let validationFlag = false;
  //0.validating hamburger selected column
  validationFlag = !checkEmptyObject(column) && column.value !== "";
  if (!validationFlag) {
    errorEventNotify("Error at column search fn validation", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }

  //1.validating the search column model
  return validationFlag;
};

const validateApplyConditionalFilterFn = (column, model) => {
  let validationFlag = false;
  //0.validating hamburger selected column
  validationFlag = !checkEmptyObject(column) && column.value !== "";
  if (!validationFlag) {
    errorEventNotify("Error at conditional filter validation", 1);
    return validationFlag;
  } else {
    validationFlag = true;
  }
  //1.validating the model
  if (checkEmptyObject(model.selectedCondition)) {
    validationFlag = false;
    // errorEventNotify("Condition selection is required field!", 1);
    errorEventNotify("Select a filter condition", 1);
  } else {
    //input allowed to be blank only for IS_BLANK and IS_NOT_BLANK
    if (
      model.selectedCondition.value.id === 9 ||
      model.selectedCondition.value.id === 10 ||
      model.selectedCondition.value.id === 15 ||
      model.selectedCondition.value.id === 16 ||
      model.selectedCondition.value.id === 17 ||
      model.selectedCondition.value.id === 18
    ) {
      validationFlag = true;
    } else {
      validationFlag = model.conditionalInput ? true : false;
      if (!validationFlag) {
        // errorEventNotify("Conditional input is required field!", 1);
        errorEventNotify("Enter a value to apply condition filter", 1);
      }
    }
  }
  return validationFlag;
};

export const gridHamburgerValidator = {
  validateCopyColumnFn: validateCopyColumnFn,
  validateSplitColumnDelemFn: validateSplitColumnDelemFn,
  validateSingleColumnMergeFn: validateSingleColumnMergeFn,
  validateColumnFreeTextSearchFn: validateColumnFreeTextSearchFn,
  validateApplyConditionalFilterFn: validateApplyConditionalFilterFn,
  validateColumnObject: validateColumnObject
};

// export const validateAlgoToSave = algo => {
//   let validityFailed = false;
//   // if (algo.length === 0) return notValid;
//   if (algo.length > 0) {
//     for (let i = 0; i < algo.length; i++) {
//       if (ifAlgoHaveNoName(algo[i])) return notValid;
//       switch (algo[i].algoName) {
//         case "Linear":
//           validityFailed = checkLinearAlgoValidity(algo[i]);
//           break;
//         case "Hierarchical":
//           validityFailed = checkHierarchicalAlgoValidity(algo[i]);
//           break;
//         case "Ontology":
//           validityFailed = checkOntologyAlgoValidity(algo[i]);
//           break;
//         case "Classification":
//           validityFailed = checkClassificationAlgoValidity(algo[i]);
//           break;
//       }
//       if (validityFailed) return notValid;
//     }
//   }
//   return valid;
// };

export const validateAlgoToSave = algo => {
  let validityObject = {};
  let message = "";
  // if (algo.length === 0) return notValid;
  if (algo.length > 0) {
    for (let i = 0; i < algo.length; i++) {
      if (ifAlgoHaveNoName(algo[i])) {
        validityObject.errorMessage = "Algorithm Name not provided";
        return validityObject;
      }
      switch (algo[i].algoName) {
        case NEXUSALGORITHM.extraction:
          message = checkLinearAlgoValidity(algo[i]);
          break;
        case NEXUSALGORITHM.align:
          message = checkHierarchicalAlgoValidity(algo[i]);
          break;
        case NEXUSALGORITHM.ontology:
          message = checkOntologyAlgoValidity(algo[i]);
          break;
        case NEXUSALGORITHM.enrichment:
          message = checkClassificationAlgoValidity(algo[i]);
          break;
      }
      if (message) {
        validityObject.errorMessage = message;
        return validityObject;
      }
    }
  }
  return validityObject;
};

function checkLinearAlgoValidity(algorithmObject) {
  let message = "";
  if (!algorithmObject.algoName) {
    message = "Please provide algorithm name";
    return message;
  } else if (!algorithmObject.learningFileName) {
    message =
      "Please select a learning file for algorithm: " +
      algorithmObject.algoName;
    return message;
  } else if (!algorithmObject.learningFilePath) {
    message =
      "Learning file path not found for algorithm: " + algorithmObject.algoName;
    return message;
  } else if (algorithmObject.productAttribute.length === 0) {
    message =
      "Please add attributes for algorithm: " + algorithmObject.algoName;
    return message;
  } else {
    return message;
  }
}

function checkHierarchicalAlgoValidity(algorithmObject) {
  let allProductAttributes = algorithmObject.productAttribute;
  let message = "";
  if (allProductAttributes.length > 0) {
    for (let i = 0; i < allProductAttributes.length; i++) {
      let threshold = allProductAttributes[i].threshold;
      let nameOfAttribute = allProductAttributes[i].name;
      if (!nameOfAttribute) {
        message =
          "Attribute name not provided for algorithm: " +
          algorithmObject.algoName;
        return message;
      } else if (
        threshold[0].isSubALgoSelectedExecute === false &&
        threshold[1].isSubALgoSelectedExecute === false &&
        threshold[2].isSubALgoSelectedExecute === false
      ) {
        message =
          "Please select atleast one Threshold for Attribute " +
          nameOfAttribute +
          ",algorithm: " +
          algorithmObject.algoName;
        return message;
      }
    }
    return "";
  } else {
    return "Please add attributes for algorithm: " + algorithmObject.algoName;
  }
}

function ifAlgoHaveNoName(algorithmObject) {
  return algorithmObject.algoName ? false : true;
}

function checkOntologyAlgoValidity(algorithmObject) {
  let allProductAttributes = algorithmObject.productAttribute;
  let message = "";
  if (!algorithmObject.learningFileName) {
    message =
      "Please select a learning file for algorithm: " +
      algorithmObject.algoName;
    return message;
  } else if (allProductAttributes.length === 0) {
    message =
      "Please add attributes for algorithm: " + algorithmObject.algoName;
    return message;
  } else {
    for (let i = 0; i < allProductAttributes.length; i++) {
      let eachObject = allProductAttributes[i];
      if (!eachObject.name) {
        message =
          "Attribute name not provided for algorithm: " +
          algorithmObject.algoName;
        return message;
      } else if (eachObject.columns.length === 0) {
        message =
          "Columns name not provided for attribute " +
          eachObject.name +
          ",algorithm: " +
          algorithmObject.algoName;
        return message;
      } else if (eachObject.threshold.value === 0) {
        message =
          "Threshold not provided for attribute " +
          eachObject.name +
          ",algorithm: " +
          algorithmObject.algoName;
        return message;
      }
    }
    return "";
  }
}
function checkClassificationAlgoValidity(algorithmObject) {
  let message = "";
  let allProductAttributes = algorithmObject.productAttribute;
  if (!algorithmObject.learningFilePath) {
    message =
      "Please select a learning file for algorithm: " +
      algorithmObject.algoName;
    return message;
  } else if (
    !(
      algorithmObject.keyColumnClassification &&
      algorithmObject.keyColumnClassification.value
    )
  ) {
    message =
      "Please select a Key Column for algorithm: " + algorithmObject.algoName;
    return message;
  } else if (allProductAttributes.length === 0) {
    message =
      "Attributes  not provided for algorithm: " + algorithmObject.algoName;
    return message;
  } else {
    for (let i = 0; i < allProductAttributes.length; i++) {
      let eachObject = allProductAttributes[i];
      if (!eachObject.name) {
        message =
          "Attribute name not provided for algorithm: " +
          algorithmObject.algoName;
        return message;
      } else if (eachObject.columns.length === 0) {
        message =
          "Columns name not provided for attribute " +
          eachObject.name +
          ", algorithm: " +
          algorithmObject.algoName;
        return message;
      }
    }
    return "";
  }
}

export function sameAttrributeCheck(algorithm) {
  let outputColumn = [];
  for (let i = 0; i < algorithm.length; i++) {
    switch (algorithm[i].algoName) {
      case NEXUSALGORITHM.extraction:
        outputColumn.push(algorithm[i].productAttribute[0].columns);
        break;
      case NEXUSALGORITHM.align:
        outputColumn.push(
          algorithm[i].productAttribute.map(m => {
            return "Global_" + m.name;
          })
        );
        break;
      case NEXUSALGORITHM.ontology:
        outputColumn.push(
          algorithm[i].productAttribute.map(m => {
            return "Global_" + m.name;
          })
        );
        break;
      case NEXUSALGORITHM.enrichment:
        outputColumn.push(algorithm[i].productAttribute.map(m => m.name));
        break;
    }
  }
  let column = outputColumn.flat();

  let uniqColumn = _.uniq(column);
  if (column.length === uniqColumn.length) {
    return true;
  } else {
    return false;
  }
}

export const validateFile = (algo, classificationAlgoAttributeOptions) => {
  let validityFailed = false;
  for (let i = 0; i < algo.length; i++) {
    switch (algo[i].algoName) {
      case NEXUSALGORITHM.extraction:
        validityFailed = checkLinearFileValidity(algo[i]);
        break;
      case NEXUSALGORITHM.enrichment:
        validityFailed = checkClassificationFileValidity(
          algo[i],
          classificationAlgoAttributeOptions
        );
        break;
    }
    if (validityFailed) return notValid;
  }
  return valid;
};

function checkLinearFileValidity(algorithmObject) {
  if (algorithmObject.productAttribute[0].columns.includes("__EMPTY")) {
    return true;
  } else {
    return false;
  }
}

function checkClassificationFileValidity(
  algorithmObject,
  classificationAlgoAttributeOptions
) {
  if (
    classificationAlgoAttributeOptions[algorithmObject.learningFilePath] &&
    classificationAlgoAttributeOptions[
      algorithmObject.learningFilePath
    ].includes("__EMPTY")
  ) {
    return true;
  } else {
    return false;
  }
}

export function specialCharacterCheck(columnName) {
  const forbiddenCharactersForColumnName = /[/\."$*<>:|?()%+=@!#^&{}';,-]/;
  if (
    columnName.trim() === "" ||
    forbiddenCharactersForColumnName.test(columnName)
  ) {
    return true;
  } else {
    return false;
  }
}

export function specialCharacterCheckForSplit(columnOne, columnTwo) {
  const forbiddenCharactersForColumnName = /[/\."$*<>:|?()%+=@!#^&{}';,-]/;
  if (
    columnOne.trim() === "" ||
    columnTwo.trim() === "" ||
    forbiddenCharactersForColumnName.test(columnOne) ||
    forbiddenCharactersForColumnName.test(columnTwo)
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkIfUserSessionIsTimedOut(
  timeWhenLastActionIsPerformed,
  currentTime
) {
  return currentTime - timeWhenLastActionIsPerformed > TIMEOUT_TIME_MS;
}

export const validateFileImport = fileParam => {
  let validationFlag = true;
  if (!fileParam.length) {
    errorEventNotify("File selected is empty", 1);
    validationFlag = false;
    return validationFlag;
  }
  //2.Extension Check
  const incomingFile = fileParam[0];
  const validExtns = [".xlsx", ".xls", ".csv"];
  const fileExtn = incomingFile["name"].substring(
    incomingFile["name"].lastIndexOf(".")
  );
  if (!validExtns.includes(fileExtn)) {
    validationFlag = false;
    errorEventNotify("Only files with extension .xlsx .xls .csv supported", 1);
    return validationFlag;
  }

  //3.Forbidden chars check
  if (testForbiddenChars(incomingFile["name"])) {
    validationFlag = false;
    errorEventNotify(
      `File Name cannot contain [/\"$*<>:|?_()%+=@!#^&{}';,-]`,
      1
    );
    return validationFlag;
  }

  //4.NaN check
  if (!isNaN(incomingFile["name"].charAt(0))) {
    validationFlag = false;
    errorEventNotify("File name cannot starts with a numeric value", 1);
    return validationFlag;
  }
  return validationFlag;
};
