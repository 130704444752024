export const ACTION_NAME = {
  split: "Split Column",
  merge: "Merge Column",
  delete: "Delete Column",
  blank: "Blank Column",
  copy: "Copy Column",
  insert: "Insert Value",
  update: "Update Value",
  globalReplace: "Global Replace",
  algoExecute: "Execute",
  removeDuplicates: "Remove Duplicates",
  arithmaticOperation: "Arithmatic Operation",
  bulkColumnDelete: "Multiple column delete",
  processOutput: "Process Output"
};

export class MERGE_SINGLE_COLUMN_MODEL {
  constructor() {
    this.targetColumn = {};
    this.mergeWithColumn = {};
    this.mergingColumnName = "";
  }
}

export class SPLIT_COLUMN_MODEL {
  constructor() {
    this.targetColumn = {};
    this.columnOne = "";
    this.columnTwo = "";
    this.delimeter = "";
  }
}

export class SINGLE_BLANK_COLUMN_MODEL {
  constructor() {
    this.targetColumn = {};
    this.newColumnName = "";
  }
}

export class COPY_COLUMN_MODEL {
  constructor() {
    this.targetColumn = {};
    this.newColumnName = "";
  }
}

export const TABLE_GRAPH_CLASS = {
  Found: "fuzzy-match",
  Exact: "fuzzy-match",
  Not_Found: "fuzzy1-match",
  Pattern: "fuzzy2-match",
  Probablistic: "pattern-match"
};

export const SUGGESTION_CARD_MODULE = {
  globalReplacement: "GR",
  UpdateData: "UD",
  InsertData: "ID"
};

// export const ALGORITHM = {
//   linear: "Linear",
//   hierarchical: "Hierarchical",
//   ontology: "Ontology",
//   classification: "Classification"
// };

// export const COLUMN_FREETEXT_SEARCH_MODEL = {
//   columnToSearch: {},
//   textToSearch: ""
// };

export class COLUMN_FREETEXT_SEARCH_MODEL {
  constructor() {
    this.columnToSearch = {};
    this.textToSearch = "";
  }
}

export const CONDITIONAL_FILTER_OPTIONS_MODEL = {
  conditions: [
    {
      text: "Equal To",
      value: {
        id: 1,
        text: "EQUALS"
      }
    },
    {
      text: "Is not equal to",
      value: {
        id: 2,
        text: "IS_NOT_EQUAL_TO"
      }
    },
    {
      text: "Contains",
      value: {
        id: 3,
        text: "CONTAINS"
      }
    },
    {
      text: "Does not contain",
      value: {
        id: 4,
        text: "DOES_NOT_CONTAIN"
      }
    },
    {
      text: "Starts with",
      value: {
        id: 5,
        text: "STARTS_WITH"
      }
    },
    {
      text: "Does not start with",
      value: {
        id: 6,
        text: "DOES_NOT_START_WITH"
      }
    },
    {
      text: "Ends with",
      value: {
        id: 7,
        text: "ENDS_WITH"
      }
    },
    {
      text: "Does not end with",
      value: {
        id: 8,
        text: "DOES_NOT_END_WITH"
      }
    },
    {
      text: "Is blank",
      value: {
        id: 9,
        text: "IS_BLANK"
      }
    },
    {
      text: "Is not blank",
      value: {
        id: 10,
        text: "IS_NOT_BLANK"
      }
    },
    {
      text: "Greater than",
      value: {
        id: 11,
        text: "GREATER_THAN"
      }
    },
    {
      text: "Greater than or equal to",
      value: {
        id: 12,
        text: "GREATER_THAN_EQUAL_TO"
      }
    },
    {
      text: "Less than",
      value: {
        id: 13,
        text: "LESS_THAN"
      }
    },
    {
      text: "Less than or equal to",
      value: {
        id: 14,
        text: "LESS_THAN_EQUAL_TO"
      }
    },
    {
      text: "Is validated",
      value: {
        id: 15,
        text: "IS_VALIDATED"
      }
    },
    {
      text: "Is not validated",
      value: {
        id: 16,
        text: "IS_NOT_VALIDATED"
      }
    },
    {
      text: "Is reviewed",
      value: {
        id: 17,
        text: "IS_REVIEWED"
      }
    },
    {
      text: "Is not reviewed",
      value: {
        id: 18,
        text: "IS_NOT_REVIEWED"
      }
    },
    {
      text: "Column matches",
      value: {
        id: 19,
        text: "COMPARE_WITH_OTHER_COLUMN_MATCHES"
      }
    },
    {
      text: "Column not matches",
      value: {
        id: 20,
        text: "COMPARE_WITH_OTHER_COLUMN_NOT_MATCHES"
      }
    },
    {
      text: "Column contains",
      value: {
        id: 21,
        text: "COMPARE_WITH_OTHER_COLUMN_CONTAINS"
      }
    },
    {
      text: "Column not contains",
      value: {
        id: 22,
        text: "COMPARE_WITH_OTHER_COLUMN_NOT_CONTAINS"
      }
    }

  ],
  numberConditions: [
    {
      text: "Equals",
      value: {
        id: 1,
        text: "EQUALS"
      }
    },
    {
      text: "Is not equal to",
      value: {
        id: 2,
        text: "DOES_NOT_EQUAL"
      }
    },
    {
      text: "Greater Than",
      value: {
        id: 3,
        text: "GREATER_THAN"
      }
    },
    {
      text: "Greater than or equal to",
      value: {
        id: 4,
        text: "GREATER_THAN_EQUAL_TO"
      }
    },
    {
      text: "Less than",
      value: {
        id: 5,
        text: "LESS_THAN"
      }
    },
    {
      text: "Less than or equal to",
      value: {
        id: 6,
        text: "LESS_THAN_EQUAL_TO"
      }
    },
    {
      text: "Is blank",
      value: {
        id: 7,
        text: "IS_BLANK"
      }
    },
    {
      text: "Is not blank",
      value: {
        id: 8,
        text: "IS_NOT_BLANK"
      }
    }
  ],
  selectedCondition: {},
  conditionalInput: "",
  selectedColumn: {}
};
export const MARK_AS_VALIDATED = {
  markedAllAsValidated: "The filtered records have been marked as validated",
  markedColumnAsValidated: "The column has been marked as validated",
  markedCellAsValidated: "The value has been marked as validated",
  unmarkedAllValidated: "The filtered records have been unmarked",
  unmarkedValidatedColumn: "The column has been unmarked",
  unmarkedValidatedCell: "The value has been unmarked",
  errorMarkedAllAsValidated: "Error occured in updating the records",
  errorMarkedColumnAsValidated: "Error occured in updating the column records",
  errorMarkedCellAsValidated: "Error occured in updating the record",
  markedAllAsValidatedInput: "Cannot perfom validation on Input column",
  errorFindValidatedData: "Error occured while filtering records"
};

export const ADMIN = "1";
export const MARK_FOR_REVIEW = {
  markedAllForReview: "The filtered records have been marked for review",
  markedColumnForReview: "The column has been marked for review",
  markedCellForReview: "The value has been marked for review",
  unmarkedAllReviewed: "The filtered records have been unmarked for review",
  unmarkReviewedCell: "The value has been unmarked for review",
  errorMarkedAllForReview: "Error occured in updating the records",
  errorMarkedColumnForReview: "Error occured in updating the column records",
  errorMarkedCellForReview: "Error occured in updating the record",
  errorFindReviewedData: "Error occured while filtering records",
  markedAllAsReviewedInput: "Cannot perfom validation on Input column"
};

export const DISPLAYFILENAME = "displayFileName";
export const DESCRIPTION = "description";
export const ALGO = "algorithm";
export const UPLOADEDBY = "uploadedBy";
export const TIMEOUT_TIME_MS = 7200000;

export const PAGELIST_ACTION_ITEMS = {
  deletion: 1,
  sendForReview: 2,
  share: 3,
  clone: 4,
  replaceFile: 5,
  extractFile: 6,
  fetchAzureFile: 7
};

export class PAGELIST_ACTION_MODAL {
  constructor() {
    this.isActive = false;
    this.actionPayload = null;
    this.actionType = null;
  }
}

export const ALGOSETUP_ACTION_ITEMS = {
  modelAlreadyExistsModal: 1,
  hierarchicalConfigModalRef: 2,
  ontologyConfigModalRef: 3,
  classificationConfigModalRef: 4
};

export const JOBSETUP_ACTION_ITEMS = {
  publishSettingsTextOrDbName: 1,
  algoToExecuteRef: 2,
  finalizeSteps: 3
};

export const STEP_MODE = {
  refreshMode: "Refresh",
  addMode: "Add",
  deleteMode: "Delete",
  ongoingMode: "Ongoing"
};

export const SFTP = "SFTP";
export const AZURE_BLOB = "Azure Blob";

export const EXTRACT_FILE_MODE = {
  loading: "Loading",
  download: "Download",
  extract: "Extract",
  failed: "Failed"
};

export const AZURE_DATA_LAKE = "Azure Data Lake";
export class PUBLISH_CONSTRUCTOR {
  constructor() {
    this.fileNameOrDbName = "";
    this.exportTo = "";
    this.customColumnsToPublish = "";
    this.customDelim = { text: "Comma", value: ",", extension: "csv" };
    this.extension = ".csv";
  }
}

export const REPLACE_FILE_MODE = {
  loading: "Loading",
  replace: "Replace",
  failed: "Failed"
};

export class SCHEDULEOBJECT {
  constructor() {
    this.scheduleValue = "";
    this.weekValue = "";
    this.inputRepoValue = "";
    this.filePath = "";
    this.fileName = "";
    this.time = "";
    this.monthValue = "";
    this.scheduleType = "auto";
    this.displayFileName = "";
  }
}

export const NEXUSALGORITHM = {
  extraction: "Linear",
  align: "Hierarchical",
  enrichment: "Classification",
  ontology: "Ontology"
};