import { EventBus } from "./../../../src/main.js";
export const successEventNotify = (message, notifyLevel) => {
  const successMessage = {
    message: message,
    variant: "success"
  };
  switch (notifyLevel) {
    case 1:
      EventBus.$emit("SUCCESS_EVENT_NOTIFY_PAGE", successMessage);
      break;
    case 2:
      EventBus.$emit("SUCCESS_EVENT_NOTIFY_MODAL", successMessage);
      break;
    default:
      return;
  }
};

export const errorEventNotify = (message, notifyLevel) => {
  const errorMessage = {
    message: message,
    variant: "danger"
  };
  switch (notifyLevel) {
    case 1:
      EventBus.$emit("ERROR_EVENT_NOTIFY_PAGE", errorMessage);
      break;
    case 2:
      EventBus.$emit("ERROR_EVENT_NOTIFY_MODAL", errorMessage);
      break;
    default:
      return;
  }
};
