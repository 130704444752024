<template>
  <div id="nexus-schedule-job-component">
    <div
      class="form-group"
      style="margin-top: 8px;"
    >
      <label style="font-size:13px;font-weight:bold">Input Configuration:</label>
      <multiselect
        :options="inputRepo"
        track-by="value"
        label="value"
        :close-on-select="true"
        v-model="scheduleObject.inputRepoValue"
      >
        <template
          slot="singleLabel"
          slot-scope="{ option }"
        >
          <strong>{{ option.value }}</strong>
        </template>
      </multiselect>
    </div>
    <div class="form-group">
      <label style="font-size:13px;font-weight:bold">File Name</label>
      <b-form-input
        v-model="scheduleObject.fileName"
        type="text"
      ></b-form-input>
    </div>
    <div class="form-group">
      <label style="font-size:13px;font-weight:bold">Display File Name</label>
      <b-form-input
        v-model="scheduleObject.displayFileName"
        type="text"
      ></b-form-input>
    </div>
    <b-form-group label="Schedule Type">
      <b-form-radio-group
        style="display: flex; margin-right: 10px"
        v-model="scheduleObject.scheduleType"
        :options="scheduleTypes"
        name="sftp-Type"
      ></b-form-radio-group>
    </b-form-group>
    <span v-if="this.scheduleObject.scheduleType === 'timeBased'">
      <div class="form-group">
        <label style="font-size:13px;font-weight:bold">Schedule Details:</label>
        <multiselect
          :options="scheduleList"
          track-by="value"
          label="value"
          :close-on-select="true"
          v-model="scheduleObject.scheduleValue"
        >
          <template
            slot="singleLabel"
            slot-scope="{ option }"
          >
            <strong>{{ option.value }}</strong>
          </template>
        </multiselect>
      </div>
      <div
        class="form-group"
        v-if="scheduleObject.scheduleValue.value === 'Month'"
      >
        <label style="font-size:13px;font-weight:bold">Date:</label>
        <input
          v-model="scheduleObject.monthValue"
          min="1"
          max="28"
          type="number"
          class="form-control floating-input"
          id="month"
          placeholder="1-28"
        />
      </div>
      <div
        class="form-group"
        v-if="scheduleObject.scheduleValue.value === 'Week'"
      >
        <label style="font-size:13px;font-weight:bold">Day:</label>
        <multiselect
          :options="weekList"
          track-by="value"
          label="value"
          :close-on-select="true"
          v-model="scheduleObject.weekValue"
        >
          <template
            slot="singleLabel"
            slot-scope="{ option }"
          >
            <strong>{{ option.value }}</strong>
          </template>
        </multiselect>
      </div>
      <div
        class="form-group"
        v-if="scheduleObject.scheduleValue != ''"
      >
        <label style="font-size:13px;font-weight:bold">Time:</label>
        <b-form-input
          v-model="scheduleObject.time"
          type="time"
        ></b-form-input>
      </div>
    </span>
  </div>
</template>

<script>
/*
  JS Code for Schedule componenet 
  Dated :: 02 July 2019.
  Last Edited : 02 July 2019.
  Edited by: Aniruddha Banerjee
*/

import {
  scheduleJob,
  getAllScheduleOptions
} from "./../services/parentservices.js";

import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import { EventBus } from "../main";
import { SCHEDULEOBJECT } from "../assets/js/constant";

// const SCHEDULEOBJECT = {
//   scheduleValue: "",
//   weekValue: "",
//   inputRepoValue: "",
//   filePath: "",
//   fileName: "",
//   time: "",
//   monthValue: "",
//   scheduleType: "auto",
//   displayFileName: ""
// };
// class SCHEDULEOBJECT {
//   constructor() {
//     this.scheduleValue = "";
//     this.weekValue = "";
//     this.inputRepoValue = "";
//     this.filePath = "";
//     this.fileName = "";
//     this.time = "";
//     this.monthValue = "";
//     this.scheduleType = "auto";
//     this.displayFileName = "";
//   }
// }

export default {
  name: "nexus-schedule-component",
  components: {
    Multiselect
  },
  data() {
    return {
      scheduleObject: new SCHEDULEOBJECT(),
      scheduleList: [{ value: "Day" }, { value: "Week" }, { value: "Month" }],
      weekList: [
        { value: "Monday" },
        { value: "Tuesday" },
        { value: "Wednesday" },
        { value: "Thursday" },
        { value: "Friday" },
        { value: "Saturday" },
        { value: "Sunday" }
      ],
      inputRepo: [
        { value: "No settings for repository found", $isDisabled: true }
      ],
      scheduleJobData: "",
      scheduleTypes: [
        { text: "On File Receive", value: "auto" },
        { text: "Schedule", value: "timeBased" }
      ]
    };
  },
  mounted() {
    this.fetchScheduleSettings();
    this.scheduleSaveEventCatcher();
  },
  methods: {
    fetchScheduleSettings() {
      getAllScheduleOptions({ userId: this.userDetails._id }).then(response => {
        if (response.data._result.length !== 0)
          this.inputRepo = response.data._result;
      });
    },
    scheduleSaveEventCatcher() {
      this.$emit("scheduleObj", this.scheduleObject);
      EventBus.$on("save-schedule-setting-event", payload => {
        this.saveScheduleData(this.scheduleObject, payload);
      });
    },
    saveScheduleData(scheduleJobData, payload) {
      if (this.scheduleObject.fileName && this.scheduleObject.inputRepoValue) {
        if (this.scheduleObject.monthValue) {
          this.scheduleObject.monthValue = parseInt(
            this.scheduleObject.monthValue
          );
        }
        if (this.scheduleObject.weekValue) {
          this.scheduleObject.weekValue = parseInt(
            this.scheduleObject.weekValue
          );
        }
        scheduleJob({
          scheduleObj: this.scheduleObject,
          email: this.userDetails.email,
          jobId: payload.jobId,
          userId: this.userDetails._id,
          name: this.userDetails.name,
          jobParent: payload.jobParent
        })
          .then(response => {
            this.scheduleObject = new SCHEDULEOBJECT();
            this.$emit("scheduleObj", this.scheduleObject);
          })
          .catch(e => {
            // errorEventNotify("Some Problems occured during scheduling", 2);
          });
      }
    }
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  }
};
</script>

<style>
.custom-control-inline {
  margin-right: 10px;
}
</style>
