export function toggleNav(elm, document) {
  const leftNav = document.querySelector(".fr-left-sidebar");
  const frWrapper = document.querySelector(".fr-warapper");
  const container = elm.parentElement;
  leftNav.classList.toggle("open");
  container.classList.toggle("open");
  frWrapper.classList.toggle("overlay");

  document.body.addEventListener(
    "click",
    function(e) {
      var target = e.target || e.srcElement;

      if (target !== container && !isChildOf(target, container)) {
        leftNav.className = "fr-left-sidebar";
        frWrapper.className = "fr-warapper";
        container.classList = "hamburger-menu";
      }
    },
    false
  );

  function isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    } else {
      return isChildOf(child.parentNode, parent);
    }
  }
}

export function toggleLogout(elm, document) {
  const logoutMenu = document.querySelector(".dropdown-menu-right");
  const container = elm.parentElement;
  logoutMenu.classList.toggle("show");

  document.body.addEventListener(
    "click",
    function(e) {
      var target = e.target || e.srcElement;

      if (target !== container && !isChildOf(target, container)) {
        logoutMenu.className = "dropdown-menu dropdown-menu-right";
      }
    },
    false
  );

  function isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    } else {
      return isChildOf(child.parentNode, parent);
    }
  }
}
