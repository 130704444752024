<template>
  <div id="navBarParentDiv">
    <header>
      <nav class="navbar navbar-expand-lg navbar-fr fixed-top">
        <div class="collapse navbar-collapse align-middle">
          <a
            class="hamburger-menu"
            @click="toggleNav($event)"
            href="javascript:void(0);"
          >
            <i class="icon24 icon-hamburger"></i>
          </a>
          <router-link
            class="navbar-brand"
            :to="{ name: 'jobLanding'}"
          >
            <img
              class="concordia-nexus-logo"
              src="../assets/images/concordia-nexus-logo.png"
            />
          </router-link>
          <ul class="navbar-nav ml-auto align-top navbar-right-menu align-items-center">
            <li class="nav-item">
              <div class="dropdown profile-dropdown">
                <button
                  class="btn btn-link dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="toggleLogout($event)"
                >{{this.$store.state.userObj.name}}</button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a
                    class="dropdown-item item-cell"
                    href="#"
                  >
                    <span>Help</span>
                    <i class="icon16 icon-help"></i>
                  </a>
                  <a
                    v-if="userDetails.roles && userDetails.roles[0] === '1'"
                    class="dropdown-item item-cell"
                    href="#"
                    @click="navigateToAdminSettings()"
                  >
                    <span>Setting</span>
                    <i class="icon16 icon-setting"></i>
                  </a>
                  <a
                    class="dropdown-item item-cell"
                    href="#"
                  >
                    <span>Change Password</span>
                    <i class="icon16 icon-password"></i>
                  </a>
                  <a
                    @click="logout()"
                    class="dropdown-item item-cell"
                    href="#"
                  >
                    <span>Logout</span>
                    <i class="icon16 icon-logout-1"></i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!--Left Navigation-->
    <!-- Left Navigation Bar -->
    <aside class="fr-left-sidebar">
      <div class="left-sidebar-wrapper">
        <nav class="left-sidebar-content">
          <!--Menu Navigation-->
          <div
            class="navbar-collapse"
            id="leftNavigation"
          >
            <ul
              class="navbar-left"
              style="cursor:pointer"
            >
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="jobListing()"
                >
                  <i class="icon24 icon-joblist"></i>
                  <span>Job List</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="inputDataListing()"
                >
                  <i class="icon24 icon-data-import"></i>
                  <span>Input Data</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="globalDictionaryListing()"
                >
                  <i class="icon24 icon-global-dictionary"></i>
                  <span>Ontology</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="TrainingDictionaryListing()"
                >
                  <i class="icon24 icon-global-dictionary"></i>
                  <span>Training Data</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
    <page-alert></page-alert>
  </div>
</template>

<script>
import { toggleNav, toggleLogout } from "../assets/js/nav-hamburger.js";
import { publishData, Options, messages } from "../assets/js/PublishData";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import {
  savePublishSettings,
  logoutParentSvc
} from "../services/parentservices.js";
import PageLevelAlert from "./PageLevelAlert.vue";
export default {
  data() {
    return {
      Messages: messages,
      publishData: publishData,
      Options: Options
    };
  },
  methods: {
    logout() {
      logoutParentSvc({ userObj: this.userDetails });
      this.$store.dispatch("logout").then(() => {
        this.$router.push({
          name: "login"
        });
      });
    },
    jobListing() {
      this.$router.push({
        name: "jobLanding"
      });
    },
    inputDataListing() {
      this.$router.push({
        name: "jobInputFileUpload"
      });
    },
    globalDictionaryListing() {
      this.$router.push({
        name: "globalFileUpload"
      });
    },
    TrainingDictionaryListing() {
      this.$router.push({
        name: "trainingFileUpload"
      });
    },
    toggleNav(event) {
      toggleNav(event.target, document);
    },
    toggleLogout(event) {
      toggleLogout(event.target, document);
    },
    navigateToAdminSettings(event) {
      this.$router.push({
        name: "save-publish-settings"
      });
    }
  },
  components: {
    Multiselect,
    "page-alert": PageLevelAlert
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  }
};
</script>
<style scoped>
.concordia-nexus-logo {
  height: 32px;
  width: 160px;
}
</style>

