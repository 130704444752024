<template>
  <div class="fr-warapper">
    <div>
      <ams-navbar />
    </div>
    <div>
      <page-alert />
      <b-card
        pills
        card
        vertical
        nav-wrapper-class="w-45"
      >
        <b-tabs
          pills
          card
          vertical
        >
          <b-tab title="Schedule">
            <div>
              <multiselect
                v-model="scheduleConnectionType"
                placeholder="Connection Type"
                track-by="value"
                label="value"
                :options="scheduleConnectionTypeOptions"
                :close-on-select="true"
                :allow-empty="false"
                autocomplete="off"
              ></multiselect>
              <template v-if="scheduleConnectionType.value === sftp">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="host"
                  type="text"
                  v-model="scheduleConnectionIP"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="username"
                  type="text"
                  v-model="scheduleConnectionUsername"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="password"
                  type="password"
                  v-model="scheduleConnectionPassword"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Folder Path"
                  type="text"
                  v-model="scheduleConnectionFolderPath"
                  autocomplete="off"
                />
              </template>
              <template v-if="scheduleConnectionType.value === azureBlob">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Account Name"
                  type="text"
                  v-model="azureStorageAccountName"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Storage Key"
                  type="text"
                  v-model="azureStorageAccountKeyAccess"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Container Name"
                  type="text"
                  v-model="azureStorageContainerName"
                  autocomplete="off"
                />
              </template>
              <template v-if="scheduleConnectionType.value === azureDataLake">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Tenant Id"
                  type="text"
                  v-model="azureTenantId"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Client Id"
                  type="text"
                  v-model="azureClientId"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Client Secret"
                  type="text"
                  v-model="azureClientSecret"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Resource"
                  type="text"
                  v-model="azureResource"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Account Name"
                  type="text"
                  v-model="azureAccountName"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Folder Path"
                  type="text"
                  v-model="azureFolderPath"
                  autocomplete="off"
                />
              </template>
              <button
                style="margin: 10px 0px;"
                type="button"
                class="btn btn-primary"
                @click="saveScheduleSettings()"
              >
                <i class="icon14 icon-save"></i> Save
              </button>
            </div>
            <div class="expo-impo-table-wrapper d-flex align-middle mt-5 mb-2">
              <b-table
                class="table data-import-export-table"
                show-empty
                :items="allScheduleSettings"
                :fields="scheduleFields"
              >
                <template v-slot:cell(connectionType)="row">{{row.item.connectionType}}</template>
                <template
                  v-slot:cell(folder)="row"
                  :v-if="row.item.connectionType == sftp || row.item.connectionType == azureDataLake"
                >{{row.item.connectionType == sftp ? row.item.sftpFolderPath : row.item.azureFolderPath}}</template>
                <template v-slot:cell(actions)="">
                  <!-- <ul class="action-list">
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-tooltip.hover
                        data-placement="top"
                        title="Edit"
                        @click="fillPublishDataInForm(row.item)"
                      >
                        <i class="icon16 icon-setting"></i>
                      </a>
                    </li>
                  </ul> -->
                </template>
              </b-table>
            </div>
          </b-tab>
          <b-tab title="Input Reading Settings">
          <div>
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Settings Name"
                type="text"
                v-model="inputSettingsName"
              />
              <multiselect
                  v-model="inputConnectionType"
                  tag-placeholder="Add this as new tag"
                  placeholder="DB Vendor"
                  track-by="value"
                  label="value"
                  :options="Options.dbVendors"
                  :taggable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                ></multiselect>
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Server Host"
                type="text"
                v-model="inputHost"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Port"
                type="text"
                v-model="inputPort"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Snowflake Schema"
                type="text"
                v-model="inputSchema"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Database"
                type="text"
                v-model="inputDatabase"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Snowflake Warehouse"
                type="text"
                v-model="inputWarehouse"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Snowflake Role"
                type="text"
                v-model="inputRole"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Username"
                type="text"
                v-model="inputUser"
              />
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Password"
                type="password"
                v-model="inputPassword"
              />
              
              <button
                type="button"
                class="btn btn-primary"
                @click="saveInputSettings()"
              >
                <i class="icon14 icon-save"></i> Save
              </button>
            </div>
            <div class="expo-impo-table-wrapper d-flex align-middle mt-5 mb-2">
              <b-table
                class="table data-import-export-table"
                show-empty
                :items="allInputSettings"
                :fields="inputSettingsFields"
              >
                <template v-slot:cell(settingsName)="row">{{row.value}}</template>
                <template v-slot:cell(IP)="row">{{row.value}}</template>
                <template v-slot:cell(database)="row">{{row.value}}</template>
              </b-table>
            </div>
          </b-tab>
          <b-tab
            title="Publish Settings"
            class="mt-3"
          >
            <span>
              <strong>{{Messages.showIntermediateMessage}}</strong>
            </span>
            <div>
              <b-alert
                :show="Messages.showFailureAlertOnPublishSettingModel &&  publishData.selectedPublishSettings.text !== 'Azure BLOB'"
                variant="danger"
              >
                <strong>Failed to establish connection</strong>
              </b-alert>
              <b-alert
                :show="Messages.showFailureAlertOnPublishSettingModel &&  publishData.selectedPublishSettings.text === 'Azure BLOB'"
                variant="danger"
              >
                <strong>No Azure container found with the provided configuration</strong>
              </b-alert>
              <b-alert
                :show="Messages.showSuccessAlertOnPublishSettingModel"
                variant="success"
              >
                <strong>Success</strong>
              </b-alert>
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="Settings Name"
                type="text"
                v-model="publishData.publishSettingName"
                autocomplete="off"
                :readonly="readonly"
              />
              <multiselect
                v-model="publishData.selectedPublishSettings"
                tag-placeholder="Add this as new tag"
                placeholder="Publish Location"
                track-by="value"
                label="value"
                :options="Options.publishSettings"
                :taggable="true"
                :close-on-select="true"
                :allow-empty="false"
                @select="onChange"
              ></multiselect>
              <div v-if="publishData.selectedPublishSettings.text==='RDBMS'">
                <multiselect
                  v-model="publishData.selectedDBVendor"
                  tag-placeholder="Add this as new tag"
                  placeholder="DB Vendor"
                  track-by="value"
                  label="value"
                  :options="Options.dbVendors"
                  :taggable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                ></multiselect>
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Schema Name"
                  type="text"
                  v-model="publishData.schemaName"
                  autocomplete="off"
                />
              </div>
              <div v-if="publishData.selectedPublishSettings.text === 'RDBMS' || publishData.selectedPublishSettings.text === 'SFTP' || publishData.selectedPublishSettings.text === 'AWS S3'">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="IPv4 of the machine"
                  type="text"
                  v-model="publishData.publishIP"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Port No : "
                  type="text"
                  v-model="publishData.publishPortNo"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Username"
                  type="text"
                  v-model="publishData.publishUsername"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Password"
                  type="password"
                  v-model="publishData.publishPassword"
                  autocomplete="off"
                />
              </div>
              <div v-if="publishData.selectedPublishSettings.text !=='RDBMS' &&
               publishData.selectedPublishSettings.text !== '' && publishData.selectedPublishSettings.text !=='Azure Data Lake' && publishData.selectedPublishSettings.text !=='Sharepoint'
                && publishData.selectedPublishSettings.text !=='Snowflake'">
                <multiselect
                  v-model="publishData.publishFileType"
                  tag-placeholder="Add this as new tag"
                  placeholder="FileType"
                  track-by="value"
                  label="value"
                  :options="Options.fileTypes"
                  :taggable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                ></multiselect>
                <input
                  v-if="publishData.selectedPublishSettings.text !=='Azure BLOB'"
                  class="form-control search-control awesomplete mt-2"
                  placeholder="File Path"
                  type="text"
                  v-model="publishData.publishFilePath"
                  autocomplete="off"
                />
                <multiselect
                  v-model="publishData.publishTimeStamp"
                  tag-placeholder="Add this as new tag"
                  placeholder="format for timestamp"
                  track-by="value"
                  label="value"
                  :options="Options.timeStamp"
                  :taggable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div v-if="publishData.selectedPublishSettings.text ==='Azure BLOB'">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Account Name"
                  type="text"
                  v-model="publishData.azureStorageAccountName"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Storage Key"
                  type="text"
                  v-model="publishData.azureStorageAccountKeyAccess"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Container Name"
                  type="text"
                  v-model="publishData.azureStorageContainerName"
                  autocomplete="off"
                />
              </div>
              <div v-if="publishData.selectedPublishSettings.text==='Azure Data Lake'">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Tenant Id"
                  type="text"
                  v-model="publishData.azureTenantId"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Client Id"
                  type="text"
                  v-model="publishData.azureClientId"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Client Secret"
                  type="text"
                  v-model="publishData.azureClientSecret"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Resource"
                  type="text"
                  v-model="publishData.azureResource"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Account Name"
                  type="text"
                  v-model="publishData.azureAccountName"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Azure Folder Path"
                  type="text"
                  v-model="publishData.azureFolderPath"
                  autocomplete="off"
                />
                <!-- <multiselect
                  v-model="publishData.selectedDBVendor"
                  tag-placeholder="Add this as new tag"
                  placeholder="DB Vendor"
                  track-by="value"
                  label="value"
                  :options="Options.dbVendors"
                  :taggable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                ></multiselect>
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Schema Name"
                  type="text"
                  v-model="publishData.schemaName"
                  autocomplete="off"
                /> -->
              </div>
              <div v-if="publishData.selectedPublishSettings.text==='Sharepoint'">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Site Url "
                  type="text"
                  v-model="publishData.siteUrl"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Username"
                  type="text"
                  v-model="publishData.sharePointUsername"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Password"
                  type="password"
                  v-model="publishData.sharePointPassword"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="SharePoint Folder Path"
                  type="text"
                  v-model="publishData.sharePointFolderPath"
                  autocomplete="off"
                />
              </div>
              <div v-if="publishData.selectedPublishSettings.text==='Snowflake'">
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Snowflake Account Name"
                  type="text"
                  v-model="publishData.snowflakeAccountName"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Platform"
                  type="text"
                  v-model="publishData.snowflakePlatform"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Schema"
                  type="text"
                  v-model="publishData.snowflakeSchema"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Database"
                  type="text"
                  v-model="publishData.snowflakeDb"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Warehouse"
                  type="text"
                  v-model="publishData.snowflakeWarehouse"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Role"
                  type="text"
                  v-model="publishData.snowflakeRole"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Username"
                  type="text"
                  v-model="publishData.snowflakeUsername"
                  autocomplete="off"
                />
                <input
                  class="form-control search-control awesomplete mt-2"
                  placeholder="Password"
                  type="password"
                  v-model="publishData.snowflakePassword"
                  autocomplete="off"
                />
              </div>
              <button
                type="button"
                class="btn btn-primary mt-2"
                @click="savePublishSettingsFn()"
              >
                <i class="icon14 icon-save"></i> Done
              </button>
            </div>
            <div class="expo-impo-table-wrapper d-flex align-middle mt-5 mb-2">
              <b-table
                class="table data-import-export-table"
                show-empty
                :items="allPublishSettings"
                :fields="fields"
              >
                <template v-slot:cell(settingsName)="row">{{row.value}}</template>
                <template v-slot:cell(ConnType)="row">{{row.value}}</template>
                <template v-slot:cell(username)="row">{{row.value}}</template>
                <template v-slot:cell(actions)="row">
                  <ul class="action-list">
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-tooltip.hover
                        data-placement="top"
                        title="Edit"
                        @click="fillPublishDataInForm(row.item)"
                      >
                        <i class="icon16 icon-setting"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import navBar from "../components/Navbar.vue";
import Multiselect from "vue-multiselect";
import * as parentservices from "../services/parentservices.js";
import { publishData, Options, messages } from "../assets/js/PublishData";
import { mapGetters } from "vuex";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import PageAlert from "../components/PageLevelAlert.vue";
import { SFTP, AZURE_BLOB, AZURE_DATA_LAKE } from "./../assets/js/constant";
export default {
  name: "globalSettings",
  components: {
    "ams-navbar": navBar,
    Multiselect,
    "page-alert": PageAlert
  },
  data() {
    return {
      fields: [
        {
          key: "settingsName",
          label: "Name"
        },
        {
          key: "connType",
          label: "Connection Type",
          sortable: true
        },
        {
          key: "username",
          label: "Username"
        },
        { key: "actions", label: "Actions" }
      ],
      inputSettingsFields: [
        {
          key: "settingsName",
          label: "Settings Name"
        },
        {
          key: "IP",
          label: "Server Details"
        },
        {
          key: "database",
          label: "Database"
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      scheduleConnectionType: "",
      scheduleConnectionIP: "",
      scheduleConnectionUsername: "",
      scheduleConnectionPassword: "",
      scheduleConnectionFolderPath: "",
      scheduleConnectionTypeOptions: [
        { text: "SFTP", value: "SFTP" },
        { text: "AWS", value: "AWS" },
        { text: "Azure Blob", value: "Azure Blob" },
        { text: "Azure Data Lake", value: "Azure Data Lake" }
      ],
      inputSettingsName: "",
      inputConnectionType: "",
      inputUser: "",
      inputHost: "",
      inputDatabase: "",
      inputPassword: "",
      inputPort: "",
      inputSchema: "",
      inputWarehouse: "",
      inputRole: "",
      Messages: messages,
      publishData: publishData,
      Options: Options,
      allPublishSettings: [],
      readonly: false,
      sftp: SFTP,
      azureBlob: AZURE_BLOB,
      azureStorageAccountName: "",
      azureStorageAccountKeyAccess: "",
      azureStorageContainerName: "",
      azureDataLake: AZURE_DATA_LAKE,
      azureTenantId: "",
      azureClientId: "",
      azureClientSecret: "",
      azureResource: "",
      azureAccountName: "",
      azureFolderPath: "",
      allScheduleSettings: [],
      allInputSettings: [],
      scheduleFields: [
        {
          key: "connectionType",
          label: "Connection Type",
          sortable: true
        },
        {
          key: "folder",
          label: "Folder Path"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  mounted() {
    this.fetchAllSavedPublishSettings();
    this.fetchAllSavedScheduleSettings();
    this.fetchAllSavedInputSettings();
  },
  methods: {
    onChange() {
      publishData.schemaName = "";
      publishData.publishIP = "";
      publishData.publishUsername = "";
      publishData.publishPassword = "";
      publishData.publishPortNo = "";
      publishData.publishFileType = "";
      publishData.publishFilePath = "";
      publishData.publishTimeStamp = "";
      publishData.selectedDBVendor = "";
      publishData.azureStorageAccountName = "";
      publishData.azureStorageAccountKeyAccess = "";
      publishData.azureStorageContainerName = "";
      publishData.selectedPublishSettings = { text: "", value: "" };
      publishData.azureTenantId = "";
      publishData.azureClientId = "";
      publishData.azureClientSecret = "";
      publishData.azureResource = "";
      publishData.azureAccountName = "";
      publishData.azureFolderPath = "";
      publishData.siteUrl = "";
      publishData.sharePointUsername = "";
      publishData.sharePointPassword = "";
      publishData.sharePointFolderPath = "";
      publishData.snowflakeAccountName = "";
      publishData.snowflakeSchema = "";
      publishData.snowflakeDb = "";
      publishData.snowflakeWarehouse = "";
      publishData.snowflakeRole = "";
      publishData.snowflakePlatform = "";
      publishData.snowflakeUsername = "";
      publishData.snowflakePassword = "";
    },
    saveScheduleSettings() {
      if (this.scheduleConnectionType && this.scheduleConnectionType.text) {
        let paramObj = {};
        if (this.scheduleConnectionType.text === this.sftp) {
          if (
            !this.scheduleConnectionIP &&
            !this.username &&
            !this.password &&
            !this.sftpFolderPath
          ) {
            errorEventNotify("All fields are mandatory", 1);
            return;
          } else {
            paramObj = {
              connectionType: this.scheduleConnectionType.text,
              IP:
                this.scheduleConnectionIP === "localhost"
                  ? "127.0.0.1"
                  : this.scheduleConnectionIP,
              username: this.scheduleConnectionUsername,
              password: this.scheduleConnectionPassword,
              userId: this.userDetails._id,
              sftpFolderPath: this.scheduleConnectionFolderPath
            };
          }
        } else if (this.scheduleConnectionType.text === this.azureBlob) {
          if (
            !this.azureStorageAccountName &&
            !this.azureStorageAccountKeyAccess &&
            !this.azureStorageContainerName
          ) {
            errorEventNotify("All fields are mandatory", 1);
            return;
          } else {
            paramObj = {
              userId: this.userDetails._id,
              connectionType: this.scheduleConnectionType.text,
              azureStorageAccountName: this.azureStorageAccountName,
              azureStorageAccountKeyAccess: this.azureStorageAccountKeyAccess,
              azureStorageContainerName: this.azureStorageContainerName
            };
          }
        } else {
          if (
            !this.azureTenantId &&
            !this.azureClientId &&
            !this.azureClientSecret &&
            !this.azureResource &&
            !this.azureAccountName &&
            !this.azureFolderPath
          ) {
            errorEventNotify("All fields are mandatory", 1);
            return;
          } else {
            paramObj = {
              userId: this.userDetails._id,
              connectionType: this.scheduleConnectionType.text,
              azureTenantId: this.azureTenantId,
              azureClientId: this.azureClientId,
              azureClientSecret: this.azureClientSecret,
              azureResource: this.azureResource,
              azureAccountName: this.azureAccountName,
              azureFolderPath: this.azureFolderPath
            };
          }
        }
        parentservices.saveScheduleSettings(paramObj).then(response => {
          if (response._isError) {
            errorEventNotify(response._message, 1);
          } else {
            successEventNotify("Configuration saved", 1);
            this.scheduleConnectionType = "";
            this.scheduleConnectionIP = "";
            this.scheduleConnectionUsername = "";
            this.scheduleConnectionPassword = "";
            this.scheduleConnectionFolderPath = "";
            this.azureStorageAccountName = "";
            this.azureStorageAccountKeyAccess = "";
            this.azureStorageContainerName = "";
            this.azureTenantId = "";
            this.azureClientId = "";
            this.azureClientSecret = "";
            this.azureResource = "";
            this.azureAccountName = "";
            this.azureFolderPath = "";
          }
        });
      } else {
        errorEventNotify("Please configure the settings", 1);
      }
    },
    saveInputSettings() {
      let paramObj = {
        settingsName: this.inputSettingsName,
        connectionType: this.inputConnectionType.value,
        IP: this.inputHost === "localhost" ? "127.0.0.1" : this.inputHost,
        username: this.inputUser,
        password: this.inputPassword,
        database: this.inputDatabase,
        port: this.inputPort,
        schema: this.inputSchema,
        warehouse: this.inputWarehouse,
        role: this.inputRole,
        userId: this.userDetails._id
      };
      parentservices.saveInputSettings(paramObj).then(response => {
        this.inputSettingsName = "";
        this.inputConnectionType = "";
        this.inputHost = "";
        this.inputUser = "";
        this.inputPassword = "";
        this.inputDatabase = "";
        this.inputPort = "";
        this.inputSchema = "";
        this.inputWarehouse = "";
        this.inputRole = "";
      });
    },
    savePublishSettingsFn() {
      if (!this.readonly) {
        for (let i = 0; i < this.allPublishSettings.length; i++) {
          if (
            this.allPublishSettings[i].settingsName ===
            publishData.publishSettingName
          ) {
            errorEventNotify("Dupicate Name Error", 1);
            return;
          }
          if ("" === publishData.publishSettingName) {
            errorEventNotify("Name cannot be blank", 1);

            return;
          }
        }
      } else {
        // console.log("Is updating");
      }
      this.Messages.showIntermediateMessage =
        "Trying to establish connection and test";
      let queryParam = {
        settingName: publishData.publishSettingName,
        connType: publishData.selectedPublishSettings.text,
        dbName:
          publishData.selectedPublishSettings.text === "RDBMS"
            ? publishData.selectedDBVendor.text
            : "",
        serverIP:
          publishData.publishIP === "localhost"
            ? "127.0.0.1"
            : publishData.publishIP,
        portNo: publishData.publishPortNo,
        schemaName:
          publishData.selectedPublishSettings.text === "RDBMS"
            ? publishData.schemaName
            : "",
        username: publishData.publishUsername,
        password: publishData.publishPassword,
        fileType:
          publishData.selectedPublishSettings.text !== "RDBMS" &&
          publishData.selectedPublishSettings.text !== "Azure Data Lake" &&
          publishData.selectedPublishSettings.text !== "Sharepoint" &&
          publishData.selectedPublishSettings.text !== "Snowflake"
            ? publishData.publishFileType.text
            : "",
        filePath:
          publishData.selectedPublishSettings.text !== "RDBMS" &&
          publishData.selectedPublishSettings.text !== "Azure Data Lake" &&
          publishData.selectedPublishSettings.text !== "Sharepoint" &&
          publishData.selectedPublishSettings.text !== "Snowflake"
            ? publishData.publishFilePath
            : "",
        timeStampFormat:
          publishData.selectedPublishSettings.text !== "RDBMS" &&
          publishData.selectedPublishSettings.text !== "Azure Data Lake" &&
          publishData.selectedPublishSettings.text !== "Sharepoint" &&
          publishData.selectedPublishSettings.text !== "Snowflake"
            ? publishData.publishTimeStamp.text
            : "",
        azureStorageAccountName:
          publishData.selectedPublishSettings.text === "Azure BLOB"
            ? publishData.azureStorageAccountName
            : "",
        azureStorageAccountKeyAccess:
          publishData.selectedPublishSettings.text === "Azure BLOB"
            ? publishData.azureStorageAccountKeyAccess
            : "",
        azureStorageContainerName:
          publishData.selectedPublishSettings.text === "Azure BLOB"
            ? publishData.azureStorageContainerName
            : "",
        azureTenantId:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureTenantId
            : "",
        azureClientId:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureClientId
            : "",
        azureClientSecret:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureClientSecret
            : "",
        azureResource:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureResource
            : "",
        azureAccountName:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureAccountName
            : "",
        azureFolderPath:
          publishData.selectedPublishSettings.text === "Azure Data Lake"
            ? publishData.azureFolderPath
            : "",
        siteUrl:
          publishData.selectedPublishSettings.text === "Sharepoint"
            ? publishData.siteUrl
            : "",
        sharePointUsername:
          publishData.selectedPublishSettings.text === "Sharepoint"
            ? publishData.sharePointUsername
            : "",
        sharePointPassword:
          publishData.selectedPublishSettings.text === "Sharepoint"
            ? publishData.sharePointPassword
            : "",
        sharePointFolderPath:
          publishData.selectedPublishSettings.text === "Sharepoint"
            ? publishData.sharePointFolderPath
            : "",
        snowflakeAccountName: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeAccountName : "",
        snowflakePlatform: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakePlatform : "",
        snowflakeSchema: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeSchema : "",
        snowflakeDb: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeDb : "",
        snowflakeWarehouse: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeWarehouse : "",
        snowflakeRole: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeRole : "",
        snowflakeUsername: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakeUsername : "",  
        snowflakePassword: 
          publishData.selectedPublishSettings.text === 'Snowflake' 
            ? publishData.snowflakePassword : "",                                                           
        fileOrDbName: "",
        userId: this.userDetails._id
      };
      parentservices.savePublishSettings(queryParam).then(response => {
        this.fetchAllSavedPublishSettings();
        if (response._isError === true) {
          this.Messages.showIntermediateMessage = "Publish Configuration";
          this.Messages.showFailureAlertOnPublishSettingModel = true;
          setTimeout(() => {
            this.Messages.showFailureAlertOnPublishSettingModel = false;
          }, 5000);
        }
        if (response._isError === false) {
          this.Messages.showIntermediateMessage = "Publish Configuration";
          this.Messages.showSuccessAlertOnPublishSettingModel = true;
          setTimeout(() => {
            this.Messages.showSuccessAlertOnPublishSettingModel = false;
            publishData.publishSettingName = "";
            this.onChange();
          }, 2000);
        }
      });
    },
    fetchAllSavedPublishSettings() {
      parentservices.fetchAllPublishSettings().then(response => {
        if (response._isError === true) {
          console.log("Internal server error in fetching publish settings");
        } else {
          this.allPublishSettings = response._result;
        }
      });
    },
    fillPublishDataInForm(clickedSetting) {
      this.readonly = true;
      publishData.publishSettingName = clickedSetting.settingsName;
      publishData.schemaName = clickedSetting.schemaName;
      publishData.publishIP = clickedSetting.serverIP;
      publishData.publishUsername = clickedSetting.username;
      publishData.publishPassword = "";
      publishData.publishPortNo = clickedSetting.portNo;
      publishData.publishFileType = clickedSetting.fileType;
      publishData.publishFilePath = clickedSetting.filePath;
      publishData.publishTimeStamp = clickedSetting.timeStampFormat;
      publishData.azureStorageAccountName =
        clickedSetting.azureStorageAccountName;
      publishData.azureStorageContainerName =
        clickedSetting.azureStorageContainerName;
      publishData.selectedDBVendor = {
        text: clickedSetting.dbName,
        value: clickedSetting.dbName
      };
      publishData.selectedPublishSettings = {
        text: clickedSetting.connType,
        value: clickedSetting.connType
      };
      publishData.snowflakeAccountName = clickedSetting.snowflakeAccountName;
      publishData.snowflakePlatform = clickedSetting.snowflakePlatform;
      publishData.snowflakeSchema = clickedSetting.snowflakeSchema;
      publishData.snowflakeDb = clickedSetting.snowflakeDb;
      publishData.snowflakeWarehouse = clickedSetting.snowflakeWarehouse;
      publishData.snowflakeRole = clickedSetting.snowflakeRole;
      publishData.snowflakeUsername = clickedSetting.snowflakeUsername;
      publishData.snowflakePassword = "";
    },
    fetchAllSavedScheduleSettings() {
      parentservices.fetchAllScheduleSettings().then(response => {
        if (response._isError === true) {
          console.log("Internal server error in fetching schedule settings");
        } else {
          this.allScheduleSettings = response._result;
        }
      });
    },
    fetchAllSavedInputSettings() {
      parentservices.fetchAllInputSettings().then(response => {
          if (response._isError === true) {
          console.log("Internal server error in fetching input settings");
          } else {
            this.allInputSettings = response._result;
            console.log("inputs:",this.allInputSettings);
          }
        });
    },
    fillInputSettingsInForm(clickedSetting) {
      this.readonly = true;
      inputSettingsName = clickedSetting.inputSettingsName;

    }
  }
};
</script>

<style scoped>
</style>