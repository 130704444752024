<template>
  <!--Fractal Wrapper-->
  <div class="fr-warapper">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <main>
      <div class="fr-content">
        <!--Main Content-->
        <div class="main-content">
          <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
              <!--Section Heading-->
              <div class="heading d-inline-block">
                <h3 class="mb-0">
                  <span v-if="jobParam">Job Name:
                    {{jobParam.jobNameToDisplay}}
                  </span>
                </h3>
              </div>
              <!--Action Button-->
              <div>
                <!-- <button class="btn btn-primary">
                <i class="icon16 icon-save"></i>Save</button>-->
                <!-- <button class="btn btn-outline-secondary">
                  <i class="icon16 icon-summary"></i>Synopsis
                </button>-->
                <!-- <button class="btn btn-outline-secondary">
                <i class="icon16 icon-submit"></i>Submit</button>-->
                <button
                  class="btn btn-outline-secondary"
                  @click="navigateBackToJobSetup($event)"
                >
                  <i class="icon14 icon-back"></i>
                  Back
                </button>
              </div>
            </div>
          </div>

          <!--Table section-->
          <div class="container-fluid p-0">
            <!--Table Configuration Section -->
            <div class="table-config-section d-flex justify-content-start">
              <!--Table Left Action List (Steps)-->
              <left-panel
                v-on:stepStatusUpdate="stepStatusUpdateFn"
                :jobParent="jobParam"
              > </left-panel>
              <div class="review-table">
                <!--Data Accuracy Cart-->
                <div class="cart-wrapper">
                  <div class="card-head d-flex justify-content-between align-items-center">
                    <div class="info-section">
                      <p
                        v-if="columnDetailsParam"
                        v-b-tooltip
                        title="Column selected for review"
                      >{{columnDetailsParam.value}}</p>
                      <span v-if="columnDetailsData">Unique Records: {{columnDetailsData.length}}</span>
                      <span>Total Records: {{initialTotalCount}}</span>
                    </div>
                    <ul class="review-action-items">
                      <li>
                        <a href="javascript:void(0);">
                          <i
                            class="icon16 icon-reload"
                            @click="refreshMenu()"
                          ></i>
                        </a>
                      </li>
                      <li class="data-menu">
                        <a href="javascript:void(0);">
                          <i
                            class="icon16 icon-sort1"
                            @click="dataMenu($event)"
                          ></i>
                        </a>
                        <ul>
                          <li>
                            <a
                              @click="sortColumn($event, {text: 'name'})"
                              href="javascript:void(0);"
                            >By Name</a>
                          </li>
                          <li>
                            <a
                              @click="sortColumn($event, {text: 'count'})"
                              href="javascript:void(0);"
                            >By Count</a>
                          </li>
                          <li>
                            <a v-if="isBusiness"
                              @click="sortColumn($event, {text: 'business'})"
                              href="javascript:void(0);"
                            >By Business</a>
                          </li>
                        </ul>
                      </li>
                      <li class="data-menu">
                        <a href="javascript:void(0);">
                          <i
                            class="icon16 icon-dots"
                            @click="dataMenu($event)"
                          ></i>
                        </a>
                        <ul>
                          <!-- <li><a href="javascript:void(0);">Show All</a></li>
                          <li><a href="javascript:void(0);">Show Validate</a></li>
                          <li><a href="javascript:void(0);">Show Unvalidate</a></li>-->
                          <li>
                            <a
                              href="javascript:void(0);"
                              class="select-multiple"
                              @click="hideMultiSelect()"
                              title="isMultiple?'':''"
                            ><span v-if="!isMultiple">Select Multiple</span><span v-if="isMultiple">Select Individual</span></a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div style="padding:10px">
                    <ul
                      :class="{'data-list':true,'multiple-data-list' : isMultiple}"
                      data-scrollbar
                    >
                      <li
                        v-for="row in columnDetailsData"
                        :key="row._id"
                        @click="isMultiple? null: applyConditionalFilterFn(row)"
                        v-bind:style="currentlyClicked === row.name ? 'color:#0090c4;':''"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div v-if="isMultiple">
                          <input
                            type="checkbox"
                            v-model="isSelected"
                            :value="row.name"
                          >
                        </div>
                        <p
                          v-b-tooltip
                          :title="getCountTitle(row.name)"
                          style="text-transform: capitalize;cursor:pointer"
                        >{{row.name}}</p>
                        <a v-if="isBusiness"
                          v-b-tooltip
                          href="javascript:void(0);"
                          class="progrss-bar"
                          :title="getBusinessTitle(row.business) + getCountTitle(row.count)"
                        >
                          <span :style="getCountStyle(row.count)"></span>
                          <p>{{row.business}}% | {{row.count}}</p>
                        </a>
                        <a v-else
                          v-b-tooltip
                          href="javascript:void(0);"
                          class="progrss-bar"
                          :title="getCountTitle(row.count)"
                        >
                          <span :style="getCountStyle(row.count)"></span>
                          <p>{{row.count}}</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="isMultiple"
                  class="review-footer"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <button
                      class="btn btn-primary btn-link"
                      @click="applyMultipleConditionalFilterFn()"
                    >
                      <i class="icon14 icon-save"></i>Apply
                    </button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="isSelected = [];"
                    >
                      <i class="icon14 icon-close"></i>Clear
                    </button>
                  </div>
                </div>
                <!--Configuration Table-->
                <grid-comp
                  v-if="jobParam"
                  :jobParent="jobParam"
                ></grid-comp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gridStore from "../store.js";
import {
  fetchGridData,
  fetchJobDetailsById,
  fetchJobDetails
} from "./../services/parentservices.js";
import { mapGetters } from "vuex";
import { convertMapToJsonString } from "./../utilbin/common-utils.js";
import gridJobValidate from "../components/Grid.vue";
import navBar from "../components/Navbar.vue";
import LeftPanel from "../components/LeftPanel.vue";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";

export default {
  name: "jobValidation",
  data() {
    return {
      jobParam: null,
      columnDetailsData: null,
      isBusiness: false,
      columnDetailsDataHeaders: [
        {
          text: "name",
          sortOrder: "asc"
        },
        {
          text: "count",
          sortOrder: "asc"
        }
      ],
      columnDetailsParam: null,
      currentSort: "count",
      currentSortOrder: "asc",
      searchText: "",
      initialTotalCount: 0,
      refreshData: [],
      isMultiple: false,
      isSelected: [],
      currentlyClicked: ""
    };
  },
  props: {},
  watch: {},
  components: {
    "grid-comp": gridJobValidate,
    "ams-navbar": navBar,
    LeftPanel
  },
  updated() {},
  created() {
    if (this.$route.params.job && this.$route.params.columnparam) {
      if (
        !(
          Object.keys(this.$route.params.columnparam).length === 0 &&
          this.$route.params.columnparam.constructor === Object
        )
      ) {
        this.columnDetailsParam = this.$route.params.columnparam;
        this.jobParam = this.$route.params.job;
        // this.fetchAll();
        this.refreshMenu();
      }
    } else {
      this.fetchJobDetailsByParam();
    }
  },
  mounted() {
    const loader = document.querySelector(".loading-gif");
    const temp = document.querySelector(".fr-warapper");

    if (loader && temp) {
      temp.classList.add("temp");
      loader.classList.add("mystyle");
    }

    this.sortColumn([], { text: "count" });
    // this.applyConditionalFilterFn(this.columnDetailsData[0]);

    /*Toggle Steps Menu*/
    const steps = document.querySelectorAll(".toggle-steps");
    for (let i = 0; i < steps.length; i++) {
      steps[i].addEventListener("click", toggleSteps);
    }

    function toggleSteps() {
      let el = document.querySelector(".table-action-list");
      el.classList.toggle("expanded");
    }

    // Left menu toggele active
    const elMenu = document.querySelectorAll(".table-action-list > li > a");
    for (let i = 0; i < elMenu.length; i++) {
      elMenu[i].onclick = function(event) {
        if (this.parentElement.classList.contains("active")) {
          this.parentElement.classList.toggle("active");
        } else {
          var c = 0;
          while (c < elMenu.length) {
            elMenu[c++].parentElement.className = "";
          }
          event.stopPropagation();
          elMenu[i].parentElement.classList.toggle("active");
        }
      };
    }
  },
  computed: {
    ...mapGetters({
      gridDataTableFilterRows: "getStoreFilteredData",
      conditionalFiltersAppliedMap: "getStoreConditionalFiltersAppliedMap",
      userDetails: "getUserObj"
    })
  },
  methods: {
    hideMultiSelect() {
      this.isMultiple = !this.isMultiple;
      this.isSelected = [];
      document.querySelector("li.active").classList.remove("active");
    },
    fetchAll: function() {
      let element = "";

      for (
        let index = 0;
        index < this.gridDataTableFilterRows.length;
        index++
      ) {
        element = this.gridDataTableFilterRows[index];
        if (
          element[this.columnDetailsParam.value] != null &&
          element[this.columnDetailsParam.value] != "" &&
          typeof element[this.columnDetailsParam.value] === "string"
        ) {
          element[this.columnDetailsParam.value] = element[
            this.columnDetailsParam.value
          ].toLowerCase();
        }
        this.refreshData.push(element);
      }

      this.columnDetailsData = _(this.refreshData)
        .groupBy(this.columnDetailsParam.value)
        .map((items, name) => ({ name: name, count: items.length }))
        .value();

      this.columnDetailsData = _.orderBy(
        this.columnDetailsData,
        ["count"],
        ["desc"]
      );
      if (this.initialTotalCount === 0) {
        this.initialTotalCount = this.gridDataTableFilterRows.length;
      }
      this.applyConditionalFilterFn(this.columnDetailsData[0]);
    },
    refreshMenu() {
      let param = {
        jobId: this.jobParam._id,
        jobName: this.jobParam.jobName,
        currentSortOrder: this.currentSortOrder,
        column: this.columnDetailsParam.value,
        jobStatus: this.jobParam.status,
        userId: this.userDetails._id,
        collectionName: this.jobParam.inputFileName.displayFileName,
        conditionalFilter: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };

      fetchGridData(param)
        .then(response => {
          if (response._isError == false && response._result) {
            let gridData = response._result;
            this.isBusiness=gridData.isBusiness

            if(this.isBusiness){
              this.columnDetailsData = gridData.groupColumns.map(item => ({
                name: item._id,
                count: item.count,
                business: parseFloat(item.business)
              }));
            } else {
              this.columnDetailsData = gridData.groupColumns.map(item => ({
                name: item._id,
                count: item.count
              }));
            }

            if (this.initialTotalCount === 0) {
              this.initialTotalCount = this.gridDataTableFilterRows.length;
            }
            this.applyConditionalFilterFn(this.columnDetailsData[0], gridData.error);
          }
        })
        .catch(e => {})
        .finally(() => {});
    },
    sortColumn: function(event, param) {
      this.currentSort = param.text;
      this.currentSortOrder = this.currentSortOrder === "asc" ? "desc" : "asc";
      this.columnDetailsData = _.orderBy(
        this.columnDetailsData,
        [this.currentSort],
        [this.currentSortOrder]
      );
    },
    searchName: function(event) {
      //debounce(event, this.searchText);
      if (this.searchText === undefined || this.searchText === "") {
        this.fetchAll();
      } else if (this.searchText && this.searchText.length > 2) {
        this.fetchAll();

        this.columnDetailsData = _.filter(
          this.columnDetailsData,
          (row, index) => {
            return row.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          }
        );
      }
    },
    getCountStyle(count) {
      let percentage = (count / this.initialTotalCount) * 100;
      if (percentage > 100) {
        return "width: 100%;";
      } else {
        return "width: " + percentage + "%;";
      }
    },
    getCountTitle(count) {
      return count + " records";
    },
    getBusinessTitle(business) {
      return business + "% of busines | ";
    },
    navigateBackToJobSetup(event) {
      if (event) {
        this.conditionalFiltersAppliedMap.delete(this.columnDetailsParam.value);

        this.$router.push({
          name: "jobSetupEdit",
          params: {
            jobId: this.jobParam._id,
            status: this.jobParam.status,
            jobParent: this.jobParam
          }
        });
      }
    },
    applyConditionalFilterFn(rowObj,error=false) {
      //here first collecting the filter applied
      this.currentlyClicked = rowObj.name;
      let filterCollectionParam = {
        columnProps: this.columnDetailsParam,
        searchInput: rowObj.name,
        criteria: {
          text: "Equal To",
          value: {
            id: 1,
            text: "EQUALS"
          }
        }
      };

      //then calling the action to collect the filter
      gridStore
        .dispatch("collectFilterAfterConditionalAction", filterCollectionParam)
        .then(response => {
          //now that actual filter has been collected calling actuala actions
          const actionParam = {
            sentForReviewArr: this.jobParam.sentForReview,
            columnProps: this.columnDetailsParam,
            searchInput: rowObj.name,
            criteria: {
              text: "Equal To",
              value: {
                id: 1,
                text: "EQUALS"
              }
            },
            jobName: this.jobParam.jobName,
            userId: this.userDetails._id,
            collectionName: this.jobParam.inputFileName.displayFileName,
            jobStatus: this.jobParam.status,
            conditionalFiltersAppliedMap: convertMapToJsonString(
              this.conditionalFiltersAppliedMap
            )
          };
          switch (this.columnDetailsParam.datatype) {
            case "string":
              gridStore
                .dispatch("conditionalFilterColumnAction", actionParam)
                .then(response => {
                  if (!(response instanceof Error)) {
                    const loader = document.querySelector(".loading-gif");
                    const temp = document.querySelector(".fr-warapper");
                    loader.classList.remove("mystyle");
                    temp.classList.remove("temp");
                    if(error)
                      errorEventNotify("condition applied successfully without business percentage calculation as business column selected not numeric", 1);
                    else
                      successEventNotify("condition applied successfully", 1);
                  } else {
                    throw response;
                  }
                })
                .catch(e => {
                  const loader = document.querySelector(".loading-gif");
                  const temp = document.querySelector(".fr-warapper");
                  loader.classList.remove("mystyle");
                  temp.classList.remove("temp");
                  errorEventNotify(e, 1);
                });
              break;
            case "number":
              if (!isNaN(actionParam.searchInput.trim())) {
                gridStore
                  .dispatch("conditionalNumberFilterColumnAction", actionParam)
                  .then(response => {
                    if (!(response instanceof Error)) {
                      const loader = document.querySelector(".loading-gif");
                      const temp = document.querySelector(".fr-warapper");
                      loader.classList.remove("mystyle");
                      temp.classList.remove("temp");
                      if(error)
                        errorEventNotify("condition applied successfully without business percentage calculation as business column selected not numeric", 1);
                      else
                        successEventNotify("condition applied successfully", 1);
                    } else {
                      throw response;
                    }
                  })
                  .catch(e => {
                    const loader = document.querySelector(".loading-gif");
                    const temp = document.querySelector(".fr-warapper");
                    loader.classList.remove("mystyle");
                    temp.classList.remove("temp");
                    errorEventNotify(e, 1);
                  });
              } else {
                const loader = document.querySelector(".loading-gif");
                const temp = document.querySelector(".fr-warapper");
                loader.classList.remove("mystyle");
                temp.classList.remove("temp");
                errorEventNotify("Your input is not a number!", 1);
              }
              break;
            default:
              const loader = document.querySelector(".loading-gif");
              const temp = document.querySelector(".fr-warapper");
              loader.classList.remove("mystyle");
              temp.classList.remove("temp");
          }
        })
        .catch(e => {
          const loader = document.querySelector(".loading-gif");
          const temp = document.querySelector(".fr-warapper");
          loader.classList.remove("mystyle");
          temp.classList.remove("temp");
        })
        .finally(() => {});
    },
    dataMenu(event) {
      event.target.parentElement.parentElement.classList.toggle("active");
      const container = event.target.parentElement.parentElement.parentElement;
      const el = container.querySelectorAll(".data-menu > a > i");
      for (let i = 0; i < el.length; i++) {
        if (
          el[i] != event.target &&
          el[i].parentElement.parentElement.classList.contains("active")
        ) {
          el[i].parentElement.parentElement.classList.toggle("active");
        }
      }
      document.body.addEventListener(
        "click",
        function(e) {
          var target = e.target || e.srcElement;
          if (target !== container && !isChildOf(target, container)) {
            const el = container.querySelectorAll("li");
            for (let i = 0; i < el.length; i++) {
              if (el[i].classList.contains("active")) {
                el[i].classList.toggle("active");
              }
            }
          }
        },
        false
      );

      function isChildOf(child, parent) {
        if (child.parentNode === parent) {
          return true;
        } else if (child.parentNode === null) {
          return false;
        } else {
          return isChildOf(child.parentNode, parent);
        }
      }
    },
    applyMultipleConditionalFilterFn() {
      let filterCollectionParam = {
        columnProps: this.columnDetailsParam,
        searchInput: this.isSelected,
        criteria: {
          text: "Equal To",
          value: {
            id: 1,
            text: "EQUALS"
          }
        }
      };
      gridStore
        .dispatch("collectFilterAfterConditionalAction", filterCollectionParam)
        .then(response => {
          const actionParam = {
            columnProps: this.columnDetailsParam,
            searchInput: this.isSelected,
            criteria: {
              text: "Equal To",
              value: {
                id: 1,
                text: "EQUALS"
              }
            },
            jobName: this.jobParam.jobName,
            collectionName: this.jobParam.inputFileName.displayFileName,
            jobStatus: this.jobParam.status,
            userId: this.userDetails._id,
            conditionalFiltersAppliedMap: convertMapToJsonString(
              this.conditionalFiltersAppliedMap
            )
          };
          gridStore
            .dispatch("multipleConditionalFilterColumnAction", actionParam)
            .then(response => {
              if (!(response instanceof Error)) {
                successEventNotify("filter applied successfully", 1);
              } else {
                throw response;
              }
            })
            .catch(e => {
              errorEventNotify(e, 1);
            });
        })
        .catch(e => {});
    },
    fetchJobDetailsByParam() {
      let routeParam = this.$route.params;
      routeParam.userObj = this.userDetails;
      routeParam.userId = this.userDetails._id;
      routeParam.conditionalFiltersAppliedMap = convertMapToJsonString(
        this.conditionalFiltersAppliedMap
      );
      const conditionalFiltersAppliedMap = this.conditionalFiltersAppliedMap;

      if (routeParam.jobId) {
        fetchJobDetails(routeParam)
          .then(response => {
            // console.log("resp ---->", response);
            if (response._isError == false && response._result) {
              this.jobParam = response._result.jobObject;
              this.columnDetailsParam = response._result.columnHeaders.filter(
                item => {
                  return item._id === this.$route.params.columnparamId;
                }
              )[0];
              this.$store.dispatch("loadGridData", response._result);
              // this.fetchAll();
              this.refreshMenu();
            }
          })
          .catch(e => {
            errorEventNotify("Some problems fetching jobParent", 1);
          })
          .finally(() => {});
      }
    },
    stepStatusUpdateFn(jobParent) {
      console.log("jobparent --->", jobParent);
      this.jobParam = jobParent;
    }
  }
};
</script>
