<template>
  <div>
    <b-modal
      id="step-dependency-modal"
      ref="stepDependency"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <h5 class="modal-title">
          <i class="icon16 icon-plus-blue"></i> Confirmation to Delete Step
        </h5>
      </div>

      <div class="modal-body">
        <p>{{ msg }}</p>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="
            deleteStepFn(
              $event,
              currentStep,
              finalStepArray,
              dependentStepArray
            )
          "
        >
          <i class="icon14 icon-save"></i> Ok
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="cancelStepFn()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </b-modal>
    <ul class="table-action-list">
      <li>
        <a
          href="javascript:void(0);"
          class="list-item list-item-menu"
          v-b-tooltip.hover
          data-placement="top"
          title="Steps"
        >
          <i class="icon24 icon-time-tbl"></i>
          <span>Steps</span>
        </a>
        <div class="step-list">
          <div class="step-head d-flex justify-content-between align-items-center">
            <h3>Job steps</h3>
            <div class="d-flex">
              <a
                v-if="
                  !showSaveCancelFlag &&
                    this.stepsAction.length > 0 &&
                    condition('edit')
                "
                href="Javascript:void(0);"
                class="edit-steps"
                @click="showSaveCancelButton()"
              >Edit</a>
              <!-- <a
                v-if="showSaveCancelFlag"
                href="Javascript:void(0);"
                class="cancel-steps mr-2"
                @click="editModeOff()"
              >Cancel</a>-->
              <a
                v-if="showSaveCancelFlag"
                href="Javascript:void(0);"
                class="save-steps"
                @click="saveAddStepsFn()"
              >Done</a>
            </div>
          </div>
          <ul>
            <li
              v-for="(step, index) in this.stepsAction"
              :key="index"
            >
              <template v-if="step.actionName === actionName.update">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.columnName.value }}</span>
                <span>Update Data: {{ step.actionParam.newValue }}</span>
              </template>
              <template v-if="step.actionName === actionName.blank">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.newColumnName }}</span>
              </template>
              <template v-if="step.actionName === actionName.split">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.targetColumn.value }}</span>
                <span>Split By: {{ step.actionParam.delimeter }}</span>
              </template>
              <template v-if="step.actionName === actionName.merge">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.targetColumn.value }}</span>
                <span>Merge with Column name:
                  {{ step.actionParam.mergeWithColumn.value }}</span>
                <span>Merge Column name:
                  {{ step.actionParam.mergingColumnName }}</span>
              </template>
              <template v-if="step.actionName === actionName.delete">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column deleted: {{ step.actionParam.value }}</span>
              </template>
              <template v-if="step.actionName === actionName.copy">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.targetColumn.value }}</span>
                <span>Copy Column name: {{ step.actionParam.newColumnName }}</span>
              </template>
              <template v-if="step.actionName === actionName.insert">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name: {{ step.actionParam.columnName.value }}</span>
                <span>Insert Data: {{ step.actionParam.newValue }}</span>
              </template>
              <template v-if="step.actionName === actionName.globalReplace">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Column name:
                  {{ step.actionParam.selectedTextColumn.value }}</span>
                <span>Selected Text: {{ step.actionParam.selectedText }}</span>
                <span>Replaced Text: {{ step.actionParam.replacementText }}</span>
              </template>
              <template v-if="step.actionName === actionName.algoExecute">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>Algorithm name: {{ step.actionParam.algoName }}</span>
              </template>
              <template v-if="
                  step.actionName === actionName.removeDuplicates ||
                    step.actionName === actionName.bulkColumnDelete
                ">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span>
                  Selected Columns :
                  <span
                    v-for="(value, index) in step.actionParam.data"
                    :key="index"
                  >{{ value }}</span>
                </span>
              </template>
              <template v-if="step.actionName === actionName.arithmaticOperation">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
                <span v-if="step.actionParam.column2">Operation: {{ step.actionParam.column1 }}
                  {{ step.actionParam.selectedOperator }}
                  {{ step.actionParam.column2 }} =
                  {{ step.actionParam.column }}</span>
                <span v-if="step.actionParam.staticNumber">Operation: {{ step.actionParam.column1 }}
                  {{ step.actionParam.selectedOperator }}
                  {{ step.actionParam.staticNumber }} =
                  {{ step.actionParam.column }}</span>
              </template>
              <template v-if="step.actionName === actionName.processOutput">
                <span class="icon-dot">{{ index + 1 }}</span>
                <p>{{ step.actionName }}</p>
              </template>
              <span v-if="step.hasOwnProperty('conditionalFiltersAppliedMap')">
                <span v-if="step.conditionalFiltersAppliedMap.length > 0">
                  Filters applied:
                  <span
                    v-for="(value, index) in step.conditionalFiltersAppliedMap"
                    :key="index"
                  >{{ value[0] }} {{ value[1].conditionApplied.text }} -
                    {{ value[1].inputValue }}</span>
                </span>
              </span>
              <a
                v-if="!showSaveCancelFlag"
                :class="disableStepAddDeleteButton(index)"
                href="Javascript:void(0);"
                class="refresh-step"
                style="margin: -12px 0px;"
                @click="refreshStepFn(step)"
              >
                <i class="icon16 icon-reload"></i>
              </a>
              <a
                :id="'deleteDisabled' + index"
                :class="disableStepAddDeleteButton(index)"
                v-if="showSaveCancelFlag"
                href="Javascript:void(0);"
                class="delete-step"
                style="display: inline-block;margin: -11px -12px 0px 0px;"
                @click="removeSteps(step)"
              >
                <i class="icon16 icon-close"></i>
              </a>
              <a
                :id="'addDisabled' + index"
                :class="disableStepAddDeleteButton(index)"
                v-if="showSaveCancelFlag"
                href="Javascript:void(0);"
                class="add-step"
                style="display: inline-block;margin: -12px 3px 0px 0px"
                @click="addSteps(step)"
              >
                <i class="icon16 icon-save-gray"></i>
              </a>
              <!-- <template v-if="step.actionName === actionName.removeDuplicates">
                <span class="icon-dot">{{index + 1}}</span>
                <p>{{step.actionName}}</p>
                <span>
                  Selected Columns :
                  <span
                    v-for="(value, index) in step.actionParam.data"
                    :key="index"
                  >{{value}}</span>
                </span>
                <a
                  href="Javascript:void(0);"
                  class="delete-step"
                >
                  <i class="icon16 icon-close"></i>
                </a>
              </template>
              <template v-if="step.actionName === actionName.bulkColumnDelete">
                <span class="icon-dot">{{index + 1}}</span>
                <p>{{step.actionName}}</p>
                <span>
                  Selected Columns :
                  <span
                    v-for="(value, index) in step.actionParam.data"
                    :key="index"
                  >{{value}}</span>
                </span>
                <a
                  href="Javascript:void(0);"
                  class="delete-step"
                >
                  <i class="icon16 icon-close"></i>
                </a>
              </template>-->
            </li>
          </ul>
        </div>
      </li>
      <!-- <li>
        <a
          href="javascript:void(0);"
          class="list-item"
          v-b-tooltip.hover
          data-placement="top"
          title="Row Operations"
        >
          <i class="icon24 icon-row-operation"></i>
          <span>Row Operations</span>
        </a>
        <ul class="drilldown row-operations">
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Cut"
            >
              <i class="icon16 icon-cut-tbl"></i>
              <span>Cut</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Delete"
            >
              <i class="icon16 icon-delete-tbl"></i>
              <span>Delete</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Append"
            >
              <i class="icon16 icon-add-tbl"></i>
              <span>Append</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li>
        <a
          href="javascript:void(0);"
          class="list-item"
          v-b-tooltip.hover
          data-placement="top"
          title="Column Operations"
        >
          <i class="icon24 icon-column-operation"></i>
          <span>Column Operations</span>
        </a>
        <ul class="drilldown column-operation">
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Hide"
            >
              <i class="icon16 icon-hide-tbl"></i>
              <span>Hide</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              v-b-modal.UnhideModal
              data-placement="top"
              title="Unhide"
              @click="generateDataForUnHide"
            >
              <i class="icon16 icon-unhide-tbl"></i>
              <span>Unhide</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              v-b-modal.ClearSelectedConditionalFilter
              data-placement="top"
              title="Remove Conditional Filter"
              @click="generateDataForRemoveConditionalFilter"
            >
              <i class="icon16 icon-conditinal-tbl"></i>
              <span>Remove Conditional Filter</span>
            </a>
          </li>
          <!-- <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Sort"
            >
              <i class="icon16 icon-sort-tbl"></i>
              <span>Sort</span>
            </a>
          </li>-->
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              v-b-modal.bulkDelete
              data-placement="top"
              title="Multi Delete"
              @click="toggleBulkDelete"
            >
              <i class="icon16 icon-delete-tbl-small"></i>
              <span>Delete</span>
            </a>
          </li>
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              v-b-modal.rearrangeColumnsModal
              data-placement="top"
              title="Rearrange"
              @click="rearrangeDataColumns"
            >
              <i class="icon16 icon-rearrange"></i>
              <span>Rearrange columns</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="list-item"
          v-b-tooltip.hover
          data-placement="top"
          title="Data Processing"
        >
          <i class="icon24 icon-data-processing"></i>
          <span>Data Processing</span>
        </a>
        <ul class="drilldown data-processing">
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Remove Duplicates"
              v-b-modal.removeDuplicateModel
              @click="toggleRM"
            >
              <i class="icon16 icon-mark-review-tbl"></i>
              <span>Remove Duplicates</span>
            </a>
          </li>
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Arithmetic Operation"
              v-b-modal.arithmaticOperations
              @click="toggleAOps"
            >
              <i class="icon16 icon-duplicate-tbl"></i>
              <span>Arithmetic Operation</span>
            </a>
          </li>
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Attribute Mapping"
              v-b-modal.attributeMappingModal
              @click="toggleAttributeMapping"
            >
              <i class="icon16 icon-atrribute-mapping"></i>
              <span>Attribute Mapping</span>
            </a>
          </li>
          <!-- <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Process as output"
              @click="processOutputFn()"
            >
              <i class="icon16 icon-unhide-tbl"></i>
              <span>Process as output</span>
            </a>
          </li> -->
          <li v-if="showSubset">
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Show Full Data Subset"
              @click="showFullDataSubsetFn()"
            >
              <i class="icon16 icon-unhide-tbl"></i>
              <span>Show Full Data Subset</span>
            </a>
          </li>
          <li v-if="!showSubset">
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Show Original Grid Data"
              @click="hideFullDataSubsetFn()"
            >
              <i class="icon16 icon-hide-tbl"></i>
              <span>Show Original Grid Data</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          class="list-item"
          v-b-tooltip.hover
          data-placement="top"
          title="Data Validation and Review"
        >
          <i class="icon24 icon-validation-review"></i>
          <span>Data Validation and Review</span>
        </a>
        <ul
          v-if="jobParent && jobParent.status !== 8"
          class="drilldown data-validation"
        >
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Mark as Validated"
              @click="markAllAsValidated()"
            >
              <i class="icon16 icon-mark-validated-tbl"></i>
              <span>Mark as Validated</span>
            </a>
          </li>
          <li>
            <a
              v-if="condition('edit')"
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Unmark all Validated"
              @click="unmarkAllValidated()"
            >
              <i class="icon16 icon-unmark-validated-tbl"></i>
              <span>UnMark all Validated</span>
            </a>
          </li>
          <li>
            <div>
              <!-- Modal Component -->
              <b-modal
                id="markReview"
                ref="markReviewModalRef"
                centered
                title="Mark For Review"
              >
                <div class="modal-body">
                  <p class="my-4">All records are marked for review.</p>
                </div>
                <div slot="modal-footer">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="hide($event)"
                  >
                    Ok
                  </button>
                </div>
              </b-modal>
            </div>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Mark for Review"
              @click="markAllForReview()"
            >
              <i class="icon16 icon-mark-review-tbl"></i>
              <span>Mark for Review</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Unmark Reviewed"
              @click="unmarkAllReviewed()"
            >
              <i class="icon16 icon-unmark-review-tbl"></i>
              <span>Unmark Reviewed</span>
            </a>
          </li>
          <li v-if="showValidateddData">
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="View all Validated"
              @click="viewAllValidatedData()"
            >
              <i class="icon16 icon-findall-validated-tbl"></i>
              <span>View All Validated</span>
            </a>
          </li>
          <li v-if="!showValidateddData">
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="View all Grid Data"
              @click="viewAllGridAndValidatedData()"
            >
              <i class="icon16 icon-hide-tbl"></i>
              <span>View All Grid Data</span>
            </a>
          </li>
          <li v-if="showReviewedData">
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="View all Reviewed"
              @click="viewAllReviewedData()"
            >
              <i class="icon16 icon-findall-review-tbl"></i>
              <span>View All Reviewed</span>
            </a>
          </li>
          <li v-if="!showReviewedData">
            <a
              href="javascript:void(0);"
              class="list-item"
              v-b-tooltip.hover
              data-placement="top"
              title="View all Grid Data"
              @click="viewAllGridAndReviewedData()"
            >
              <i class="icon16 icon-hide-tbl"></i>
              <span>View All Grid Data</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <data-modal
      id="removeDuplicateModel"
      ref1="removeDuplicate"
      title="Remove Duplicates"
      v-if="isRMVisible"
    >
      <div slot="body">
        <label
          style="color: gray;"
          for="send-to"
        ></label>
        <multiselect
          v-model="selected"
          placeholder="Search or add a column"
          track-by="value"
          label="value"
          :options="this.gridDataTableDisplayCols"
          :multiple="true"
          @tag="addTag"
          :close-on-select="false"
        ></multiselect>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="removeDupliateFn()"
          :disabled="selected.length === 0"
        >
          <i class="icon14 icon-save"></i> Remove
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onCancel()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </data-modal>

    <data-modal
      id="arithmaticOperations"
      ref1="arithmaticOps"
      centered
      title="Arithmetic Operations"
      v-if="isAMVisible"
    >
      <div slot="body">
        <label
          style="color: gray;"
          for="send-to"
        ></label>
        <multiselect
          v-model="arithmaticAdditionColOne"
          tag-placeholder="Add this as new tag"
          placeholder="Select 1st Column"
          track-by="value"
          label="value"
          :options="this.gridDataTableDisplayCols"
          :taggable="true"
          :close-on-select="true"
          :allow-empty="false"
        ></multiselect>
        <multiselect
          v-model="arithmaticAdditionColTwo"
          tag-placeholder="Add this as new tag"
          placeholder="Select 2nd Column"
          track-by="value"
          label="value"
          :options="this.gridDataTableDisplayCols"
          :taggable="true"
          :close-on-select="true"
          :allow-empty="true"
        ></multiselect>
        <input
          class="form-control search-control awesomplete mt-2"
          placeholder="Name of Column in which result to be stored"
          type="text"
          v-model="arithmaticAdditionColThree"
        />
        <multiselect
          v-model="arithmaticOperator"
          tag-placeholder="Add this as new tag"
          placeholder="Operation"
          track-by="value"
          label="value"
          :options="this.arithmaticOperations"
          :taggable="true"
          :close-on-select="true"
          :allow-empty="false"
        ></multiselect>
        <input
          class="form-control search-control awesomplete mt-2"
          placeholder="Enter a number"
          type="number"
          v-model="staticNumber"
          :disabled="arithmaticAdditionColTwo != null"
        />
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="performArithmaticOperation()"
          :disabled="disableArithmaticOpsDoneButton()"
        >
          <i class="icon14 icon-save"></i> Done
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onCancel"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </data-modal>
    <data-modal
      id="UnhideModal"
      ref1="unhideModalReference"
      centered
      title="Unhide Columns"
      v-if="isRMVisible"
    >
      <div slot="body">
        <div v-if="hiddenColumnsArray.length === 0">No columns to unhide</div>
        <div
          v-for="(column, index) in this.allColumnsArray"
          :key="index"
        >
          <a
            href="javascript:void(0);"
            v-if="column.isHidden === true"
            class="list-item"
            data-placement="top"
            title="UnHide"
            @click="unhideColumnByColumnName(column.value)"
          >
            <i class="icon16 icon-hide-tbl"></i>
            <span>{{ column.text }}</span>
          </a>
        </div>
      </div>
    </data-modal>
    <data-modal
      id="ClearSelectedConditionalFilter"
      ref1="ClearSelectedConditionalFilterReference"
      centered
      title="Conditional Filter on Grid"
      v-if="isRMVisible"
    >
      <div slot="body">
        <modal-alert></modal-alert>
        <span v-if="conditionalFilterArrayFromMap.length === 0">No Filters Applied</span>
        <span v-if="conditionalFilterArrayFromMap.length > 0">
          <div id="accordion">
            <span
              v-for="(value, index) in conditionalFilterArrayFromMap"
              :key="index"
            >
              <div class="card-header fliterBorder">
                Column Name : {{ value[0] }}
                <br />
                Filter Applied : {{ value[1].conditionApplied.text }} -
                {{ value[1].inputValue }}
                <a
                  href="javascript:void(0);"
                  class="list-item floatingDeleteIcon"
                  data-placement="top"
                  title="UnHide"
                  @click="removeSingleConditionalFilter(value[0])"
                >
                  <i class="icon14 icon-close-blue mr-3 remove-accordion"></i>
                </a>
              </div>
            </span>
          </div>
        </span>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="conditionalFilterArrayFromMap.length === 0"
          @click="removeSingleConditionalFilter('')"
        >
          <i class="icon14 icon-save"></i> Remove All Filter
        </button>
      </div>
    </data-modal>
    <data-modal
      id="bulkDelete"
      ref1="bulkDeleteReference"
      title="Multiple Row Delete"
      v-if="isBulkDeleteVisible"
    >
      <div slot="body">
        <modal-alert></modal-alert>
        <multiselect
          v-model="bulkSelected"
          placeholder="Search or add a column"
          track-by="value"
          label="value"
          :options="this.gridDataTableDisplayCols"
          :multiple="true"
          @tag="addTag"
          :close-on-select="false"
        ></multiselect>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="bulkSelected.length === 0"
          @click="removeBulkColumnsFromGrid()"
        >
          <i class="icon14 icon-save"></i> Delete
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onCancel"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </data-modal>

    <data-modal
      id="attributeMappingModal"
      ref1="attributeMappingModalRef"
      title="Attribute Mapping"
      v-if="isAttributeMappingModalVisible"
    >
      <div slot="body">
        <modal-alert></modal-alert>
        <div v-show="!attrribMappingFileUploadComplete">
          <label for="fileReplace">
            <input
              type="file"
              name="AttributeMappingFile"
              required
              accept=".xlsx, .xls, .csv"
              id="fileReplace"
              ref="fileReplace"
              onclick="this.blur()"
              @change="
                uploadAttributeMappingFile(
                  $event.target.name,
                  $event.target.files
                )
              "
            />
          </label>
        </div>
        <div v-show="attrribMappingFileUploadComplete">
          <label>Lookup Column from file</label>
          <multiselect
            class="mul-required-field"
            v-model="selectColumnFromAttribMappingFile"
            placeholder="Select column"
            track-by="value"
            label="value"
            :options="columnsInAttributeMappingFile"
            @tag="addTag"
          ></multiselect>
          <label>Lookup Column from Grid</label>
          <multiselect
            class="mul-required-field"
            v-model="selectColumnFromGrid"
            placeholder="Select column"
            track-by="value"
            label="value"
            :options="this.gridDataTableDisplayCols"
            @tag="addTag"
          ></multiselect>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          v-show="attrribMappingFileUploadComplete"
          @click="performAttributeMapping($event)"
        >
          <i class="icon14 icon-save"></i> Done
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="toggleAttributeMapping()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </data-modal>
    <data-modal
      id="rearrangeColumnsModal"
      ref1="rearrangeColumns"
      title="Rearrange columns"
      v-if="isRMVisible"
    >
      <div slot="body">
        <modal-alert></modal-alert>
        <draggable
          v-model="dataColumns"
          @start="drag = true"
          @end="drag = false"
          style="cursor:pointer;"
        >
          <div
            class="column-drag"
            v-for="element in dataColumns"
            :key="element"
          >
            {{ element }}
          </div>
        </draggable>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="saveColumnOrder($event)"
        >
          <i class="icon14 icon-save"></i> Done
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="onCancel"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </data-modal>
  </div>
</template>

<script>
import Alert from "../components/Alert.vue";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import DataProcessingModal from "./DataProcessingModal.vue";
import {
  convertMapToJsonString,
  encryptData
} from "./../utilbin/common-utils.js";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import {
  removeDupliate,
  refreshStep,
  saveAddSteps,
  deleteStep,
  markManyAsValidated,
  unmarkManyAsValidated,
  markManyForReview,
  unmarkManyReviewed,
  viewAllValidated,
  viewAllReviewed,
  updateJobStepStatus,
  fetchHeaderMetaDataForAttributeMappingFile,
  processOutput,
  showFullDataSubset,
  hideFullDataSubset,
  viewAllGridAndReviewed,
  viewAllGridAndValidated,
  saveColumnOrder
} from "../services/parentservices.js";
import { EventBus } from "../main.js";
import {
  ACTION_NAME,
  MARK_AS_VALIDATED,
  MARK_FOR_REVIEW,
  STEP_MODE
} from "../assets/js/constant";
import notifyAction from "./NotifyAction.vue";
import {
  validateDeleteStepDependencies,
  addStep,
  validateDependencies
} from "./../utilbin/step-utility";
import isEmpty from "lodash/isEmpty";
import { validateFileImport } from "./../utilbin/validation.js";
import draggable from "vuedraggable";
const FILE_UPLOAD_KEY = "trainingFile";

export default {
  data() {
    return {
      showNotify: true,
      arithmaticAdditionColOne: null,
      arithmaticAdditionColTwo: null,
      arithmaticAdditionColThree: null,
      arithmaticOperator: null,
      staticNumber: null,
      selected: [],
      bulkSelected: [],
      options: [],
      arithmaticOperations: [
        { value: "+" },
        { value: "-" },
        { value: "*" },
        { value: "/" }
      ],
      isRMVisible: false,
      isAMVisible: false,
      actionName: ACTION_NAME,
      showSaveCancelFlag: false,
      msg: "",
      finalStepArray: [],
      dependentStepArray: [],
      currentStep: "",
      isBulkDeleteVisible: false,
      hiddenColumnsArray: [],
      conditionalFilterArrayFromMap: [],
      isAttributeMappingModalVisible: false,
      fileInfo: "",
      selectColumnFromAttribMappingFile: null,
      columnsInAttributeMappingFile: [],
      attrribMappingFileUploadComplete: false,
      selectColumnFromGrid: null,
      fullQualifiedFileName: null,
      fileExtension: null,
      editStepsClicked: false,
      showReviewedData: true,
      showValidateddData: true,
      dataColumns: []
    };
  },
  methods: {
    removeBulkColumnsFromGrid() {
      if (this.gridDataTableDisplayCols.length === 1) {
        errorEventNotify("Grid contains only one column", 2);
      } else if (
        this.gridDataTableDisplayCols.length === this.bulkSelected.length
      ) {
        errorEventNotify("Cannot delete all columns from the grid", 2);
      } else {
        let queryParam = {
          actionName: this.actionName.bulkColumnDelete,
          data: this.bulkSelected.map(x => x.value),
          collectionName: this.jobParent.inputFileName.displayFileName,
          jobName: this.jobParent.jobName,
          jobStatus: this.jobParent.status,
          jobId: this.jobParent._id,
          editAddStepMode: this.editAction.editModeOn,
          userId: this.userDetails._id,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          )
        };
        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. now check dependencies on steps below
            const dependencies = validateDependencies(
              this.actionName.bulkColumnDelete,
              this.bulkSelected.map(x => x.value),
              this.editAction.prevStep,
              this.stepsAction
            );
            // 5. if dependencies found capture steps show modal with dependencies
            if (dependencies.arr.length > 0) {
              let updateSteps = addStep(
                this.actionName.bulkColumnDelete,
                { data: this.bulkSelected.map(x => x.value) },
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(queryParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                dependencies.removeArr
              );
              queryParam.updatedSteps = updateSteps;
              this.$refs.stepDependency.show();
              this.editModeActionParamTemp = queryParam;
              this.dependentStepArray = dependencies.arr;
              let dependentActions = [];
              for (let i = 0; i < dependencies.arr.length; i++) {
                dependentActions.push(dependencies.arr[i].actionName);
              }
              this.msg =
                "Column " +
                this.bulkSelected.map(x => x.value) +
                "  has been used in " +
                dependentActions +
                " step below. On click yes, below step will also be get deleted.";
            } else {
              // 6. if no dependencies found capture steps and call backend function
              let updateSteps = addStep(
                this.actionName.bulkColumnDelete,
                {
                  data: this.bulkSelected.map(x => x.value)
                },
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(queryParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                this.stepsAction
              );
              queryParam.updatedSteps = updateSteps;
              this.removeBulkColumns(queryParam);
              this.addStepModeFn();
            }
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.removeBulkColumns(queryParam);
          }
        }
      }
    },
    removeBulkColumns(queryParam) {
      this.$store
        .dispatch("removeBulkColumnFromGridAction", queryParam)
        .then(response => {
          if (!(response instanceof Error)) {
            this.isBulkDeleteVisible = false;
            this.resetAllData();
            successEventNotify("Deleted columns successfully", 1);
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify(
            "Some Problems deleting the columns from the grid",
            1
          );
        })
        .finally(() => {});
    },
    removeSingleConditionalFilter(columnName) {
      this.$store
        .dispatch("removeConditionalFilterOnColumnActionFromLP", columnName)
        .then(response => {
          const actionParam = {
            sentForReviewArr: this.jobParent.sentForReview,
            jobName: this.jobParent.jobName,
            collectionName: this.jobGridInputFileName,
            jobStatus: this.jobParent.status,
            userId: this.userDetails._id,
            conditionalFiltersAppliedMap: convertMapToJsonString(
              this.conditionalFiltersAppliedMap
            )
          };
          this.$store
            .dispatch("fetchDataAfterClearAction", actionParam)
            .then(response => {
              if (!(response instanceof Error)) {
                this.generateDataForRemoveConditionalFilter();
                successEventNotify("Cleared filters successfully", 2);
              } else {
                throw response;
              }
            })
            .catch(e => {
              errorEventNotify(
                "Some Problems Removing the filter from the grid",
                2
              );
            })
            .finally(() => {});
        });
    },
    generateDataForRemoveConditionalFilter() {
      this.conditionalFilterArrayFromMap = convertMapToJsonString(
        this.conditionalFiltersAppliedMap
      );
      this.conditionalFilterArrayFromMap = JSON.parse(
        this.conditionalFilterArrayFromMap
      );
      this.isRMVisible = true;
    },
    generateDataForUnHide() {
      this.hiddenColumnsArray = this.allColumnsArray.filter(x => {
        return x.isHidden === true;
      });
      this.isRMVisible = true;
    },
    unhideColumnByColumnName(columnName) {
      this.hiddenColumnsArray = this.hiddenColumnsArray.filter(x => {
        return x.text !== columnName;
      });

      if (event && !isEmpty(event) && event.isTrusted) {
        this.$store.dispatch("unhideSingleColumnAction", columnName);
      }
    },
    disableStepAddDeleteButton: function(index) {
      if (
        this.editAction.prevStep !== "" &&
        this.editAction.newStepNumber !== ""
      ) {
        if (this.editAction.newStepNumber < index) {
          return "disabledStep";
        }
      }
      if (
        this.jobParent &&
        this.jobParent.jobStepStatus &&
        this.jobParent.jobStepStatus.stepMode === "Refresh"
      ) {
        if (this.jobParent.stepNumberTillWhereToExecute < index) {
          return "disabledStep";
        }
      }
    },
    toggleAOps() {
      this.isAMVisible = this.isAMVisible ? false : true;
      this.resetAllData();
    },
    toggleRM() {
      this.isRMVisible = ~this.isRMVisible;
      this.selected = [];
    },
    toggleAttributeMapping() {
      if (this.isAttributeMappingModalVisible) {
        this.attrribMappingFileUploadComplete = false;
        this.clearAttributeMappingData();
      }
      this.isAttributeMappingModalVisible = this.isAttributeMappingModalVisible
        ? false
        : true;
    },
    toggleBulkDelete() {
      this.isBulkDeleteVisible = ~this.isBulkDeleteVisible;
      this.bulkSelected = [];
    },
    intializeGridComponent() {
      let myGrid = new Grid("demoGrid", {
        allowGridResize: true,
        allowColumnResize: true,
        allowClientSideSorting: true,
        allowSelections: true,
        allintializeGridComponentowMultipleSelections: true,
        showSelectionColumn: true,
        colBGColors: this.computeFixedColsBgColorFn(),
        fixedCols: this.fixedColHeaderObjectsLength,
        totalXCols: this.totalColumns
      });
    },
    disableArithmaticOpsDoneButton() {
      return (
        this.arithmaticAdditionColOne == null ||
        (this.arithmaticAdditionColTwo == null && this.staticNumber == null) ||
        this.arithmaticAdditionColThree == null ||
        this.arithmaticOperator == null
      );
    },
    removeDupliateFn() {
      let queryParam = {
        actionName: this.actionName.removeDuplicates,
        data: this.selected.map(x => x.value),
        collectionName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        jobStatus: this.jobParent.status,
        jobId: this.jobParent._id,
        editAddStepMode: this.editAction.editModeOn,
        userId: this.userDetails._id,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };

      if (this.editAction.editModeOn) {
        // 3. check add step mode (if false show alert msg and return)
        if (!this.editAction.addStepModeOn) {
          errorEventNotify(
            "You are in edit mode, Please click on add button to perform any operation on grid.",
            1
          );
          return;
        } else {
          // 4. capture steps and call backend function
          let updateSteps = addStep(
            this.actionName.removeDuplicates,
            {
              data: this.selected.map(x => x.value)
            },
            this.editAction.prevStep.stepNumber + 1,
            JSON.parse(queryParam.conditionalFiltersAppliedMap),
            this.editAction.prevStep.lastBackup,
            this.editAction.prevStep.stepAfterLastBackup,
            this.stepsAction
          );
          queryParam.updatedSteps = updateSteps;
          this.removeDupliate(queryParam);
          this.addStepModeFn();
        }
      } else {
        if (
          this.jobParent.jobStepStatus &&
          this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
        ) {
          errorEventNotify(
            "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
            1
          );
        } else {
          this.removeDupliate(queryParam);
        }
      }
    },
    addStepModeFn() {
      let obj = {
        addStepModeOn: false,
        prevStep: "",
        newStepNumber: "",
        runStepNumber: this.editAction.prevStep.stepNumber
      };
      for (
        let i = this.editAction.prevStep.stepNumber;
        i < this.stepsAction.length;
        i++
      ) {
        if (this.stepsAction[i].actionName === ACTION_NAME.algoExecute) {
          obj.prevStep = this.editAction.prevStep;
          obj.newStepNumber = this.editAction.prevStep.stepNumber + 1;
          break;
        }
      }
      this.$store
        .dispatch("addStepModeAction", obj)
        .then(response => {})
        .catch(e => {});
    },
    removeDupliate(queryParam) {
      this.$store
        .dispatch("removeDuplicateAction", queryParam)
        .then(response => {
          successEventNotify("Removed Duplicates successfully", 1);
        });
      this.isRMVisible = false;
      this.resetAllData();
    },
    arithmeticOperation(queryParam) {
      this.$store
        .dispatch("arithmaticOperationAction", queryParam)
        .then(response => {
          successEventNotify("Arithmetic Operation performed successfully", 1);
        });
      this.isAMVisible = false;
    },
    performArithmaticOperation() {
      let queryParam = {
        actionName: this.actionName.arithmaticOperation,
        column1: this.arithmaticAdditionColOne.value,
        column2:
          this.arithmaticAdditionColTwo == null
            ? null
            : this.arithmaticAdditionColTwo.value,
        column: this.arithmaticAdditionColThree,
        selectedOperator: this.arithmaticOperator.value,
        collectionName: this.jobParent.inputFileName.displayFileName,
        staticNumber: this.staticNumber,
        jobName: this.jobParent.jobName,
        jobStatus: this.jobParent.status,
        jobId: this.jobParent._id,
        userId: this.userDetails._id,
        editAddStepMode: this.editAction.editModeOn,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };
      //  let param = queryParam;
      if (this.editAction.editModeOn) {
        // 3. check add step mode (if false show alert msg and return)
        if (!this.editAction.addStepModeOn) {
          errorEventNotify(
            "You are in edit mode, Please click on add button to perform any operation on grid.",
            1
          );
          return;
        } else {
          // 4. capture steps and call backend function
          let updateSteps = addStep(
            this.actionName.arithmaticOperation,
            {
              column1: this.arithmaticAdditionColOne.value,
              column2:
                this.arithmaticAdditionColTwo == null
                  ? null
                  : this.arithmaticAdditionColTwo.value,
              column: this.arithmaticAdditionColThree,
              selectedOperator: this.arithmaticOperator.value,
              staticNumber: this.staticNumber
            },
            this.editAction.prevStep.stepNumber + 1,
            JSON.parse(queryParam.conditionalFiltersAppliedMap),
            this.editAction.prevStep.lastBackup,
            this.editAction.prevStep.stepAfterLastBackup,
            this.stepsAction
          );
          queryParam.updatedSteps = updateSteps;
          this.arithmeticOperation(queryParam);
          this.addStepModeFn();
        }
      } else {
        if (
          this.jobParent.jobStepStatus &&
          this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
        ) {
          errorEventNotify(
            "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
            1
          );
        } else {
          this.arithmeticOperation(queryParam);
        }
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    gridUnhideColumnsFn(event) {
      if (event && !isEmpty(event) && event.isTrusted) {
        this.$store
          .dispatch("globalUnhideColumnsAction", event)
          .then(response => {})
          .catch(e => {})
          .finally(() => {
            this.toggleLeftMenuFloaterFn();
          });
      }
    },
    toggleLeftMenuFloaterFn() {
      let leftMenuItem = document.querySelector(
        ".table-config-section .table-action-list > li.active"
      );
      leftMenuItem.classList.toggle("active");
    },
    onCancel() {
      this.isRMVisible = false;
      this.isAMVisible = false;
      this.isBulkDeleteVisible = false;
      this.resetAllData();
    },
    resetAllData() {
      this.arithmaticAdditionColOne = null;
      this.arithmaticAdditionColTwo = null;
      this.arithmaticAdditionColThree = null;
      this.arithmaticOperator = null;
      this.staticNumber = null;
      this.selected = [];
      this.options = [];
      this.bulkSelected = [];
    },
    refreshStepFn(step) {
      let actionParam = {
        jobId: this.jobParent._id,
        stepNumber: step.stepNumber,
        userId: this.userDetails._id,
        stepMode: STEP_MODE.refreshMode
      };
      successEventNotify("Refreshing the grid", 1);
      return new Promise((resolve, reject) => {
        refreshStep(actionParam)
          .then(response => {
            if (
              !response._isError &&
              !isEmpty(response._result) &&
              response._result.rowsData.length > 0
            ) {
              this.$emit("stepStatusUpdate", response._result.jobObject);
              this.$store
                .dispatch("refreshGridDataAction", response._result)
                .then(response => {
                  resolve();
                  successEventNotify("Refresh completed", 1);
                })
                .catch(e => {
                  reject(e);
                });
            } else {
              reject("response empty at refreshStepFn()");
            }
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    addSteps(step) {
      this.refreshStepFn(step)
        .then(data => {
          let obj = {
            addStepModeOn: true,
            prevStep: step,
            newStepNumber: step.stepNumber,
            runStepNumber: step.stepNumber
          };
          this.$store
            .dispatch("addStepModeAction", obj)
            .then(response => {
              this.disableStepAddDeleteButton(step.stepNumber);
              let updateStepObj = {
                jobId: this.jobParent._id,
                userId: this.userDetails._id,
                stepMode: STEP_MODE.addMode
              };
              updateStepObj.stepNumber = this.editAction.runStepNumber;
              if (this.editAction.deleteStepNumber !== "") {
                updateStepObj.stepNumber =
                  this.editAction.deleteStepNumber >
                  this.editAction.runStepNumber
                    ? this.editAction.runStepNumber
                    : this.editAction.deleteStepNumber;
              }
              updateJobStepStatus(updateStepObj)
                .then(response => {
                  this.$emit("stepStatusUpdate", response._result);
                })
                .catch(e => {
                  errorEventNotify("Some problems updating the step status", 1);
                });
              // successEventNotify(
              //   "Now perform any operation on grid, And it will be captured to the steps",
              //   1
              // );
              successEventNotify(
                "Action performed on grid will be captured as step",
                1
              );
              //this.showAlert();
            })
            .catch(e => {});
        })
        .catch(e => {});
    },
    removeSteps(step) {
      const dependencies = validateDeleteStepDependencies(
        step,
        this.stepsAction
      );
      this.finalStepArray = dependencies.finalStepArray;
      this.dependentStepArray = dependencies.presentStep;
      this.currentStep = step;
      if (dependencies.presentStep.length > 1) {
        this.$refs.stepDependency.show();
        let dependentActions = [];
        for (let i = 0; i < dependencies.presentStep.length; i++) {
          dependentActions.push(dependencies.presentStep[i].actionName);
        }
        this.msg =
          "It has dependencies in the following steps : " +
          dependentActions +
          ". On click yes, below step will also get deleted.";
      } else {
        this.$refs.stepDependency.show();
        this.msg = "Are you sure you want to delete this step ?";
      }
    },
    deleteStepFn(event, currentStep, updatedSteps, dependentStepArray) {
      let executeFound = dependentStepArray.filter(el => {
        return el.actionName === ACTION_NAME.algoExecute;
      });
      if (executeFound.length > 0) {
        for (let j = currentStep.stepNumber; j < updatedSteps.length; j++) {
          if (updatedSteps[j].actionName === "Execute") {
            break;
          } else {
            if (currentStep.stepNumber === 0) {
              updatedSteps[j].lastBackup =
                updatedSteps[currentStep.stepNumber].lastBackup;
              updatedSteps[j].stepAfterLastBackup =
                updatedSteps[currentStep.stepNumber].stepAfterLastBackup;
            } else {
              updatedSteps[j].lastBackup =
                updatedSteps[currentStep.stepNumber - 1].lastBackup;
              updatedSteps[j].stepAfterLastBackup =
                updatedSteps[currentStep.stepNumber - 1].stepAfterLastBackup;
            }
          }
        }
      }
      if (this.editAction.prevStep !== "") {
        this.$store
          .dispatch("updateDisabledStepAction", currentStep.stepNumber)
          .then(response => {})
          .catch(e => {});
      }
      const actionParam = {
        jobId: this.jobParent._id,
        updatedSteps: updatedSteps,
        userId: this.userDetails._id
      };
      deleteStep(actionParam)
        .then(response => {
          this.$refs.stepDependency.hide();
          let obj = {
            stepData: response._result
          };
          this.editAction.deleteStepNumber === ""
            ? currentStep.stepNumber === 0
              ? (obj.deleteStepNumber = currentStep.stepNumber)
              : (obj.deleteStepNumber = currentStep.stepNumber - 1)
            : (obj.deleteStepNumber =
                this.editAction.deleteStepNumber >= currentStep.stepNumber
                  ? currentStep.stepNumber - 1
                  : this.editAction.deleteStepNumber);
          this.$store
            .dispatch("deleteStepModeAction", obj)
            .then(response => {
              let updateStepObj = {
                jobId: this.jobParent._id,
                userId: this.userDetails._id,
                stepMode: STEP_MODE.deleteMode
              };
              updateStepObj.stepNumber = this.editAction.deleteStepNumber;
              if (this.editAction.runStepNumber !== "") {
                updateStepObj.stepNumber =
                  this.editAction.deleteStepNumber >
                  this.editAction.runStepNumber
                    ? this.editAction.runStepNumber
                    : this.editAction.deleteStepNumber;
              }
              updateJobStepStatus(updateStepObj)
                .then(response => {
                  this.$emit("stepStatusUpdate", response._result);
                })
                .catch(e => {
                  errorEventNotify("Some problems updating the step status", 1);
                });
            })
            .catch(e => {});
        })
        .catch(e => {});
    },
    cancelStepFn() {
      this.$refs.stepDependency.hide();
    },
    showSaveCancelButton() {
      this.editStepsClicked = true;
      this.showSaveCancelFlag = true;
      let obj = {
        editModeOn: this.showSaveCancelFlag,
        addStepModeOn: false,
        prevStep: this.editAction.prevStep,
        newStepNumber: this.editAction.newStepNumber,
        runStepNumber: this.editAction.runStepNumber,
        deleteStepNumber: this.editAction.deleteStepNumber
      };
      this.$store
        .dispatch("editModeAction", obj)
        .then(response => {})
        .catch(e => {})
        .finally(() => {});
    },
    navigateToJobLanding() {
      //clearing the store condtional map first
      this.$store
        .dispatch("clearJobLevelStoreDataAction")
        .then(response => {
          //then going job landing
          this.$router.push({
            name: "jobLanding"
          });
        })
        .catch(e => {});
    },
    saveAddStepsFn() {
      let stepNumber = this.editAction.runStepNumber;
      if (this.editAction.deleteStepNumber !== "") {
        stepNumber =
          this.editAction.deleteStepNumber > this.editAction.runStepNumber
            ? this.editAction.runStepNumber
            : this.editAction.deleteStepNumber;
      }
      if (stepNumber === "") {
        // successEventNotify("There are no changes to save", 1);
      } else {
        const actionParam = {
          //actionName: "Execute",
          stepNumber: stepNumber,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobParent.inputFileName.displayFileName,
          jobStatus: this.jobParent.status,
          userId: this.userDetails._id
        };
        successEventNotify(
          "This might take a while to refresh, you will be notified through email ",
          1
        );
        saveAddSteps(actionParam)
          .then(response => {})
          .catch(e => {});
        updateJobStepStatus(actionParam)
          .then(res => {
            this.$emit("stepStatusUpdate", res._result);
          })
          .catch(e => {
            errorEventNotify("Some problems clearing the step", 1);
          });
        this.navigateToJobLanding();
      }
      this.editModeOff();
    },
    editModeOff() {
      this.showSaveCancelFlag = false;
      let obj = {
        editModeOn: this.showSaveCancelFlag,
        addStepModeOn: false,
        prevStep: "",
        newStepNumber: "",
        runStepNumber: "",
        deleteStepNumber: ""
      };
      this.$store
        .dispatch("editModeAction", obj)
        .then(response => {})
        .catch(e => {});
    },
    markAllAsValidated() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);
      if (columnList.length === 0) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        validatedColumnList: columnList,
        userId: this.userDetails._id
      };
      markManyAsValidated(params)
        .then(response => {
          if (!response._isError) {
            // if (
            //   response._result != null &&
            //   response._result != undefined &&
            //   response._result.length > 0
            // ) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
            // }
            successEventNotify(MARK_AS_VALIDATED.markedAllAsValidated, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedAllAsValidated, 1);
          }
        })
        .catch(e => {});
    },
    viewAllValidatedData() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);
      if (columnList.length === 0) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      this.showValidateddData = false;
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        validatedColumnList: columnList,
        userId: this.userDetails._id
      };
      viewAllValidated(params)
        .then(response => {
          if (!response._isError) {
            // if (
            //   response._result != null &&
            //   response._result != undefined &&
            //   response._result.length > 0
            // ) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
            // }
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorFindValidatedData, 1);
          }
        })
        .catch(e => {});
    },
    markAllForReview() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        reviewColumnList: columnList,
        userId: this.userDetails._id
      };
      markManyForReview(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
            successEventNotify(MARK_FOR_REVIEW.markedAllForReview, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedAllForReview, 1);
          }
        })
        .catch(e => {});
    },
    viewAllReviewedData() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);

      if (columnList.length === 0) {
        errorEventNotify(MARK_FOR_REVIEW.markedAllAsReviewedInput, 1);
        return;
      }
      this.showReviewedData = false;

      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        reviewColumnList: columnList,
        userId: this.userDetails._id
      };

      viewAllReviewed(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorFindReviewedData, 1);
          }
        })
        .catch(e => {});
    },
    condition(value) {
      if (
        (this.jobParent && this.jobParent.owner == this.userDetails._id) ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        if (this.jobParent && this.jobParent.status !== 6) {
          return true;
        } else {
          return false;
        }
      } else {
        console.log("else");
        if (this.jobParent.shareAccess.length > 0 && this.userDetails._id) {
          return this.jobParent.shareAccess
            .find(x => x._id == this.userDetails._id)
            .privileges.includes(value);
        }
        return false;
      }
    },
    unmarkAllValidated() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        validatedColumnList: columnList,
        userId: this.userDetails._id
      };
      unmarkManyAsValidated(params)
        .then(response => {
          if (!response._isError) {
            if (
              response._result != null &&
              response._result != undefined &&
              response._result.length > 0
            ) {
              this.$store.dispatch("refreshOnlyGridRows", response._result);
            }
            successEventNotify(MARK_AS_VALIDATED.unmarkedAllValidated, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedAllAsValidated, 1);
          }
        })
        .catch(e => {});
    },
    unmarkAllReviewed() {
      let columnList = this.gridDataTableDisplayCols
        .filter(element => !element.isInput)
        .map(element => element.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        reviewColumnList: columnList,
        userId: this.userDetails._id
      };
      unmarkManyReviewed(params)
        .then(response => {
          if (!response._isError) {
            if (
              response._result != null &&
              response._result != undefined &&
              response._result.length > 0
            ) {
              this.$store.dispatch("refreshOnlyGridRows", response._result);
            }
            successEventNotify(MARK_FOR_REVIEW.unmarkedAllReviewed, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedAllForReview, 1);
          }
        })
        .catch(e => {});
    },

    attributeMappingFileChange(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
      } else {
        errorEventNotify("Could not upload file", 1);
      }
    },

    async uploadAttributeMappingFile(fieldName, fileList) {
      const validationFlag = validateFileImport(fileList);
      if (validationFlag) {
        this.fileInfo = fileList;
      } else {
        errorEventNotify("Could not upload file", 1);
        return;
      }
      let formData = new FormData();
      Array.from(Array(this.fileInfo.length).keys()).map(x => {
        formData.append(
          FILE_UPLOAD_KEY,
          this.fileInfo[x],
          this.fileInfo[x].name
        );
      });

      const obj = {
        userId: this.userDetails._id,
        jobId: this.jobParent._id
      };

      formData.append("encryptedData", await encryptData(JSON.stringify(obj)));
      fetchHeaderMetaDataForAttributeMappingFile(formData)
        .then(response => {
          this.fileInfo = "";
          let sameColumnCount = 0;
          if (!response._isError) {
            this.columnsInAttributeMappingFile = response._result.resultToSend;
            this.fullQualifiedFileName =
              response._result.fullyQualifiedFileName;
            this.fileExtension = response._result.extension;
            this.columnsInAttributeMappingFile.forEach(eachColumn => {
              this.gridDataTableDisplayCols.forEach(oneGridCol => {
                if (eachColumn.value === oneGridCol.value) {
                  sameColumnCount++;
                }
              });
            });
            if (sameColumnCount > 1) {
              errorEventNotify(
                "MORE THAN ONE COLUMN NAME IN FILE IS SAME AS THAT OF GRID",
                1
              );
            } else {
              this.attrribMappingFileUploadComplete = true;
            }
          } else {
            errorEventNotify(response._message, 2);
          }
        })
        .catch(error => {
          this.fileInfo = "";
          errorEventNotify(error.message, 2);
        });
    },
    performAttributeMapping() {
      let isParamsValid = this.performAttrMapingValValidation();
      if (isParamsValid) {
        const loader = document.querySelector(".loading-gif");
        const temp = document.querySelector(".fr-warapper");
        temp.classList.add("temp");
        loader.classList.add("mystyle");
        let obj = {
          userId: this.userDetails._id,
          jobId: this.jobParent._id,
          gridColumn: this.selectColumnFromGrid.value,
          attribFileColumn: this.selectColumnFromAttribMappingFile.value,
          path: this.fullQualifiedFileName,
          fileExtension: this.fileExtension,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          )
        };
        if (
          !this.selectColumnFromGrid ||
          !this.selectColumnFromAttribMappingFile
        ) {
          errorEventNotify("Both fields are mandatory", 2);
          return;
        }
        this.$store
          .dispatch("attributeMappingAction", obj)
          .then(response => {
            const loader = document.querySelector(".loading-gif");
            const temp = document.querySelector(".fr-warapper");
            loader.classList.remove("mystyle");
            temp.classList.remove("temp");
            if (!(response instanceof Error)) {
              this.toggleAttributeMapping();
              this.clearAttributeMappingData();
            } else {
              this.fileInfo = "";
              errorEventNotify(response, 2);
            }
          })
          .catch(e => {})
          .finally(() => {});
      } else {
        errorEventNotify("BOTH ARE MANDATORY FIELDS", 2);
      }
    },
    clearAttributeMappingData() {
      this.attrribMappingFileUploadComplete = false;
      this.fileInfo = "";
      this.selectColumnFromAttribMappingFile = null;
      this.columnsInAttributeMappingFile = [];
      this.attrribMappingFileUploadComplete = false;
      this.selectColumnFromGrid = null;
      this.fullQualifiedFileName = null;
      this.fileExtension = null;
    },
    performAttrMapingValValidation() {
      return (
        this.userDetails._id &&
        this.jobParent._id &&
        this.selectColumnFromGrid &&
        this.selectColumnFromAttribMappingFile &&
        this.fullQualifiedFileName &&
        this.fileExtension
      );
    },
    resetLeftPanel() {
      const activeLeftPanel = document.body.querySelector(
        ".table-action-list li.active"
      );
      if (activeLeftPanel && !this.editStepsClicked) {
        activeLeftPanel.classList.remove("active");
      }
      this.editStepsClicked = false;
    },
    processOutputFn() {
      let actionParam = {
        actionName: this.actionName.processOutput,
        data: { status: 4 },
        collectionName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        jobStatus: this.jobParent.status,
        jobId: this.jobParent._id,
        editAddStepMode: this.editAction.editModeOn,
        userId: this.userDetails._id,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };

      if (this.editAction.editModeOn) {
        // 3. check add step mode (if false show alert msg and return)
        if (!this.editAction.addStepModeOn) {
          errorEventNotify(
            "You are in edit mode, Please click on add button to perform any operation on grid.",
            1
          );
          return;
        } else {
          // 4. capture steps and call backend function
          let updateSteps = addStep(
            this.actionName.processOutput,
            actionParam.data,
            this.editAction.prevStep.stepNumber + 1,
            JSON.parse(actionParam.conditionalFiltersAppliedMap),
            this.editAction.prevStep.lastBackup,
            this.editAction.prevStep.stepAfterLastBackup,
            this.stepsAction
          );
          actionParam.updatedSteps = updateSteps;
          this.processAsOutput(actionParam);
          this.addStepModeFn();
        }
      } else {
        if (
          this.jobParent.jobStepStatus &&
          this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
        ) {
          errorEventNotify(
            "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
            1
          );
        } else {
          this.processAsOutput(actionParam);
        }
      }
    },
    processAsOutput(actionParam) {
      processOutput(actionParam)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("updateColumnHeaderAction", response._result);
            this.$emit("stepStatusUpdate", response._result.stepFinal);
            // this.jobParent = response._result.jobObject
            successEventNotify("Process as output completed successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        });
    },
    showFullDataSubsetFn() {
      // this.showSubset = false;
      let actionParam = {
        userId: this.userDetails._id,
        jobName: this.jobParent.jobName,
        collectionName: this.jobParent.inputFileName.displayFileName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };

      showFullDataSubset(actionParam)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("showFullDataSubsetAction", {
              response: response._result,
              showSubset: false
            });
            // this.$emit("stepStatusUpdate", response._result.stepFinal);
            // this.jobParent = response._result.jobObject
            successEventNotify("Action completed successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        });
    },
    hideFullDataSubsetFn() {
      // this.showSubset = true;
      let actionParam = {
        userId: this.userDetails._id,
        jobName: this.jobParent.jobName,
        collectionName: this.jobParent.inputFileName.displayFileName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        )
      };

      hideFullDataSubset(actionParam)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("showFullDataSubsetAction", {
              response: response._result,
              showSubset: true
            });
            // this.$emit("stepStatusUpdate", response._result.stepFinal);
            // this.jobParent = response._result.jobObject
            successEventNotify("Action completed successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          errorEventNotify(e.message, 1);
        });
    },
    viewAllGridAndReviewedData() {
      this.showReviewedData = true;
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        userId: this.userDetails._id
      };

      viewAllGridAndReviewed(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorFindReviewedData, 1);
          }
        })
        .catch(e => {});
    },
    viewAllGridAndValidatedData() {
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        userId: this.userDetails._id
      };
      this.showValidateddData = true;
      viewAllGridAndValidated(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorFindValidatedData, 1);
          }
        })
        .catch(e => {});
    },
    rearrangeDataColumns() {
      this.isRMVisible = true;

      this.dataColumns = [];

      if (
        this.jobParent.sortedColumns &&
        this.jobParent.sortedColumns.length > 0
      ) {
        this.dataColumns = this.jobParent.sortedColumns;
      } else {
        this.gridDataTableDisplayCols.forEach(columns => {
          this.dataColumns.push(columns.value);
        });
      }
    },
    saveColumnOrder() {
      let params = {
        dataColumns: this.dataColumns,
        jobId: this.jobParent._id,
        userId: this.userDetails._id
      };

      saveColumnOrder(params)
        .then(response => {
          if (!response._isError) {
            this.$emit("stepStatusUpdate", response._result);
            // this.$store.dispatch("refreshOnlyGridRows", dataColumns);
          } else {
            errorEventNotify("Save Column Order failed", 1);
          }
        })
        .catch(e => {
          errorEventNotify("Save Column Order failed", 1);
        });
      //this.$store.dispatch("gridDataTableDisplayColsAction", this.dataColumns);

      this.isRMVisible = false;
    }
  },
  components: {
    Multiselect,
    "data-modal": DataProcessingModal,
    "notify-action": notifyAction,
    "modal-alert": Alert,
    draggable
  },
  computed: {
    ...mapGetters({
      gridDataTableDisplayRows: "getStoreDisplayData",
      gridDataTableDisplayCols: "getStoreDatableDisplayColumns",
      conditionalFiltersAppliedMap: "getStoreConditionalFiltersAppliedMap",
      stepsAction: "getStepAction",
      editAction: "getEditAction",
      // allColumnsArray: "getStoreDatableDisplayColumns",
      allColumnsArray: "getStoreHiddenColumns",
      userDetails: "getUserObj",
      showSubset: "showSubset"
    }),
    jobGridInputFileName: function() {
      if (
        this.jobParent.inputFileName.displayFileName !== "" ||
        this.jobParent.inputFileName.displayFileName !== null
      ) {
        return this.jobParent.inputFileName.displayFileName;
      } else {
        return null;
      }
    }
  },
  props: {
    jobParent: Object
  },
  mounted() {
    document.body.addEventListener("click", this.resetLeftPanel, false);
  },
  created() {
    // this.$store.dispatch("showFullDataSubsetModeAction", {
    //           showSubset: false
    //         });
  },
  updated() {
    let colFound = this.gridDataTableDisplayCols.filter(item => {
      return item.text === "subsetData";
    });
    console.log("col -->", colFound);
    let subset = colFound.length > 0 ? false : true;
    this.$store.dispatch("showFullDataSubsetModeAction", {
      showSubset: subset
    });
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.resetLeftPanel, false);
  }
};
</script>
<style scoped>
.fliterBorder {
  border-bottom: 2px solid lightgray;
}
.floatingDeleteIcon {
  float: right;
}
.icon-atrribute-mapping {
  background-position: -217px -26px;
}
.column-drag {
  height: 30px;
  border: 1px solid lightgray;
  background: rgb(233, 233, 233);
  margin: 5px 0px;
  padding-left: 15px;
  padding-top: 3px;
  transform: translateZ(0px);
}
</style>
