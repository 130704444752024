<template>
  <div>
    <page-alert />
    <!-- Default form login -->
    <div class="col-12">
      <div class="form-section">
        <div class="resetpwd login-reg-card">
          <div class="title text-center">
            <h3>Reset Password</h3>
          </div>
          <b-form @submit="resetPassword">
            <b-form-group
              style="color: blue;"
              id="pwd"
              label="Password:"
            >
              <b-form-input
                id="password"
                type="password"
                v-model="userData.newPassword"
                required
                placeholder="Enter new password"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              style="color: blue;"
              id="confirmPwd"
              label="Confirm Password:"
            >
              <b-form-input
                id="confirmPassword"
                type="password"
                v-model="userData.confirmPassword"
                required
                placeholder="Re-Type password"
              ></b-form-input>
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              style="margin-top:8px"
            >Submit</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageAlert from "../components/PageLevelAlert.vue";
import { passwordReset } from "./../services/parentservices.js";
import { mapGetters } from "vuex";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
export default {
  components: {
    "page-alert": PageAlert
  },
  data() {
    return {
      userData: {
        email: "",
        newPassword: "",
        confirmPassword: ""
      },
      alertMsg: "",
      showErrorMsg: false
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    resetPassword(evt) {
      evt.preventDefault();
      this.userData.email = this.userDetails.email;
      if (this.userData.newPassword !== this.userData.confirmPassword) {
        errorEventNotify("Password does not match.", 1);
      } else {
        passwordReset({ userObj: this.userData, userId: this.userDetails._id })
          .then(response => {
            if (response._isError) {
              errorEventNotify(
                "There was some Technical Issue, Please try again.",
                1
              );
            } else {
              if (response._result.weakPassword) {
                errorEventNotify(response._result.msg, 1);
              } else {
                successEventNotify(response._result.msg, 1);
                setTimeout(
                  () =>
                    this.$store.dispatch("logout").then(() => {
                      this.$router.push({
                        name: "login"
                      });
                    }),
                  5000
                );
              }
            }
            //this.navigateToJobLanding();
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    showAlert(errorMsg) {
      this.alertMsg = errorMsg;
      this.showErrorMsg = true;
    }
  }
};
</script>

<style scoped>
.resetpwd {
  border: 1px solid gray;
  padding: 10px 25px;
}
</style>
