<script>
import { Bar } from "vue-chartjs";
import {
  getReportCount,
  getFilterReport
} from "./../services/parentservices.js";
import { mapGetters } from "vuex";
import { EventBus } from "../main";

export default {
  extends: Bar,
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  data: function() {
    return {
      datacollection: {
        labels: [
          "File Upload",
          "Training File Upload",
          "Algorithm Execution",
          "Job Publish"
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: []
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function(tooltipItems, data) {
              return "Count: " + tooltipItems.yLabel;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200
      }
    };
  },
  created() {
    // this.getReportCountFn();
    this.fetchFilterReport({});
  },
  mounted() {
    // this.chartData is created in the mixin
    // this.renderChart(this.datacollection, this.options);
    EventBus.$on("fetch-report-filter", payload => {
      this.fetchFilterReport(payload);
    });
  },
  methods: {
    // getReportCountFn() {
    //   getReportCount({
    //     userId: this.userDetails._id
    //   }).then(response => {
    //     console.log("resp --->", response);
    //     if (!response._isError) {
    //       this.datacollection.datasets[0].data = response._result.data;
    //       this.datacollection.labels = response._result.label;
    //       console.log("--->", this.datacollection);
    //       this.renderChart(this.datacollection, this.options);
    //     }
    //   });
    // },
    fetchFilterReport(param) {
      console.log("param --->", param);
      getFilterReport({
        userId: this.userDetails._id,
        toDate: param.toDate,
        fromDate: param.fromDate,
        selectedUser: param.selectedUser
      }).then(response => {
        console.log("resp --->", response);
        if (!response._isError) {
          this.datacollection.datasets[0].data = response._result.data;
          this.datacollection.labels = response._result.label;
          console.log("--->", this.datacollection);
          let userLastSeen;
          if (
            response._result.userLastSeen &&
            response._result.userLastSeen.length > 0
          ) {
            EventBus.$emit("fetch-user-last-seen", {
              userLastSeen: response._result.userLastSeen
            });
          }
          this.renderChart(this.datacollection, this.options);
        }
      });
    }
  }
};
</script>
