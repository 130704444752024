<template>
  <!--Fractal Wrapper-->
  <div class="fr-warapper">
    <div v-if="stepModeOn">
      <b-alert
        class="temp-solution"
        :show="dismissCountDown"
        dismissible
        fade
        variant="warning"
        @dismiss-count-down="countDownChanged"
      >This might take a while saving the edit steps that you left
        unsaved,Once done you will be notified through email. And you will be
        redirect to job landing in {{ dismissCountDown }} seconds...</b-alert>
    </div>
    <template v-if="!stepModeOn">
      <!--Header component-->
      <ams-navbar></ams-navbar>
      <main>
        <!--Fractal content area-->
        <div class="fr-content">
          <!--Main Content-->
          <div class="main-content">
            <div class="container-fluid">
              <!--Table section-->
              <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                <!--Section Heading-->
                <div class="heading d-inline-block">
                  <h3 class="mb-0">
                    <span>Job Name:</span>
                    {{ jobParent.jobNameToDisplay }}
                  </h3>
                </div>
                <!--Action Button-->
                <div>
                  <!--Job status 6 means published only back button should be there-->
                  <button
                    class="btn btn-primary"
                    @click="onDownload()"
                  >
                    <i class="icon14 icon-download"></i>Download
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="
                      showAlgoSetupModalFn(
                        jobSetupActionItem.algoToExecuteRef,
                        ''
                      )
                    "
                    v-if="jobParent.status == 1 && condition('execute')"
                  >
                    <i class="icon14 icon-execute"></i>Execute
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="
                      showAlgoSetupModalFn(
                        jobSetupActionItem.algoToExecuteRef,
                        ''
                      )
                    "
                    v-if="
                      (jobParent.status == 4 ||
                        jobParent.status == 7 ||
                        jobParent.status == 9 ||
                        jobParent.status == 11) &&
                        condition('execute')
                    "
                  >
                    <i class="icon14 icon-execute"></i>Re-Execute
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="reviewedFn($event, 9, jobParent)"
                    v-if="jobParent.status == 8"
                  >
                    <i class="icon14 icon-review-complete"></i>
                    Review Complete
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="publishJob($event, jobParent)"
                    v-if="
                      (jobParent.status == 4 ||
                        jobParent.status == 9 ||
                        jobParent.status == 11) &&
                        condition('publish')
                    "
                  >
                    <i class="icon16 icon-publish"></i>Publish
                  </button>
                  <button
                    class="btn btn-outline-secondary"
                    @click="navigateToJobLanding()"
                  >
                    <i class="icon14 icon-back"></i>
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div class="container-fluid p-0">
              <!--Table Configuration Section -->
              <div class="table-config-section d-flex justify-content-start">
                <!--Table Left Action List (Steps)-->
                <left-panel
                  v-on:stepStatusUpdate="stepStatusUpdateFn"
                  :jobParent="jobParent"
                ></left-panel>

                <!--Configuration Table-->
                <grid-comp
                  v-if="jobParent._id"
                  v-on:stepStatusUpdate="stepStatusUpdateFn"
                  :jobParent="jobParent"
                ></grid-comp>

                <algo-setup-comp
                  v-if="jobParent._id"
                  v-on:stepStatusUpdate="stepStatusUpdateFn"
                  :jobParent="jobParent"
                ></algo-setup-comp>
              </div>
            </div>
          </div>
        </div>
      </main>

      <!--JOB SETUP ACTIONS MODAL STARTS-->
      <modal-action
        v-if="jobSetupActionModal.isActive"
        v-on:closeActionModal="closeActionModalFn"
      >
        <!-- Publish Modal Component STARTS-->
        <template v-if="jobSetupActionModal.actionType === 1">
          <span slot="header">
            <h5 class="modal-title">Enter file name or table Name</h5>
          </span>
          <span slot="body">
            <modal-alert></modal-alert>
            <div>
              <input
                class="form-control search-control awesomplete mt-2"
                placeholder="File Or Database Table Name"
                type="text"
                :disabled="publishData.fileOrDbName ? true : false"
                v-model="jobParent.fileNameOrDbName"
              />
              <multiselect
                class="mul-required-field"
                placeholder="Select a publish settings"
                v-model="currentSelectionForPublishSettings"
                track-by="settingsName"
                label="settingsName"
                :options="allPublishSettings"
                :close-on-select="true"
              ></multiselect>
            </div>
          </span>
          <span slot="footer">
            <button
              @click="applyJobSetupActionModalFn($event)"
              type="button"
              class="btn btn-primary nex-modal-btn"
              aria-label="Close modal"
            >
              Done
            </button>
          </span>
        </template>
        <!-- Publish Modal Component ENDS-->
        <!--Job Execute Modal Component STARTS-->
        <template v-if="jobSetupActionModal.actionType === 2">
          <span slot="header">
            <h5 class="modal-title">Select Algorithm to Execute</h5>
          </span>
          <span slot="body">
            <modal-alert></modal-alert>
            <span v-if="algosToExecute.length > 0">
              <div
                v-for="(algo, index) in algosToExecute"
                :key="index"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  :id="'algo' + index"
                  :checked="
                    algosToExecute.length === 1
                      ? (algo.isSelectedExecute = true)
                      : ''
                  "
                  v-model="algo.isSelectedExecute"
                />
                <span class="checkmark"></span>
                <label
                  style="padding-left:30px; cursor: pointer;"
                  :for="'algo' + index"
                >{{ algo.algoName }}
                  {{ algosToExecute.length - index }}</label>
              </div>
            </span>
            <span v-if="algosToExecute.length === 0">
              <h5>No Algorithms to Execute</h5>
            </span>
          </span>
          <span slot="footer">
            <button
              @click="applyJobSetupActionModalFn($event)"
              type="button"
              class="btn btn-primary nex-modal-btn"
              aria-label="Close modal"
            >
              Submit
            </button>
          </span>
        </template>
        <!--Job Execute Modal Component ENDS-->
        <!--Finalize steps Modal Component STARTS-->
        <template v-if="jobSetupActionModal.actionType === 3">
          <span slot="header">
            <h5 class="modal-title">Select steps to retain</h5>
          </span>
          <span slot="body">
            <div>
              <multiselect
                class="mul-required-field"
                placeholder="Select step number"
                v-model="stepTillPreserve"
                track-by="stepNumber"
                label="stepNumber"
                :options="stepsAction"
                :close-on-select="true"
              ></multiselect>
            </div>
          </span>
          <span slot="footer">
            <button
              @click="showPublishModalAfterStepsFinalize($event)"
              type="button"
              class="btn btn-primary nex-modal-btn"
              aria-label="Close modal"
            >
              Done
            </button>
          </span>
        </template>
        <!--Finalize steps Modal Component ENDS-->
      </modal-action>
      <!--JOB SETUP ACTIONS MODAL ENDS-->
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import navBar from "../components/Navbar.vue";
import gridComp from "../components/Grid.vue";
import algoSetupComp from "../components/AlgoSetup.vue";
import {
  checkEmptyObject,
  checkNullObject,
  encryptData,
  convertMapToJsonString
} from "./../utilbin/common-utils.js";
import {
  fetchJobDetails,
  fetchJobDetailsFromBackup,
  sendAlgorithmLearningFile,
  executeAlgorithm,
  changeStatus,
  reviewed,
  publishJobParentService,
  isFirstPublish,
  fetchAllPublishSettings,
  deleteStepsAfterSelectedSteps,
  fetchJobDataAfterStep,
  refreshStep,
  fetchJobParent,
  updateJobStepStatus,
  saveAddSteps,
  downloadFileAsCSV
} from "./../services/parentservices.js";
import {
  ACTION_NAME,
  PAGELIST_ACTION_MODAL,
  JOBSETUP_ACTION_ITEMS,
  STEP_MODE
} from "../assets/js/constant.js";
import { publishData } from "../assets/js/PublishData";
import LeftPanel from "../components/LeftPanel";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import Alert from "../components/Alert.vue";
import modal from "./../components/Modal.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";

const jobModel = {
  _id: null,
  jobName: "",
  status: null,
  active: null,
  inputFileName: "",
  algorithm: [],
  shareAccess: [],
  owner: "",
  sentForReview: [],
  jobNameToDisplay: "",
  steps: [],
  dataSets: null,
  createdDate: null,
  lastBackup: null,
  jobId: "",
  jobStepStatus: {},
  modifiedBy: ""
};
const ALGONAME = [
  "Select",
  "Hierarchical",
  "Ontology",
  "Linear",
  "Other",
  "Classification"
];
export default {
  name: "jobSetup",
  components: {
    "ams-navbar": navBar,
    "grid-comp": gridComp,
    "algo-setup-comp": algoSetupComp,
    LeftPanel,
    "modal-alert": Alert,
    "modal-action": modal,
    Multiselect
  },
  data() {
    return {
      actionName: ACTION_NAME,
      tempLoader: false,
      jobParent: jobModel,
      algoName: ALGONAME,
      publishData: publishData,
      allPublishSettings: [],
      jobSetupActionModal: new PAGELIST_ACTION_MODAL(),
      jobSetupActionItem: JOBSETUP_ACTION_ITEMS,
      currentSelectionForPublishSettings: publishData,
      stepTillPreserve: {},
      stepModeOn: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false
    };
  },
  created() {
    this.fetchJobParentFn();
    this.fetchAllSavedPublishSettingsFn();
  },
  mounted() {
    if (this.tempLoader == false) {
      const loader = document.querySelector(".loading-gif");
      const temp = document.querySelector(".fr-warapper");
      temp.classList.add("temp");
      loader.classList.add("mystyle");
    }

    /*Toggle Steps Menu*/
    const steps = document.querySelectorAll(".toggle-steps");
    for (let i = 0; i < steps.length; i++) {
      steps[i].addEventListener("click", toggleSteps);
    }
    function toggleSteps() {
      let el = document.querySelector(".table-action-list");
      el.classList.toggle("expanded");
    }
    // Left menu toggele active
    const elMenu = document.querySelectorAll(".table-action-list > li > a");
    for (let i = 0; i < elMenu.length; i++) {
      elMenu[i].onclick = function(event) {
        if (this.parentElement.classList.contains("active")) {
          this.parentElement.classList.toggle("active");
        } else {
          var c = 0;
          while (c < elMenu.length) {
            elMenu[c++].parentElement.className = "";
          }
          event.stopPropagation();
          elMenu[i].parentElement.classList.toggle("active");
        }
      };
    }
  },
  updated() {},
  computed: {
    ...mapGetters({
      conditionalFiltersAppliedMap: "getStoreConditionalFiltersAppliedMap",
      userDetails: "getUserObj",
      editAction: "getEditAction",
      algosToExecute: "getAlgoToExecute",
      stepsAction: "getStepAction"
    })
  },
  methods: {
    condition(value) {
      if (
        this.jobParent.owner == this.userDetails._id ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        return true;
      } else {
        if (this.jobParent.shareAccess.length > 0 && this.userDetails._id) {
          return this.jobParent.shareAccess
            .find(x => x._id == this.userDetails._id)
            .privileges.includes(value);
        }
        return false;
      }
    },
    publishJobFn(event, jobObject) {
      if (event && event.isTrusted && !checkEmptyObject(jobObject)) {
        //status 6 is for published job
        const obj = {
          status: 6,
          jobData: jobObject,
          userId: this.userDetails._id
        };

        changeStatus(obj)
          .then(response => {
            this.navigateToJobLanding();
          })
          .catch(e => {})
          .finally(() => {});
      }
    },
    validateJobSetupRouteParam: param => {
      if (
        !checkEmptyObject(param) &&
        "jobId" in param &&
        param["jobId"] !== "" &&
        param["jobId"] !== undefined &&
        param["jobId"] !== null
      ) {
        return true;
      } else {
        return false;
      }
    },
    fetchJobDetailsByParam() {
      let routeParam = this.$route.params;
      routeParam.userObj = this.userDetails;
      routeParam.userId = this.userDetails._id;
      this.jobId = this.$route.params;
      routeParam.conditionalFiltersAppliedMap = convertMapToJsonString(
        this.conditionalFiltersAppliedMap
      );
      const conditionalFiltersAppliedMap = this.conditionalFiltersAppliedMap;

      if (this.validateJobSetupRouteParam(routeParam)) {
        fetchJobDetails(routeParam)
          .then(response => {
            if (response._isError == false && response._result) {
              this.jobParent = response._result.jobObject;
              this.$store
                .dispatch("algosToExecuteAction", [
                  ...response._result.jobObject.algorithm
                ])
                .then(response => {})
                .catch(e => {
                  errorEventNotify("Some problems setting the algorithm", 2);
                });
              //this.algosToExecute = [...response._result.jobObject.algorithm];
              this.$store.dispatch("loadGridData", response._result);
              this.showInputFileGrid = true;
              const loader = document.querySelector(".loading-gif");
              const temp = document.querySelector(".fr-warapper");
              loader.classList.remove("mystyle");
              temp.classList.remove("temp");
              if (this.jobParent.status > 1) {
                this.toggleAlgoSetup();
              }
            }
          })
          .catch(e => {})
          .finally(() => {});
      } else {
        this.tempLoader = true;
        this.jobParent = routeParam.jobParent;
        const loader = document.querySelector(".loading-gif");
        const temp = document.querySelector(".fr-warapper");

        loader.classList.remove("mystyle");
        temp.classList.remove("temp");
      }
    },
    navigateToJobLanding() {
      //clearing the store condtional map first
      this.$store
        .dispatch("clearJobLevelStoreDataAction")
        .then(response => {
          //then going job landing
          this.$router.push({
            name: "jobLanding"
          });
        })
        .catch(e => {})
        .finally(() => {});
      // clear editmode mutation
      this.editStepModeFn();
    },
    async LearningFileChange(fieldName, fileList, algoObject) {
      // handle file changes
      let formData = new FormData();
      let obj = {};
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append("learningfile", fileList[x], fileList[x].name);
      });
      obj["userId"] = this.userDetails._id;
      formData.append("encryptedData", await encryptData(JSON.stringify(obj)));
      this.saveLearningFile(formData, algoObject);
    },
    saveLearningFile(formData, algoObject) {
      sendAlgorithmLearningFile(formData)
        .then(response => {
          if (!response._isError) {
            algoObject.learningFileName = response._result.learningFileName;
            algoObject.learningFilePath = response._result.learningFilePath;
          }
        })
        .catch(e => {});
    },
    executeAlgorithmFn(event) {
      if (
        checkNullObject(this.algosToExecute) &&
        this.algosToExecute.length === 0
      ) {
        return false;
      }
      let isSelected = false;
      for (let index = 0; index < this.algosToExecute.length; index++) {
        const algo = this.algosToExecute[index];
        if (
          algo.isSelectedExecute == true ||
          this.algosToExecute.length === 1
        ) {
          isSelected = true;
          break;
        }
      }
      if (isSelected == false) {
        errorEventNotify("No algorithm selected for execution", 2);
        return false;
      }
      const loader = document.querySelector(".loading-gif");
      const temp = document.querySelector(".fr-warapper");
      temp.classList.add("temp");
      loader.classList.add("mystyle");

      if (event && event.isTrusted) {
        let algoIdArray = [];
        for (let index = 0; index < this.algosToExecute.length; index++)
          if (this.algosToExecute[index].isSelectedExecute === true) {
            algoIdArray.push(this.algosToExecute[index]._id);
          }
        let param = {};
        param.jobId = this.jobParent._id;
        param.algoIdArray = algoIdArray;
        param.userObj = this.userDetails;
        param.actionName = "Execute";
        param.jobName = this.jobParent.jobNameToDisplay;
        executeAlgorithm(param)
          .then(response => {
            if (response._result.length > 0) {
              this.$store
                .dispatch("executeStepAction", response)
                .then(response => {})
                .catch(e => {
                  loader.classList.remove("mystyle");
                  temp.classList.remove("temp");
                })
                .finally(() => {
                  this.jobSetupActionModal = new PAGELIST_ACTION_MODAL();
                });
            }
          })
          .catch(e => {})
          .finally(() => {
            loader.classList.remove("mystyle");
            temp.classList.remove("temp");
            this.navigateToJobLanding();
          });
      } else {
        loader.classList.remove("mystyle");
        temp.classList.remove("temp");
      }
    },
    restoreData(event) {
      let param = this.$route.params;
      param.userObj = this.userDetails;
      fetchJobDetailsFromBackup(param)
        .then(response => {
          if (response._isError == false) {
            this.fetchJobDetailsByParam();
          }
        })
        .catch(e => {});
    },
    hide(event) {
      if (event && event.isTrusted) {
        this.$refs.markReviewModalRef.hide();
      }
    },
    reviewedFn(event, status, jobData) {
      if (event && event.isTrusted) {
        let obj = {
          status: status,
          jobData: jobData,
          jobId: jobData._id,
          userObj: this.userDetails,
          userId: this.userDetails._id
        };
        reviewed(obj)
          .then(response => {
            this.navigateToJobLanding();
          })
          .catch(e => {})
          .finally(() => {});
      }
    },
    showFinalizeStepsModalFn() {
      this.jobSetupActionModal.isActive = true;
      this.jobSetupActionModal.actionType = JOBSETUP_ACTION_ITEMS.finalizeSteps;
      this.jobSetupActionModal.actionPayload = this.stepsAction;
    },
    showPublishJobModalFn(payload) {
      this.jobSetupActionModal.isActive = true;
      this.jobSetupActionModal.actionType =
        JOBSETUP_ACTION_ITEMS.publishSettingsTextOrDbName;
      this.jobSetupActionModal.actionPayload = payload;
      this.publishData.fileOrDbName = payload;
    },
    publishJob(event, jobData) {
      this.$router.push({
        name: "publish",
        params: { jobId: jobData._id }
      });
    },
    showPublishModalAfterStepsFinalize() {
      let param = {
        jobId: this.jobParent._id,
        userId: this.userDetails._id,
        lastStepNumberToConserve: this.stepTillPreserve
      };
      deleteStepsAfterSelectedSteps(param).then(resp => {
        if (!resp._isError) {
          isFirstPublish({
            id: this.jobParent._id,
            userId: this.userDetails._id
          }).then(data => {
            if (!data._isError) {
              this.showPublishJobModalFn(data._result);
            }
          });
        } else {
          errorEventNotify("Failed at removing steps", 2);
          this.jobSetupActionModal.isActive = false;
          this.jobSetupActionModal = new PAGELIST_ACTION_MODAL();
        }
      });
    },
    finalPublishJobWithFileName(fileName, settingsName) {
      let obj = {
        jobParent: this.jobParent,
        fileName: fileName,
        jobId: this.jobParent._id,
        userObj: this.userDetails,
        publishSettingsName: settingsName
      };
      publishJobParentService(obj).then(response => {
        this.navigateToJobLanding();
        this.jobSetupActionModal = new PAGELIST_ACTION_MODAL();
      });
    },
    finalPublishJob() {
      let obj = {
        jobParent: this.jobParent,
        jobId: this.jobParent._id,
        userObj: this.userDetails
      };
      publishJobParentService(obj).then(response =>
        this.navigateToJobLanding()
      );
    },
    editStepModeFn() {
      let obj = {
        editModeOn: false,
        addStepModeOn: false,
        prevStep: "",
        newStepNumber: "",
        runStepNumber: "",
        deleteStepNumber: ""
      };

      this.$store
        .dispatch("editModeAction", obj)
        .then(response => {})
        .catch(e => {});
    },
    showAlgoSetupModalFn(type, payload) {
      this.jobSetupActionModal.isActive = true;
      this.jobSetupActionModal.actionType = type;
      this.jobSetupActionModal.actionPayload = payload;
    },
    closeActionModalFn() {
      this.jobSetupActionModal = new PAGELIST_ACTION_MODAL();
      this.currentSelectionForPublishSettings = publishData;
    },
    applyJobSetupActionModalFn(event) {
      switch (this.jobSetupActionModal.actionType) {
        case 1:
          if (
            !this.jobParent.fileNameOrDbName ||
            this.currentSelectionForPublishSettings.settingsName.length === 0
          ) {
            errorEventNotify("Both fields are mandatory", 2);
          } else {
            this.finalPublishJobWithFileName(
              this.jobParent.fileNameOrDbName,
              this.currentSelectionForPublishSettings.settingsName
            );
          }

          break;
        case 2:
          this.executeAlgorithmFn(event);
          break;
        default:
      }
    },
    fetchAllSavedPublishSettingsFn() {
      fetchAllPublishSettings().then(response => {
        if (response._isError === true) {
        } else {
          this.allPublishSettings = response._result;
        }
      });
    },
    fetchJobDetailsByStep(result) {
      let routeParam = this.$route.params;
      routeParam.userId = this.userDetails._id;
      routeParam.stepNumber = result.steps.length - 1;
      this.jobId = this.$route.params;
      const conditionalFiltersAppliedMap = this.conditionalFiltersAppliedMap;
      if (result.stepNumberTillWhereToExecute !== 9999) {
        routeParam.stepNumber = result.stepNumberTillWhereToExecute;
        routeParam.stepMode = STEP_MODE.refreshMode;
      }
      if (
        this.validateJobSetupRouteParam(routeParam) &&
        conditionalFiltersAppliedMap.size === 0
      ) {
        if (
          result.jobStepStatus &&
          result.jobStepStatus.stepMode === STEP_MODE.refreshMode
        ) {
          refreshStep(routeParam)
            .then(response => {
              if (response._isError == false && !_.isEmpty(response._result)) {
                this.updateJobStepStatusFn(routeParam);
                this.jobParent = response._result.jobObject;
                this.algosToExecuteActionFn(
                  response._result.jobObject.algorithm
                );
                this.$store.dispatch("loadJobDataAction", response._result);
                this.showInputFileGrid = true;
                this.removeLoader();
                if (this.jobParent.status > 1) {
                  this.toggleAlgoSetup();
                }
              }
            })
            .catch(e => {})
            .finally(() => {});
        } else {
          routeParam.stepNumber = result.jobStepStatus.stepToRunFrom;
          routeParam.collectionName = result.inputFileName.displayFileName;
          routeParam.jobStatus = result.status;
          routeParam.jobName = result.jobName;
          for (
            let i = result.jobStepStatus.stepToRunFrom + 1;
            i < result.steps.length;
            i++
          ) {
            if (result.steps[i].actionName === ACTION_NAME.algoExecute) {
              this.removeLoader();
              this.stepModeOn = true;
              this.showAlert();
              break;
            }
          }
          saveAddSteps(routeParam)
            .then(response => {
              if (response._isError == false && !_.isEmpty(response._result)) {
                this.updateJobStepStatusFn(routeParam);
                this.jobParent = response._result.jobObject;
                this.algosToExecuteActionFn(
                  response._result.jobObject.algorithm
                );
                this.$store.dispatch("loadJobDataAction", response._result);
                this.showInputFileGrid = true;
                this.removeLoader();
                if (this.jobParent.status > 1) {
                  this.toggleAlgoSetup();
                }
                this.stepModeOn = false;
              }
            })
            .catch(e => {})
            .finally(() => {});
        }
      } else {
        this.tempLoader = true;
        this.jobParent = routeParam.jobParent;
        this.removeLoader();
      }
    },
    fetchJobParentFn() {
      let routeParam = this.$route.params;
      routeParam.userId = this.userDetails._id;
      const conditionalFiltersAppliedMap = this.conditionalFiltersAppliedMap;

      if (this.validateJobSetupRouteParam(routeParam)) {
        fetchJobParent(routeParam).then(response => {
          if (response._isError == false && !_.isEmpty(response._result)) {
            if (
              response._result.jobStepStatus &&
              response._result.jobStepStatus.stepMode
            ) {
              this.fetchJobDetailsByStep(response._result);
            } else {
              this.fetchJobDetailsByParam();
            }
          }
        });
      }
    },
    stepStatusUpdateFn(jobParent) {
      this.jobParent = jobParent;
    },
    updateJobStepStatusFn(routeParam) {
      updateJobStepStatus(routeParam)
        .then(res => {
          this.$emit("stepStatusUpdate", res._result);
        })
        .catch(e => {
          errorEventNotify("Some problems clearing the step", 1);
        });
    },
    algosToExecuteActionFn(algorithm) {
      this.$store
        .dispatch("algosToExecuteAction", [...algorithm])
        .then(response => {})
        .catch(e => {
          errorEventNotify("Some problems setting the algorithm", 2);
        });
    },
    removeLoader() {
      const loader = document.querySelector(".loading-gif");
      const temp = document.querySelector(".fr-warapper");
      loader.classList.remove("mystyle");
      temp.classList.remove("temp");
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (this.dismissCountDown === 1) {
        this.navigateToJobLanding();
      }
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    onDownload() {
      let objectParam = {
        userId: this.userDetails._id,
        jobId: this.jobParent._id,
        jobName: this.jobParent.jobName,
        collectionName: this.jobParent.inputFileName.displayFileName
      };
      downloadFileAsCSV(objectParam)
        .then(response => {
          console.log("response --->", response);
          if (!response._isError) {
            axios({
              url:
                process.env.VUE_APP_API_ENDPOINT +
                "/download/" +
                this.jobParent.jobName +
                ".csv",
              method: "GET",
              responseType: "blob"
            }).then(res => {
              console.log("rep--->", res);
              this.download(res.data);
            });
          } else {
            errorEventNotify(
              "Some problems occured while downloading the file",
              1
            );
          }
          // let csvRows = [];

          // const headers = Object.keys(dataParam[0]);
          // csvRows.push(headers.join(","));
          // for (const row of dataParam) {
          //   const values = headers.map(header => {
          //     const escaped = ("" + row[header]).replace(/"/g, '\\"');
          //     return `"${escaped}"`;
          //     // return `"${row[header]}"`
          //   });
          //   csvRows.push(values.join(","));
          // }

          // csvRows = csvRows.join("\n");
          // this.download(dataParam);
        })
        .catch(err => {});
    },
    download(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.jobParent.jobNameToDisplay + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>
<style scoped>
.temp-solution {
  text-align: center;
  position: absolute;
  z-index: 5;
  width: 100%;
  padding: 10px;
  color: white;
  background-color: #4caf50;
}

.icon-download {
  transform: rotate(180deg);
  background-position: -37px -144px;
  border-radius: 50%;
}
</style>