<template>
  <!--Fractal Wrapper-->
  <div class="fr-warapper">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <main>
      <!--Fractal content area-->
      <div class="fr-content">
        <!--Main Content-->
        <div class="main-content">
          <div class="container-fluid">
            <!--Job List Filter, Pagination and search-->
            <div class="d-flex align-middle mt-3 mb-2">
              <div class="mr-auto mt-2">
                <!--Section Heading-->
                <div class="heading d-inline-block">
                  <h2 class="mb0">Job List</h2>
                </div>
                <!--Add new-->
                <b-btn
                  v-b-modal.createNewModal
                  variant="primary"
                  class="ml-3"
                  v-if="
                    this.userDetails.roles && this.userDetails.roles[0] !== '2'
                  "
                  @click="clearOnClickOutside()"
                >
                  <i class="icon16 icon-plus"></i>New
                </b-btn>

                <!-- Modal Component -->
                <b-modal
                  ref="createNewModal"
                  id="createNewModal"
                  centered
                  title="Bootstrap-Vue"
                >
                  <div
                    slot="modal-header"
                    style="width:100%"
                  >
                    <modal-alert></modal-alert>
                    <button
                      type="button"
                      class="close"
                      @click="hideModal($event)"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h5
                      class="modal-title"
                      id="addNewJobTitle"
                    >
                      <i class="icon16 icon-plus-blue"></i> New Job
                    </h5>
                  </div>
                  <div class="modal-body create-job-body">
                    <div class="form-group floating-label">
                      <input
                        type="text"
                        class="form-control floating-input"
                        id="user-name"
                        placeholder=" "
                        v-model.trim="newJobName"
                        autocomplete="off"
                        :maxlength="maxJobLengthLimit"
                      />
                      <label
                        class="required-field"
                        for="user-name"
                      >Enter Name</label>
                    </div>
                    <multiselect
                      v-model="inputFileSelected"
                      track-by="fileName"
                      label="displayFileName"
                      :options="inputFilesList"
                      :close-on-select="true"
                      placeholder="Select a file"
                    ></multiselect>
                  </div>
                  <div slot="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="createNewJob($event, !cloneJobFlag)"
                      :disabled="disableCreate"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="hideModal($event)"
                    >
                      Cancel
                    </button>
                  </div>
                </b-modal>
                <!--Clone Job modal -->
                <b-modal
                  ref="cloneJobModal"
                  id="cloneJobModal"
                  centered
                  title="Bootstrap-Vue"
                >
                  <div
                    slot="modal-header"
                    style="width:100%"
                  >
                    <modal-alert></modal-alert>
                    <button
                      type="button"
                      class="close"
                      @click="hideModal($event)"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h5
                      class="modal-title"
                      id="cloneJobTitle"
                    >
                      <i class="icon16 icon-plus-blue"></i> Clone Job
                    </h5>
                  </div>
                  <div class="modal-body create-job-body">
                    <div class="form-group floating-label">
                      <input
                        type="text"
                        class="form-control floating-input"
                        id="user-name"
                        placeholder=" "
                        v-model="newJobName"
                        autocomplete="off"
                        :maxlength="maxJobLengthLimit"
                      />
                      <label
                        class="required-field"
                        for="user-name"
                      >Enter Name</label>
                    </div>
                    <multiselect
                      v-model="inputFileSelected"
                      track-by="fileName"
                      label="displayFileName"
                      :options="inputFilesList"
                      :close-on-select="true"
                      placeholder="Select a file"
                    ></multiselect>
                    <div style="margin-top: 30px;">
                      <input
                        type="checkbox"
                        value="cloneAlgo"
                        v-model="cloneAlgoFlag"
                        style="margin:5px;"
                      />
                      Clone Algo Setup
                    </div>
                  </div>
                  <div slot="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="createNewJob($event, cloneJobFlag)"
                      :disabled="disableCreate"
                    >
                      Create
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="hideModal($event)"
                    >
                      Cancel
                    </button>
                  </div>
                </b-modal>
                <!-- Send review email modal -->
                <b-modal
                  id="sent-review-email-modal"
                  ref="sentReviewModalRef"
                  centered
                  title="Bootstrap-Vue"
                >
                  <div
                    slot="modal-header"
                    style="width:100%"
                  >
                    <button
                      type="button"
                      class="close"
                      @click="hide($event)"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h5 class="modal-title">
                      <i class="icon16 icon-plus-blue"></i>Send for review
                    </h5>
                  </div>

                  <div class="modal-body">
                    <div>
                      <b-alert
                        :show="showErrorMsg"
                        dismissible
                        variant="danger"
                        @dismissed="showErrorMsg = false"
                      >{{ this.alertMsg }}</b-alert>
                    </div>
                    <label
                      style="color: gray;"
                      for="send-to"
                    >Send To</label>
                    <multiselect
                      v-model="selected"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search or add an email"
                      label="email"
                      track-by="email"
                      :options="sentForReviewEmailIds"
                      :multiple="true"
                      :taggable="true"
                      @tag="addTag"
                    ></multiselect>
                    <br />
                    <b-form-textarea
                      id="textarea1"
                      v-model="emailBody"
                      placeholder="Email body"
                      :rows="3"
                      :max-rows="6"
                    ></b-form-textarea>
                  </div>
                  <div slot="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="
                        sendForReviewFn($event, selected, emailBody, 8, row)
                      "
                    >
                      <i class="icon14 icon-save"></i> Send
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="hide($event)"
                      data-dismiss="modal"
                    >
                      <i class="icon14 icon-close"></i> Cancel
                    </button>
                  </div>
                </b-modal>

                <!-- Schedule modal -->
                <b-modal
                  ref="scheduleModal"
                  id="scheduleModal"
                  centered
                  title="Bootstrap-Vue"
                >
                  <div
                    slot="modal-header"
                    style="width:100%"
                  >
                    <button
                      type="button"
                      class="close"
                      @click="hideScheduleModal($event)"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h5 class="modal-title">
                      <i class="icon16 icon-plus-blue"></i> Schedule Job
                    </h5>
                  </div>
                  <div class="modal-body">
                    <modal-alert></modal-alert>
                    <schedule-job v-on:scheduleObj="scheduleObjFn" />
                  </div>
                  <div slot="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="scheduleJobModal(row.item)"
                      :disabled="
                        inputRepo[0].value ===
                          'No settings for repository found'
                      "
                    >
                      Schedule
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="hideScheduleModal($event)"
                    >
                      Cancel
                    </button>
                  </div>
                </b-modal>
              </div>
              <div class="ml-auto">
                <!--Search, Filter Pagination-->
                <ul class="filter-list">
                  <li>
                    <div class="search-item">
                      <div class="awesomplete">
                        <input
                          class="form-control search-control awesomplete"
                          v-model="jobListSearch"
                          @input="debounceSearch($event)"
                          data-list="#jobList"
                          placeholder="Search Here"
                          autocomplete="off"
                          aria-autocomplete="list"
                          :maxlength="maxJobLengthLimit"
                        />
                        <ul hidden></ul>
                        <span
                          class="visually-hidden"
                          role="status"
                          aria-live="assertive"
                          aria-relevant="additions"
                        ></span>
                      </div>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-search"></i>
                      </a>
                      <datalist id="jobList">
                        <option
                          v-for="item in mainJobLandingData"
                          :key="item._id"
                          :value="item.jobName"
                        ></option>
                      </datalist>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown show filter-item">
                      <a
                        class="collapsed"
                        v-b-toggle.collapseFilter
                      >
                        <i class="icon24 icon-filter"></i>
                      </a>
                    </div>
                  </li>
                  <li>
                    <vue-lpage
                      :data="filteredData"
                      :page="currentPage"
                      :results-per-page="perPage"
                    >
                      <div
                        slot-scope="{
                          subset,
                          totalPages,
                          isFirstPage,
                          isLastPage
                        }"
                        class="pagination-item"
                      >
                        <input
                          class="form-control"
                          :min="1"
                          :max="Math.ceil(totalRows / perPage)"
                          type="text"
                          v-model="currentPage"
                        />
                        of {{ Math.ceil(totalRows / perPage) }}
                        <a
                          href="javascript:void(0);"
                          :disabled="isFirstPage"
                          @click="
                            currentPage > 1 ? currentPage-- : (currentPage = 1)
                          "
                        >
                          <i class="icon24 icon-leftArrow"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          :disabled="isLastPage"
                          @click="
                            currentPage < Math.ceil(totalRows / perPage)
                              ? currentPage++
                              : (currentPage = Math.ceil(totalRows / perPage))
                          "
                        >
                          <i class="icon24 icon-rightArrow"></i>
                        </a>
                      </div>
                    </vue-lpage>
                  </li>
                </ul>
              </div>
            </div>

            <!--Filter collapse STARTS (Removed class collapse from b-collapse)-->
            <b-collapse id="collapseFilter">
              <b-card
                no-body
                class="filter-card"
              >
                <div class="card-header d-flex align-middle justify-content-between">
                  <p>Filters</p>
                  <div class>
                    <button
                      class="btn btn-primary"
                      @click="applyFilterPanelFn($event)"
                    >
                      Apply
                    </button>
                    <button
                      class="btn btn-secondary"
                      @click="clearFilterPanelFn($event)"
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group floating-label">
                        <input
                          type="text"
                          v-model="jobFilterPanelModel.jobName"
                          class="form-control floating-input"
                          id="user-name"
                          placeholder=" "
                          autocomplete="off"
                          :maxlength="maxJobLengthLimit"
                        />
                        <label for="user-name">Job Name</label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group floating-label">
                        <input
                          type="text"
                          v-model="jobFilterPanelModel.owner"
                          class="form-control floating-input"
                          id="user-name"
                          placeholder=" "
                          autocomplete="off"
                          maxlength="30"
                        />
                        <label for="user-name">Owner</label>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group floating-label">
                        <multiselect
                          v-model="jobFilterPanelModel.status"
                          track-by="value"
                          label="value"
                          :options="statusList"
                          :close-on-select="true"
                          placeholder="Status"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender from"
                              id="fromDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">From</label>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group floating-label mb-0">
                            <input
                              type="text"
                              class="form-control floating-input calender to"
                              id="toDate"
                              placeholder=" "
                            />
                            <i class="icon24 icon-calendar"></i>
                            <label for="user-name">To</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>
            <!--Filter collapse ENDS-->
            <!--Joblist Table STARTS (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
            <b-table
              class="joblist-table"
              show-empty
              stacked="md"
              @row-clicked="rowClick($event)"
              selectable
              :items="filteredData"
              :fields="fields"
              :current-page="Number.parseInt(currentPage)"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
            >
              <template>
                <div
                  class="checkbox"
                  @click="$event.stopPropagation()"
                >
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </div>
              </template>
              <template v-slot:cell(jobName)="row">{{ row.value }}</template>
              <template v-slot:cell(dataSets)="row">{{ row.value }}</template>
              <template v-slot:cell(createdDate)="row">{{
                row.value | formatDate
              }}</template>
              <template v-slot:cell(owner)="row">{{ row.value }}</template>
              <template v-slot:cell(status)="row">
                <span :class="getStatusColClass(row.value.key)">{{
                  row.value.value
                }}</span>
              </template>
              <template v-slot:cell(actions)="row">
                <ul
                  class="action-list"
                  @click="$event.stopPropagation()"
                >
                  <!---draft action template-->
                  <template v-if="row.item.status.key === 1">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>

                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!---./draft action template-->
                  <!--Queued action template-->
                  <template v-if="row.item.status.key === 2">
                    <li>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-pause"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="progrss-bar"
                      >
                        <span style=" width: 90%;"></span>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./Queued action template-->
                  <!--in-progress action template-->
                  <template v-if="row.item.status.key === 3">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="progrss-bar"
                      >
                        <span style=" width: 80%;"></span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-pause"></i>
                      </a>
                    </li>
                  </template>
                  <!--./in-progress action template-->
                  <!--validation pending action template-->
                  <template v-if="row.item.status.key === 4">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-modal.sent-review-email-modal
                        @click="sendReviewEmail(row.item)"
                      >
                        <i class="icon24 icon-send"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./validation pending action template-->
                  <!--send for review action template-->
                  <template v-if="row.item.status.key === 5">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-refresh"></i>
                      </a>
                    </li>
                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./send for review action template-->
                  <!--failed action template-->
                  <template v-if="row.item.status.key === 6">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('execute', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="automateSteps(row.item)"
                      >
                        <i class="icon24 icon-play"></i>
                      </a>
                      <!-- <a href="javascript:void(0);">
                        <i class="icon24 icon-refresh"></i>
                      </a>-->
                    </li>
                    <li v-if="condition('publish', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showScheduleModal(row.item)"
                      >
                        <i class="icon24 icon-calendar-published"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./failed action template-->
                  <!--completed action template-->
                  <template v-if="row.item.status.key === 7">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <!-- <li>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-send"></i>
                      </a>
                    </li> -->
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./completed action template-->
                  <!--under review action template-->
                  <template v-if="row.item.status.key === 8">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-modal.sent-review-email-modal
                        @click="sendReviewEmail(row.item)"
                      >
                        <i class="icon24 icon-send"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./under review action template-->
                  <!--review completed action template-->
                  <template v-if="row.item.status.key === 9">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('share', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="navigateToShareAccess(row.item)"
                      >
                        <i class="icon24 icon-share"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        v-b-modal.sent-review-email-modal
                        @click="sendReviewEmail(row.item)"
                      >
                        <i class="icon24 icon-send"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./review completed action template-->
                  <!--publishing action template-->
                  <template v-if="row.item.status.key === 10">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="progrss-bar"
                      >
                        <span style=" width: 80%;"></span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <i class="icon24 icon-pause"></i>
                      </a>
                    </li>
                  </template>
                  <!--./publishing action template-->
                  <!--publishing failed action template-->
                  <template v-if="row.item.status.key === 11">
                    <li>
                      <a
                        href="javascript:void(0);"
                        @click="cloneJob(row.item)"
                      >
                        <i class="icon24 icon-clone-job"></i>
                      </a>
                    </li>
                    <li v-if="condition('execute', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="automateSteps(row.item)"
                      >
                        <i class="icon24 icon-play"></i>
                      </a>
                    </li>
                    <li v-if="condition('publish', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showScheduleModal(row.item)"
                      >
                        <i class="icon24 icon-calendar-published"></i>
                      </a>
                    </li>
                    <li v-if="condition('delete', row.item)">
                      <a
                        href="javascript:void(0);"
                        @click="showJobDeleteModalFn(row.item)"
                      >
                        <i
                          class="icon24 icon-delete"
                          title="Delete job"
                          v-b-tooltip.hover
                        ></i>
                      </a>
                    </li>
                  </template>
                  <!--./publishing failed action template-->
                </ul>
              </template>
            </b-table>
            <!--Joblist Table ENDS-->
          </div>
        </div>
      </div>
    </main>
    <!--JOB LIST ACTIONS MODAL STARTS-->
    <modal-action
      v-if="jobListActionModal.isActive"
      v-on:closeActionModal="closeActionModalFn"
    >
      <template v-if="jobListActionModal.actionType === 1">
        <span slot="header">
          <h5 class="modal-title">Delete Job</h5>
        </span>
        <span slot="body">Are you sure you want to delete the job?</span>
        <span slot="footer">
          <button
            @click="applyJobListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            YES
          </button>
        </span>
      </template>
    </modal-action>
    <!--JOB LIST ACTIONS MODAL ENDS-->
  </div>
</template>
<script>
import navBar from "../components/Navbar.vue";
import ScheduleJob from "../components/ScheduleJob.vue";
import {
  checkEmptyObject,
  checkEmptyString,
  testForbiddenChars,
} from "./../utilbin/common-utils.js";
import notifyAction from "./../components/NotifyAction.vue";
import modal from "./../components/Modal.vue";
import {
  fetchUploadedFilesList,
  fetchAllJobList,
  deleteJob,
  automateStep,
  saveNewJobData,
  sentForReview,
  scheduleJob,
  getAllScheduleOptions,
  fetchReviewUsersList,
  fetchJobStatus,
} from "./../services/parentservices.js";
import _ from "lodash";
import VueLpage from "vue-lpage";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import router from "../router";
import TinyDatePicker from "tiny-date-picker";
import { EventBus } from "../main.js";
import Alert from "../components/Alert.vue";
import {
  successEventNotify,
  errorEventNotify,
} from "./../assets/js/notifyAction.js";
import {
  PAGELIST_ACTION_ITEMS,
  PAGELIST_ACTION_MODAL,
  SCHEDULEOBJECT,
} from "./../assets/js/constant.js";
import io from "socket.io-client";

class FILTER_PANEL_MODEL {
  constructor() {
    this.jobName = "";
    this.owner = "";
    this.fromDate = "";
    this.toDate = "";
    this.status = null;
  }
}

const validateFilterPanelValues = (param) => {
  if (
    param.jobName ||
    param.owner ||
    param.status ||
    (param.toDate && param.fromDate)
  ) {
    return true;
  } else {
    errorEventNotify(
      "Atleast one filter or in date filter both ranges are required!",
      1
    );
    return false;
  }
};

export default {
  name: "jobLanding",
  components: {
    "ams-navbar": navBar,
    VueLpage,
    Multiselect,
    "modal-alert": Alert,
    "modal-action": modal,
    "schedule-job": ScheduleJob,
  },
  data() {
    return {
      cloneJobFlag: true,
      oldJobName: "",
      testJobName: null,
      newJobName: null,
      alertMsg: "",
      showErrorMsg: false,
      inputFilesList: [],
      inputFileSelected: "",
      mainJobLandingData: [],
      filteredData: [],
      jobListSearch: "",
      jobFilterPanelModel: new FILTER_PANEL_MODEL(),
      scheduleObject: new SCHEDULEOBJECT(),
      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "jobNameToDisplay",
          label: "Job Name",
          sortable: true,
        },
        {
          key: "dataSets",
          label: "Records",
          sortable: true,
          // class: "text-right"
        },
        {
          key: "createdDate",
          label: "Created Date",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "owner", label: "Owner" },
        { key: "status", label: "Status" },
        { key: "actions", label: "" },
      ],
      currentPage: 1,
      perPage: 6,
      totalRows: 0,
      refreshFlag: "",
      pageOptions: [5, 10, 15],
      maxJobLengthLimit: 40,
      sortBy: "createdDate",
      sortDesc: true,
      sortDirection: "desc",
      file: null,
      filter: null,
      uploadFieldName: "jobInputUploadedFiles",
      inputFileName: "",
      emailBody: "",
      selected: [], // Must be an array reference!
      sentForReviewEmailIds: [],
      row: "",
      statusList: [
        // { name: "Draft", value: 1 },
        // { name: "Queued", value: 2 },
        // { name: "In progress", value: 3 },
        // { name: "Validation pending", value: 4 },
        // { name: "Sent for review", value: 5 }
      ],
      scheduleList: [{ value: "Day" }, { value: "Week" }, { value: "Month" }],
      weekList: [
        { value: "Monday" },
        { value: "Tuesday" },
        { value: "Wednesday" },
        { value: "Thursday" },
        { value: "Friday" },
        { value: "Saturday" },
        { value: "Sunday" },
      ],
      inputRepo: [
        { value: "No settings for repository found", $isDisabled: true },
      ],
      scheduleJobData: "",
      scheduleTypes: [
        { text: "On File Recieved", value: "auto" },
        { text: "Scheduled Based", value: "timeBased" },
      ],
      cloneAlgoFlag: false,
      jobListActionModal: new PAGELIST_ACTION_MODAL(),
      socket: io(process.env.VUE_APP_SOCKET_ENDPOINT, {
        path: process.env.VUE_APP_SOCKET_PATH,
        transports: ["polling"],
      }),
      disableCreate: false,
    };
  },
  created() {
    if (this.userDetails.isFirstTimeLogin) {
      this.$router.push({
        name: "resetPassword",
        params: { email: this.userDetails.email },
      });
    }

    this.fetchJobLandingList();
    this.fetchUsers();
    this.fetchAllJobStatus();
    this.debounceSearch = _.debounce(this.applySearch, 1000);
    this.$store
      .dispatch("clearJobLevelStoreDataAction")
      .then((response) => {
        //then going job landing
        // this.$router.push({
        //   name: "jobLanding"
        // });
      })
      .catch((e) => {})
      .finally(() => {});
    // this.refreshFlag = setInterval(
    //   function() {
    //     this.fetchJobLandingList();
    //   }.bind(this),
    //   10000
    // );
  },
  beforeMount() {},
  mounted() {
    this.fetchInputFilesList();
    this.fetchScheduleSettings();
    TinyDatePicker(document.querySelector(".calender.from"));
    TinyDatePicker(document.querySelector(".calender.to"));
    this.socket.on("REFRESH_JOB_LANDING_PAGE", (data) => {
      this.fetchJobLandingList();
    });
  },
  updated() {},
  beforeDestroy() {
    // clearInterval(this.refreshFlag);
  },
  methods: {
    cloneJob(jobObj) {
      this.inputFileSelected = {
        fileName: jobObj.inputFileName.displayFileName,
      };
      this.oldJobName = jobObj.jobName;
      this.$refs.cloneJobModal.show();
    },
    condition(value, jobObject) {
      if (
        jobObject.ownerId == this.userDetails._id ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        return true;
      } else {
        if (jobObject.shareAccess.length > 0 && this.userDetails._id) {
          return jobObject.shareAccess
            .find((x) => x._id == this.userDetails._id)
            .privileges.includes(value);
        }
        return false;
      }
    },
    navigateToShareAccess(item) {
      if (!checkEmptyObject(item)) {
        this.$router.push({
          name: "shareAccess",
          params: { jobId: item._id },
        });
      }
    },
    fetchUsers() {
      fetchReviewUsersList({
        jobId: this.row._id,
        userId: this.userDetails._id,
      }).then((response) => {
        if (!response._isError && response._result.length > 0) {
          this.sentForReviewEmailIds = response._result;
        }
      });
    },
    fetchInputFilesList() {
      fetchUploadedFilesList({
        userId: this.userDetails._id,
        userRole: this.userDetails.roles[0],
      }).then((response) => {
        if (!response._isError && response._result.length > 0) {
          this.inputFilesList = response._result;
        }
      });
    },
    debounceSearch() {},
    applySearch(event) {
      this.filteredData = [];
      this.filteredData = this.mainJobLandingData.filter((obj) => {
        return (
          obj["jobName"]
            .toLowerCase()
            .includes(this.jobListSearch.toLowerCase()) ||
          obj["owner"].toLowerCase().includes(this.jobListSearch.toLowerCase())
        );
      });
      this.totalRows = this.filteredData.length;
    },
    fetchJobLandingList() {
      fetchAllJobList({
        userObj: this.userDetails,
        userId: this.userDetails._id,
      })
        .then((response) => {
          console.log("job data -->", response);
          if (!response._isError) {
            this.mainJobLandingData = response._result;
            if (this.jobListSearch !== "") {
              this.filteredData = this.mainJobLandingData.filter((obj) => {
                return (
                  obj["jobName"]
                    .toLowerCase()
                    .includes(this.jobListSearch.toLowerCase()) ||
                  obj["owner"]
                    .toLowerCase()
                    .includes(this.jobListSearch.toLowerCase())
                );
              });
            } else {
              this.filteredData = this.mainJobLandingData;
            }
            console.log("filter -->", this.filteredData);
            this.totalRows = this.filteredData.length;
          }
        })
        .catch((e) => {});
    },
    deleteJobLandingList(jobObject) {
      deleteJob({
        jobId: jobObject._id,
        userObj: this.userDetails,
      })
        .then((response) => {
          if (!response._isError) {
            this.fetchJobLandingList();
            successEventNotify("Job deleted successfully", 1);
          } else {
            throw new Error(response._message);
          }
        })
        .catch((e) => {
          errorEventNotify(e.message, 1);
        })
        .finally(() => {
          this.jobListActionModal = new PAGELIST_ACTION_MODAL();
        });
    },
    automateSteps(jobObject) {
      let actionParam = {
        jobId: jobObject._id,
        jobName: jobObject.jobName,
        collectionName: jobObject.inputFileName.displayFileName,
        jobStatus: jobObject.status.key,
        userObj: this.userDetails,
      };

      if (jobObject.stepNumberTillWhereToExecute !== 9999) {
        actionParam.stepEnd = jobObject.stepNumberTillWhereToExecute;
        actionParam.stepMode = "Refresh";
      }
      automateStep(actionParam)
        .then((response) => {
          // this.fetchJobLandingList();
        })
        .catch((e) => {});
    },
    applyFilterPanelFn(event) {
      //1.setting the dates from Tiny date picker
      this.jobFilterPanelModel.fromDate = document.querySelector(
        ".calender.from"
      ).value;
      this.jobFilterPanelModel.toDate = document.querySelector(
        ".calender.to"
      ).value;

      //1.Validating filter options and dates (both dates incase date selected)
      if (!this.jobFilterPanelModel.status) {
        this.jobFilterPanelModel.status = {};
      }
      let validationFlag =
        this.jobFilterPanelModel.jobName ||
        this.jobFilterPanelModel.owner ||
        !checkEmptyObject(this.jobFilterPanelModel.status) ||
        this.jobFilterPanelModel.fromDate ||
        this.jobFilterPanelModel.toDate;

      if (
        validationFlag &&
        !this.jobFilterPanelModel.fromDate &&
        !this.jobFilterPanelModel.toDate
      ) {
        validationFlag = true;
      } else {
        if (
          this.jobFilterPanelModel.fromDate &&
          this.jobFilterPanelModel.toDate
        ) {
          validationFlag = true;
          successEventNotify("success", 1);
        } else {
          errorEventNotify(
            "Atleast one option or both dates are required for filter!",
            1
          );
          validationFlag = false;
          return;
        }
      }

      //3.Filtering jobs
      if (validationFlag) {
        let filteredJobs = [...this.mainJobLandingData];
        if (!checkEmptyString(this.jobFilterPanelModel.jobName)) {
          filteredJobs = filteredJobs.filter((job) => {
            return job["jobNameToDisplay"]
              .toLowerCase()
              .includes(this.jobFilterPanelModel.jobName.toLowerCase());
          });
        }
        if (!checkEmptyString(this.jobFilterPanelModel.owner)) {
          filteredJobs = filteredJobs.filter((job) => {
            return job["owner"]
              .toLowerCase()
              .includes(this.jobFilterPanelModel.owner.toLowerCase());
          });
        }
        if (!checkEmptyObject(this.jobFilterPanelModel["status"])) {
          filteredJobs = filteredJobs.filter((job) => {
            return (
              !checkEmptyObject(job["status"]) &&
              job["status"]["key"] === this.jobFilterPanelModel["status"]["key"]
            );
          });
        }

        if (
          !checkEmptyString(this.jobFilterPanelModel.fromDate) &&
          !checkEmptyString(this.jobFilterPanelModel.toDate)
        ) {
          filteredJobs = filteredJobs.filter((job) => {
            return (
              new Date(job["createdDate"]) >=
                new Date(this.jobFilterPanelModel.fromDate) &&
              new Date(job["createdDate"]) <=
                new Date(this.jobFilterPanelModel.toDate)
            );
          });
        }
        //4.Setting filtered jobs and pagination length
        this.filteredData = [];
        this.filteredData = filteredJobs;
        this.totalRows = filteredJobs.length;
      }
    },
    clearFilterPanelFn(event) {
      this.jobFilterPanelModel = new FILTER_PANEL_MODEL();
      this.filteredData = this.totalRows = null;
      document.querySelector(".calender.from").value = "";
      document.querySelector(".calender.to").value = "";
      this.filteredData = this.mainJobLandingData;
      this.totalRows = this.filteredData.length;
    },
    rowClick(event, rowData) {
      if (event != null && !event._id != undefined) {
        if (
          event.status.key !== 3 &&
          event.status.key !== 10 &&
          event.status.key !== 6 &&
          event.status.key !== 2
        ) {
          this.$router.push({
            name: "jobSetupEdit",
            params: {
              jobId: event._id,
              status: event.status.key,
            },
          });
        }
      }
    },
    getStatusColClass(statusId) {
      switch (statusId) {
        case 1:
          return "pending";
          break;
        case 2:
          return "queued";
          break;
        case 3:
        case 10:
          return "in-progress";
          break;
        case 4:
          return "pending";
          break;
        case 5:
          return "in-review";
          break;
        case 6:
          return "Completed";
          break;
        case 7:
        case 11:
          return "failed";
          break;
        default:
          return null;
          break;
      }
    },
    hideModal(event) {
      this.inputFileSelected = "";
      this.newJobName = null;
      this.cloneAlgoFlag = false;
      this.$refs.createNewModal.hide();
      this.$refs.cloneJobModal.hide();
      this.showErrorMsg = false;
    },
    scheduleJobModal() {
      if (
        this.scheduleObject &&
        this.scheduleObject.displayFileName &&
        this.scheduleObject.inputRepoValue &&
        this.scheduleObject.fileName
      ) {
        let fileExists = this.inputFilesList.filter((file) => {
          return (
            !this.scheduleJobData.schedule &&
            file.displayFileName === this.scheduleObject.displayFileName
          );
        });
        let fileNameValidation = /([a-zA-Z0-9\s_\\.\-\(\):])+(.csv|.xlsx|.xls)$/i;
        if (!fileNameValidation.test(this.scheduleObject.fileName)) {
          errorEventNotify(
            "Only files with extension .xlsx .xls .csv supported",
            2
          );
        } else if (
          fileExists.length > 0 ||
          testForbiddenChars(this.scheduleObject.displayFileName)
        ) {
          errorEventNotify(
            "Display file name exist or may contain a special character, choose different name",
            2
          );
        } else {
          EventBus.$emit("save-schedule-setting-event", {
            jobId: this.scheduleJobData._id,
            jobParent: this.scheduleJobData,
          });
          this.hideScheduleModal(event);
        }
      } else {
        errorEventNotify("All fields are mandatory", 2);
      }
    },
    hideScheduleModal(event) {
      this.$refs.scheduleModal.hide();
    },
    showScheduleModal(data) {
      this.$refs.scheduleModal.show();
      this.scheduleJobData = data;
      if (data.schedule) {
        this.scheduleObject.inputRepoValue = { value: data.schedule.inputType };
        this.scheduleObject.fileName = data.schedule.fileName;
        this.scheduleObject.displayFileName = data.schedule.displayFileName;
        this.scheduleObject.scheduleType =
          data.schedule.scheduleType == "auto" ? "auto" : "timeBased";
        this.scheduleObject.time = data.schedule.time;
        if (data.schedule.scheduleType === "daily") {
          this.scheduleObject.scheduleValue = {
            value: "Day",
          };
        }

        if (data.schedule.scheduleType === "week") {
          let week;
          switch (data.schedule.scheduleValue) {
            case 0:
              week = "Sunday";
              break;
            case 1:
              week = "Monday";
              break;
            case 2:
              week = "Tuesday";
              break;
            case 3:
              week = "Wednesday";
              break;
            case 4:
              week = "Thursday";
              break;
            case 5:
              week = "Friday";
              break;
            case 6:
              week = "Saturday";
              break;
            default:
              break;
          }
          this.scheduleObject.scheduleValue = {
            value: "Week",
          };
          this.scheduleObject.weekValue = { value: week };
        }

        if (data.schedule.scheduleType === "month") {
          this.scheduleObject.scheduleValue = {
            value: "Month",
          };
          this.scheduleObject.monthValue = data.schedule.scheduleValue;
        }
      }
    },
    createNewJob(event, cloneJobFlag) {
      this.disableCreate = true;
      if (
        event &&
        event.isTrusted &&
        this.newJobName &&
        this.inputFileSelected
      ) {
        const forbiddenCharactersForJobName = /[\/\\."$*<>:|?_()%+=@!#^&{}';,-]/;

        if (forbiddenCharactersForJobName.test(this.newJobName)) {
          errorEventNotify(
            `Job Name cannot contain [/\."$*<>:|?_()%+=@!#^&{}';,-]`,
            2
          );
          this.disableCreate = false;
          return;
        } else if (!isNaN(this.newJobName.charAt(0))) {
          errorEventNotify("Job Name cannot start with numeric value", 2);
          this.disableCreate = false;
          return;
        } else {
          saveNewJobData({
            inputFileName: {
              originalFileName: this.inputFileSelected.fileName.replace(
                / /g,
                "_"
              ),
              displayFileName: this.inputFileSelected.displayFileName.replace(
                / /g,
                "_"
              ),
              type: this.inputFileSelected.type,
            },
            jobName: this.newJobName.replace(/ /g, "_"),
            jobNameToDisplay: this.newJobName,
            cloneAlgoFlag: this.cloneAlgoFlag,
            oldJobName: this.oldJobName,
            owner: this.userDetails._id,
            modifiedBy: this.userDetails._id,
            cloneJobFlag: cloneJobFlag,
            userId: this.userDetails._id,
            createdBy: this.userDetails._id,
          })
            .then((response) => {
              if (!response._isError) {
                this.hideModal(event);
                this.disableCreate = false;
                this.fetchJobLandingList();
                if (cloneJobFlag) {
                  successEventNotify("Job cloned successfully", 1);
                } else {
                  // successEventNotify("New job created successfully", 1);
                }
              } else {
                //this.$refs.createNewModal.hide();
                errorEventNotify(
                  "New job creation failed::" + response._message,
                  2
                );
                this.disableCreate = false;
                return;
              }
            })
            .catch((e) => {});
        }
      } else {
        errorEventNotify("Both fields are mandatory", 2);
      }
    },
    showAlert(errorMsg) {
      this.alertMsg = errorMsg;
      this.showErrorMsg = true;
    },
    hide(event) {
      if (event && event.isTrusted) {
        this.$refs.sentReviewModalRef.hide();
        this.selected = "";
        this.emailBody = "";
        this.showErrorMsg = false;
      }
    },
    sendReviewEmail(data) {
      this.row = data;
      this.selected = "";
      this.emailBody = "";
      this.fetchUsers();
    },
    sendForReviewFn(event, emailIds, emailBody, status, jobData) {
      if (event && event.isTrusted) {
        let ownerName = jobData.owner;
        jobData.owner = jobData.ownerId;
        let obj = {
          status: status,
          emailIds: emailIds,
          emailBody: emailBody,
          jobData: jobData,
          jobId: jobData._id,
          userObj: this.userDetails,
          userId: this.userDetails._id,
          ownerName: ownerName,
        };
        console.log("shahs;", obj);
        if (emailIds !== "") {
          sentForReview(obj)
            .then((response) => {
              if (response._isError) {
                this.showAlert(response._message);
              } else {
                this.$refs.sentReviewModalRef.hide();
                this.fetchJobLandingList();
              }
            })
            .catch((e) => {})
            .finally(() => {});
        } else {
          this.showAlert("Please enter email id to send for review");
        }
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    fetchScheduleSettings() {
      getAllScheduleOptions({ userId: this.userDetails._id }).then(
        (response) => {
          if (response.data._result.length !== 0)
            this.inputRepo = response.data._result;
        }
      );
    },
    clearOnClickOutside() {
      this.newJobName = "";
      this.inputFileSelected = "";
    },
    closeActionModalFn() {
      this.jobListActionModal = new PAGELIST_ACTION_MODAL();
    },
    showJobDeleteModalFn(job) {
      this.jobListActionModal.isActive = true;
      this.jobListActionModal.actionType = PAGELIST_ACTION_ITEMS.deletion;
      this.jobListActionModal.actionPayload = job;
    },
    applyJobListActionFn(event) {
      switch (this.jobListActionModal.actionType) {
        case 1:
          this.deleteJobLandingList(this.jobListActionModal.actionPayload);
          break;
        case 2:
          break;
        default:
      }
    },
    fetchAllJobStatus() {
      fetchJobStatus({
        userId: this.userDetails._id,
      }).then((response) => {
        this.statusList = response._result;
        // response._result.forEach(x => {});
      });
    },
    scheduleObjFn(scheduleObject) {
      this.scheduleObject = scheduleObject;
    },
  },
  filters: {
    toDate: function (str) {
      return new Date(str);
    },
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj",
    }),
  },
};
</script>
