<template>
  <div id="globalVariantsParentDiv">
    <!--Header component-->
    <ams-navbar></ams-navbar>
    <div class="fr-warapper">
      <main>
        <!--Fractal content area-->
        <div class="fr-content global-replace">
          <ul class="global-replace-menu">
            <h3> Global Operations </h3>
            <li
              class="list-item"
              id="globalReplace"
            >
              <h4 class="list-name">Global Replacement</h4>
              <div class="list-content">
                <div>
                  <span class="required-field">Selected Text:</span>
                  <input
                    type="text"
                    class="form-control"
                    v-bind:readonly="true"
                    v-bind:value="selectedText"
                  >
                </div>
                <div>
                  <span class="required-field">Replace With:</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model.lazy="replacementText"
                  >
                </div>
                <!--Change wrt new replace options STARTS-->
                <div>
                  <span class="required-field">Entire Word:</span>
                  <input
                    id="boundary-replace-flag"
                    class="form-control"
                    style="vertical-align: top;"
                    type="checkbox"
                    v-model.lazy="boundaryReplaceFlag"
                  >
                </div>
                <!--Change wrt new replace options ENDS-->
              </div>
            </li>
            <div class="algo-footer">
              <div class="d-flex align-items-center justify-content-center">
                <button
                  class="btn btn-primary btn-link"
                  @click="suggestionBox($event)"
                >
                  <i class="icon14 icon-save"></i>Apply
                </button>
                <button
                  class="btn btn-outline-secondary"
                  @click="resetSuggestionPanelFn($event)"
                >
                  <i class="icon14 icon-close"></i>Cancel
                </button>
              </div>
            </div>
          </ul>
          <!--Main Content-->
          <div class="main-content">
            <div class="container-fluid">
              <!--Job List Filter, Pagination and search-->
              <!-- <?php include("includes/global-replace/filter-pagination-search.php");?> -->
              <div class="d-flex align-middle mt-2 mb-2">
                <div class="mr-auto d-flex align-items-center">
                  <!--Section Heading-->
                  <div class="heading d-inline-block">
                    <h3 class="mb0">Classification Training Data</h3>
                  </div>
                </div>
                <div class="ml-auto">
                  <!--Search, Filter Pagination-->
                  <ul class="filter-list">
                    <li>
                      <button
                        class="btn btn-primary"
                        @click="saveClassificationData($event)"
                      >
                        <i class="icon14 icon-save"></i>Save
                      </button>
                      <button
                        class="btn btn-outline-secondary"
                        @click="trainingListing()"
                      >
                        <i class="icon14 icon-close"></i> Cancel
                      </button>
                    </li>
                    <li>
                      <div class="dropdown show filter-item">
                        <a
                          class="collapsed"
                          v-b-toggle.collapseFilter
                        >
                          <i class="icon24 icon-filter"></i>
                        </a>
                      </div>
                    </li>
                    <li>
                      <vue-lpage
                        :data="globalTagsArray"
                        :page="currentPage"
                        :results-per-page="perPage"
                      >
                        <div
                          slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
                          class="pagination-item"
                        >
                          <input
                            class="form-control"
                            :min="1"
                            :max="Math.ceil(totalRows/perPage)"
                            type="text"
                            v-model="currentPage"
                          >
                          of {{ Math.ceil(totalRows/perPage) }}
                          <a
                            href="javascript:void(0);"
                            :disabled="isFirstPage"
                            @click="(currentPage>1) ? currentPage-- : currentPage=1"
                          >
                            <i class="icon24 icon-leftArrow"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            :disabled="isLastPage"
                            @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
                          >
                            <i class="icon24 icon-rightArrow"></i>
                          </a>
                        </div>
                      </vue-lpage>

                      <!-- <div class="pagination-item item-cell">
                        <input class="form-control" value="1"> of 100
                        <a href="javascript:void(0);"><i class="icon24 icon-leftArrow"></i></a>
                        <a href="javascript:void(0);"><i class="icon24 icon-rightArrow"></i></a>
                      </div>-->
                    </li>
                  </ul>
                </div>
              </div>

              <!--Filter collapse -->
              <!-- <?php include("includes/global-replace/filter-colapse.php");?> -->
              <b-collapse id="collapseFilter">
                <b-card
                  no-body
                  class="filter-card"
                >
                  <div class="card-header item-cell">
                    <p>Filters</p>
                    <div class>
                      <button
                        class="btn btn-primary"
                        @click="applyConditionalFilterFn($event)"
                      >Apply</button>
                      <button
                        class="btn btn-secondary"
                        @click="clearFilterFn()"
                      >Clear</button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group mb-0">
                          <label for="user-name">Column Name</label>
                          <!-- <select v-model="filterOptions.selectedCondition" class="form-control choices-js" value="">
                            <option v-for=" (option,idx) in filterOptions.conditions" :label="option.text" :value="option" :key="idx"></option>
                          </select>-->
                          <multiselect
                            style="bottom:9px"
                            v-model="selectedColumns"
                            placeholder="Search or add a column"
                            track-by="value"
                            label="value"
                            :options="columnData"
                            :multiple="true"
                            @tag="addTag"
                            :close-on-select="false"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group mb-0">
                          <label for="user-name">Status</label>
                          <!-- <select v-model="filterOptions.selectedCondition" class="form-control choices-js" value="">
                            <option v-for=" (option,idx) in filterOptions.conditions" :label="option.text" :value="option" :key="idx"></option>
                          </select>-->
                          <multiselect
                            v-model="filterOptions.selectedCondition"
                            track-by="text"
                            style="margin-top: -10px;"
                            label="text"
                            :options="filterOptions.conditions"
                            :close-on-select="true"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              <strong>{{ option.text }}</strong>
                            </template>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div
                          class="search-item form-group mb-0"
                          style="width:100%"
                        >
                          <label for="user-name">Search</label>
                          <input
                            ref="applyFilter"
                            type="text"
                            v-model="filterOptions.conditionalInput"
                            class="form-control search-control awesomplete"
                          >
                          <!-- <a href="javascript:void(0);"><i class="icon24 icon-search"></i></a> -->
                          <!-- <datalist id="jobList">
                          </datalist>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-collapse>

              <!--Configuration Table (Number.parseInt is a temp fix coz of b-table bug that will get sorted in next major release)-->
              <div class="table-record">
                <b-table
                  class="export-import-table"
                  :fields="fields"
                  :items="globalTagsArray"
                  :per-page="perPage"
                  :current-page="Number.parseInt(currentPage)"
                >
                  <template
                    v-for="(val,index) in columnData"
                    slot-scope="data"
                    :slot="val.value"
                  >
                    <input
                      :disabled="index === 0"
                      @blur="(!(data.item.isDirty) || !('isDirty' in data.item)) ? dirtyTriggerOnRowInput(data.item) : {}"
                      :key="val.value"
                      :id="data.item._id"
                      :text="data.item[val.value]"
                      v-model="data.item[val.value]"
                      @mouseup="textSelected($event, val, data.item)"
                      class="form-control table-input"
                    >
                  </template>
                </b-table>
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  fetchClassificationTrainingData,
  saveClassificationDataManipulations,
  conditionalClassificationStringFilter,
  columnReplaceActionTraining
} from "../services/parentservices.js";
import navBar from "../components/Navbar.vue";
import VueLpage from "vue-lpage";
import Multiselect from "vue-multiselect";
import isEmpty from "lodash/isEmpty";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";

export default {
  name: "classificationTraining",
  components: {
    "ams-navbar": navBar,
    VueLpage,
    Multiselect
  },
  data() {
    return {
      fields: [],
      selectedColumns: [],
      // hierarchies: [],
      // hierarchy: "",
      // newhierarchy: "",
      collectionName: "",
      isDirtyRows: new Set(),
      currentPage: 1,
      perPage: 18,
      totalRows: 0,
      pageOptions: [5, 10, 15],
      // maxVariantLength: 3,
      globalTagsArray: [],
      columnData: [],
      suggestionCard: "",
      selectedText: "",
      replacementText: "",
      boundaryReplaceFlag: true,
      selectedTextColumn: [],
      filterOptions: {
        conditions: [
          {
            text: "Equal To",
            value: {
              id: 1,
              text: "EQUALS"
            }
          },
          {
            text: "Is not equal to",
            value: {
              id: 2,
              text: "IS_NOT_EQUAL_TO"
            }
          },
          {
            text: "Contains",
            value: {
              id: 3,
              text: "CONTAINS"
            }
          },
          {
            text: "Does not contain",
            value: {
              id: 4,
              text: "DOES_NOT_CONTAIN"
            }
          },
          {
            text: "Starts with",
            value: {
              id: 5,
              text: "STARTS_WITH"
            }
          },
          {
            text: "Does not start with",
            value: {
              id: 6,
              text: "DOES_NOT_START_WITH"
            }
          },
          {
            text: "Ends with",
            value: {
              id: 7,
              text: "ENDS_WITH"
            }
          },
          {
            text: "Does not end with",
            value: {
              id: 8,
              text: "DOES_NOT_END_WITH"
            }
          }
        ],
        selectedCondition: {
          text: "Contains",
          value: {
            id: 3,
            text: "CONTAINS"
          }
        },
        conditionalInput: null
      }
    };
  },
  created() {
    this.collectionName = this.$route.params.filename;
    this.fetchClassificationData();
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    dirtyTriggerOnRowInput(data) {
      data.isDirty = true;
      this.isDirtyRows.add(data);
    },
    saveClassificationData(event) {
      let param = {
        data: JSON.stringify([...this.isDirtyRows]),
        collectionName: this.collectionName,
        userId: this.userDetails._id
      };
      saveClassificationDataManipulations(param)
        .then(response => {
          if (!response._isError) {
            this.isDirtyRows = new Set();
            successEventNotify("Saved successfully", 1);
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened applying filter!", 1);
        });
    },
    applyConditionalFilterFn(event) {
      let columns = [];
      for (let i = 0; i < this.selectedColumns.length; i++) {
        columns.push(this.selectedColumns[i].value);
      }
      if (
        event.isTrusted &&
        columns.length > 0 &&
        this.filterOptions.conditionalInput &&
        !_.isEmpty(this.filterOptions.selectedCondition)
      ) {
        let actionParam = {
          searchInput: this.filterOptions.conditionalInput,
          criteria: this.filterOptions.selectedCondition.value,
          collectionName: this.collectionName,
          columns: columns,
          userId: this.userDetails._id
        };

        conditionalClassificationStringFilter(actionParam)
          .then(response => {
            if (!response._isError) {
              successEventNotify("Filter applied successfully", 1);
              let dataPackage = response._result;
              this.globalTagsArray = dataPackage;
              this.totalRows = dataPackage.length;
            } else {
              errorEventNotify("Some error happened applying filter!", 1);
              return;
            }
          })
          .catch(e => {
            errorEventNotify("Some error happened applying filter!", 1);
          });
      } else {
        errorEventNotify("All parameters are required", 1);
      }
    },
    clearFilterFn() {
      this.filterOptions.conditionalInput = null;
      this.selectedColumns = [];
      // this.$refs.applyFilter.value = "";
      this.fetchClassificationData();
    },
    fetchClassificationData() {
      let param = {
        collectionName: this.collectionName,
        userId: this.userDetails._id
      };
      fetchClassificationTrainingData(param)
        .then(response => {
          //console.log("result -->", response);
          if (!response._isError) {
            let dataPackage = response._result;
            this.currentPage = 1;
            this.globalTagsArray = dataPackage.rowsData;
            this.columnData = dataPackage.columnData;
            this.totalRows = this.globalTagsArray.length;
            for (let i = 0; i < dataPackage.columnData.length; i++) {
              this.fields.push({ key: dataPackage.columnData[i].value });
            }
          } else {
            return;
          }
        })
        .catch(e => {});
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    suggestionBox(event) {
      if (this.selectedText) {
        this.columnReplaceTriggeredFn(event);
      } else {
        errorEventNotify("Selected text cannot be empty.", 1);
      }
    },
    columnReplaceTriggeredFn(event) {
      // console.log("text --?", this.selectedTextColumn);
      if (this.selectedTextColumn.length > 0) {
        // 1. set actionParam object for backend
        let selectedText = this.selectedText;
        let regexTestExp = null;
        const input = selectedText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

        if (!this.toDoBoundaryReplace) {
          regexTestExp = new RegExp(
            "\\B" +
              input +
              "|" +
              input +
              "\\B" +
              "|" +
              "\\b" +
              input +
              "|" +
              input +
              "\\b",
            "gi"
          );
        } else {
          regexTestExp = new RegExp(
            "\\B" + input + "\\B" + "|" + "\\b" + input + "\\b",
            "gi"
          );
        }

        this.globalTagsArray.filter(item => {
          this.selectedTextColumn.forEach(col => {
            if (regexTestExp.test(item[col.value])) {
              const replaceValue = item[col.value].replace(
                regexTestExp,
                this.replacementText
              );
              item[col.value] = replaceValue;
              this.dirtyTriggerOnRowInput(item);
            }
          });
        });
        successEventNotify("Filter applied successfully", 1);
        this.resetSuggestionPanelFn(event);
        // console.log("found --->", this.globalTagsArray);
        // this.gridColumnReplaceTriggeredFn(event, actionParam);
      } else {
        errorEventNotify("Please re-select the text.", 1);
      }
    },
    resetSuggestionPanelFn(event) {
      this.selectedText = "";
      this.replacementText = "";
      this.boundaryReplaceFlag = false;
      this.selectedTextColumn = [];
    },
    textSelected(event, column, rowData) {
      if (
        window.getSelection().toString() &&
        window.getSelection().toString().length > 0 &&
        !isEmpty(rowData) &&
        !isEmpty(column)
      ) {
        this.selectedText = window.getSelection().toString();
        this.selectedTextColumn.push(column);
        // this.toggleSuggestionBox();
      }

      // this works currently on year column
      // to make this more generic
      // add additional logic here as follows
      // search for selected text in rowdata and get matching key name
      // that should be the index/key of the header column
      // convert selectedText to object
      // add selected text and header key in the replaceTriggeredFn payload
    },
    trainingListing() {
      this.$router.push({
        name: "trainingFileUpload"
      });
    }
  }
};
</script>
<style scoped>
.fr-warapper .fr-content.global-replace ul.global-replace-menu {
  width: 290px;
}
.fr-warapper .fr-content.global-replace .main-content {
  padding-left: 290px;
}
.global-replace-menu h3,
.global-replace-menu h4 {
  border: 1px solid lightgray;
  padding: 8px;
  background-color: #e9e9e9;
}
.list-item {
  margin: 20px 10px;
  border: 2px solid lightgray;
}
#boundary-replace-flag {
  margin-left: 13px;
  width: 8%;
}
.list-content {
  padding: 4px 13px;
}
.list-content div {
  padding-bottom: 4px;
}
/* .fr-warapper .fr-content.global-replace .main-content {
  padding-left: 35px;
} */
.table-record .export-import-table td input.form-control.table-input {
  width: 100%;
}
/* .card.card-suggestion .card-header {
  margin-top: 10px;
}
.card.card-suggestion {
  height: 15px;
}
.table-record {
  height: 385px;
}
.with-suggestion .table-record {
  height: calc(100vh - 143px);
  position: relative;
} */
/* .table-record {
  height: calc(100vh - 245px);
} */
</style>
