import { ACTION_NAME } from "../assets/js/constant";

export const addStep = (
  actionName,
  actionParam,
  stepNumber,
  conditionalFiltersAppliedMap,
  lastBackup,
  stepAfterLastBackup,
  stepsData
) => {
  let stepObj = {};
  stepObj.actionName = actionName;
  stepObj.actionParam = actionParam;
  stepObj.stepNumber = stepNumber;
  stepObj.conditionalFiltersAppliedMap = conditionalFiltersAppliedMap;
  stepObj.lastBackup = lastBackup;
  stepObj.stepAfterLastBackup = stepAfterLastBackup;
  stepObj.dependentColumn = columnDependency(
    actionName,
    actionParam,
    conditionalFiltersAppliedMap
  );
  stepObj.toTakeBackUpAtThisStep = false;
  let updateSteps = [...stepsData];

  updateSteps.splice(stepNumber, 0, stepObj);

  for (let i = 0; i < updateSteps.length; i++) {
    updateSteps[i].stepNumber = i;
  }

  return updateSteps;
};

export const conditionalFiltersDependenciesCheck = (
  conditionalFiltersAppliedMap,
  dependentColumn
) => {
  let flag = false;
  if (conditionalFiltersAppliedMap.length > 0) {
    for (let j = 0; j < conditionalFiltersAppliedMap.length; j++) {
      if (conditionalFiltersAppliedMap[j][0] === dependentColumn) {
        flag = true;
      }
    }
  }
  return flag;
};

export const matchDependentColumns = (stepData, uniqDependentColumnArray) => {
  let found = stepData.dependentColumn.inputColumn.some(r =>
    uniqDependentColumnArray.includes(r)
  );
  return found;
};

export const validateDependencies = (
  actionName,
  dependentColumn,
  prevStep,
  stepsAction
) => {
  let dependentColumnArray = [];
  if (dependentColumn instanceof Array) {
    for (let i = prevStep.stepNumber + 1; i < stepsAction.length; i++) {
      dependentColumn.forEach(column => {
        if (stepsAction[i].dependentColumn.inputColumn.includes(column)) {
          dependentColumnArray.push(column);
          dependentColumnArray.push(
            stepsAction[i].dependentColumn.outputColumn
          );
        }
      });
    }
  } else {
    for (let i = prevStep.stepNumber + 1; i < stepsAction.length; i++) {
      if (
        stepsAction[i].dependentColumn.inputColumn.includes(dependentColumn)
      ) {
        dependentColumnArray.push(dependentColumn);
        dependentColumnArray.push(stepsAction[i].dependentColumn.outputColumn);
      }
    }
  }
  const flatDependentColumnArray = _.flattenDeep(dependentColumnArray);
  const uniqDependentColumnArray = _.uniq(flatDependentColumnArray);

  let arr = [];
  let found;
  let removeArr = [...stepsAction];
  if (
    actionName === ACTION_NAME.delete ||
    actionName === ACTION_NAME.bulkColumnDelete
  ) {
    for (let i = prevStep.stepNumber + 1; i < stepsAction.length; i++) {
      found = matchDependentColumns(stepsAction[i], uniqDependentColumnArray);
      if (found) {
        arr.push(stepsAction[i]);
      }
    }
  }
  for (var i = 0; i < arr.length; i++) {
    for (var j = 0; j < removeArr.length; j++) {
      if (arr[i].stepNumber === removeArr[j].stepNumber) {
        removeArr.splice(j, 1);
        break;
      }
    }
  }
  return { arr: arr, removeArr: removeArr };
};

export const validateDeleteStepDependencies = (currentStep, stepsAction) => {
  let dependentOutputColumn = currentStep.dependentColumn.outputColumn;
  let presentStep = [];
  presentStep.push(currentStep);
  let found;
  let finalStepArray = JSON.parse(JSON.stringify(stepsAction));

  for (let i = currentStep.stepNumber + 1; i < stepsAction.length; i++) {
    found = matchDependentColumns(stepsAction[i], dependentOutputColumn);
    if (found) {
      presentStep.push(stepsAction[i]);
    }
  }
  for (let i = 0; i < presentStep.length; i++) {
    for (let j = 0; j < finalStepArray.length; j++) {
      if (presentStep[i].stepNumber === finalStepArray[j].stepNumber) {
        finalStepArray.splice(j, 1);
        break;
      }
    }
  }
  for (let i = 0; i < finalStepArray.length; i++) {
    finalStepArray[i].stepNumber = i;
  }
  return { presentStep: presentStep, finalStepArray: finalStepArray };
};

export const columnDependency = (
  action,
  actionParam,
  conditionalFiltersAppliedMap
) => {
  let dependentColumn = [];
  let inputColumn = [];
  let outputColumn = [];
  let columnFilters;
  switch (action) {
    case ACTION_NAME.delete:
      inputColumn.push(actionParam.value);
      outputColumn.push(actionParam.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.blank:
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      inputColumn.push(actionParam.newColumnName);
      outputColumn.push(actionParam.newColumnName);
      break;
    case ACTION_NAME.bulkColumnDelete:
      inputColumn = actionParam.data;
      outputColumn = actionParam.data;
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.copy:
      inputColumn.push(actionParam.targetColumn.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      outputColumn.push(actionParam.newColumnName);
      break;
    case ACTION_NAME.insert:
    case ACTION_NAME.update:
      inputColumn.push(actionParam.columnName.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      outputColumn.push(actionParam.columnName.value);
      break;
    case ACTION_NAME.globalReplace:
      inputColumn.push(actionParam.selectedTextColumn.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      outputColumn.push(actionParam.selectedTextColumn.value);
      break;
    case ACTION_NAME.split:
      outputColumn.push(actionParam.columnOne);
      outputColumn.push(actionParam.columnTwo);
      inputColumn.push(actionParam.targetColumn.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.merge:
      outputColumn.push(actionParam.mergingColumnName);
      inputColumn.push(actionParam.targetColumn.value);
      inputColumn.push(actionParam.mergeWithColumn.value);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.removeDuplicates:
      inputColumn = actionParam.data;
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.arithmaticOperation:
      outputColumn.push(actionParam.column);
      inputColumn.push(actionParam.column1);
      inputColumn.push(actionParam.column2);
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    case ACTION_NAME.processOutput:
      if (conditionalFiltersAppliedMap.length > 0) {
        columnFilters = conditionalFiltersAppliedMapDependentColumn(
          conditionalFiltersAppliedMap
        );
        inputColumn.push(columnFilters);
      }
      break;
    default:
      break;
  }
  const flatInputColumn = [].concat(...inputColumn);
  const flatOutputColumn = [].concat(...outputColumn);

  dependentColumn.push({
    inputColumn: flatInputColumn,
    outputColumn: flatOutputColumn
  });
  return dependentColumn[0];
};

export const conditionalFiltersAppliedMapDependentColumn = conditionalFiltersAppliedMap => {
  let conditionalFiltersDependentColumn = [];
  for (let j = 0; j < conditionalFiltersAppliedMap.length; j++) {
    conditionalFiltersDependentColumn.push(conditionalFiltersAppliedMap[j][0]);
  }
  return conditionalFiltersDependentColumn;
};
