<template>
  <!--Fractal Wrapper-->
  <div class="fra-loginWrapper">
    <div class="container-fuild p-0">
      <div class="row no-gutters">
        <!--illustration section-->
        <div class="col-12 col-lg-7">
          <div class="illustration-section">
            <div class="bg-image">
              <img
                src="./../assets/images/login-bg.jpg"
                class="img-fluid"
                alt="login Illustration"
              >
            </div>
            <div class="illustration-card">
              <img
                src="./../assets/images/fractal-white-logo.svg"
                class="img-fluid"
                alt="login Illustration"
              >
              <!-- <h4>Cognitive, self-learning algorithms for speed and scale of enterprise decisions</h4> -->
            </div>
          </div>
        </div>
        <!-- Modal section -->
        <b-modal
          id="forgotPassword"
          ref="forgotPassword"
          centered
          title="Forget Password"
        >
          <div class="modal-body">
            <b-alert
              :show="showErrorMsg"
              dismissible
              variant="danger"
              @dismissed="showErrorMsg=false"
            >
              {{this.alertMsg}}
            </b-alert>
            <b-form-group
              id="email_id"
              label="Email address:"
            >
              <b-form-input
                id="email_id"
                type="email"
                v-model="email"
                placeholder="Enter email"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div slot="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="forgetPwd($event,email)"
            ><i class="icon14 icon-save"></i> Submit </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="hide($event)"
              data-dismiss="modal"
            ><i class="icon14 icon-close"></i> Cancel </button>
          </div>
        </b-modal>
        <!--form section-->
        <div class="col-12 col-lg-5">
          <div class="form-section">
            <div class="login-reg-card">
              <div class="title text-center">
                <h3>Sign In</h3>
              </div>
              <form @submit.prevent="loginFn">
                <div v-if="selectedMode.includes('database')">
                  <div class="form-group floating-label">
                    <input
                      type="email"
                      class="form-control floating-input user"
                      v-model="username"
                      id="user-name"
                      placeholder=" "
                      required
                    >
                    <i class="icon24 icon-user"></i>
                    <span class="highlight"></span>
                    <label for="user-name">User Name</label>
                  </div>

                  <div class="form-group floating-label">
                    <input
                      type="password"
                      class="form-control floating-input password"
                      v-model="password"
                      id="password"
                      placeholder=" "
                      required
                    >
                    <i class="icon24 icon-password"></i>
                    <span class="highlight"></span>
                    <label for="password">Password</label>
                  </div>

                  <div class="text-center">
                    <button
                      style="margin-bottom:15px"
                      class="btn btn-primary btn-lg btn-block"
                    >Sign in</button>
                  </div>
                </div>
                <div class="text-center">
                  <b v-if="selectedMode.includes('database') && selectedMode.includes('saml')"> OR </b></div>
                <div v-if="selectedMode.includes('saml')">
                  <a
                    style="
                        margin-top:15px"
                    :href='microsoftEndpoint'
                    class="btn btn-primary btn-lg btn-block"
                  >Sign in Microsoft</a>
                </div>

                <div class="text-center">
                  <p>{{this.$store.state.message}}</p>
                </div>
                <b-alert
                  :show="showMsg"
                  dismissible
                  variant="success"
                  @dismissed="showMsg=false"
                >
                  {{this.alertMsg}}
                </b-alert>
                <div
                  class="text-center forgot-password"
                  v-if="selectedMode.includes('database')"
                >
                  <a
                    href="javascript:void(0);"
                    v-b-modal.forgotPassword
                    @click="clear()"
                    class=""
                  >Forgot Password ?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {
  forgetPassword,
  fetchLoginModes
} from "./../services/parentservices.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      username: "",
      password: "",
      email: "",
      alertMsg: "",
      showErrorMsg: false,
      showMsg: false,
      microsoftEndpoint: process.env.VUE_APP_MICROSOFT_LOGIN_ENDPOINT,
      loginModes: [],
      selectedMode: []
    };
  },
  created() {
    this.getLoginModes();
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  methods: {
    loginFn() {
      const actionPayload = {
        username: this.username,
        password: this.password
      };
      this.$store
        .dispatch("loginAction", actionPayload)
        .then(response => {
          if (this.$store.getters.isLoggedIn) {
            this.$router.push({
              name: "jobLanding"
            });
          } else {
            this.$router.push("/");
          }
        })
        .catch();
    },
    forgetPwd(event, email) {
      if (event && event.isTrusted) {
        if (email) {
          forgetPassword({ email: email, userId: this.userDetails._id })
            .then(response => {
              if (response._isError) {
                this.showAlert(
                  "There was some Technical Issue, Please try again."
                );
              } else {
                this.showAlert(response._result.msg);
                if (response._result.success) {
                  this.showPopup(response._result.msg);
                  this.$refs.forgotPassword.hide();
                  this.email = "";
                }
              }
              //this.navigateToJobLanding();
            })
            .catch(e => {})
            .finally(() => {});
        } else {
          this.showAlert("Please enter your email id .");
        }
      }
    },
    hide(event) {
      if (event && event.isTrusted) {
        this.$refs.forgotPassword.hide();
        this.email = "";
        this.showErrorMsg = false;
      }
    },
    clear() {
      this.email = "";
      this.showErrorMsg = false;
    },
    showAlert(errorMsg) {
      this.alertMsg = errorMsg;
      this.showErrorMsg = true;
    },
    showPopup(errorMsg) {
      this.alertMsg = errorMsg;
      this.showMsg = true;
    },
    getLoginModes() {
      fetchLoginModes()
        .then(response => {
          if (response._isError) {
            this.showAlert(
              "There was some technical issue while fetching login option."
            );
          } else {
            this.loginModes = response._result;
            for (let modeObj of this.loginModes) {
              if (modeObj.selected) {
                this.selectedMode.push(modeObj.mode);
                // break;
              }
            }
          }
        })
        .catch(e => {
          this.showAlert(
            "There was some technical issue while fetching login option."
          );
        })
        .finally(() => {});
    }
  }
};
</script>

<style scoped>
p {
  padding-top: 25px;
  color: red;
  font-size: 20px;
}
</style>
