<template>
  <div>
    <b-modal
      ref="changejobstatusmodal"
      id="changeJobStatusModalID"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <modal-alert></modal-alert>
        <button
          type="button"
          class="close"
          @click="onStatusModalCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5
          class="modal-title"
          id="changeJobStatusTitle"
        >Change job status for - {{jobToChangeStatus.jobNameToDisplay}}</h5>
      </div>
      <div class="modal-body create-job-body">
        <div class="form-group floating-label">
          <multiselect
            v-model="jobStatusSelected"
            track-by="key"
            label="value"
            :options="allJobStatuses"
            :close-on-select="true"
            :multiple="false"
          ></multiselect>
        </div>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="changeJobStatus()"
        >Change</button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="onStatusModalCancel()"
        >Cancel</button>
      </div>
    </b-modal>
    <b-modal
      ref="changeownershipmodal"
      id="changeOwnerShipModalID"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <modal-alert></modal-alert>
        <button
          type="button"
          class="close"
          @click="onCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5
          class="modal-title"
          id="changeJobOwnershipTitle"
        >Change Ownership for - {{jobToChangeOwnership.jobNameToDisplay}}</h5>
      </div>
      <div class="modal-body create-job-body">
        <div class="form-group floating-label">
          <multiselect
            placeholder="Select user"
            v-model="destinationUserId"
            track-by="email"
            label="email"
            :options="jobOwnershipChangeUserList"
            :close-on-select="true"
            :multiple="false"
          ></multiselect>
        </div>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="changeOwnershipForTheJob()"
        >Change</button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="onCancel()"
        >Cancel</button>
      </div>
    </b-modal>
    <b-modal
      ref="bulkChangeownershipmodal"
      id="bulkChangeOwnerShipModalID"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <modal-alert></modal-alert>
        <button
          type="button"
          class="close"
          @click="onCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h5
          class="modal-title"
          id="changeJobOwnershipTitle"
        >Bulk Job Ownership Change</h5>
      </div>
      <div class="modal-body create-job-body">
        <div class="form-group floating-label">
          <multiselect
            placeholder="Select user"
            v-model="destinationUserId"
            track-by="email"
            label="email"
            :options="jobOwnershipChangeUserList"
            :close-on-select="true"
            :multiple="false"
          ></multiselect>
        </div>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="bulkChangeOwnershipForTheJob()"
        >Change</button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="onCancel()"
        >Cancel</button>
      </div>
    </b-modal>
    <div style="float:right; display:inline-flex;">
      <button
        v-if="toShowFloatingButton"
        type="button"
        class="btn btn-primary"
        style="margin: 2px 10px;"
        @click="showBulkChangeOwnerShipModal()"
      >Change Ownership</button>
      <vue-lpage
        :data="allJobsList"
        :page="currentPage"
        :results-per-page="perPage"
      >
        <div
          slot-scope="{subset, totalPages, isFirstPage, isLastPage}"
          class="pagination-item"
        >
          <input
            class="form-control"
            :min="1"
            :max="Math.ceil(totalRows/perPage)"
            type="text"
            v-model="currentPage"
          />
          of {{ Math.ceil(totalRows/perPage) }}
          <a
            href="javascript:void(0);"
            :disabled="isFirstPage"
            @click="(currentPage>1) ? currentPage-- : currentPage=1"
          >
            <i class="icon24 icon-leftArrow"></i>
          </a>
          <a
            href="javascript:void(0);"
            :disabled="isLastPage"
            @click="(currentPage< Math.ceil(totalRows/perPage) ) ? currentPage++ : currentPage=Math.ceil(totalRows/perPage)"
          >
            <i class="icon24 icon-rightArrow"></i>
          </a>
        </div>
      </vue-lpage>
    </div>
    <b-table
      class="joblist-table"
      show-empty
      stacked="md"
      selectable
      :items="allJobsList"
      :fields="fields"
      :current-page="Number.parseInt(currentPage)"
      :per-page="perPage"
    >
      <template v-slot:cell(isSelectedInUI)="data">
        <div class="checkbox">
          <input
            type="checkbox"
            :id="'checkbox'+data.index"
            :name="'checkbox'+data.index"
            @change="handleClick()"
            v-model="data.item.isSelectedInUI"
          />
          <span class="checkmark"></span>
        </div>
      </template>
      <template v-slot:cell(jobName)="row">{{row.value}}</template>
      <template v-slot:cell(status)="row">
        <span :class="getStatusColClass(row.value.key)">{{row.value.value}}</span>
      </template>
      <template v-slot:cell(actions)="row">
        <ul
          class="action-list"
          @click="$event.stopPropagation();"
        >
          <template>
            <li v-if="row.item.status.key !== 3 && !toShowFloatingButton">
              <a
                href="javascript:void(0);"
                @click="showChangeOwnerShipModal(row.item)"
              >
                <i
                  class="icon24 user-icon"
                  title="Change Ownership"
                  v-b-tooltip.hover
                ></i>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                @click="showJobStatusChangeMOdal(row.item)"
              >
                <i
                  class="icon24 change-status-icon"
                  title="Change Status"
                  v-b-tooltip.hover
                ></i>
              </a>
            </li>
          </template>
        </ul>
      </template>
    </b-table>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import VueLpage from "vue-lpage";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import {
  fetchAllJobList,
  changeOwnership,
  fetchJobStatus,
  updateJobStatusForcefully,
  fetchUserForOwnershipChange
} from "./../services/parentservices.js";

import Alert from "../components/Alert.vue";

export default {
  components: {
    VueLpage,
    "modal-alert": Alert,
    Multiselect
  },
  data() {
    return {
      allJobsList: [],
      fields: [
        {
          key: "isSelectedInUI",
          label: ""
        },
        {
          key: "jobNameToDisplay",
          label: "Job Name",
          sortable: true
        },
        { key: "owner", label: "Owner" },
        { key: "status", label: "Status" },
        { key: "actions", label: "" }
      ],
      currentPage: 1,
      perPage: 6,
      totalRows: 0,
      refreshFlag: "",
      pageOptions: [5, 10, 15],
      jobToChangeOwnership: "",
      destinationUserId: "",
      allJobStatuses: [],
      jobStatusSelected: -999,
      jobToChangeStatus: "",
      jobList: [],
      toShowFloatingButton: false,
      jobOwnershipChangeUserList: []
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  created: function() {
    this.getAllJobs();
    this.fetchAllJobStatus();
    this.getUsersList();
  },
  methods: {
    getAllJobs() {
      fetchAllJobList({
        userObj: this.userDetails,
        userId: this.userDetails._id
      })
        .then(response => {
          if (!response._isError) {
            this.allJobsList = response._result;
            this.totalRows = this.allJobsList.length;
            // console.log(this.allJobsList[0]);
            for (let i = 0; i < this.allJobsList.length; i++) {
              this.jobList[i] = {
                _id: this.allJobsList[i]._id,
                isSelected: false
              };
            }
          }
        })
        .catch(e => {});
    },
    getStatusColClass(statusId) {
      switch (statusId) {
        case 1:
          return "pending";
          break;
        case 2:
          return "queued";
          break;
        case 3:
          return "in-progress";
          break;
        case 4:
          return "pending";
          break;
        case 5:
          return "in-review";
          break;
        case 6:
          return "Completed";
          break;
        case 7:
          return "failed";
          break;
        default:
          return null;
          break;
      }
    },
    showChangeOwnerShipModal(param) {
      this.$refs.changeownershipmodal.show();
      this.jobToChangeOwnership = param;
    },
    showJobStatusChangeMOdal(param) {
      this.$refs.changejobstatusmodal.show();
      this.jobToChangeStatus = param;
    },
    showBulkChangeOwnerShipModal() {
      // console.log(this.jobOwnershipChangeUserList);
      this.$refs.bulkChangeownershipmodal.show();
    },
    onCancel() {
      this.destinationUserId = "";
      this.$refs.changeownershipmodal.hide();
      this.$refs.bulkChangeownershipmodal.hide();
    },
    onStatusModalCancel() {
      this.$refs.changejobstatusmodal.hide();
    },
    changeOwnershipForTheJob() {
      let jobIdToSendToBackend = [];
      let ownerIdsToSendToBackend = [];
      ownerIdsToSendToBackend.push(this.jobToChangeOwnership.ownerId);
      jobIdToSendToBackend.push(this.jobToChangeOwnership._id);
      let obj = {
        userId: this.userDetails._id,
        ownerId: ownerIdsToSendToBackend,
        jobId: jobIdToSendToBackend,
        destinationUserId: this.destinationUserId.email
      };
      changeOwnership(obj).then(response => {
        if (response._isError) {
          errorEventNotify(response._message, 2);
        } else {
          this.$router.push({
            name: "jobLanding"
          });
        }
      });
    },
    fetchAllJobStatus() {
      fetchJobStatus({
        userId: this.userDetails._id
      }).then(response => {
        this.allJobStatuses = response._result;
        response._result.forEach(x => {});
      });
    },
    changeJobStatus() {
      let param = {
        jobId: this.jobToChangeStatus._id,
        userObj: this.userDetails,
        status: this.jobStatusSelected.key
      };
      updateJobStatusForcefully(param)
        .then(response => {
          if (!response._isError) {
            successEventNotify("Status changed successfully", 2);
            this.jobStatusSelected = -999;
            this.$refs.changejobstatusmodal.hide();
            this.getAllJobs();
          } else {
            errorEventNotify("Some Problems changing the status of the job", 2);
          }
        })
        .catch(e => {
          errorEventNotify(
            "Error at changing job status forcefully::" + response._message,
            2
          );
        });
    },
    handleClick() {
      let atleastOneIsSelected = false;
      for (let i = 0; i < this.allJobsList.length; i++) {
        if (this.allJobsList[i].isSelectedInUI) {
          this.toShowFloatingButton = true;
          atleastOneIsSelected = true;
          break;
        }
      }
      if (!atleastOneIsSelected) {
        this.toShowFloatingButton = false;
      }
    },
    bulkChangeOwnershipForTheJob() {
      let jobIdToSendToBackend = [];
      let ownerIdsToSendToBackend = [];

      for (let i = 0; i < this.allJobsList.length; i++) {
        if (this.allJobsList[i].isSelectedInUI) {
          jobIdToSendToBackend.push(this.allJobsList[i]._id);
        }
      }
      for (let i = 0; i < this.allJobsList.length; i++) {
        if (this.allJobsList[i].isSelectedInUI) {
          ownerIdsToSendToBackend.push(this.allJobsList[i].ownerId);
        }
      }
      let obj = {
        userId: this.userDetails._id,
        ownerId: ownerIdsToSendToBackend,
        jobId: jobIdToSendToBackend,
        destinationUserId: this.destinationUserId.email
      };
      changeOwnership(obj).then(response => {
        if (response._isError) {
          errorEventNotify(response._message, 2);
        } else {
          this.$router.push({
            name: "jobLanding"
          });
        }
      });
    },
    getUsersList() {
      fetchUserForOwnershipChange({ userId: this.userDetails._id }).then(
        response => {
          this.jobOwnershipChangeUserList = response._result;
        }
      );
    }
  }
};
</script>

<style scoped>
.user-icon {
  background-position: -10px -62px;
}
.change-status-icon {
  background-position: -128px -62px;
}
</style>
