import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import {
  gridClOperations,
  deleteMultiCol,
  performAttributeMapping
} from "./services/parentservices.js";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import { filterHiddenColumnsFn, encryptData } from "./utilbin/common-utils";
class conditionalFiltersApplied {
  constructor(column, conditionApplied, inputValue) {
    this.column = column;
    this.conditionApplied = conditionApplied;
    this.inputValue = inputValue;
  }
}

const MARKED_AS_VALIDATED = "Validated";
const COLUMN_FREETEXT_SEARCH_OBJ = {
  text: "Freetext Search",
  value: {
    id: 99,
    text: "FREETEXT_SEARCH"
  }
};

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    stepsAction: [],
    storeDatableOriginalColumns: [],
    storeDatableDisplayColumns: [],
    storeFilteredData: [],
    status: "",
    token: "",
    message: "",
    conditionalFiltersAppliedMap: new Map(),
    userObj: {},
    editModeOn: false,
    addStepModeOn: false,
    prevStep: "",
    newStepNumber: "",
    runStepNumber: "",
    deleteStepNumber: "",
    hiddenColumns: [],
    algosToExecute: [],
    showSubset: ""
  },
  plugins: [
    createPersistedState({
      paths: ["token", "userObj"]
    })
  ],
  actions: {
    removeBulkColumnFromGridAction: (context, payload) => {
      return deleteMultiCol(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit(
              "removeBulkColumnFromGridMutation",
              response._result
            );
            return response;
          } else {
            throw new Error(
              "Found response._result empty at bulk column delete"
            );
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    removeConditionalFilterOnColumnActionFromLP: (context, payload) => {
      context.commit(
        "removeConditionalFilterOnColumnActionFromLPMutation",
        payload
      );
    },
    unhideSingleColumnAction: (context, payload) => {
      context.commit("unhideSingleColumnMutation", payload);
    },
    hideSingleColumnAction: (context, payload) => {
      context.commit("hideSingleColumnMutation", payload);
    },
    globalUnhideColumnsAction: (context, payload) => {
      context.commit("globalUnhideColumnsMutation", payload);
    },
    addBlankColumnAction: (context, payload) => {
      return gridClOperations
        .addBlankClSvc(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("addBlankColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    singleColumnMergeAction: (context, payload) => {
      return gridClOperations
        .mergeClSvc(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("singleColumnMergeMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    copyColumnAction: (context, payload) => {
      return gridClOperations
        .copyClSvc(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("copyColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    deleteColumnAction: (context, payload) => {
      return gridClOperations
        .deleteClSvc(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("deleteColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    splitColumnAction: (context, payload) => {
      return gridClOperations
        .splitClSvc(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("splitColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    removeDuplicateAction: (context, payload) => {
      return gridClOperations
        .removeDuplicate(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("removeDuplicateMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    arithmaticOperationAction: (context, payload) => {
      return gridClOperations
        .performArithmaticOps(payload)
        .then(response => {
          if (
            !response._isError &&
            !_.isEmpty(response._result) &&
            response._result.rowsData.length > 0
          ) {
            context.commit("arithmaticOperationMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    collectFilterAfterSearchAction: (context, payload) => {
      const filterObject = new conditionalFiltersApplied(
        payload,
        COLUMN_FREETEXT_SEARCH_OBJ,
        payload.filterText
      );
      context.commit("collectConditionalFilterMutation", filterObject);
    },
    collectFilterAfterConditionalAction: (context, payload) => {
      const filterObject = new conditionalFiltersApplied(
        payload.columnProps,
        payload.criteria,
        payload.searchInput
      );
      context.commit("collectConditionalFilterMutation", filterObject);
    },
    searchSingleColumnAction: (context, payload) => {
      gridClOperations
        .freeTextSearchSvc(payload)
        .then(response => {
          if (!response._isError) {
            context.commit("searchSingleColumnMutation", response._result);
          } else {
          }
        })
        .catch(e => {})
        .finally(() => {});
    },
    sortColumnAction: (context, payload) => {
      return gridClOperations
        .sortSingleClSvc(payload)
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            context.commit("sortColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    colorFilterAction: (context, payload) => {
      return gridClOperations
        .colorFilterClSvc(payload)
        .then(response => {
          if (!response._isError && response._result.length > 0) {
            context.commit("colorFilterMutation", response._result);
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    conditionalFilterColumnAction: (context, payload) => {
      return gridClOperations
        .conditionalStringFilterSvc(payload)
        .then(response => {
          if (!response._isError) {
            context.commit("conditionalFilterColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    conditionalNumberFilterColumnAction: (context, payload) => {
      return gridClOperations
        .conditionalNumberFilterSvc(payload)
        .then(response => {
          if (!response._isError) {
            context.commit(
              "conditionalNumberFilterColumnMutation",
              response._result
            );
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    multipleConditionalFilterColumnAction: (context, payload) => {
      return gridClOperations
        .multipleConditionalFilterSvc(payload)
        .then(response => {
          if (!response._isError) {
            context.commit("conditionalFilterColumnMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    globalReplaceAction: (context, payload) => {
      //context.commit("globalReplaceMutation", payload);
      gridClOperations
        .globalDataReplaceSvc(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("globalReplaceMutation", response._result);
          } else {
          }
        })
        .catch(e => {})
        .finally(() => {});
    },
    columnReplaceAction: (context, payload) => {
      return gridClOperations
        .singleClReplaceSvc(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("columnReplaceMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    markRowDirtyAction: (context, payload) => {
      context.commit("markRowDirtyMutation", payload);
    },
    freezeColumnAction: (context, payload) => {
      context.commit("freezeColumnMutation", payload);
    },
    unFreezeColumnAction: (context, payload) => {
      context.commit("unFreezeColumnMutation", payload);
    },
    loadGridData: (context, payload) => {
      let stock = {
        rowsData: null,
        columnsData: null,
        stepFinal: null
      };
      if (!_.isEmpty(payload)) {
        stock.rowsData = payload.data;
        stock.columnsData = payload.columnHeaders;
        stock.stepFinal = payload.jobObject.steps;
        context.commit("loadGridData", stock);
      } else {
        return;
      }
    },
    loginAction: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        context.commit("auth_request");
        let data = await encryptData(JSON.stringify(payload));
        payload = { encryptedData: data };
        axios({
          url: process.env.VUE_APP_API_ENDPOINT + "/login",
          data: payload,
          method: "POST"
        })
          .then(resp => {
            if (resp.data._result.authStatus === 401) {
              context.commit("auth_error", resp.data._message);
              resolve(resp);
            } else {
              const token = resp.data._result.userObject.ldapToken;
              // const username = resp.data._username;
              axios.defaults.headers.common["Authorization"] = token;
              context.commit("auth_success", resp.data._result.userObject);
              resolve(resp);
            }
          })
          .catch(err => {
            context.commit("auth_error", "Error");
            reject(err);
          });
      });
    },
    logout: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("logout");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      }).then();
    },
    insertSuggestion: (context, payload) => {
      return gridClOperations
        .suggesstionInsertFilters(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("insertSuggestionMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    updateSuggestion: (context, payload) => {
      return gridClOperations
        .suggesstionUpdateFilters(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("insertSuggestionMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    removeConditionalFilterOnColumnAction(context, payload) {
      return context.commit("removeConditionalFilterOnColumnMutation", payload);
    },
    fetchDataAfterClearAction(context, payload) {
      return gridClOperations
        .removeConditionalFilterOnColumn(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("fetchDataAfterClearMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        })
        .finally(() => {});
    },
    clearJobLevelStoreDataAction(context, payload) {
      context.commit("clearJobLevelStoreDataMutation", payload);
    },
    executeStepAction(context, payload) {
      context.commit("executeStepMutation", payload);
    },
    refreshGridDataAction: (context, payload) => {
      context.commit("refreshGridDataMutation", payload);
    },
    editModeAction: (context, payload) => {
      context.commit("editModeMutation", payload);
    },
    addStepModeAction: (context, payload) => {
      context.commit("addStepModeMutation", payload);
    },
    deleteStepModeAction: (context, payload) => {
      context.commit("deleteStepModeMutation", payload);
    },
    updateDisabledStepAction: (context, payload) => {
      context.commit("updateDisabledStepMutation", payload);
    },
    refreshOnlyGridRows: (context, payload) => {
      context.commit("refreshOnlyGridRowsMutation", payload);
    },
    markOneAsValidated: (context, payload) => {
      context.commit("markOneAsValidatedMutation", payload);
    },
    unmarkOneValidated: (context, payload) => {
      context.commit("unMarkOneValidatedMutation", payload);
    },
    markOneForReview: (context, payload) => {
      context.commit("markOneForReviewMutation", payload);
    },
    unmarkOneReviewed: (context, payload) => {
      context.commit("unmarkOneReviewedMutation", payload);
    },
    algosToExecuteAction: (context, payload) => {
      context.commit("algosToExecuteMutation", payload);
    },
    loadJobDataAction: (context, payload) => {
      context.commit("loadGridData", payload);
    },
    attributeMappingAction: (context, payload) => {
      return performAttributeMapping(payload)
        .then(response => {
          if (!response._isError && !_.isEmpty(response._result)) {
            context.commit("attributeMappingMutation", response._result);
            return response;
          } else {
            throw new Error(response._message);
          }
        })
        .catch(e => {
          return e;
        });
    },
    microsoftLoginAction: (context, payload) => {
      return new Promise(async (resolve, reject) => {
        context.commit("auth_request");
        let data = await encryptData(JSON.stringify(payload));
        payload = { encryptedData: data };
        axios({
          url: process.env.VUE_APP_API_ENDPOINT + "/",
          data: payload,
          method: "POST"
        })
          .then(resp => {
            if (resp.data._result.authStatus === 401) {
              context.commit("auth_error", resp.data._message);
              resolve(resp);
            } else {
              const token = resp.data._result.userObject.ldapToken;
              // const username = resp.data._username;
              axios.defaults.headers.common["Authorization"] = token;
              context.commit("auth_success", resp.data._result.userObject);
              resolve(resp);
            }
          })
          .catch(err => {
            context.commit("auth_error", "Error");
            reject(err);
          });
      });
    },
    updateColumnHeaderAction: (context, payload) => {
      context.commit("updateColumnHeaderMutation", payload);
    },
    showFullDataSubsetAction: (context, payload) => {
      context.commit("showFullDataSubsetMutation", payload);
    },
    showFullDataSubsetModeAction: (context, payload) => {
      context.commit("showFullDataSubsetModeMutation", payload);
    }
  },
  mutations: {
    executeStepMutation(state, payload) {
      state.stepsAction = [];
      state.stepsAction = payload._result.steps;
    },
    fetchDataAfterClearMutation(state, payload) {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    removeConditionalFilterOnColumnMutation: (state, payload) => {
      state.conditionalFiltersAppliedMap.delete(payload.value);
    },
    insertSuggestionMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.stepsAction = [];
      state.storeFilteredData = payload.rowsData;
      state.stepsAction = payload.stepFinal.steps;
    },
    collectConditionalFilterMutation: (state, payload) => {
      state.conditionalFiltersAppliedMap.set(payload.column.value, payload);
    },
    hideSingleColumnMutation: (state, payload) => {
      //finding index of column to hide
      let indexOfColumn = _.findIndex(
        state.storeDatableDisplayColumns,
        headerObj => {
          return headerObj.value === payload.value;
        }
      );

      if (!isNaN(indexOfColumn) && indexOfColumn > -1) {
        state.storeDatableDisplayColumns[indexOfColumn].isHidden = true;
        state.hiddenColumns.push(
          state.storeDatableDisplayColumns[indexOfColumn]
        );
      }
    },
    removeConditionalFilterOnColumnActionFromLPMutation: (state, payload) => {
      if (payload) {
        state.conditionalFiltersAppliedMap.delete(payload);
      } else {
        state.conditionalFiltersAppliedMap = new Map();
      }
    },
    removeBulkColumnFromGridMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableOriginalColumns = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      state.storeFilteredData = payload.rowsData;
      state.storeDatableOriginalColumns = payload.columnsData;
      state.storeDatableDisplayColumns = payload.columnsData;
      state.stepsAction = payload.stepFinal.steps;
    },
    unhideSingleColumnMutation: (state, payload) => {
      let indexOfColumn = _.findIndex(
        state.storeDatableDisplayColumns,
        headerObj => {
          return headerObj.value === payload;
        }
      );

      if (!isNaN(indexOfColumn) && indexOfColumn > -1) {
        state.storeDatableDisplayColumns[indexOfColumn].isHidden = false;
        state.hiddenColumns = state.hiddenColumns.filter(e => {
          return e.value !== payload;
        });
      }
    },
    globalUnhideColumnsMutation: (state, payload) => {
      let newHeaders = _.forEach(state.storeDatableDisplayColumns, function(
        obj
      ) {
        obj.isHidden = false;
      });
      if (newHeaders.length > 0) {
        state.storeDatableDisplayColumns = [];
        state.storeDatableDisplayColumns = newHeaders;
      }
    },
    searchSingleColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    addBlankColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    singleColumnMergeMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    copyColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    deleteColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    splitColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    removeDuplicateMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload.rowsData;
      state.stepsAction = payload.stepFinal.steps;
    },
    arithmaticOperationMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = payload.stepFinal.steps;
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = payload.columnsData;
    },
    sortColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    colorFilterMutation: (state, payload) => {
      state.storeFilteredData = payload;
    },
    conditionalFilterColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    conditionalNumberFilterColumnMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    columnReplaceMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.stepsAction = [];

      state.storeFilteredData = payload.rowsData;
      state.stepsAction = payload.stepFinal.steps;
    },
    globalReplaceMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.stepsAction = [];

      state.storeFilteredData = payload.rowsData;
      state.stepsAction = payload.stepFinal.steps;
    },
    markRowDirtyMutation: (state, payload) => {
      if (!payload.isDirty) {
        payload.isDirty = true;
        let index = _.findIndex(state.storeFilteredData, {
          _id: payload._id
        });

        state.storeFilteredData.splice(index, 1, payload);
      }
    },
    freezeColumnMutation: (state, payload) => {
      let indexOfColumn = _.findIndex(
        state.storeDatableDisplayColumns,
        headerObj => {
          return headerObj.value === payload.value;
        }
      );

      state.storeDatableDisplayColumns[indexOfColumn].fixed = true;
    },
    unFreezeColumnMutation: (state, payload) => {
      let indexOfColumn = _.findIndex(
        state.storeDatableDisplayColumns,
        headerObj => {
          return headerObj.value === payload.value;
        }
      );

      state.storeDatableDisplayColumns[indexOfColumn].fixed = false;
    },
    loadGridData: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableOriginalColumns = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      state.storeFilteredData = payload.rowsData;

      state.storeDatableOriginalColumns = payload.columnsData;
      state.storeDatableDisplayColumns = payload.columnsData;
      state.stepsAction = payload.stepFinal;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, loginObject) {
      state.status = "success";
      state.token = loginObject.ldapToken;
      state.userObj = loginObject;
      state.userObj["sessionID"] = Date.now();
    },
    auth_error(state, msg) {
      state.status = "error";
      state.message = msg;
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.userObj = {};
      state.message = "";
    },
    clearJobLevelStoreDataMutation: (state, payload) => {
      state.conditionalFiltersAppliedMap = new Map();
      state.storeDatableDisplayColumns = [];
      state.storeDatableOriginalColumns = [];
      state.storeFilteredData = [];
    },
    refreshGridDataMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableOriginalColumns = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      state.storeFilteredData = payload.rowsData;
      state.storeDatableOriginalColumns = payload.columnsData;
      state.storeDatableDisplayColumns = payload.columnsData;
      state.stepsAction = payload.stepFinal;
    },
    editModeMutation(state, payload) {
      state.editModeOn = payload.editModeOn;
      state.addStepModeOn = payload.addStepModeOn;
      state.prevStep = payload.prevStep;
      state.newStepNumber = payload.newStepNumber;
      state.runStepNumber = payload.runStepNumber;
      state.deleteStepNumber = payload.deleteStepNumber;
    },
    addStepModeMutation(state, payload) {
      state.addStepModeOn = payload.addStepModeOn;
      state.prevStep = payload.prevStep;
      state.newStepNumber = payload.newStepNumber;
      state.runStepNumber = payload.runStepNumber;
    },
    deleteStepModeMutation(state, payload) {
      state.stepsAction = [];
      state.deleteStepNumber = payload.deleteStepNumber;
      state.stepsAction = payload.stepData.stepFinal.steps;
    },
    updateDisabledStepMutation(state, payload) {
      state.newStepNumber = payload;
    },
    refreshOnlyGridRowsMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeFilteredData = payload;
    },
    markOneAsValidatedMutation: (state, payload) => {
      let row = state.storeFilteredData.find(row => row._id == payload.rowId);
      if (row.isValidated == undefined) {
        row.isValidated = [];
      }
      row.isValidated.push(...payload.validatedColumn);
    },
    unMarkOneValidatedMutation: (state, payload) => {
      let row = state.storeFilteredData.find(row => row._id == payload.rowId);
      row.isValidated = row.isValidated.filter(
        item => !payload.validatedColumn.includes(item)
      );
    },
    markOneForReviewMutation: (state, payload) => {
      let row = state.storeFilteredData.find(row => row._id == payload.rowId);
      if (row.markedForReview == undefined) {
        row.markedForReview = [];
      }
      row.markedForReview.push(...payload.reviewColumn);
    },
    unmarkOneReviewedMutation: (state, payload) => {
      let row = state.storeFilteredData.find(row => row._id == payload.rowId);
      row.markedForReview = row.markedForReview.filter(
        item => !payload.reviewColumn.includes(item)
      );
    },
    algosToExecuteMutation: (state, payload) => {
      state.algosToExecute = [];
      state.algosToExecute = payload;
    },
    attributeMappingMutation: (state, payload) => {
      state.storeFilteredData = payload.rowsData;
      state.storeDatableOriginalColumns = payload.columnsData;
      state.storeDatableDisplayColumns = payload.columnsData;
    },
    updateColumnHeaderMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      state.stepsAction = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.stepsAction = payload.stepFinal.steps;
    },
    showFullDataSubsetMutation: (state, payload) => {
      state.storeFilteredData = [];
      state.storeDatableDisplayColumns = [];
      let filterHiddenColumns = filterHiddenColumnsFn(
        payload.response.columnsData,
        state.hiddenColumns
      );
      state.storeFilteredData = payload.response.rowsData;
      state.storeDatableDisplayColumns = filterHiddenColumns;
      state.showSubset = payload.showSubset;
    },
    showFullDataSubsetModeMutation: (state, payload) => {
      state.showSubset = payload.showSubset;
    }
  },
  getters: {
    getStepAction: state => {
      return state.stepsAction;
    },
    getStoreDatableOriginalColumns: state => {
      return state.storeDatableOriginalColumns;
    },
    getStoreDatableDisplayColumns: state => {
      return state.storeDatableDisplayColumns;
    },
    getStoreFilteredData: state => {
      return state.storeFilteredData;
    },
    getStoreDisplayData: state => {
      return _.slice(state.storeFilteredData, 0, 1500);
    },
    isLoggedIn: state => {
      //alert(state.token);
      // return !!state.token;
      if (state.token) {
        //alert("reached if");
        return true;
      } else {
        //alert("reached else");
        //alert(state.token);
        return false;
      }
    },
    authStatus: state => state.status,
    getStoreConditionalFiltersAppliedMap: state => {
      return state.conditionalFiltersAppliedMap;
    },
    getUserObj: state => {
      return state.userObj;
    },
    getEditAction: state => {
      return {
        editModeOn: state.editModeOn,
        addStepModeOn: state.addStepModeOn,
        prevStep: state.prevStep,
        newStepNumber: state.newStepNumber,
        runStepNumber: state.runStepNumber,
        deleteStepNumber: state.deleteStepNumber
      };
    },
    getStoreHiddenColumns: state => {
      return state.hiddenColumns;
    },
    getAlgoToExecute: state => {
      return state.algosToExecute;
    },
    showSubset: state => {
      return state.showSubset;
    },
    getStoreDatableInputColumns: state =>
      state.storeDatableDisplayColumns
        .filter(col => col.isInput)
        .map(col => {
          return {
            text: col.text,
            value: col.value
          };
        })
  }
});
