import axios from "axios";
import store from "../store";
import router from "../router";
import { checkIfUserSessionIsTimedOut } from "../utilbin/validation";
import { encryptData } from "./../utilbin/common-utils";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

axiosInstance.interceptors.request.use(async config => {
  if (
    config.url != "/file" &&
    config.url != "/upload-learningfile" &&
    config.url != "/uploadGlobalListingFile" &&
    config.url != "/upload-training-file" &&
    config.url != "/replace-input-file" &&
    config.url != "/replace-training-file" &&
    config.url != "/replace-global-file" &&
    config.url != "/attribute-mapping-file-header-metaData" &&
    config.url != "/login-mode"
  ) {
    if (config.data != null && config.data != undefined) {
      let data = await encryptData(JSON.stringify(config.data));
      config.data = { encryptedData: data };
    }
  }
  if (config.url === "/fetch-activejobs") {
    return config;
  }
  if (
    checkIfUserSessionIsTimedOut(
      store.getters.getUserObj.sessionID,
      Date.now()
    ) &&
    store.getters.getUserObj.sessionID !== undefined
  ) {
    store.dispatch("logout").then(() => {
      router.push({
        name: "login"
      });
    });
    throw new axios.Cancel("Operation canceled as sessionID is invalid");
  }
  store.getters.getUserObj.sessionID = Date.now();
  return config;
});

export default () => axiosInstance;
