<template>
  <div class="with-suggestion">
    <!--COMPONENT of HAMBURGER DROPDOWN STARTS-->
    <div
      class="grid-column"
      style="visibility:hidden; line-height:0; font-size:0;"
    >
      <ul class="table-column-action">
        <li>
          <div
            id="hamburgerDropdownContainer"
            class="dropdown-container"
          >
            <a
              style="display:none"
              class="hamburger-actions"
              href="javascript:void(0);"
            >
              <i class="icon16 icon-hamburger"></i>
            </a>
            <ul
              id="hamburgerDropdown"
              class="hamburger-dropdown"
            >
              <li>
                <div class="search-item">
                  <input
                    class="form-control search-control awesomplete"
                    placeholder="Search"
                    v-model="columnFreeTextSearchModel.textToSearch"
                    @input="columnFreeTextSearchModel.textToSearch ? columnFreeTextSearchFn($event) : ''"
                    @focus="searchClick($event)"
                  >
                  <a
                    href="javascript:void(0);"
                    :class="{close:columnSearchFieldClass}"
                    @click="clearColumnLevelSearchFn($event)"
                  >
                    <i class="icon24 icon-search"></i>
                  </a>
                </div>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="hidden-after"
                  @click="removeconditionalFiltersAppliedMapFn($event)"
                >
                  <i class="icon16 icon-close"></i>Clear Filters
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="hidden-after"
                  @click="sortColumnFn($event)"
                >
                  <i class="icon16 icon-sort"></i>Sort
                </a>
              </li>
              <li v-if="condition()">
                <a href="javascript:void(0);">
                  <i class="icon16 icon-merge"></i>Merge
                </a>
                <div class="filter merge-filter-list">
                  <div class="head d-flex align-items-center justify-content-between">
                    <span>Merge</span>
                    <i class="icon16 icon-merge"></i>
                  </div>
                  <ul>
                    <li>
                      <span class="required-field">
                        Column name
                        <input
                          type="text"
                          v-model.lazy="mergeSingleColumnModel.mergingColumnName"
                          class="form-control"
                          :maxlength="maxColumnLengthLimit"
                        >
                      </span>
                    </li>
                    <li>
                      <span class="required-field">
                        Merge with
                        <multiselect
                          v-model.lazy="mergeSingleColumnModel.mergeWithColumn"
                          class="small-dropdown"
                          track-by="text"
                          label="text"
                          :options="headersObjects"
                          :close-on-select="true"
                        ></multiselect>
                      </span>
                    </li>
                  </ul>
                  <div class="button-list text-right">
                    <button
                      class="btn btn-primary"
                      @click="singleColumnMergeFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="resetMerge"
                    >Reset</button>
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="hidden-after"
                  @click="hideSingleColumnFn($event)"
                >
                  <i class="icon16 icon-hide-unhide"></i>Hide
                </a>
              </li>
              <li v-if="condition()">
                <a href="javascript:void(0);">
                  <i class="icon16 icon-split"></i>Split
                </a>
                <div class="filter split-filter-list">
                  <div class="head d-flex align-items-center justify-content-between">
                    <span>Split</span>
                    <i class="icon16 icon-split"></i>
                  </div>
                  <ul>
                    <li>
                      <span class="required-field">
                        Column 1 name
                        <input
                          type="text"
                          v-model.lazy="splitColumnModel.columnOne"
                          class="form-control"
                          value
                        >
                      </span>
                    </li>
                    <li>
                      <span class="required-field">
                        Column 2 name
                        <input
                          type="text"
                          v-model.lazy="splitColumnModel.columnTwo"
                          class="form-control"
                          value
                        >
                      </span>
                    </li>
                    <li>
                      <span class="required-field">
                        Split data by
                        <multiselect
                          v-model.lazy="splitColumnModel.delimeter"
                          class="small-dropdown"
                          :options="optionDelimeter"
                          :close-on-select="true"
                          :disabled="selectedManualDelimeter"
                          @input="(splitColumnModel.delimeter!=null)?selectedDelimeter=true:selectedDelimeter=false"
                        ></multiselect>OR
                      </span>
                    </li>
                    <li>
                      <span class="required-field">
                        Enter delimiter manually
                        <input
                          type="text"
                          :disabled="selectedDelimeter"
                          v-model.lazy="splitColumnModel.delimeter"
                          class="form-control"
                          @input="(splitColumnModel.delimeter!='')?selectedManualDelimeter=true:selectedManualDelimeter=false"
                        >
                      </span>
                    </li>
                  </ul>
                  <div class="button-list text-right">
                    <button
                      class="btn btn-primary"
                      @click="splitColumnDelemFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="resetSplit"
                    >Reset</button>
                  </div>
                </div>
              </li>
              <li v-if="condition()">
                <a href="javascript:void(0);">
                  <i class="icon16 icon-copy"></i>Copy Column
                </a>
                <div class="filter split-filter-list">
                  <div class="head d-flex align-items-center justify-content-between">
                    <span>Copy Column</span>
                    <i
                      class="icon16 icon-copy"
                      style="background-position: -218px -26px;"
                    ></i>
                  </div>
                  <ul>
                    <li>
                      <span class="required-field">
                        Column name
                        <input
                          type="text"
                          v-model.lazy="copyColumnModel.newColumnName"
                          class="form-control"
                          value
                          :maxlength="maxColumnLengthLimit"
                        >
                      </span>
                    </li>
                  </ul>
                  <div class="button-list text-right">
                    <button
                      class="btn btn-primary"
                      @click="copyColumnFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="resetCopyColumnFn"
                    >Reset</button>
                  </div>
                </div>
              </li>
              <li>
                <a>
                  <i class="icon16 icon-conditional-filter"></i>Conditional Filter
                </a>
                <div class="filter conditional-filter-list">
                  <div class="clearfix">
                    <div class="filter">
                      <div class="head d-flex align-items-center justify-content-between">
                        <span class="required-field">Filter</span>
                        <i class="icon16 icon-filter"></i>
                      </div>
                      <div class="body">
                        <multiselect
                          v-model.lazy="filterOptions.selectedCondition"
                          class="small-dropdown"
                          track-by="text"
                          label="text"
                          :options="filterOptions.conditions"
                          :close-on-select="true"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="value">
                      <div class="head d-flex align-items-center justify-content-between">
                        <span class="required-field">Value</span>
                        <i class="icon16 icon-percentage"></i>
                      </div>
                      <div class="body" v-if="checkEnableForConditionFilterMultiSelect(filterOptions.selectedCondition)"> 
                        <multiselect 
                          v-model="filterOptions.conditionalInput"
                          class="small-dropdown"
                          track-by="text"
                          label="text"
                          :options="getOtherColumnForComparision(gridDataTableDisplayCols)"
                          :close-on-select="true"
                        ></multiselect>
                      </div> 
                      <div class="body" v-else >
                        <input
                          type="text"
                          :disabled="checkDisableForCondtionalFilterInputFn(filterOptions.selectedCondition)"
                          v-model.lazy="filterOptions.conditionalInput"
                          class="form-control"
                          :maxlength="maxInputLengthLimit"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="button-list text-right">
                    <button
                      class="btn btn-primary"
                      @click="applyConditionalFilterFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="resetConditionalFilter"
                    >Reset</button>
                  </div>
                </div>
              </li>
              <li v-if="condition()">
                <a href="javascript:void(0);">
                  <i class="icon16 icon-blank-col"></i>Blank Column
                </a>
                <div class="filter split-filter-list">
                  <div class="head d-flex align-items-center justify-content-between">
                    <span>Blank Column</span>
                    <i class="icon16 icon-blank-col"></i>
                  </div>
                  <ul>
                    <li>
                      <span class="required-field">
                        New blank column name
                        <input
                          type="text"
                          v-model.lazy="singleBlankColumnModel.newColumnName"
                          class="form-control"
                          value
                          :maxlength="maxColumnLengthLimit"
                        >
                      </span>
                    </li>
                  </ul>
                  <div class="button-list text-right">
                    <button
                      class="btn btn-primary"
                      @click="addBlankColumnFn($event)"
                    >Apply</button>
                    <button
                      class="btn btn-outline-secondary"
                      @click="resetBlank"
                    >Reset</button>
                  </div>
                </div>
              </li>
              <li v-if="condition()">
                <a
                  href="javascript:void(0);"
                  @click="showColumnDeleteModalFn($event)"
                  class="hidden-after"
                >
                  <i class="icon16 icon-delete-col"></i>Delete Column
                </a>
              </li>
              <li v-if="condition() && jobParent.status!==8">
                <a
                  href="javascript:void(0);"
                  @click="markColumnAsValidated()"
                  class="hidden-after"
                >
                  <i class="icon16 icon-mark-validated-tbl"></i>Mark as Validated
                </a>
              </li>
              <li v-if="condition() && jobParent.status!==8">
                <a
                  href="javascript:void(0);"
                  @click="unmarkValidated()"
                  class="hidden-after"
                >
                  <i class="icon16 icon-unmark-validated-tbl"></i>Unmark Validated
                </a>
              </li>
              <li v-if="condition() && jobParent.status!==8">
                <a
                  href="javascript:void(0);"
                  @click="markColumnForReview()"
                  class="hidden-after"
                >
                  <i class="icon16 icon-mark-review-tbl"></i>Mark for Review
                </a>
              </li>
              <li v-if="condition() && jobParent.status!==8">
                <a
                  href="javascript:void(0);"
                  @click="unmarkColumnReviewed()"
                  class="hidden-after"
                >
                  <i class="icon16 icon-unmark-review-tbl"></i>Unmark Reviewed
                </a>
              </li>
              <li v-if="toShowReview">
                <a
                  href="javascript:void(0);"
                  @click="navigateToValidate($event)"
                  class="hidden-after"
                >
                  <i class="icon16 icon-review"></i>Review
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!--COMPONENT of HAMBURGER DROPDOWN ENDS-->
    <b-modal
      id="step-dependency-modal"
      ref="stepDependency"
      click-to-close="false"
      centered
      title="Bootstrap-Vue"
    >
      <div
        slot="modal-header"
        style="width:100%"
      >
        <h5 class="modal-title">
          <i class="icon16 icon-plus-blue"></i> Confirmation to Add Step
        </h5>
      </div>

      <div class="modal-body">
        <p>{{msg}}</p>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="removeDependencyStep($event,editModeActionParamTemp,dependentStepArray)"
        >
          <i class="icon14 icon-save"></i> Yes
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          @click="cancelStep()"
        >
          <i class="icon14 icon-close"></i> Cancel
        </button>
      </div>
    </b-modal>

    <!--Main grid Panel STARTS-->
    <div id="demoDiv">
      <div
        id="demoGrid"
        class="output-table"
      >
        <div class="g_Base">
          <div class="g_Head">
            <div class="g_HeadFixed">
              <!--Iterating over the objects in fixed cols to have layout of g_HeadFixed-->
              <div
                v-for="(item,index) in fixedColHeaderObjects"
                :key="index+'_hf_priitr'"
                :class="['g_Cl', 'g_Cl'+index, 'g_FCl']"
                v-if="!item.isHidden"
              >
                <span :class="['g_RS', 'g_RS'+index]">&nbsp;</span>
                <div class="g_C g_HR g_R0">
                  <div class="d-flex justify-content-between align-items-center grid-column">
                    <span
                      id="demoHdr0"
                      class="headerContent"
                      :class="'demoHdr' + item.text.replace(/ /g, '_')"
                    >{{item.text}}</span>
                    <!--Table actions list STARTS-->
                    <ul class="table-column-action">
                      <li>
                        <a
                          class="pin-action active"
                          href="javascript:void(0);"
                          @click="!item.fixed? freezeColumnFn($event,item): unFreezeColumnFn($event,item)"
                        >
                          <i class="icon16 icon-pin"></i>
                        </a>
                      </li>
                      <li>
                        <div class="dropdown-container">
                          <a
                            class="hamburger-actions"
                            href="javascript:void(0);"
                          >
                            <i
                              class="icon16 icon-hamburger"
                              @click="toggleHamburger($event, item)"
                            ></i>
                          </a>
                          <!--HERE WAS the hamburger-dropdown UL FOR FIXED COLS-->
                        </div>
                      </li>
                    </ul>
                    <!--Table actions list ENDS-->
                  </div>

                  <!--Table Graph STARTS-->
                  <!-- <ul
                    class="graph-list"
                    v-if="!(item.isInput && item.hasOwnProperty('count'))"
                  >
                    <li>
                      <a href="javascript:void(0);" class="line-graph">
                        <span
                          v-for="(value,field) in item.count"
                          :key="field"
                          v-b-tooltip.hover
                          :title="gridGraphCategoryTitle(field,item)"
                          :style="gridGraphCategoryWidths(field,item)"
                          :class="gridHeaderGraphClass[field]"
                          @click="colorFilter($event,field,item)"
                        ></span>
                      </a>
                    </li>
                  </ul>-->
                  <!--Table Graph ENDS-->
                </div>
              </div>
            </div>
            <div class="g_HeadStatic">
              <!--g_HeadStatic also expects to have empty fixed col div to compute widths-->
              <div
                v-for="(item,index) in fixedColHeaderObjects"
                :key="index+'_hs_priitr'"
                :class="['g_Cl', 'g_Cl'+index, 'g_FCl']"
                v-if="!item.isHidden"
              ></div>
              <!-- now Drawing rest of headers that are not fixed computing from length of fixed and iterator of non-fixed-->
              <div
                v-for="(item, index) in nFixedColHeaderObjects"
                :key="index+'_hs_secitr'"
                :class="['g_Cl', 'g_Cl'+(index+fixedColHeaderObjectsLength)]"
                v-if="!item.isHidden"
              >
                <span :class="['g_RS', 'g_RS'+(index+fixedColHeaderObjectsLength)]">&nbsp;</span>
                <div class="g_C g_HR g_R0">
                  <div class="d-flex justify-content-between align-items-center grid-column">
                    <span
                      :id="['demoHdr'+(index+fixedColHeaderObjectsLength)]"
                      :title="item.text"
                      class="headerContent"
                      :class="'demoHdr' + item.text.replace(/ /g, '_')"
                    >{{item.text}}</span>
                    <!--Table actions list STARTS-->
                    <ul class="table-column-action">
                      <li>
                        <a
                          class="pin-action"
                          href="javascript:void(0);"
                          @click="!item.fixed? freezeColumnFn($event,item): unFreezeColumnFn($event,item)"
                        >
                          <i class="icon16 icon-pin"></i>
                        </a>
                      </li>
                      <li>
                        <div class="dropdown-container">
                          <a
                            class="hamburger-actions"
                            href="javascript:void(0);"
                          >
                            <i
                              class="icon16 icon-hamburger"
                              @click="toggleHamburger($event, item)"
                            ></i>
                          </a>
                          <!--HERE WAS the hamburger-dropdown UL FOR STATIC COLS-->
                        </div>
                      </li>
                    </ul>
                    <!--Table actions list ENDS-->
                  </div>
                  <!--Table Graph STARTS-->
                  <!-- <ul
                    class="graph-list"
                    v-if="!(item.isInput && item.hasOwnProperty('count'))"
                  >
                    <li>
                      <a
                        href="javascript:void(0);"
                        class="line-graph"
                      >
                        <span
                          v-for="(value, key) in item.count"
                          :key="key"
                        >
                          {{key}}
                        </span>
                        <span
                          v-for="(value, field) in item.count"
                          :key="field"
                          v-b-tooltip.hover
                          :title="gridGraphCategoryTitle(field,item)"
                          :style="gridGraphCategoryWidths(field,item)"
                          :class="gridHeaderGraphClass[field]"
                          @click="colorFilter($event,field,item)"
                        ></span>
                      </a>
                    </li>
                  </ul>-->
                  <!--Table Graph ENDS-->
                </div>
              </div>
            </div>
          </div>
          <div class="g_Foot">
            <div class="g_FootFixed"></div>
            <div class="g_FootStatic"></div>
          </div>
          <div class="g_Body">
            <!--No Records display div STARTS-->
            <div
              v-if="gridDataTableDisplayRows.length === 0"
              style="padding: .75rem 1.25rem; font-size: x-large; text-align: center; margin-left: auto; margin-right: auto; #1b1e21;  background-color: #d6d8d9; border-color: #c6c8ca; position: relative; top: 50%; width:240px; transform: translateY(-50%);"
            >No Records Found</div>
            <!--No Records display div ENDS-->
            <div class="g_BodyFixed">
              <div class="g_BodyFixed2">
                <!--First iterating over fixed col objects and then iterating over array of values found via header param passed-->
                <div
                  v-for="(item, index) in fixedColHeaderObjects"
                  :key="index+'_bf_priitr'"
                  :class="['g_Cl', 'g_Cl'+index, 'g_FCl']"
                  v-if="!item.isHidden"
                >
                  <div
                    v-html="item2[item.value]"
                    v-for="(item2, index2) in gridDataTableDisplayRows"
                    :key="index2+'_bf_secitr'"
                    :class="['g_C', 'g_BR', 'g_R'+index2]"
                    :contenteditable="condition && item.editable"
                    :style="(item2.isValidated != undefined && item2.isValidated.includes(item.value)) || (item2.markedForReview != undefined && item2.markedForReview.includes(item.value)) ? 'background-color: #F3F781;' : ''"
                    @contextmenu.prevent="$refs.rowContext.open($event, {header: item ,data: item2})"
                    @focusout="updateGridData($event,item2,item)"
                    @mouseup="!item.editable ? {} : textSelected($event, item, item2)"
                  ></div>
                </div>
              </div>
            </div>
            <div class="g_BodyStatic">
              <!--Similar to g_HeadStatic g_BodyStatic expects an empty divs for fixed columns -->
              <div
                v-for="(item, index) in fixedColHeaderObjects"
                :key="index"
                :class="['g_Cl', 'g_Cl'+index, 'g_FCl']"
                v-if="!item.isHidden"
              ></div>
              <!-- Then iterating on non-fixed columns that starts with succeeding index of previous and go till the end rollback markDirty once-->
              <div
                v-for="(item, index) in nFixedColHeaderObjects"
                :key="index+'_bs__priitr'"
                :class="['g_Cl', 'g_Cl'+(index+fixedColHeaderObjectsLength)]"
                v-if="!item.isHidden"
              >
                <div
                  v-html="item2[item.value]"
                  v-for="(item2, index2) in gridDataTableDisplayRows"
                  :key="index2+'_bs_secitr'"
                  :class="['g_C', 'g_BR', 'g_R'+index2]"
                  :contenteditable="condition && item.editable"
                  :style="(item2.isValidated != undefined && item2.isValidated.includes(item.value)) ? 'background-color: rgb(153, 224, 200);' : '' || (item2.markedForReview != undefined && item2.markedForReview.includes(item.value)) ? 'background-color: #F3F781;' : ''"
                  @contextmenu.prevent="$refs.rowContext? $refs.rowContext.open($event, {header: item ,data: item2}):''"
                  @focusout="updateGridData($event,item2,item)"
                  @mouseup="!item.editable ? {} : textSelected($event, item, item2)"
                ></div>
              </div>
            </div>
          </div>
          <div class="g_BaseResize"></div>
        </div>
      </div>
      <!--Suggestion Box-->
      <!-- <div class="card-body">
      </div>-->
      <!--context-menu options-->
      <context-menu
        v-if="jobParent.status!==8"
        id="rowContextMenu"
        ref="rowContext"
        @ctx-open="rowContextMenuOpen"
        @ctx-cancel="rowResetcontextMenu"
        @ctx-close="rowContextMenuClose"
      >
        <li
          v-if="contextRowSelected.markedForReview!==undefined && !contextRowSelected.markedForReview.includes(contextColumnSelected.value)"
          class="ctx-item"
          @click="markCellForReview()"
        >
          <i class="icon16 icon-mark-review-context-menu"></i>Mark for Review
        </li>
        <li
          v-if="contextRowSelected.markedForReview!==undefined && contextRowSelected.markedForReview.includes(contextColumnSelected.value)"
          class="ctx-item"
          @click="unmarkCellReviewed()"
        >
          <i class="icon16 icon-unmark-review-context-menu"></i>Unmark Reviewed
        </li>
        <li
          v-if="contextRowSelected.isValidated!==undefined && !contextRowSelected.isValidated.includes(contextColumnSelected.value)"
          class="ctx-item"
          @click="markCellAsValidated()"
        >
          <i class="icon16 icon-mark-validated-context-menu"></i>Mark as Validated
        </li>
        <li
          v-if="contextRowSelected.isValidated!==undefined && contextRowSelected.isValidated.includes(contextColumnSelected.value)"
          class="ctx-item"
          @click="unmarkValidatedCell()"
        >
          <i class="icon16 icon-unmark-validated-context-menu"></i>Unmark Validated
        </li>
      </context-menu>
    </div>
    <!--Main grid Panel ENDS-->
    <!--Suggestion Panel STARTS-->
    <div class="card card-suggestion">
      <div class="card-header d-flex justify-content-between align-items-center">
        <span>Global Operations</span>
        <!-- <span class="visually-hidden">Selected Column: <strong>Sub Brand</strong></span> -->
        <div class="group-right">
          <!-- Gobal buttons -->
          <div class="btn-group btn-suggestion">
            <button
              type="button"
              @click="suggestionBox($event)"
              class="btn btn-primary"
            >Apply</button>
            <button
              type="button"
              @click="resetSuggestionPanelFn($event)"
              class="btn btn-outline-secondary suggestion-cancel"
            >Cancel</button>
          </div>
          <!-- Toggle suggestion -->
          <a
            @click="toggleSuggestionBox()"
            href="javascript:void(0);"
            class="toggle-suggestion"
          >
            <i class="icon24 icon-arrow-tbl"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <ul
          class="suggestion-list clearfix"
          style="height:130px"
        >
          <li
            v-if="condition()"
            class="list-item"
            id="globalReplace"
          >
            <h4 class="list-name">Global Replacement</h4>
            <div class="list-content">
              <div>
                <span class="required-field">Selected Text:</span>
                <input
                  type="text"
                  class="form-control"
                  v-bind:readonly="true"
                  v-bind:value="selectedText"
                >
              </div>
              <div>
                <span class="required-field">Replace With:</span>
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="replacementText"
                  :maxlength="maxInputLengthLimit"
                >
              </div>
              <!--Change wrt new replace options STARTS-->
              <div>
                <span class="required-field">Entire Word:</span>
                <input
                  id="boundary-replace-flag"
                  class="form-control"
                  style="vertical-align: top;"
                  type="checkbox"
                  v-model.lazy="boundaryReplaceFlag"
                >
              </div>
              <!--Change wrt new replace options ENDS-->
            </div>
          </li>
          <li
            v-if="condition()"
            class="list-item"
            id="updateData"
          >
            <h4 class="list-name">Update Data</h4>
            <div class="list-content">
              <div>
                <span class="required-field">Select Column:</span>
                <multiselect
                  v-model.lazy="coloumnName"
                  class="small-dropdown"
                  track-by="text"
                  label="text"
                  :options="editableColumnHeadersCp"
                  :close-on-select="true"
                ></multiselect>
              </div>
              <div>
                <span class="required-field">Insert Value:</span>
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="newColumnValue"
                  value
                  :maxlength="maxInputLengthLimit"
                >
              </div>
              <div></div>
            </div>
          </li>
          <li
            v-if="condition()"
            class="list-item"
            id="insertData"
          >
            <h4 class="list-name">Insert Data</h4>
            <div class="list-content">
              <div>
                <span class="required-field">Select Column:</span>
                <multiselect
                  v-model.lazy="coloumnNameInsertData"
                  class="small-dropdown"
                  track-by="text"
                  label="text"
                  :options="editableColumnHeadersCp"
                  :close-on-select="true"
                ></multiselect>
              </div>
              <div>
                <span class="required-field">Insert Value:</span>
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="newColumnValueInsertData"
                  value
                  :maxlength="maxInputLengthLimit"
                >
              </div>
              <div></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--Suggestion Panel ENDS-->

    <!--Delete Column Modal Starts-->
    <modal-action
      v-if="columnListActionModal.isActive"
      v-on:closeActionModal="closeActionModalFn"
    ><template v-if="columnListActionModal.actionType === 1">
        <span slot="header">
          <h5 class="modal-title">Delete Column
          </h5>
        </span>
        <span slot="body">Are you sure you want to delete this column?</span>
        <span slot="footer">
          <button
            @click="applyColumnListActionFn($event)"
            type="button"
            class="btn btn-primary nex-modal-btn"
            aria-label="Close modal"
          >
            YES
          </button>
        </span>
      </template></modal-action>
    <!--Delete Column Modal Ends-->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modal from "./../components/Modal.vue";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import contextMenu from "vue-context-menu";
import {
  saveGridDataManipulationService,
  updateColumnMetaData,
  markManyAsValidated,
  unmarkManyAsValidated,
  unmarkOneValidated,
  markOneAsValidated,
  markManyForReview,
  markOneForReview,
  unmarkOneReviewed,
  unmarkManyReviewed
} from "./../services/parentservices.js";
import { convertMapToJsonString } from "./../utilbin/common-utils.js";
import Multiselect from "vue-multiselect";
import {
  ACTION_NAME,
  MERGE_SINGLE_COLUMN_MODEL,
  SPLIT_COLUMN_MODEL,
  SINGLE_BLANK_COLUMN_MODEL,
  COPY_COLUMN_MODEL,
  TABLE_GRAPH_CLASS,
  SUGGESTION_CARD_MODULE,
  COLUMN_FREETEXT_SEARCH_MODEL,
  CONDITIONAL_FILTER_OPTIONS_MODEL,
  MARK_AS_VALIDATED,
  MARK_FOR_REVIEW,
  STEP_MODE,
  PAGELIST_ACTION_ITEMS,
  PAGELIST_ACTION_MODAL
} from "../assets/js/constant";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import { validateDependencies, addStep } from "./../utilbin/step-utility";
import {
  validateColumnFn,
  validateSplitColumnFn,
  validateMergeColumnFn,
  specialCharacterCheck,
  gridHamburgerValidator,
  specialCharacterCheckForSplit
} from "./../utilbin/validation";

let dropdown = "";

export default {
  name: "jobGrid",
  data() {
    return {
      maxColumnLengthLimit: 40,
      maxInputLengthLimit: 255,
      actionName: ACTION_NAME,
      boundaryReplaceFlag: true,
      isActive: false,
      gridHeaderGraphClass: TABLE_GRAPH_CLASS,
      mergeSingleColumnModel: new MERGE_SINGLE_COLUMN_MODEL(),
      splitColumnModel: new SPLIT_COLUMN_MODEL(),
      singleBlankColumnModel: new SINGLE_BLANK_COLUMN_MODEL(),
      copyColumnModel: new COPY_COLUMN_MODEL(),
      currentSort: "id",
      currentSortDir: "asc",
      filterOptions: CONDITIONAL_FILTER_OPTIONS_MODEL,
      selectedText: "",
      replacementText: "",
      selectedTextColumn: null,
      columnSearchFieldClass: false,
      contextRowSelected: "",
      contextColumnSelected: "",
      coloumnName: null,
      coloumnNameInsertData: null,
      newColumnValue: "",
      newColumnValueInsertData: "",
      optionDelimeter: ["-", ",", ":"],
      suggestionCard: "",
      selectedDelimeter: false,
      selectedManualDelimeter: false,
      msg: "",
      editModeActionParamTemp: "",
      dependentStepArray: "",
      hamburgerColumnClicked: {},
      columnFreeTextSearchModel: new COLUMN_FREETEXT_SEARCH_MODEL(),
      globalReplaceCardElem: null,
      insertDataCardElem: null,
      toShowReview: true,
      columnListActionModal: new PAGELIST_ACTION_MODAL()
    };
  },
  props: {
    jobParent: Object,
    jobName: String,
    inputFileName: String
  },
  watch: {
    gridDataTableDisplayRows: function(newRows, oldRows) {
      this.intializeGridComponent();
    }
  },
  components: { contextMenu, Multiselect, "modal-action": modal },
  updated() {
    if (this.gridDataTableDisplayRows.length > 0) {
      document.querySelector(".g_Body").scrollLeft += 1;
    }
  },
  created() {
    this.toShowReview =
      this.$router.currentRoute.name != "jobValidation" ? true : false;
  },
  mounted() {
    //1.Code to handle toggle of suggestion box once dom is rendered
    this.intializeGridComponent();
    if (this.condition()) {
      const globalReplaceCard = document.querySelector("li#globalReplace");
      const updateDataCard = document.querySelector("li#updateData");
      const insertDataCard = document.querySelector("li#insertData");
      globalReplaceCard.addEventListener("click", this.activateGRCardFn, false);
      updateDataCard.addEventListener("click", this.activateUDCardFn, false);
      insertDataCard.addEventListener("click", this.activateIDCardFn, false);
    }

    //2.Binds event to all elems execept ham to hide hamburger
    document.body.addEventListener("click", this.resetColumnHamburger, false);
  },
  beforeMount() {},
  beforeDestroy() {
    document.body.removeEventListener(
      "click",
      this.resetColumnHamburger,
      false
    );
  },
  updated() {
    let conditionalFiltersAppliedMap = convertMapToJsonString(
      this.conditionalFiltersAppliedMap
    );
    let conditionsMap = new Map(JSON.parse(conditionalFiltersAppliedMap));
    // let headerId = ".demoHdr" + "Manufacturer";
    // console.log(headerId);
    if (conditionsMap.size > 0) {
      for (const [key, value] of conditionsMap) {
        let headerId = ".demoHdr" + key.replace(/ /g, "_");
        document.querySelector(headerId).classList.add("hamburgermenutitle");
      }
    }
  },
  computed: {
    ...mapGetters({
      gridDataTableDisplayRows: "getStoreDisplayData",
      gridDataTableDisplayCols: "getStoreDatableDisplayColumns",
      conditionalFiltersAppliedMap: "getStoreConditionalFiltersAppliedMap",
      userDetails: "getUserObj",
      stepsAction: "getStepAction",
      editAction: "getEditAction"
    }),
    fixedColHeaderObjects: function() {
      let fixedColHeader = this.gridDataTableDisplayCols.filter(
        object => object.fixed
      );
      fixedColHeader.sort(this.propComparator(this.jobParent.sortedColumns));
      return fixedColHeader;
    },
    nFixedColHeaderObjects: function() {
      let nFixedColHeader = this.gridDataTableDisplayCols.filter(
        object => !object.fixed
      );
      nFixedColHeader.sort(this.propComparator(this.jobParent.sortedColumns));
      return nFixedColHeader;
    },
    fixedColHeaderObjectsLength: function() {
      return this.fixedColHeaderObjects.length;
    },
    totalColumnsCp() {
      //return Object.keys(this.gridDataTableDisplayCols).length;
      return this.gridDataTableDisplayCols.filter(o => !o.isHidden).length;
    },
    headersObjects: function() {
      return this.gridDataTableDisplayCols;
    },
    jobGridInputFileName: function() {
      if (
        this.jobParent.inputFileName.displayFileName !== "" ||
        this.jobParent.inputFileName.displayFileName !== null
      ) {
        return this.jobParent.inputFileName.displayFileName;
      } else {
        return null;
      }
    },
    editableColumnHeadersCp: function() {
      return this.gridDataTableDisplayCols.filter(o => o.editable);
    }
  },
  methods: {
    updateGridData(event, data, header) {
      let enteredValue;
      //1.Validating if the entered text is a string or a number
      if (event.target.innerText === "" || isNaN(event.target.innerText)) {
        enteredValue = event.target.innerText;
      } else {
        enteredValue = Number(event.target.innerText);
      }
      //2.Validating with old value if false no need to update
      const oldValue = data[header.value];
      const validationFlag = oldValue === enteredValue;
      if (!validationFlag) {
        //3.Now switching over algorithm type of the column edited
        // const algorithmType = header["algoMetaInfo"]["algorithmName"];
        let updatedRowData = data;
        updatedRowData[header.value] = enteredValue;
        const param = {
          data: updatedRowData,
          jobId: this.jobParent._id,
          userObj: this.userDetails,
          editedColumn: header
        };
        //4.Calling the update api
        saveGridDataManipulationService(param)
          .then(response => {})
          .catch(e => {});
        return;
      } else {
        return;
      }
    },
    applyConditionalFilterFn(event) {
      //0.doing validation
      const validationFlag = gridHamburgerValidator.validateApplyConditionalFilterFn(
        this.hamburgerColumnClicked,
        this.filterOptions
      );
      if (validationFlag) {
        //1.Collecting the filter applied
        let conditionalInputVal;
        if(this.filterOptions.selectedCondition.text=="Column matches"|| this.filterOptions.selectedCondition.text=="Column not matches" || this.filterOptions.selectedCondition.text=="Column contains" || this.filterOptions.selectedCondition.text=="Column not contains"){
          conditionalInputVal = this.filterOptions.conditionalInput.text
        }else{
         conditionalInputVal =
          isNaN(this.filterOptions.conditionalInput) ||
          /\s/g.test(this.filterOptions.conditionalInput)
            ? this.filterOptions.conditionalInput
            : Number(this.filterOptions.conditionalInput);
        }
        
        const filterCollectionParam = {
          columnProps: this.hamburgerColumnClicked,
          searchInput: conditionalInputVal,
          criteria: this.filterOptions.selectedCondition
        };
        this.$store
          .dispatch(
            "collectFilterAfterConditionalAction",
            filterCollectionParam
          )
          .then(response => {
            //2.Now calling action to do actual condtional filter
            const actionParam = {
              columnProps: this.hamburgerColumnClicked,
              searchInput: conditionalInputVal,
              criteria: this.filterOptions.selectedCondition,
              jobName: this.jobParent.jobName,
              collectionName: this.jobGridInputFileName,
              jobStatus: this.jobParent.status,
              sentForReviewArr: this.jobParent.sentForReview,
              length: this.maxInputLengthLimit,
              userId: this.userDetails._id,
              conditionalFiltersAppliedMap: convertMapToJsonString(
                this.conditionalFiltersAppliedMap
              )
            };

            this.$store
              .dispatch("conditionalFilterColumnAction", actionParam)
              .then(response => {
                if (!(response instanceof Error)) {
                  if (response._result.length === 0) {
                    successEventNotify(
                      "No records found matching the condition",
                      1
                    );
                  } else {
                    successEventNotify("condition applied successfully", 1);
                  }
                } else {
                  throw response;
                }
              })
              .catch(e => {
                errorEventNotify("Some error happened applying filter!", 1);
              })
              .finally(() => {
                this.closeAllHamburger(event);
                this.resetConditionalFilter();
              });
          })
          .catch(e => {
            errorEventNotify("Some error happened applying filter!", 1);
          })
          .finally(() => {});
      } else {
        this.closeAllHamburger(event);
        this.resetConditionalFilter();
        //errorEventNotify("Some error happened applying filter!", 1);
        return;
      }
    },
    resetConditionalFilter() {
      this.filterOptions.selectedCondition = {};
      this.filterOptions.conditionalInput = "";
      this.filterOptions.selectedColumn = {};
    },
    addBlankColumnFn(event) {
      if (
        gridHamburgerValidator.validateCopyColumnFn(
          this.hamburgerColumnClicked,
          this.singleBlankColumnModel,
          this.gridDataTableDisplayCols
        )
      ) {
        if (specialCharacterCheck(this.singleBlankColumnModel.newColumnName)) {
          errorEventNotify(
            `Column Name cannot contain [/\."$*<>:|?()%+=@!#^&{}';,-]`,
            1
          );
          return;
        }
        // 1. set actionParam object for backend
        this.singleBlankColumnModel.targetColumn = this.hamburgerColumnClicked;
        let actionParam = {
          blankColumnProps: this.singleBlankColumnModel,
          actionName: this.actionName.blank,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          length: this.maxColumnLengthLimit,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          editAddStepMode: this.editAction.addStepModeOn
        };

        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. capture steps and call backend function
            let updateSteps = addStep(
              this.actionName.blank,
              this.singleBlankColumnModel,
              this.editAction.prevStep.stepNumber + 1,
              JSON.parse(actionParam.conditionalFiltersAppliedMap),
              this.editAction.prevStep.lastBackup,
              this.editAction.prevStep.stepAfterLastBackup,
              this.stepsAction
            );
            actionParam.updatedSteps = updateSteps;
            this.gridAddBlankColumnFn(event, actionParam);
            this.addStepModeFn();
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridAddBlankColumnFn(event, actionParam);
          }
        }
      } else {
        return;
      }
    },
    gridAddBlankColumnFn(event, actionParam) {
      this.$store
        .dispatch("addBlankColumnAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            //this.resetBlank();
            this.intializeGridComponent();
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Added blank column successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Added blank column successfully", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened adding blank column!", 1);
        })
        .finally(() => {
          this.resetBlank();
          this.closeAllHamburger(event);
        });
    },
    resetBlank() {
      // this.singleBlankColumnModel.newColumnName = "";
      // this.singleBlankColumnModel.targetColumn = {};
      this.singleBlankColumnModel = new SINGLE_BLANK_COLUMN_MODEL();
    },
    resetCopyColumnFn() {
      // this.copyColumnModel.newColumnName = "";
      // this.copyColumnModel.targetColumn = {};
      this.copyColumnModel = new COPY_COLUMN_MODEL();
    },
    async singleColumnDeleteFn(event) {
      if (
        gridHamburgerValidator.validateColumnObject(this.hamburgerColumnClicked)
      ) {
        //0.1.Since this is a delete operation first removing this column's filter
        const column = this.hamburgerColumnClicked;
        const filterRemovalPromise = this.$store.dispatch(
          "removeConditionalFilterOnColumnAction",
          column
        );
        await filterRemovalPromise;

        //0.2.Now doing the actual delete operation on the grid
        // 1. set actionParam object for backend
        let actionParam = {
          deleteColumnProps: this.hamburgerColumnClicked,
          actionName: this.actionName.delete,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          editAddStepMode: this.editAction.editModeOn
        };

        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. now check dependencies on steps below
            const dependencies = validateDependencies(
              this.actionName.delete,
              this.hamburgerColumnClicked.value,
              this.editAction.prevStep,
              this.stepsAction
            );
            // 5. if dependencies found capture steps show modal with dependencies
            if (dependencies.arr.length > 0) {
              let updateSteps = addStep(
                this.actionName.delete,
                this.hamburgerColumnClicked,
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(actionParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                dependencies.removeArr
              );
              actionParam.updatedSteps = updateSteps;
              this.$refs.stepDependency.show();
              this.editModeActionParamTemp = actionParam;
              this.dependentStepArray = dependencies.arr;
              let dependentActions = [];
              for (let i = 0; i < dependencies.arr.length; i++) {
                dependentActions.push(dependencies.arr[i].actionName);
              }
              this.msg =
                "Column " +
                this.hamburgerColumnClicked.value +
                "  has been used in " +
                dependentActions +
                " step below. On click yes, below step will also be get deleted.";
            } else {
              // 6. if no dependencies found capture steps and call backend function
              let updateSteps = addStep(
                this.actionName.delete,
                this.hamburgerColumnClicked,
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(actionParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                this.stepsAction
              );
              actionParam.updatedSteps = updateSteps;
              this.gridSingleColumnDeleteFn(event, actionParam);
              this.addStepModeFn();
            }
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridSingleColumnDeleteFn(event, actionParam);
          }
        }
      } else {
        this.closeAllHamburger(event);
        return;
      }
      this.columnListActionModal = new PAGELIST_ACTION_MODAL();
    },
    gridSingleColumnDeleteFn(event, actionParam) {
      this.$store
        .dispatch("deleteColumnAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            this.intializeGridComponent();
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Column deleted successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Column deleted successfully", 1);
            }
          } else {
            errorEventNotify(response.message, 1);
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened deleting the column!", 1);
        })
        .finally(() => {
          this.closeAllHamburger(event);
        });
    },
    splitColumnDelemFn(event) {
      if (
        specialCharacterCheckForSplit(
          this.splitColumnModel.columnOne,
          this.splitColumnModel.columnTwo
        )
      ) {
        errorEventNotify(
          `Column Name cannot contain [/\."$*<>:|?()%+=@!#^&{}';,-]`,
          1
        );
        return;
      }
      if (
        gridHamburgerValidator.validateSplitColumnDelemFn(
          this.hamburgerColumnClicked,
          this.splitColumnModel,
          this.gridDataTableDisplayCols
        )
      ) {
        // 1. set actionParam object for backend
        this.splitColumnModel.targetColumn = this.hamburgerColumnClicked;
        const actionParam = {
          splitColumnProps: this.splitColumnModel,
          actionName: this.actionName.split,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          editAddStepMode: this.editAction.editModeOn
        };
        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. capture steps and call backend function
            let updateSteps = addStep(
              this.actionName.split,
              this.splitColumnModel,
              this.editAction.prevStep.stepNumber + 1,
              JSON.parse(actionParam.conditionalFiltersAppliedMap),
              this.editAction.prevStep.lastBackup,
              this.stepsAction
            );
            actionParam.updatedSteps = updateSteps;
            this.gridSplitColumnDelemFn(event, actionParam);
            this.addStepModeFn();
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridSplitColumnDelemFn(event, actionParam);
          }
        }
      } else {
        return;
      }
    },
    gridSplitColumnDelemFn(event, actionParam) {
      this.$store
        .dispatch("splitColumnAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            // this.resetSplit();
            this.intializeGridComponent();
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Column splitted successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Column splitted successfully", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened splitting the column!", 1);
        })
        .finally(() => {
          this.resetSplit();
          this.closeAllHamburger(event);
        });
    },
    resetSplit() {
      // this.splitColumnModel.targetColumn = {};
      // this.splitColumnModel.columnOne = this.splitColumnModel.columnTwo = this.splitColumnModel.delimeter =
      //   "";
      this.splitColumnModel = new SPLIT_COLUMN_MODEL();
      this.selectedDelimeter = false;
      this.selectedManualDelimeter = false;
    },
    singleColumnMergeFn(event) {
      if (
        specialCharacterCheck(this.mergeSingleColumnModel.mergingColumnName)
      ) {
        errorEventNotify(
          `Column Name cannot contain [/\."$*<>:|?()%+=@!#^&{}';,-]`,
          1
        );
        return;
      }
      if (
        gridHamburgerValidator.validateSingleColumnMergeFn(
          this.hamburgerColumnClicked,
          this.mergeSingleColumnModel,
          this.gridDataTableDisplayCols
        )
      ) {
        // 1. set actionParam object for backend
        this.mergeSingleColumnModel.targetColumn = this.hamburgerColumnClicked;
        let actionParam = {
          mergeProps: this.mergeSingleColumnModel,
          actionName: this.actionName.merge,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          length: this.maxColumnLengthLimit,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          editAddStepMode: this.editAction.editModeOn
        };

        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. capture steps and call backend function
            let updateSteps = addStep(
              this.actionName.merge,
              this.mergeSingleColumnModel,
              this.editAction.prevStep.stepNumber + 1,
              JSON.parse(actionParam.conditionalFiltersAppliedMap),
              this.editAction.prevStep.lastBackup,
              this.editAction.prevStep.stepAfterLastBackup,
              this.stepsAction
            );
            actionParam.updatedSteps = updateSteps;
            this.gridSingleColumnMergeFn(event, actionParam);
            this.addStepModeFn();
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridSingleColumnMergeFn(event, actionParam);
          }
        }
      } else {
        return;
      }
    },
    gridSingleColumnMergeFn(event, actionParam) {
      this.$store
        .dispatch("singleColumnMergeAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            //this.resetMerge();
            this.intializeGridComponent();
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Columns merged successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Columns merged successfully", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened merging the columns!", 1);
        })
        .finally(() => {
          this.resetMerge();
          this.closeAllHamburger(event);
        });
    },
    resetMerge() {
      this.mergeSingleColumnModel = new MERGE_SINGLE_COLUMN_MODEL();
    },
    intializeGridComponent() {
      let myGrid = new Grid("demoGrid", {
        allowGridResize: true,
        allowColumnResize: true,
        allowClientSideSorting: true,
        allowSelections: true,
        allowMultipleSelections: true,
        showSelectionColumn: true,
        colBGColors: this.computeFixedColsBgColorFn(),
        fixedCols: this.fixedColHeaderObjectsLength,
        totalXCols: this.totalColumnsCp
      });
    },
    computeFixedColsBgColorFn() {
      let colorArray = [];
      let color = "#F8F8F8";
      if (this.fixedColHeaderObjectsLength > 0) {
        for (let i = 0, len = this.fixedColHeaderObjectsLength; i < len; i++) {
          colorArray.push(color);
        }
      }
      return colorArray;
    },
    rowContextMenuOpen(param) {
      this.contextColumnSelected = param.header;
      this.contextRowSelected = param.data;
    },
    rowResetcontextMenu(param) {},
    rowContextMenuClose(param) {},
    copyColumnFn(event) {
      if (specialCharacterCheck(this.copyColumnModel.newColumnName)) {
        errorEventNotify(
          `Column Name cannot contain [/\."$*<>:|?()%+=@!#^&{}';,-]`,
          1
        );
        return;
      }
      if (
        gridHamburgerValidator.validateCopyColumnFn(
          this.hamburgerColumnClicked,
          this.copyColumnModel,
          this.gridDataTableDisplayCols
        )
      ) {
        // 1. set actionParam object for backend
        this.copyColumnModel.targetColumn = this.hamburgerColumnClicked;
        const actionParam = {
          actionName: this.actionName.copy,
          jobId: this.jobParent._id,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          copyColumnProps: this.copyColumnModel,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          length: this.maxColumnLengthLimit,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          editAddStepMode: this.editAction.editModeOn
        };
        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. capture steps and call backend function
            let updateSteps = addStep(
              this.actionName.copy,
              this.copyColumnModel,
              this.editAction.prevStep.stepNumber + 1,
              JSON.parse(actionParam.conditionalFiltersAppliedMap),
              this.editAction.prevStep.lastBackup,
              this.editAction.prevStep.stepAfterLastBackup,
              this.stepsAction
            );
            actionParam.updatedSteps = updateSteps;
            this.gridCopyColumnFn(event, actionParam);
            this.addStepModeFn();
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridCopyColumnFn(event, actionParam);
          }
        }
      } else {
        if (
          validateColumnFn(this.copyColumnModel, this.gridDataTableDisplayCols)
        ) {
          errorEventNotify("Copy column name cannot be empty", 1);
        }
        return;
      }
    },
    gridCopyColumnFn(event, actionParam) {
      this.$store
        .dispatch("copyColumnAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            //this.resetCopyColumnFn();
            this.intializeGridComponent();
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Copied column successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Copied column successfully", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened copying the column!", 1);
        })
        .finally(() => {
          this.resetCopyColumnFn();
          this.closeAllHamburger(event);
        });
    },
    sortColumnFn(event) {
      if (
        gridHamburgerValidator.validateColumnObject(
          this.hamburgerColumnClicked
        ) &&
        this.jobGridInputFileName !== null
      ) {
        //if column.value == current sort, reverse
        const column = this.hamburgerColumnClicked;
        if (this.currentSort !== "" && column.value === this.currentSort) {
          this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
        }
        this.currentSort = column.value;
        let actionParam = {
          sortColumnProps: column,
          keyToSort: this.currentSort,
          sortType: this.currentSortDir,
          jobName: this.jobParent.jobName,
          sentForReviewArr: this.jobParent.sentForReview,
          userId: this.userDetails._id,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          )
        };
        this.$store
          .dispatch("sortColumnAction", actionParam)
          .then(response => {})
          .catch(e => {
            errorEventNotify("Some error at sorting the column!", 1);
          })
          .finally(() => {
            this.closeAllHamburger(event);
          });
      } else {
        this.closeAllHamburger(event);
        errorEventNotify("Some error at sorting the column!", 1);
        return;
      }
    },
    columnFreeTextSearchFn: debounce(function(event) {
      if (
        gridHamburgerValidator.validateColumnFreeTextSearchFn(
          this.hamburgerColumnClicked,
          this.columnFreeTextSearchModel
        ) &&
        this.jobGridInputFileName !== null
      ) {
        //0.first collecting this filter into the store filter-map
        this.hamburgerColumnClicked.filterText = this.columnFreeTextSearchModel.textToSearch;
        const column = this.hamburgerColumnClicked;
        this.$store
          .dispatch("collectFilterAfterSearchAction", column)
          .then(response => {
            const actionParam = {
              sentForReviewArr: this.jobParent.sentForReview,
              freeTextSearchColProps: column,
              jobName: this.jobParent.jobName,
              collectionName: this.jobGridInputFileName,
              jobStatus: this.jobParent.status,
              userId: this.userDetails._id,
              conditionalFiltersAppliedMap: convertMapToJsonString(
                this.conditionalFiltersAppliedMap
              )
            };
            //1.Then actually doing the search operation
            this.$store
              .dispatch("searchSingleColumnAction", actionParam)
              .then(response => {
                this.columnSearchFieldClass = true;
              })
              .catch(e => {
                errorEventNotify(
                  "Some error happened searching the column!",
                  1
                );
              })
              .finally(() => {
                //this.closeAllHamburger(event);
              });
          })
          .catch(e => {
            errorEventNotify("Some error happened searching the column!", 1);
          })
          .finally(() => {});
      } else {
        errorEventNotify("Some error happened searching the column!", 1);
        this.closeAllHamburger(event);
        return;
      }
    }, 1000),
    textSelected(event, column, rowData) {
      if (
        window.getSelection().toString() &&
        window.getSelection().toString().length > 0 &&
        !isEmpty(rowData) &&
        !isEmpty(column)
      ) {
        this.selectedText = window.getSelection().toString();
        this.selectedTextColumn = column;
        this.toggleSuggestionBox();
      }

      // this works currently on year column
      // to make this more generic
      // add additional logic here as follows
      // search for selected text in rowdata and get matching key name
      // that should be the index/key of the header column
      // convert selectedText to object
      // add selected text and header key in the replaceTriggeredFn payload
    },
    columnReplaceTriggeredFn(event) {
      if (!isEmpty(this.selectedTextColumn)) {
        // 1. set actionParam object for backend
        const actionParam = {
          actionName: this.actionName.globalReplace,
          jobId: this.jobParent._id,
          selectedText: this.selectedText,
          replacementText: this.replacementText,
          selectedTextColumn: this.selectedTextColumn,
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          userObj: this.userDetails,
          conditionalFiltersAppliedMap: convertMapToJsonString(
            this.conditionalFiltersAppliedMap
          ),
          toDoBoundaryReplace: !this.boundaryReplaceFlag,
          editAddStepMode: this.editAction.editModeOn
        };
        // 2. check edit mode (if false perform grid operation)
        if (this.editAction.editModeOn) {
          // 3. check add step mode (if false show alert msg and return)
          if (!this.editAction.addStepModeOn) {
            errorEventNotify(
              "You are in edit mode, Please click on add button to perform any operation on grid.",
              1
            );
            return;
          } else {
            // 4. capture steps and call backend function
            let globalReplaceObj = {
              selectedText: this.selectedText.trim(),
              replacementText: this.replacementText,
              selectedTextColumn: this.selectedTextColumn,
              toDoBoundaryReplace: !this.boundaryReplaceFlag
            };
            let updateSteps = addStep(
              this.actionName.globalReplace,
              globalReplaceObj,
              this.editAction.prevStep.stepNumber + 1,
              JSON.parse(actionParam.conditionalFiltersAppliedMap),
              this.editAction.prevStep.lastBackup,
              this.editAction.prevStep.stepAfterLastBackup,
              this.stepsAction
            );
            actionParam.updatedSteps = updateSteps;
            this.gridColumnReplaceTriggeredFn(event, actionParam);
            this.addStepModeFn();
          }
        } else {
          if (
            this.jobParent.jobStepStatus &&
            this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
          ) {
            errorEventNotify(
              "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
              1
            );
          } else {
            this.gridColumnReplaceTriggeredFn(event, actionParam);
          }
        }
      }
    },
    gridColumnReplaceTriggeredFn(event, actionParam) {
      this.$store
        .dispatch("columnReplaceAction", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Column replace action completed. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Column replace action completed", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened doing column replace!", 1);
        })
        .finally(() => {
          this.resetSuggestionPanelFn(event);
          this.closeAllHamburger(event);
        });
    },
    replaceTriggeredFn(event) {
      if (!isEmpty(this.selectedTextColumn)) {
        const actionParam = {
          selectedText: this.selectedText.trim(),
          replacementText: this.replacementText.trim(),
          jobName: this.jobParent.jobName,
          collectionName: this.jobGridInputFileName,
          jobStatus: this.jobParent.status,
          jobId: this.jobParent._id,
          userObj: this.userDetails
        };
        this.$store
          .dispatch("globalReplaceAction", actionParam)
          .then(response => {})
          .catch(e => {
            errorEventNotify("Some error happened doing global replace!", 1);
          })
          .finally(() => {
            this.closeAllHamburger(event);
          });
      }
    },
    clearReplaceText() {
      this.selectedText = "";
      this.replacementText = "";
    },
    dirtyTriggerOnRowInput(rowParam) {
      this.$store.dispatch("markRowDirtyAction", rowParam);
    },
    freezeColumnFn(event, column) {
      column.fixed = true;
      if (gridHamburgerValidator.validateColumnObject(column)) {
        const param = {
          column: column,
          jobName: this.jobParent.jobName,
          userId: this.userDetails._id
        };
        updateColumnMetaData(param)
          .then(response => {
            if (response._isError === false) {
              this.$store.dispatch("freezeColumnAction", column);
              this.intializeGridComponent();
            }
          })
          .catch(e => {})
          .finally(() => {});
      } else {
        return;
      }
    },
    unFreezeColumnFn(event, column) {
      column.fixed = false;
      if (gridHamburgerValidator.validateColumnObject(column)) {
        const param = {
          column: column,
          jobName: this.jobParent.jobName,
          userId: this.userDetails._id
        };
        updateColumnMetaData(param)
          .then(response => {
            if (response._isError === false) {
              this.$store.dispatch("unFreezeColumnAction", column);
              this.intializeGridComponent();
            }
          })
          .catch(e => {})
          .finally(() => {});
      } else {
        return;
      }
    },
    showDetails(event, columnParam) {
      this.$router.push({
        name: "jobValidation",
        params: { columnparam: columnParam }
      });
    },
    goToGlobalVariant(event, columnParam) {
      let paramItem = {
        jobName: this.jobName,
        productAttribute: columnParam.value
      };
      this.$router.push({
        name: "globalVariants",
        params: { columnparam: paramItem }
      });
    },
    hideSingleColumnFn(event) {
      if (
        gridHamburgerValidator.validateColumnObject(this.hamburgerColumnClicked)
      ) {
        this.$store
          .dispatch("hideSingleColumnAction", this.hamburgerColumnClicked)
          .then(response => {
            successEventNotify("Column hidden successfully!", 1);
            0;
          })
          .catch(e => {
            errorEventNotify("Some error happened hiding the column!", 1);
          })
          .finally(() => {
            this.closeAllHamburger(event);
          });
      } else {
        errorEventNotify("Some error happened hiding the column!", 1);
        this.closeAllHamburger(event);
        return;
      }
    },
    navigateToValidate(event) {
      if (
        gridHamburgerValidator.validateColumnObject(
          this.hamburgerColumnClicked
        ) &&
        !isEmpty(this.jobParent)
      ) {
        const loader = document.querySelector(".loading-gif");
        const temp = document.querySelector(".fr-warapper");
        this.$router.push({
          name: "jobValidation",
          params: {
            columnparamId: this.hamburgerColumnClicked._id,
            jobId: this.jobParent._id,
            columnparam: this.hamburgerColumnClicked,
            job: this.jobParent
          }
        });
      } else {
        errorEventNotify("Some error happened going to review page!", 1);
      }
    },
    colorFilter(event, key, column) {
      let actionParam = {
        column: column,
        matchType: key,
        jobName: this.jobParent.jobName,
        collectionName: this.jobGridInputFileName,
        jobStatus: this.jobParent.status,
        userId: this.userDetails._id
      };
      this.$store
        .dispatch("colorFilterAction", actionParam)
        .then(() => {})
        .catch(e => {});
    },
    toggleHamburger(event, column) {
      //0.First getting the anchor tag that was clicked over and setting it active -its the parent of icon
      this.hamburgerColumnClicked = column;
      let conditionalFiltersAppliedMap = convertMapToJsonString(
        this.conditionalFiltersAppliedMap
      );
      let conditionsMap = new Map(JSON.parse(conditionalFiltersAppliedMap));
      if (conditionsMap.size > 0) {
        for (const [key, value] of conditionsMap) {
          if (value.column.value !== this.hamburgerColumnClicked.value) {
            this.columnFreeTextSearchModel = new COLUMN_FREETEXT_SEARCH_MODEL();
          }
          if (key === this.hamburgerColumnClicked.value) {
            this.columnFreeTextSearchModel.textToSearch = value["inputValue"];
            break;
          }
        }
      }
      let parentAnchorElm = event.target.parentElement;
      parentAnchorElm.classList.toggle("active");

      //0.1 Remove active from previous active if exists
      const previousActiveDropdown = document.querySelector("div.dd-active");
      if (previousActiveDropdown) {
        previousActiveDropdown.classList.remove("dd-active");
        previousActiveDropdown
          .querySelector("a.hamburger-actions")
          .classList.remove("active");
        this.resetAllColumnActionsModel();
      }

      //1.Getting dropdown elem moving it to mouse click and also getting its parent container div and other variables
      const originalContainer = parentAnchorElm.parentNode;
      originalContainer.classList.toggle("dd-active");
      const container = document.querySelector("#hamburgerDropdownContainer");
      let dropdown = container.querySelector(".hamburger-dropdown");
      dropdown.style.position = "fixed";
      dropdown.style.left = event.clientX - 50 + "px";
      dropdown.style.top = event.clientY + 10 + "px";

      const gridWidth = document.querySelector("#demoGrid").clientWidth;
      const leftPosition = parentAnchorElm.offsetLeft + 350;

      //2.Iterating through all list to toggle their active-inactive status
      if (parentAnchorElm.classList.contains("active")) {
        dropdown.className = "hamburger-dropdown open";
        setTimeout(function() {
          if (leftPosition > gridWidth) {
            dropdown.className = "hamburger-dropdown open visible right-filter";
          } else {
            dropdown.className = "hamburger-dropdown open visible";
          }
        }, 10);

        //3.Going through all the li in this context and binding click events on those
        let el = container.querySelectorAll(".hamburger-dropdown > li > a");
        for (let i = 0, len = el.length; i < len; ++i) {
          el[i].onclick = function(event) {
            let currentElm = event.target;
            if (currentElm.classList.contains("active")) {
              currentElm.classList.toggle("active");
            } else {
              var c = 0;
              while (c < el.length) {
                el[c++].parentElement.className = "";
              }
              event.stopPropagation();
              el[i].parentElement.classList.toggle("active");
            }
          };
        }
      } else {
        this.resetAllColumnActionsModel();
        dropdown.className = "hamburger-dropdown";
        let el = container.querySelectorAll("li");
        for (let i = 0, len = el.length; i < len; ++i) {
          if (el[i].classList.contains("active")) {
            el[i].classList.toggle("active");
          }
        }
      }
    },
    closeAllHamburger(event) {
      const activeDropDown = document.querySelector("div.dd-active");
      activeDropDown ? activeDropDown.classList.remove("dd-active") : "";
      let hamburgerMenu = document.querySelector("#hamburgerDropdown");
      let allHamburgerActions = document.querySelectorAll(
        ".hamburger-actions.active"
      );

      //removing active from hamburger icons-anchors
      allHamburgerActions.forEach(a => {
        a.classList.remove("active");
      });

      //removing active from sub menu list
      hamburgerMenu.classList.remove("open");
      let submenuList = hamburgerMenu.querySelectorAll("li.active");
      submenuList.forEach(l => {
        l.classList.remove("active");
      });
    },
    searchClick(event) {
      const hamburgerDropdown = document.querySelector("#hamburgerDropdown");
      let listElems = hamburgerDropdown.querySelectorAll("li");
      for (let i = 0, l = listElems.length; i < l; i++) {
        if (listElems[i].classList.contains("active")) {
          listElems[i].classList.toggle("active");
        }
      }
      //return searchClick(event.target);
    },
    clearColumnLevelSearchFn(event) {
      if (this.columnSearchFieldClass) {
        this.removeconditionalFiltersAppliedMapFn(event);
        this.columnSearchFieldClass = false;
      } else {
        return;
      }
    },
    suggestionBox(event) {
      if (this.suggestionCard === "UD") {
        if (!isEmpty(this.coloumnName) && this.coloumnName.value) {
          // 1. set actionParam object for backend
          const actionParam = {
            column: this.coloumnName,
            actionName: this.actionName.update,
            jobId: this.jobParent._id,
            newColumnValue: isNaN(this.newColumnValue)
              ? this.newColumnValue
              : parseFloat(this.newColumnValue),
            jobName: this.jobParent.jobName,
            collectionName: this.jobGridInputFileName,
            jobStatus: this.jobParent.status,
            userObj: this.userDetails,
            conditionalFiltersAppliedMap: convertMapToJsonString(
              this.conditionalFiltersAppliedMap
            ),
            editAddStepMode: this.editAction.editModeOn
          };
          // 2. check edit mode (if false perform grid operation)
          if (this.editAction.editModeOn) {
            // 3. check add step mode (if false show alert msg and return)
            if (!this.editAction.addStepModeOn) {
              errorEventNotify(
                "You are in edit mode, Please click on add button to perform any operation on grid.",
                1
              );
              return;
            } else {
              // 4. capture steps and call backend function
              let insertObj = {
                columnName: this.coloumnName,
                newValue: isNaN(this.newColumnValue)
                  ? this.newColumnValue
                  : parseFloat(this.newColumnValue)
              };
              let updateSteps = addStep(
                this.actionName.update,
                insertObj,
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(actionParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                this.stepsAction
              );
              actionParam.updatedSteps = updateSteps;
              this.gridUpdateSuggestionFn(event, actionParam);
              this.addStepModeFn();
            }
          } else {
            if (
              this.jobParent.jobStepStatus &&
              this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
            ) {
              errorEventNotify(
                "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
                1
              );
            } else {
              this.gridUpdateSuggestionFn(event, actionParam);
            }
          }
        } else {
          errorEventNotify("Column name is mandatory", 1);
        }
      } else if (this.suggestionCard === "GR") {
        if (this.selectedText) {
          this.columnReplaceTriggeredFn(event);
        } else {
          errorEventNotify("Selected text cannot be empty.", 1);
        }
      } else if (this.suggestionCard === "ID") {
        if (
          !isEmpty(this.coloumnNameInsertData) &&
          this.coloumnNameInsertData.value
        ) {
          // 1. set actionParam object for backend
          const actionParam = {
            column: this.coloumnNameInsertData,
            actionName: this.actionName.insert,
            jobId: this.jobParent._id,
            newColumnValue: isNaN(this.newColumnValueInsertData)
              ? this.newColumnValueInsertData
              : parseFloat(this.newColumnValueInsertData),
            jobName: this.jobParent.jobName,
            collectionName: this.jobGridInputFileName,
            jobStatus: this.jobParent.status,
            userObj: this.userDetails,
            conditionalFiltersAppliedMap: convertMapToJsonString(
              this.conditionalFiltersAppliedMap
            ),
            editAddStepMode: this.editAction.editModeOn
          };
          // 2. check edit mode (if false perform grid operation)
          if (this.editAction.editModeOn) {
            // 3. check add step mode (if false show alert msg and return)
            if (!this.editAction.addStepModeOn) {
              errorEventNotify(
                "You are in edit mode, Please click on add button to perform any operation on grid.",
                1
              );
              return;
            } else {
              // 4. capture steps and call backend function
              let insertObj = {
                columnName: this.coloumnNameInsertData,
                newValue: isNaN(this.newColumnValueInsertData)
                  ? this.newColumnValueInsertData
                  : parseFloat(this.newColumnValueInsertData)
              };
              let updateSteps = addStep(
                this.actionName.insert,
                insertObj,
                this.editAction.prevStep.stepNumber + 1,
                JSON.parse(actionParam.conditionalFiltersAppliedMap),
                this.editAction.prevStep.lastBackup,
                this.editAction.prevStep.stepAfterLastBackup,
                this.stepsAction
              );
              actionParam.updatedSteps = updateSteps;
              this.gridInsertSuggestionFn(event, actionParam);
              this.addStepModeFn();
            }
          } else {
            if (
              this.jobParent.jobStepStatus &&
              this.jobParent.jobStepStatus.stepMode === STEP_MODE.refreshMode
            ) {
              errorEventNotify(
                "You are in view mode, Please click on edit/add/delete button to perform any operation on grid.",
                1
              );
            } else {
              this.gridInsertSuggestionFn(event, actionParam);
            }
          }
        } else {
          errorEventNotify("Column name is mandatory", 1);
        }
      } else {
        errorEventNotify("Select the operation to be performed", 1);
        return;
      }
    },
    gridUpdateSuggestionFn(event, actionParam) {
      this.$store
        .dispatch("updateSuggestion", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Values inserted successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Values inserted successfully.", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened doing update operation!", 1);
        })
        .finally(() => {
          this.resetSuggestionPanelFn(event);
          this.closeAllHamburger(event);
        });
    },
    gridInsertSuggestionFn(event, actionParam) {
      this.$store
        .dispatch("insertSuggestion", actionParam)
        .then(response => {
          if (!(response instanceof Error)) {
            if (
              this.editAction.editModeOn &&
              this.editAction.prevStep !== "" &&
              this.editAction.newStepNumber !== ""
            ) {
              successEventNotify(
                "Values inserted successfully. Click done to apply the changes.",
                1
              );
            } else {
              successEventNotify("Values inserted successfully.", 1);
            }
          } else {
            throw response;
          }
        })
        .catch(e => {
          errorEventNotify("Some error happened doing insert operation!", 1);
        })
        .finally(() => {
          this.resetSuggestionPanelFn(event);
          this.closeAllHamburger(event);
        });
    },
    getOtherColumnForComparision(columns){
      let otherColumns= JSON.parse(JSON.stringify(columns));;
      const index = columns.indexOf(this.hamburgerColumnClicked);
      if (index > -1) { 
        otherColumns.splice(index, 1); 
      }
      return otherColumns
    },
    checkEnableForConditionFilterMultiSelect(condition){
      if (
          condition && condition.value && condition.value.text &&
          (condition.value.text === "COMPARE_WITH_OTHER_COLUMN_MATCHES" ||
          condition.value.text === "COMPARE_WITH_OTHER_COLUMN_NOT_MATCHES" ||
          condition.value.text === "COMPARE_WITH_OTHER_COLUMN_CONTAINS" ||
          condition.value.text === "COMPARE_WITH_OTHER_COLUMN_NOT_CONTAINS")
          ){
            return true
          };
          return false
    },
    checkDisableForCondtionalFilterInputFn(condition) {
      if (isEmpty(condition)) {
        return true;
      } else {
        return (
          condition.value.text === "IS_BLANK" ||
          condition.value.text === "IS_NOT_BLANK" ||
          condition.value.text.includes("VALIDATED") ||
          condition.value.text.includes("REVIEWED")
        );
      }
    },
    removeconditionalFiltersAppliedMapFn(event) {
      this.hamburgerColumnClicked.filterText = this.columnFreeTextSearchModel.textToSearch =
        "";
      if (
        gridHamburgerValidator.validateColumnObject(this.hamburgerColumnClicked)
      ) {
        const column = this.hamburgerColumnClicked;
        if (!this.conditionalFiltersAppliedMap.get(column.value)) {
          errorEventNotify("No filters were applied on this column!", 1);
          this.closeAllHamburger(event);
          return;
        }
        this.$store
          .dispatch("removeConditionalFilterOnColumnAction", column)
          .then(response => {
            const actionParam = {
              sentForReviewArr: this.jobParent.sentForReview,
              jobName: this.jobParent.jobName,
              collectionName: this.jobGridInputFileName,
              jobStatus: this.jobParent.status,
              userId: this.userDetails._id,
              conditionalFiltersAppliedMap: convertMapToJsonString(
                this.conditionalFiltersAppliedMap
              )
            };
            this.$store
              .dispatch("fetchDataAfterClearAction", actionParam)
              .then(response => {
                if (!(response instanceof Error)) {
                  successEventNotify("Cleared filters successfully", 1);
                } else {
                  throw response;
                }
              })
              .catch(e => {
                errorEventNotify("Some error happened clearing filters!", 1);
              })
              .finally(() => {
                this.closeAllHamburger(event);
              });
          })
          .catch(e => {
            errorEventNotify("Some error happened clearing filters!", 1);
          })
          .finally(() => {});
      }
    },
    resetSuggestionPanelFn(event) {
      if (this.suggestionCard !== "") {
        switch (this.suggestionCard) {
          case SUGGESTION_CARD_MODULE.globalReplacement:
            this.selectedText = "";
            this.replacementText = "";
            this.boundaryReplaceFlag = false;
            this.toggleSuggestionBox();
            document.querySelector("li#updateData").classList.remove("active");
            document.querySelector("li#insertData").classList.remove("active");
            document
              .querySelector("li#globalReplace")
              .classList.remove("active");

            break;
          case SUGGESTION_CARD_MODULE.UpdateData:
            this.coloumnName = null;
            this.newColumnValue = "";
            this.toggleSuggestionBox();
            document.querySelector("li#updateData").classList.remove("active");
            document.querySelector("li#insertData").classList.remove("active");
            document
              .querySelector("li#globalReplace")
              .classList.remove("active");
            break;
          case SUGGESTION_CARD_MODULE.InsertData:
            this.coloumnNameInsertData = null;
            this.newColumnValueInsertData = "";
            this.toggleSuggestionBox();
            document.querySelector("li#insertData").classList.remove("active");
            document.querySelector("li#updateData").classList.remove("active");
            document
              .querySelector("li#globalReplace")
              .classList.remove("active");
            break;
          default:
            this.toggleSuggestionBox();
        }
      } else {
        if (!event.target.classList.contains("suggestion-cancel")) {
          this.toggleSuggestionBox();
        } else {
          this.toggleSuggestionBox();
        }
      }
    },
    toggleSuggestionBox() {
      const table = document.querySelector("#demoDiv");
      //const tableHeight = table.clientHeight;
      let suggestionDiv = document.querySelector("div.card.card-suggestion");
      suggestionDiv.classList.toggle("open");
      table.style.height = suggestionDiv.classList.contains("open")
        ? 520 - 145 + "px"
        : 520 + "px";
    },
    removeDependencyStep(event, actionParam, dependentStepArray) {
      let executeFound = dependentStepArray.filter(el => {
        return el.actionName === ACTION_NAME.algoExecute;
      });
      if (executeFound.length > 0) {
        for (
          let j = this.editAction.newStepNumber;
          j < actionParam.updatedSteps.length;
          j++
        ) {
          if (actionParam.updatedSteps[j].actionName === "Execute") {
            break;
          } else {
            actionParam.updatedSteps[
              j
            ].lastBackup = this.editAction.prevStep.lastBackup;
            actionParam.updatedSteps[
              j
            ].stepAfterLastBackup = this.editAction.prevStep.stepAfterLastBackup;
          }
        }
      }
      this.gridSingleColumnDeleteFn(event, actionParam);
      this.addStepModeFn();
      this.$refs.stepDependency.hide();
    },
    cancelStep() {
      // const cancelStep = true
      this.cancelStepModeFn();
      this.$refs.stepDependency.hide();
    },
    addStepModeFn() {
      let obj = {
        addStepModeOn: false,
        prevStep: "",
        newStepNumber: "",
        runStepNumber: this.editAction.prevStep.stepNumber
      };
      for (
        let i = this.editAction.prevStep.stepNumber;
        i < this.stepsAction.length;
        i++
      ) {
        if (this.stepsAction[i].actionName === ACTION_NAME.algoExecute) {
          obj.prevStep = this.editAction.prevStep;
          obj.newStepNumber = this.editAction.prevStep.stepNumber + 1;
          break;
        }
      }
      this.$store
        .dispatch("addStepModeAction", obj)
        .then(response => {})
        .catch(e => {});
    },
    cancelStepModeFn() {
      let obj = {
        addStepModeOn: false,
        prevStep: "",
        newStepNumber: "",
        runStepNumber: this.editAction.prevStep.stepNumber
      };
      for (
        let i = this.editAction.prevStep.stepNumber;
        i < this.stepsAction.length;
        i++
      ) {
        if (this.stepsAction[i].actionName === ACTION_NAME.algoExecute) {
          obj.prevStep = this.editAction.prevStep;
          obj.newStepNumber = this.editAction.prevStep.stepNumber;
          break;
        }
      }
      this.$store
        .dispatch("addStepModeAction", obj)
        .then(response => {})
        .catch(e => {});
    },
    markColumnAsValidated() {
      let columnList = [];
      if (this.hamburgerColumnClicked.isInput) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      columnList.push(this.hamburgerColumnClicked.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        validatedColumnList: columnList,
        userId: this.userDetails._id
      };
      markManyAsValidated(params)
        .then(response => {
          if (!response._isError) {
            // if (
            //   response._result != null &&
            //   response._result != undefined &&
            //   response._result.length > 0
            // ) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
            // }
            successEventNotify(MARK_AS_VALIDATED.markedColumnAsValidated, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedColumnAsValidated, 1);
          }
        })
        .catch(e => {})
        .finally(() => {
          this.closeAllHamburger("");
        });
    },
    unmarkValidated() {
      let columnList = [];
      if (this.hamburgerColumnClicked.isInput) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      columnList.push(this.hamburgerColumnClicked.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        validatedColumnList: columnList,
        userId: this.userDetails._id
      };
      unmarkManyAsValidated(params)
        .then(response => {
          if (!response._isError) {
            if (
              response._result != null &&
              response._result != undefined &&
              response._result.length > 0
            ) {
              this.$store.dispatch("refreshOnlyGridRows", response._result);
            }
            successEventNotify(MARK_AS_VALIDATED.unmarkedValidatedColumn, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedColumnAsValidated, 1);
          }
        })
        .catch(e => {})
        .finally(() => {
          this.closeAllHamburger("");
        });
    },
    markCellAsValidated() {
      let columnList = [];
      if (this.contextColumnSelected.isInput) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      //list will always contain only one value, list used to maintain consistency
      columnList.push(this.contextColumnSelected.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        rowId: this.contextRowSelected._id,
        validatedColumn: columnList,
        userId: this.userDetails._id
      };
      markOneAsValidated(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("markOneAsValidated", params);
            successEventNotify(MARK_AS_VALIDATED.markedCellAsValidated, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedCellAsValidated, 1);
          }
        })
        .catch(e => {});
    },
    unmarkValidatedCell() {
      let columnList = [];
      if (this.contextColumnSelected.isInput) {
        errorEventNotify(MARK_AS_VALIDATED.markedAllAsValidatedInput, 1);
        return;
      }
      //list will always contain only one value, list used to maintain consistency
      columnList.push(this.contextColumnSelected.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        rowId: this.contextRowSelected._id,
        validatedColumn: columnList,
        userId: this.userDetails._id
      };
      unmarkOneValidated(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("unmarkOneValidated", params);
            successEventNotify(MARK_AS_VALIDATED.unmarkedValidatedCell, 1);
          } else {
            errorEventNotify(MARK_AS_VALIDATED.errorMarkedCellAsValidated, 1);
          }
        })
        .catch(e => {});
    },
    markColumnForReview() {
      let columnList = [];
      if (this.hamburgerColumnClicked.isInput) {
        errorEventNotify(MARK_FOR_REVIEW.markedAllAsReviewedInput, 1);
        return;
      }
      columnList.push(this.hamburgerColumnClicked.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        reviewColumnList: columnList,
        userId: this.userDetails._id
      };
      markManyForReview(params)
        .then(response => {
          if (!response._isError) {
            // if (
            //   response._result != null &&
            //   response._result != undefined &&
            //   response._result.length > 0
            // ) {
            this.$store.dispatch("refreshOnlyGridRows", response._result);
            // }
            successEventNotify(MARK_FOR_REVIEW.markedColumnForReview, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedColumnForReview, 1);
          }
        })
        .catch(e => {})
        .finally(() => {
          this.closeAllHamburger("");
        });
    },
    unmarkColumnReviewed() {
      let columnList = [];
      if (this.hamburgerColumnClicked.isInput) {
        errorEventNotify(MARK_FOR_REVIEW.markedAllAsReviewedInput, 1);
        return;
      }
      columnList.push(this.hamburgerColumnClicked.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        conditionalFiltersAppliedMap: convertMapToJsonString(
          this.conditionalFiltersAppliedMap
        ),
        reviewColumnList: columnList,
        userId: this.userDetails._id
      };
      unmarkManyReviewed(params)
        .then(response => {
          if (!response._isError) {
            if (
              response._result != null &&
              response._result != undefined &&
              response._result.length > 0
            ) {
              this.$store.dispatch("refreshOnlyGridRows", response._result);
            }
            successEventNotify(MARK_FOR_REVIEW.unmarkedAllReviewed, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedColumnForReview, 1);
          }
        })
        .catch(e => {})
        .finally(() => {
          this.closeAllHamburger("");
        });
    },
    markCellForReview() {
      let columnList = [];
      if (this.contextColumnSelected.isInput) {
        errorEventNotify(MARK_FOR_REVIEW.markedAllAsReviewedInput, 1);
        return;
      }
      //list will always contain only one value, list used to maintain consistency
      columnList.push(this.contextColumnSelected.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        rowId: this.contextRowSelected._id,
        reviewColumn: columnList,
        userId: this.userDetails._id
      };
      markOneForReview(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("markOneForReview", params);
            successEventNotify(MARK_FOR_REVIEW.markedCellForReview, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedCellForReview, 1);
          }
        })
        .catch(e => {});
    },
    unmarkCellReviewed() {
      let columnList = [];
      if (this.contextColumnSelected.isInput) {
        errorEventNotify(MARK_FOR_REVIEW.markedAllAsReviewedInput, 1);
        return;
      }
      //list will always contain only one value, list used to maintain consistency
      columnList.push(this.contextColumnSelected.value);
      let params = {
        inputFileName: this.jobParent.inputFileName.displayFileName,
        jobName: this.jobParent.jobName,
        rowId: this.contextRowSelected._id,
        reviewColumn: columnList,
        userId: this.userDetails._id
      };
      unmarkOneReviewed(params)
        .then(response => {
          if (!response._isError) {
            this.$store.dispatch("unmarkOneReviewed", params);
            successEventNotify(MARK_FOR_REVIEW.unmarkReviewedCell, 1);
          } else {
            errorEventNotify(MARK_FOR_REVIEW.errorMarkedCellForReview, 1);
          }
        })
        .catch(e => {});
    },
    resetAllColumnActionsModel() {
      this.copyColumnModel = new COPY_COLUMN_MODEL();
      this.mergeSingleColumnModel = new MERGE_SINGLE_COLUMN_MODEL();
      this.splitColumnModel = new SPLIT_COLUMN_MODEL();
      this.singleBlankColumnModel = new SINGLE_BLANK_COLUMN_MODEL();
    },
    resetColumnHamburger(e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      const target = e.target || e.srcElement;
      const activeDropDown = document.body.querySelector("div.dd-active");
      const actuaListElem = document.body.querySelector("#hamburgerDropdown");
      if (activeDropDown) {
        if (
          target !== activeDropDown &&
          !activeDropDown.contains(target) &&
          !actuaListElem.contains(target)
        ) {
          activeDropDown.classList.remove("dd-active");
          this.resetAllColumnActionsModel();
          activeDropDown
            .querySelector("a.hamburger-actions")
            .classList.remove("active");

          actuaListElem.className = "hamburger-dropdown";
          const liItems = actuaListElem.querySelectorAll("li");
          for (let j = 0, len = liItems.length; j < len; ++j) {
            liItems[j].classList.remove("active");
          }
        } else {
          return;
        }
      } else {
        return;
      }
    },
    activateGRCardFn(e) {
      this.suggestionCard = "GR";
      document.querySelector("li#updateData").classList.remove("active");
      document.querySelector("li#insertData").classList.remove("active");
      document.querySelector("li#globalReplace").classList.add("active");
    },
    activateUDCardFn(e) {
      this.suggestionCard = "UD";
      document.querySelector("li#globalReplace").classList.remove("active");
      document.querySelector("li#insertData").classList.remove("active");
      document.querySelector("li#updateData").classList.add("active");
    },
    activateIDCardFn(e) {
      this.suggestionCard = "ID";
      document.querySelector("li#globalReplace").classList.remove("active");
      document.querySelector("li#updateData").classList.remove("active");
      document.querySelector("li#insertData").classList.add("active");
    },
    condition() {
      if (
        this.jobParent.owner == this.userDetails._id ||
        (this.userDetails.roles && this.userDetails.roles.includes("1"))
      ) {
        if (this.jobParent && this.jobParent.status !== 6) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.jobParent.shareAccess.length > 0 && this.userDetails._id) {
          return this.jobParent.shareAccess
            .find(x => x._id == this.userDetails._id)
            .privileges.includes("edit");
        }
        return false;
      }
    },
    closeActionModalFn() {
      this.columnListActionModal = new PAGELIST_ACTION_MODAL();
    },
    showColumnDeleteModalFn(event) {
      this.columnListActionModal.isActive = true;
      this.columnListActionModal.actionType = PAGELIST_ACTION_ITEMS.deletion;
      this.columnListActionModal.actionPayload = event;
    },
    applyColumnListActionFn(event) {
      switch (this.columnListActionModal.actionType) {
        case 1:
          this.singleColumnDeleteFn(this.columnListActionModal.actionPayload);
          break;
        case 2:
          break;
        default:
      }
    },
    propComparator(prop) {
      return function(a, b) {
        var val1 = prop.indexOf(a.value);
        var val2 = prop.indexOf(b.value);

        val1 == -1 ? (val1 = 1000) : (val1 = val1);
        val2 == -1 ? (val2 = 1000) : (val2 = val2);

        if (val1 < val2) return -1;
        else if (val1 > val2) return 1;
        else return 0;
      };
    }
  }
};
</script>
<style scoped>
.hamburgermenutitle {
  color: blue;
}
</style>