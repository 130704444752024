<template>
  <div class="fr-warapper">
    <ams-navbar></ams-navbar>
    <page-alert />
    <b-card
      no-body
      class="filter-card"
    >
      <div class="card-header d-flex align-middle justify-content-between">
        <p>Reports</p>
      </div>
      <div class="card-body panel">
        <div style="width:60%">
          <label class="filter1"> Date Filter </label>
          <div class="row">
            <div class="col-lg-5">
              <div class="form-group floating-label mb-0">
                <input
                  type="text"
                  class="form-control floating-input calender from"
                  id="fromDate"
                  placeholder=" "
                />
                <i class="icon24 icon-calendar"></i>
                <label for="user-name">From</label>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="form-group floating-label mb-0">
                <input
                  type="text"
                  class="form-control floating-input calender to"
                  id="toDate"
                  placeholder=" "
                />
                <i class="icon24 icon-calendar"></i>
                <label for="user-name">To</label>
              </div>
            </div>
          </div>
          <label class="filter2"> User Filter </label>
          <div class="select-user">
            <multiselect
              v-model="currentUser"
              :options="reportUser"
              :searchable="true"
              :close-on-select="true"
              placeholder="Select User"
            ></multiselect>
          </div>
          <div class="filter3">
            <button
              class="filter-btn btn btn-primary"
              @click="applyFilterFn()"
            >
              Apply
            </button>
          </div>
        </div>
        <div style="width:40%">
          <label
            v-if="userLastSeen"
            style="font-weight:bold;padding-bottom:22px;padding-left:50%"
          > User Last Seen: {{userLastSeen}} </label>
          <bar-chart></bar-chart>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import navBar from "../components/Navbar.vue";
import { mapGetters } from "vuex";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import PageAlert from "../components/PageLevelAlert.vue";
import BarChart from "../components/BarChart";
import TinyDatePicker from "tiny-date-picker";
import { EventBus } from "../main.js";
import { fetchReportUser } from "../services/parentservices";
import Multiselect from "vue-multiselect";

export default {
  name: "Reports",
  components: {
    "page-alert": PageAlert,
    "ams-navbar": navBar,
    "bar-chart": BarChart,
    Multiselect
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  data() {
    return {
      fromDate: "",
      toDate: "",
      reportUser: [],
      currentUser: "",
      userLastSeen: ""
    };
  },
  created: function() {
    this.fetchReportUserFn();
  },
  mounted() {
    console.log(document.querySelector(".calender.from"));
    TinyDatePicker(document.querySelector(".calender.from"));
    TinyDatePicker(document.querySelector(".calender.to"));
    EventBus.$on("fetch-user-last-seen", payload => {
      console.log("payload --->", payload);
      this.userLastSeen =
        new Date(payload.userLastSeen[0].date).toLocaleDateString() +
        " " +
        payload.userLastSeen[0].time;
    });
  },
  methods: {
    applyFilterFn() {
      this.fromDate = document.querySelector(".calender.from").value;
      this.toDate = document.querySelector(".calender.to").value;
      if (!this.fromDate && !this.toDate && !this.currentUser) {
        errorEventNotify("Please apply the filter.", 1);
        return;
      }

      if (this.toDate == "" && this.fromDate == "" && this.currentUser) {
        EventBus.$emit("fetch-report-filter", {
          selectedUser: this.currentUser
        });
        return;
      }

      if (this.toDate != "" && this.fromDate != "" && this.currentUser) {
        EventBus.$emit("fetch-report-filter", {
          fromDate: this.fromDate,
          toDate: this.toDate,
          selectedUser: this.currentUser
        });
        return;
      }

      if (this.toDate != "" && this.fromDate != "") {
        EventBus.$emit("fetch-report-filter", {
          fromDate: this.fromDate,
          toDate: this.toDate
        });
        return;
      } else {
        errorEventNotify("Please select both the dates.", 1);
        return;
      }
    },
    fetchReportUserFn() {
      fetchReportUser({
        userId: this.userDetails._id
      }).then(response => {
        console.log("resp --->", response);
        if (!response._isError) {
          this.reportUser = response._result;
          // console.log("--->", this.reportUser);
        }
      });
    }
  }
};
</script>

<style scoped>
.panel {
  display: flex;
}
.filter-card {
  border-bottom: 0px;
}
.filter1 {
  font-weight: bold;
  margin-bottom: 20px;
}
.filter2 {
  padding-top: 20px;
  font-weight: bold;
}
.select-user {
  width: 40%;
}
.filter3 {
  padding-top: 35px;
}
.filter-btn {
  height: 35px;
  width: 10%;
  font-size: 15px;
}
</style>
