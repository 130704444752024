<template>
  <div class="fr-warapper">
    <ams-navbar />
    <page-alert />
    <div class="tab">
      <b-card no-body>
        <b-tabs card>
          <b-tab
            title="Add User"
            active
          >
            <div class="contact100-pic row">
              <div class="rotating col-lg-5">
                <img
                  src="../assets/images/Picture1.png"
                  alt="IMG"
                />
              </div>
              <b-form
                class="col-lg-6 pad-top"
                @submit="create"
              >
                <div class="row">
                  <div class="col-lg-6 form-group floating-label">
                    <input
                      type="email"
                      v-model="userData.email"
                      class="form-control floating-input"
                      required
                      placeholder=" "
                      autocomplete="off"
                    />
                    <label for>Email address</label>
                  </div>
                  <div class="col-lg-6 form-group floating-label">
                    <input
                      type="text"
                      pattern="[A-Za-z\s]{5,}"
                      v-model="userData.name"
                      required
                      class="form-control floating-input"
                      placeholder=" "
                      autocomplete="off"
                    />
                    <label for>Your Name</label>
                  </div>
                  <label class="col-lg-12">Internal/External User</label>
                  <div class="radio-group">
                    <input
                      v-model="userData.type"
                      type="radio"
                      value="internal"
                      id="option-one"
                      name="type"
                    />
                    <label
                      class="radio-label"
                      for="option-one"
                    >LDAP</label>
                    <input
                      v-model="userData.type"
                      value="external"
                      type="radio"
                      id="option-two"
                      name="type"
                    />
                    <label
                      class="radio-label"
                      for="option-two"
                    >DATABASE</label>
                  </div>
                  <label class="col-lg-12">Roles</label>
                  <div
                    class="radio-group"
                    v-for="(userRole, index) in userRoles"
                    :key="index"
                  >
                    <input
                      name="role"
                      type="radio"
                      v-model="userData.role"
                      :value="userRole.key"
                      :id="index"
                    />
                    <label
                      class="radio-label"
                      :for="index"
                    >{{ userRole.value }}</label>
                  </div>
                </div>
                <b-button
                  type="submit"
                  class="btn-submit"
                  variant="primary"
                >SUBMIT</b-button>
              </b-form>
            </div>
          </b-tab>
          <b-tab title="Job Level Edit">
            <change-job-ownership></change-job-ownership>
          </b-tab>
          <b-tab title="Users">
            <display-all-users></display-all-users>
          </b-tab>
          <b-tab title="Login Mode">
            <login-mode></login-mode>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import navBar from "../components/Navbar.vue";
import { mapGetters } from "vuex";
import { createUser, getUserRoles } from "./../services/parentservices.js";
import {
  successEventNotify,
  errorEventNotify
} from "./../assets/js/notifyAction.js";
import PageAlert from "../components/PageLevelAlert.vue";
import ChangeJobOwnership from "../components/ChangeJobOwnership.vue";
import DisplayAllUsers from "../components/DisplayAllUsers.vue";
import LoginMode from "../components/LoginMode.vue";
const USERTYPE = "internal";
// const INTENALID = "fractal.ai";

export default {
  components: {
    "page-alert": PageAlert,
    "ams-navbar": navBar,
    "change-job-ownership": ChangeJobOwnership,
    "display-all-users": DisplayAllUsers,
    "login-mode": LoginMode
  },
  data() {
    return {
      userData: {
        name: "",
        email: "",
        type: "internal",
        role: ""
      },
      roles: [
        { text: "Admin", value: "admin" },
        { text: "Steward", value: "steward" },
        { text: "SME", value: "sme" }
      ],
      types: [
        { text: "LDAP", value: "internal" },
        { text: "Database", value: "external" }
      ],
      selected: "first",
      options: [
        { text: "First radio", value: "first" },
        { text: "Second radio", value: "second" }
      ],
      userRoles: []
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  created: function() {
    this.getAllUserRoles();
  },
  methods: {
    create(evt) {
      evt.preventDefault();
      let internalUserId = this.userData.email.split("@")[1];
      if (
        this.userData.type === USERTYPE &&
        internalUserId !== process.env.VUE_APP_INTENALID
      ) {
        errorEventNotify("Select database to proceed.", 1);
        return;
      }
      createUser({ userObj: this.userData, userId: this.userDetails._id })
        .then(response => {
          if (response._isError) {
            errorEventNotify(
              "There was some Technical Issue, Please try again.",
              1
            );
          } else {
            if (response._result.success) {
              successEventNotify(response._result.msg, 1);
              this.userData = {
                name: "",
                email: "",
                type: "internal",
                role: ""
              };
            } else {
              errorEventNotify(response._result.msg, 1);
            }
          }
        })
        .catch(e => {})
        .finally(() => {});
    },
    getAllUserRoles() {
      getUserRoles().then(response => {
        this.userRoles = response._result;
      });
    }
  }
};
</script>

<style scoped>
.tab {
  margin: 20px;
}
.pad-top {
  padding-top: 55px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #0090c4;
}
.contact100-pic {
  width: 100%;
  padding-left: 55px;
  display: inline-flex;
}

.contact100-pic img {
  max-width: 100%;
  padding: 80px 0px 0px 90px;
}

.btn-submit {
  font-weight: bold;
  margin: 12px 5px;
  height: 35px !important;
  width: 15%;
}

input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.radio-label {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 20px;
  margin: 0px;
  font-size: 12px;
}

input[type="radio"]:checked + label {
  color: #fff;
  background: #0090c4;
}

label + input[type="radio"] + label {
  border-left: solid 3px lightgray;
}
.radio-group {
  border: solid 3px lightgray;
  display: inline-block;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #bcbcbd;
}
/* .rotating {
  transition: transform 1s ease-in-out;
}
.rotating:hover {
  transform: rotateZ(360deg);
} */
</style>
