<template>
  <div class="fr-warapper">
    <ams-navbar></ams-navbar>
    <div
      clas="container"
      style="padding:15px;display:flex"
    >
      <div style="width:20%;padding:0px 5px;">
        <ul class="global-replace-menu">
          <h3>Logs</h3>
          <li
            v-for="item in uniqueJobs"
            :key="item"
            @click="fetchJobLog(item)"
            class="job-logs"
            :style="currentlyClicked === item ? 'color:#0090c4;' : ''"
          >
            {{ item }}
          </li>
          <!-- <li class="job-logs">job 2</li>
          <li class="job-logs">job 3</li>
          <li class="job-logs">job 4</li> -->
        </ul>
      </div>
      <div style="width:80%">
        <table
          style="width:100%"
          id="stepTable"
          class="table-header-font"
        >
          <tr>
            <th>DATE</th>
            <th>TIME</th>
            <th>LOGLEVEL</th>
            <th>DESCRIPTION</th>
          </tr>
          <tr
            v-for="(log, index) in this.logsForTheJob"
            :key="index"
          >
            <td style="width : 10%">{{ log.date }}</td>
            <td style="width : 10%">{{ log.time }}</td>
            <td
              style="width : 25%; background-color"
              v-bind:style="[
                log.logLevel == 'INFO'
                  ? INFO
                  : log.logLevel == 'DEBUG'
                  ? DEBUG
                  : ERROR
              ]"
            >
              {{ log.logLevel }}
            </td>
            <td style="width : 75%">{{ log.message }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "../components/Navbar.vue";
import { getLogsForJob, getJobsLog } from "../services/parentservices.js";
import { mapGetters } from "vuex";

export default {
  components: {
    "ams-navbar": navBar
  },
  created() {
    this.fetchDinstictJobsLog();
    // this.fetchLogsForJob();
    this.toRefershLogs = setInterval(
      function() {
        this.fetchJobLog(this.currentlyClicked);
      }.bind(this),
      this.automaticRefreshTime
    );
  },
  mounted() {
    // if (this.currentlyClicked) {
    // console.log("mounted called");
    // }
  },
  data() {
    return {
      automaticRefreshTime: 10000,
      logsForTheJob: [],
      INFO: {
        color: "#0090c4"
      },
      DEBUG: {
        color: "yellow"
      },
      ERROR: {
        color: "red"
      },
      toRefershLogs: null,
      uniqueJobs: [],
      currentlyClicked: ""
    };
  },
  methods: {
    fetchDinstictJobsLog() {
      getJobsLog({
        userId: this.userDetails._id,
        userDetails: this.userDetails
      }).then(response => {
        console.log("resp job-->", response);
        this.uniqueJobs = response._result;
      });
    },
    // fetchLogsForJob() {
    //   let routeParam = this.$route.params.jobId;
    //   getLogsForJob({
    //     jobId: routeParam,
    //     userId: this.userDetails._id
    //   }).then(response => {
    //     console.log("resp -->", response);
    //     this.logsForTheJob = response._result;
    //   });
    // },
    fetchJobLog(processId) {
      console.log("process ---", processId);
      this.currentlyClicked = processId;
      if (this.currentlyClicked) {
        getLogsForJob({
          userId: this.userDetails._id,
          processId: processId
        }).then(response => {
          console.log("resp -->", response);
          this.logsForTheJob = response._result;
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      userDetails: "getUserObj"
    })
  },
  beforeDestroy() {
    clearInterval(this.toRefershLogs);
  }
};
</script>

<style scoped>
#stepTable {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#stepTable td,
#stepTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#stepTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#stepTable tr:hover {
  background-color: #ddd;
}

#stepTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e9e9e9;
  color: black;
}
.global-replace-menu {
  border: 1px solid lightgray;
}
.global-replace-menu h3,
.global-replace-menu h4 {
  border: 1px solid lightgray;
  padding: 8px;
  background-color: #e9e9e9;
}
.job-logs {
  padding: 10px;
  color: black;
  font-weight: bold;
}
</style>
